import styled from "styled-components";
import {
  FormControl,
  Checkbox,
  Paper,
  RadioGroup,
  FormControlLabel,
  Box
} from "@material-ui/core";
import CustomHeading from "components/common/CustomHeading";
import { styledTheme } from "../../../../../styles/StyledTheme";

export const PaperWrapper = styled(Paper)`
  font-size: 22px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 18px;
  min-height: calc(100% - 50px);

  .wrapper {
    padding: 40px;

    p {
      font-size: 16px;
      margin: 0px !important;
    }

    hr {
      margin-bottom: 0px !important;
    }

    label {
      font-size: 10px;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.defaultDark};
    }
  }
`;

export const CommonCheckbox = styled(Checkbox)`
  color: #3182ce;
`;

export const CommonFormControl = styled(FormControl)`
  width: 100%;
`;

export const CommonFormControlLabel = styled(FormControlLabel)`
  [class*="MuiFormControlLabel-label"] {
    font-family: Inter;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 20px;
    color: #26272c !important;
  }
`;

export const HeadingSpacing = styled(CustomHeading)`
  margin-bottom: 20px;
`;

export const OptionWrapper = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
`;

export const BoxWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50px;
  border-radius: 12px;
  color: white;
  width: 100%;
  border: 2px solid;

  .icon {
    position: absolute;
    left: -14px;
    cursor: pointer;
  }

  .icon-disabled {
    position: absolute;
    left: -14px;
  }

  .MuiSvgIcon-root {
    width: 30px;
    height: 0%;
    color: black;
    background-color: white;
    border-radius: 100%;
  }

  .text {
    font-size: 14px;
    font-weight: bold;
    cursor: default;
  }

  .notification-icon {
    margin-right: 10px;
  }

  &.has-margin-top {
    margin-top: 24px;
  }

  &.inactive { 
    background-color: #F0F5F8; 
    border-color: #96ACB9; 
    color: #96ACB9; 
  }

  &.active {
    background-color: ${styledTheme.colors.secondaryDarker};
  }
`;