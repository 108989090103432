import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import styled from "styled-components";

export const Container = styled(Grid)``;

export const SubscriptionModal = styled(Modal)``;

export const Wrapper = styled.div`
   scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0.01px;
  }
`;

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 430,
    scrollMarginTop: 300,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 60,
    borderRadius: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "80%",
    overflowY: "scroll",
  },
}));
