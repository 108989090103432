import React, { useState, useEffect, useContext, useRef } from "react";
import { Grid, IconButton } from "@material-ui/core";
import styled from "styled-components";
import MainVideoimage from "images/Main-video-image.jpg";
import { ConsultationContext } from 'contexts'
import { API, graphqlOperation } from "aws-amplify";
import { onUpdateConsultation } from 'gql/subscriptions';
import VideoSlider from "./Slider";
import {
  BTEndCallGreenWhite,
} from "icons";
import VideoParticipant from "./VideoParticipant";
import { GStyledH3, GStyledH5, GStyledB, GStyledP } from 'components/common/StyledComponents'
import { styledTheme } from 'styles/StyledTheme'
import Timer from "components/common/Timer"
import {AttorneyVideoWrapper } from './video.styles'

const GridWrapper = styled(Grid)`
  position: relative;
  height: 80%;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  background: black;
  @media (max-width: 1279px) {
    height: auto;
    margin-top: 20px;
  }
  margin-top: 10px;
  // background-image: URL(${MainVideoimage});
  // background-position: center;
  // background-size: cover;
  .images {
    .mute {
      height: 40px;
    }
    .disconnect {
      height: 80px;
    }
    .video-button.control {
      background-size: contain;
      background-repeat: no-repeat;
      height: 50px;
      width: 50px;
    }
    .speaker-btn {
      background-image: url(${require('icons/multimedia/muteTrans.svg')});

      &.active {
        background-image: url(${require('icons/multimedia/muteFill.svg')})
      }
    }
    .mic-btn {
      background-image: url(${require('icons/multimedia/micOff.svg')});

      &.active {
        background-image: url(${require('icons/multimedia/micTrans.svg')})
      }
    }
  }

  .video-button {
    cursor: pointer;
    transition: all 0.25s;
    display: inline-block;
    vertical-align: middle;
  }
`
const StyledIconBtn = styled(IconButton)`
  .overlay, .endCallOverlay {
    position: absolute;
    border-radius: 50%;
    opacity: 0.3;
    transition: .2s ease;
  } 
  .overlay { 
    height: 88%;
    width: 90%;
    background-color: white;
  }
  .endCallOverlay {
    opacity: 0;
    height: 80%;
    width: 80%;
    ${() => `background-color: ${styledTheme.colors.warning}`}
  }
  :hover .overlay, :hover .endCallOverlay {
    opacity: 1;
  }
`
const ClientVideo = styled(VideoParticipant)`
  background-color: black;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
`
const MyVideo = styled(VideoParticipant)`
  width: 100%;
  padding: 10px;
  border-radius: 18px;
  height: 160px;
  position: relative;
  z-index: 999;
`
const StyledGrid = styled(Grid)`
  position: relative;
  z-index: 999;
  background: black;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
`
const StyledP = styled.p`
  margin-top: 30%;
  font-size: 12px;
  ${() => `color: ${ styledTheme.colors.secondaryDark }`}
`
const StyledDiv = styled.div`
  border-top: ${ `1px solid ${ ({ theme }) => theme.colors.secondary }` };
  width: 60px;
  margin-bottom: 5px;
`
const StyledLabel = styled.label`
  color: ${ ({ theme }) => theme.colors.secondary };
  font-size: 12px;
`

const VideoConsultation = ({details, room, participant}) => {
  const {
    handleComplete,
    activeConsultation, 
    onConsultationComplete,
    setLastSeen,
    lastSeen,
    isClientOnline,
  } = useContext(ConsultationContext);

  const [isMuted, setMuted] = useState(false)
  const [toggleMute, setToggleMute] = useState(false)
  const [isSpeakerOn, setIsSpeakerOn] = useState(true)
  const [toggleSpeaker, setToggleSpeaker] = useState(false)
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [actualLength, setActualLength] = useState(0);

  let {
    client : { firstName, lastName},
    timeLimit,
    consultation
  } = details ;

  useEffect(() => {
      let subscription = API.graphql(graphqlOperation(onUpdateConsultation))
        .subscribe({
          next: async (consultation) => {
            const updatedConsultation =
              consultation.value.data.onUpdateConsultation;
              const { actualLength, purchasedLength } = updatedConsultation;
              setActualLength(actualLength);
            if (
              updatedConsultation &&
              updatedConsultation.id === activeConsultation.id
            ) {
              if (updatedConsultation.status === 'Completed') {
                onConsultationComplete(updatedConsultation.actualLength);
              } else {
                setLastSeen(
                  lastSeen !== updatedConsultation.lastOnlineClient ? 
                    updatedConsultation.lastOnlineClient : 
                    null
                );
              }
            }
          }
        }
      )
      return () => {
        subscription.unsubscribe();
      }
  }, [])

  useEffect(() => {
    if (room) {
      setIsSpeakerOn(true);
    }
  }, [room])

  return (
    <GridWrapper container item xs={12} md={12} lg={12}>
      {
        participant && 
        <ClientVideo 
          r="remote"
          key={participant.sid}
          participant={participant}
          toggleSpeaker={toggleSpeaker}
          setIsSpeakerOn={setIsSpeakerOn}
          isSpeakerOn={isSpeakerOn}
          setMuted={() => {}}/>
      }
      
      {
        room && (
        <AttorneyVideoWrapper container xs={isSliderOpen ? 8 : 12} 
          spacing={0}
          alignItems="center"
          justify="center"
        >
          <Grid item xs={4}>
            <MyVideo
              r="local"
              key={room.localParticipant.sid}
              participant={room.localParticipant}
              toggleSpeaker={toggleSpeaker}
              setIsSpeakerOn={setIsSpeakerOn}
              isSpeakerOn={isSpeakerOn}
              toggleMute={toggleMute}
              setMuted={setMuted}
              isMuted={isMuted}
            />
          </Grid>
        </AttorneyVideoWrapper>
      )}
      {!isSliderOpen && (
        <StyledGrid container alignItems="flex-end">
          <Grid item xs={12} container justify="center" alignItems="center"
            style={{
              background: 'linear-gradient(360deg, rgba(66,184,131,0.47) 0%, rgba(255,255,255,0) 100%)',
              borderBottomLeftRadius: '18px',
              borderBottomRightRadius: '18px',
            }}
          >
            <Grid
              xs={12}
              sm={6}
              md={3}
              item
              container
              justify="center"
              alignItems="center"
            >
              <GStyledH3
                fontSize= '17px'
                fontWeight= 'bold'
                color={ styledTheme.colors.white }
              >
                {firstName} {lastName} <span style={{fontSize: 12}}>({isClientOnline ? 'Online' : 'Offline'})</span>
              </GStyledH3>
            </Grid>
            <Grid item xs={12} sm={6} md={6} container justify="center" alignItems="center">
              <Grid className="images">
                <StyledIconBtn
                  size="small"
                  onClick={() => {
                    setToggleMute(!toggleMute)
                  }}
                >
                  <span
                    className="mute video-button mic-btn control active"
                    alt="mute"
                  />
                  <div className='overlay'>
                    <StyledP>{ isMuted ? 'unmute' : 'mute' }</StyledP> 
                  </div>
                </StyledIconBtn>
                <StyledIconBtn
                  onClick={handleComplete}
                >
                  <img
                    className="disconnect"
                    src={BTEndCallGreenWhite}
                    alt="disconnect"
                  />
                  <div className='endCallOverlay'>
                    <GStyledP marginTop='35%' fontSize='16px' color={styledTheme.colors.white}>end call</GStyledP> 
                  </div>
                </StyledIconBtn>
                <StyledIconBtn
                  size="small"
                  onClick={() => {
                    setToggleSpeaker(!toggleSpeaker)
                  }}
                >
                  <span
                    className="mute video-button speaker-btn control"
                    alt="speaker"
                  />
                  <div className='overlay'>
                    <StyledP>{ isSpeakerOn ? 'on' : 'off' }</StyledP>
                  </div>
                </StyledIconBtn>
              </Grid>
            </Grid>

            <Grid
              xs={12}
              md={3}
              item
              container
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                xs={4}
                className="mr-4"
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{ textAlign: "center" }}
              >
                <Timer initialTime={actualLength} timeLimit={timeLimit} />
                <StyledDiv />

                <StyledLabel>
                  TIME
                </StyledLabel>
              </Grid>
              { 
              details.payment?.calculatedAttorneyAmount !== 0 && 
                <Grid
                  item
                  xs={4}
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  style={{ textAlign: "center" }}
                >
                  <GStyledH5 color={ styledTheme.colors.white }>
                    <GStyledB fontSize= '13px' marginRight= '3px'> $</GStyledB>{details.payment?.calculatedAttorneyAmount.toFixed(2)}
                  </GStyledH5>
                  <StyledDiv />
                  <StyledLabel>
                    EARNINGS
                  </StyledLabel>
                </Grid>
              }
            </Grid>
          </Grid>
        </StyledGrid>
      )}
      {isSliderOpen && (
        <Grid item xs={4}>
          <VideoSlider
            isSliderOpen={isSliderOpen}
            // handleToggleSliderView={handleToggleSliderView}
          />
        </Grid>
      )}
    </GridWrapper>
  )
};

export default VideoConsultation;
