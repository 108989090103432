import styled from "styled-components";
import Image from "../../../Image";

export const Container = styled.div``;

export const GoogleButton = styled(Image)`
  img {
    cursor: pointer;
    max-width: 270px;
  }
`;
