export enum ConsultationFlow {
  COMMON = "Common",
  ONBOARDING = "Onboarding",
}

export enum PaymentMethod {
  FREE = "Free",
}

export enum ConnectionMethod {
  TEXT = "Text",
  VOICE = "Voice",
  VIDEO = "Video",
}

export enum ConsultationStatus {
  PENDING = "Pending",
  SCHEDULED = "Scheduled",
  READY = "Ready",
  IN_PROGRESS = "InProgress",
  COMPLETE = "Completed",
  PAUSE = "Paused",
  CANCELED = "Canceled",
}

export enum FutureConsultationStatus {
  SCHEDULED = "Scheduled",
  COMPLETE = "Completed",
  CANCELED = "Canceled",
}

export enum DateTimeScheduledRequestBy {
  CLIENT = "Client",
  ATTORNEY = "Attorney",
}

export enum DateTimeScheduledRequestStatus {
  PENDING = "Pending",
  ACCEPT = "Accept",
  REJECT = "Reject",
}
