import React from 'react'
import { Button } from '@material-ui/core'
import styled from 'styled-components'

const ButtonWrapper = styled.div`
width:100%;
margin:auto;
.MuiButton-contained {
width:100%;
padding:10px 0;
font-size:10px;
background-color: ${ ({ theme }) => theme.colors.secondary };
box-shadow: none;
}
`

export default function ConsulationButton({children,...otherProps}) {
    return (
        <ButtonWrapper >
            <Button  variant='contained' color='primary' {...otherProps}>
                {
                    children
                }
            </Button>   
        </ButtonWrapper>
    )
}
