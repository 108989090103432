import ButtonWithIcon from "components/common/ButtonGroup/ButtonWithIcon";
import { useState } from "react";
import { styledTheme } from "styles/StyledTheme";
import { ButtonWrapper, Container } from "./connectionMethodSelector.styles";

interface IConnectionMethodSelectorProps {
  onChange: (
    selectedText: boolean,
    selectedVoice: boolean,
    selectedVideo: boolean
  ) => void;
}

const ConnectionMethodSelector = (props: IConnectionMethodSelectorProps) => {
  const { onChange } = props;
  const [selectedText, setSelectedText] = useState<boolean>(true);
  const [selectedVoice, setSelectedVoice] = useState<boolean>(true);
  const [selectedVideo, setSelectedVideo] = useState<boolean>(true);
  const isActiveAll = selectedText && selectedVoice && selectedVideo;

  return (
    <Container>
      <ButtonWrapper>
        <ButtonWithIcon
          onClick={() => {
            onChange(!isActiveAll, !isActiveAll, !isActiveAll);
            setSelectedText(!isActiveAll);
            setSelectedVoice(!isActiveAll);
            setSelectedVideo(!isActiveAll);
          }}
          isActive={isActiveAll}
          color={styledTheme.colors.white}
          inActiveColor={styledTheme.colors.secondaryDark}
          backgroundColor={
            isActiveAll
              ? styledTheme.colors.secondaryDark
              : styledTheme.colors.white
          }
          iconName="fluent_square-multiple-white.svg"
          inActiveIconName="fluent_square-multiple-blue.svg"
          label="All"
          iconWidth="24px"
          borderColor={styledTheme.colors.secondaryDark}
          inActiveBorderColor={styledTheme.colors.secondaryDark}
        />
      </ButtonWrapper>
      <ButtonWrapper>
        <ButtonWithIcon
          onClick={() => {
            onChange(!selectedText, selectedVoice, selectedVideo);
            setSelectedText(!selectedText);
          }}
          isActive={selectedText}
          color={styledTheme.colors.white}
          inActiveColor={styledTheme.colors.secondaryDark}
          borderColor="#225AA9"
          inActiveBorderColor={styledTheme.colors.secondaryDark}
          backgroundColor={selectedText ? "#225AA9" : styledTheme.colors.white}
          iconName="text-white.svg"
          inActiveIconName="text-green.svg"
          label="Text"
          iconWidth="24px"
        />
      </ButtonWrapper>
      <ButtonWrapper>
        <ButtonWithIcon
          onClick={() => {
            onChange(selectedText, !selectedVoice, selectedVideo);
            setSelectedVoice(!selectedVoice);
          }}
          isActive={selectedVoice}
          color={styledTheme.colors.white}
          inActiveColor={styledTheme.colors.secondaryDark}
          borderColor="#287EC7"
          inActiveBorderColor={styledTheme.colors.secondaryDark}
          backgroundColor={selectedVoice ? "#287EC7" : styledTheme.colors.white}
          iconName="audio-white.svg"
          inActiveIconName="audio-green.svg"
          label="Voice"
          iconWidth="24px"
        />
      </ButtonWrapper>
      <ButtonWrapper>
        <ButtonWithIcon
          onClick={() => {
            onChange(selectedText, selectedVoice, !selectedVideo);
            setSelectedVideo(!selectedVideo);
          }}
          isActive={selectedVideo}
          color={styledTheme.colors.white}
          inActiveColor={styledTheme.colors.secondaryDark}
          borderColor="#5390D2"
          inActiveBorderColor={styledTheme.colors.secondaryDark}
          backgroundColor={selectedVideo ? "#5390D2" : styledTheme.colors.white}
          iconName="video-white.svg"
          inActiveIconName="video-green.svg"
          label="Video"
          iconWidth="24px"
        />
      </ButtonWrapper>
    </Container>
  );
};

export default ConnectionMethodSelector;
