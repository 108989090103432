import axios from "axios";
import { IAccessToken } from "interfaces";
import moment from "moment";
import { IRequestHeader } from "types";
import { getLocalStorage, setLocalStorage } from "utils/common/storage";
import { Auth } from "aws-amplify";
import { StorageKeys } from "../constant/enum";

const { REACT_APP_API_URL } = process.env;

export const api = axios.create({
  baseURL: REACT_APP_API_URL,
});

export async function auth(): Promise<IRequestHeader> {
  if (typeof window === "undefined") {
    return {};
  }

  const accessToken: IAccessToken = getLocalStorage(
    StorageKeys.ATTORNEY_ACCESS_TOKEN
  );

  if (
    !accessToken?.payload?.exp ||
    moment.unix(accessToken?.payload?.exp).isBefore(moment())
  ) {
    const currentSession = await Auth.currentSession();
    const currentAccessToken = currentSession.getAccessToken();
    setLocalStorage(StorageKeys.ATTORNEY_ACCESS_TOKEN, currentAccessToken);
    return { authorization: `Bearer ${currentAccessToken?.getJwtToken()}` };
  }

  return { authorization: `Bearer ${accessToken?.jwtToken}` };
}
