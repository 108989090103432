import { IOption } from "interfaces";

export const unsubscribeOptions: IOption<string>[] = [
  {
    label: "My financial situation changed",
    value: "1",
  },
  {
    label: "I did not receive enough long term clients",
    value: "2",
  },
  {
    label: "There were technical issues with the Dashboard",
    value: "3",
  },
  {
    label: "I no longer need the service",
    value: "4",
  },
  {
    label: "Some other reasons. I’ll explain below",
    value: "5",
  },
];
