import { IActiveRequest } from "interfaces/acceptConsultationRequest";
import { Fragment } from "react";
import Loader from "react-loader-spinner";
import { styledTheme } from "styles/StyledTheme";
import { getSubmitButtonLabel, isDisableSubmitButton } from "../../utils";
import {
  ClientRescheduleLabel,
  RequestChangeButton,
  LoaderWrapper,
  CancelButton,
} from "./rescheduleAccept.styles";

interface IRescheduleAcceptProps {
  selectedActiveRequest: IActiveRequest;
  isLoading: boolean;
  setOpenRejectRescheduleModal: (openCancelModal: boolean) => void;
  onClickAccept: () => void;
}

const RescheduleAccept = (props: IRescheduleAcceptProps) => {
  const {
    selectedActiveRequest,
    isLoading,
    setOpenRejectRescheduleModal,
    onClickAccept,
  } = props;

  return (
    <Fragment>
      <ClientRescheduleLabel>
        {`${selectedActiveRequest?.case?.client?.firstName} ${selectedActiveRequest?.case?.client?.lastName} rescheduled appointment`}
      </ClientRescheduleLabel>
      <RequestChangeButton
        disabled={isDisableSubmitButton(selectedActiveRequest, isLoading)}
        onClick={onClickAccept}
      >
        {isLoading && (
          <LoaderWrapper>
            <Loader
              type="Oval"
              color={styledTheme.colors.white}
              height={14}
              width={14}
            />
          </LoaderWrapper>
        )}
        {getSubmitButtonLabel(selectedActiveRequest)}
      </RequestChangeButton>
      <CancelButton onClick={() => setOpenRejectRescheduleModal(true)}>
        Reject schedule
      </CancelButton>
    </Fragment>
  );
};

export default RescheduleAccept;
