import React, { useRef, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import styled, { keyframes } from 'styled-components'
import { urlRegex, convertTextToUrl } from './utils'

const msgAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
  }
  100% {
    transform: scale(1);
  }
`

const ReceivedTextWrapper = styled(Grid)`
  text-align: left;
  width:70% ;
  .time{
    font-size:8px;
    font-weight:bold;
    color:#BCC7D3;
    margin-top:4px ;
  }
`
const ReceivedTextBody = styled.div`
  background-color: #ECF5FA;
  padding:10px 20px;
  font-size:12px;
  border-radius:12px;
  word-break: break-all;
`

const BodyWrapper = styled.div`
  width: fit-content;
  margin-right: auto;
  animation: 0.15s ${msgAnimation} ease-out;
`

const Image = styled.img`
  width: 17rem;
  border-radius: 16px 16px 16px 0;
`

const ReceivedText = ({ data, onViewImage }) => {
  const { msg, time, contentType, imageUrl } = data;
  const textRef = useRef(null)

  useEffect(() => {
    if (msg) {
      textRef.current.innerHTML = msg?.replace(urlRegex, (matchedText) => convertTextToUrl(matchedText));
    }
  }, [msg])

  return (
    <ReceivedTextWrapper item xs={12}>
      <BodyWrapper>
        {
          contentType === 'media'
            ? <Image src={imageUrl} onClick={() => onViewImage(imageUrl)} />
            : <ReceivedTextBody ref={textRef}>{msg}</ReceivedTextBody>
        }
        <p className="time">{time}</p>
      </BodyWrapper>
    </ReceivedTextWrapper>
  )
}

export default ReceivedText