import { Grid } from "@material-ui/core";
import Image from "components/common/Image";
import styled from "styled-components";
import { breakpoints } from "../../../../../styles/layout";

export const Container = styled(Grid)`
  position: relative;
`;

export const GuidelinesWrapper = styled(Grid)`
  ${breakpoints("padding-top", [
    {
      xxl: "10px",
    },
    {
      xl: "10px",
    },
    {
      lg: "10px",
    },
    {
      md: "10px",
    },
    {
      sm: "10px",
    },
    {
      xs: "30px",
    },
  ])}
`;

export const GuidelinesBackground = styled.div`
  background-color: #287ec7;
  min-height: 134px;
  border-radius: 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const UserImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 24px;

  ${breakpoints("display", [
    {
      xxl: "block",
    },
    {
      xl: "block",
    },
    {
      lg: "none",
    },
    {
      md: "none",
    },
    {
      sm: "none",
    },
    {
      xs: "none",
    },
  ])}
`;

export const Description = styled(Grid)`
  padding-right: 32px;

  ${breakpoints("padding-left", [
    {
      xxl: "calc(256px + 28px)",
    },
    {
      xl: "calc(256px + 28px)",
    },
    {
      lg: "32px",
    },
    {
      md: "32px",
    },
    {
      sm: "32px",
    },
    {
      xs: "32px",
    },
  ])}
`;

export const TitleText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  cursor: default;

  padding-top: 30px;
  padding-bottom: 8px;
`;

export const DescriptionText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;

  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: default;

  padding-bottom: 8px;
`;

export const SignatureText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: default;

  padding-left: 12px;
  padding-bottom: 22px;
`;
