export function dayIndexMapping(dayIndex: number, isFullDay?: boolean): string {
  const dayLabel: string[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dayLabelFull: string[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  if (dayIndex > -1 && dayIndex < 7) {
    return isFullDay ? dayLabelFull[dayIndex] : dayLabel[dayIndex];
  }
  return "";
}
