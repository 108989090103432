import { IAutoSchedulePackageDetail } from "interfaces/attorney/leadDistribution";

export enum NotificationMethodEnum {
  EMAIL = "EMAIL",
  SMS = "SMS",
}

export enum AttorneyConsultationRequestStatus {
  PENDING = "Pending",
  ACCEPT = "Accept",
  REJECT = "Reject",
}

export enum EmailNotificationStatus {
  ON = "EMAIL ON",
  OFF = "EMAIL OFF",
}

export enum SMSNotificationStatus {
  ON = "SMS ON",
  OFF = "SMS OFF",
}

export enum SignUpPlatform {
  CLIENT = "client",
  ATTORNEY = "attorney",
}

export enum AutoSchedulePackage {
  PackageZero = "PackageZero",
  PackageOne = "PackageOne",
  PackageTwo = "PackageTwo",
  PackageThree = "PackageThree",
}

export const AutoSchedulePackageDetail: Record<
  AutoSchedulePackage,
  IAutoSchedulePackageDetail
> = {
  [AutoSchedulePackage.PackageZero]: {
    label: "0 leads/week",
    dayLimit: 0,
    weekLimit: 0,
    estimatedCost: 0,
  },
  [AutoSchedulePackage.PackageOne]: {
    label: "5 leads/week",
    dayLimit: 1,
    weekLimit: 5,
    estimatedCost: 225,
  },
  [AutoSchedulePackage.PackageTwo]: {
    label: "10 leads/week",
    dayLimit: 2,
    weekLimit: 10,
    estimatedCost: 450,
  },
  [AutoSchedulePackage.PackageThree]: {
    label: "15 leads/week",
    dayLimit: 3,
    weekLimit: 15,
    estimatedCost: 675,
  },
};
