import { IStripeSubscription } from "interfaces/subscription";
import { IStripeCustomer } from "interfaces/user";
import { action, observable, makeObservable } from "mobx";
import stripeService from "services/stripeService";
import { validateStrategicPartner as validateStrategicPartnerAPI } from "utils/common";
import { checkStripeAccountExisted } from "API/stripe";
import { IRootStore } from "./rootStore";

class SubscriptionStore {
  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      stripeSubscription: observable,
      stripeCustomer: observable,
      subscriptionError: observable,
      isLoading: observable,
      isStrategicPartner: observable,
      stripeAccountExisted: observable,
      getSubscriptionInformation: action,
      validateStrategicPartner: action,
      setSubscriptionError: action,
      setLoading: action,
      syncStripeAccountStatus: action,
    });
    this.rootStore = rootStore;
  }

  public stripeSubscription: IStripeSubscription = {} as IStripeSubscription;

  public stripeCustomer: IStripeCustomer = {} as IStripeCustomer;

  public subscriptionError = "";

  public isLoading = false;

  public isStrategicPartner = false;

  public stripeAccountExisted = false;

  public async getSubscriptionInformation(
    stripeSubscriptionId: string
  ): Promise<void> {
    if (!stripeSubscriptionId) {
      return;
    }

    const stripeSubscription = await stripeService.retrieveAttorneySubscription(
      stripeSubscriptionId
    );
    this.stripeSubscription = stripeSubscription;

    const stripeCustomer = await stripeService.retrieveCustomer(
      stripeSubscription.customer
    );

    this.stripeCustomer = stripeCustomer;
  }

  public async validateStrategicPartner(emailWork: string): Promise<void> {
    this.isStrategicPartner = await validateStrategicPartnerAPI(emailWork);
  }

  public setSubscriptionError(subscriptionError: string): void {
    this.subscriptionError = subscriptionError;
  }

  public setLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  public async syncStripeAccountStatus(): Promise<boolean> {
    const { isExisted } = await checkStripeAccountExisted();
    this.stripeAccountExisted = isExisted;
    return this.stripeAccountExisted;
  }
}

export default SubscriptionStore;
