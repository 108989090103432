import Landing from 'pages/Landing'
import Registration from 'pages/Registration'
import Dashboard from 'pages/Dashboard'
import Confirmation from 'pages/Confirmation'
import LoginSignup from './pages/Landing/LoginSignup/index.tsx';
import Signup from './pages/Landing/Signup/index.tsx';

const ROUTES = [
  {
    path: '/',
    title: 'Login',
    component: Landing,
    exact: true,
    auth: false
  },
  {
    path: '/registration/*',
    initialPath: '/registration/user-details',
    title: 'Registration',
    component: Registration,
    auth: false
  },
  {
    path: '/dashboard',
    title: 'Dashboard',
    component: Dashboard,
    auth: false
  },
  {
    path: '/confirmation',
    title: 'Confirmation',
    component: Confirmation,
    auth: false
  },
  {
    path: '/embed/signup',
    component: LoginSignup,
    auth: false
  },
  {
    path: '/embedded/signup',
    component: Signup,
    auth: false
  }
]

export default ROUTES
