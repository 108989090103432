import { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import HomeHeader from "./Header";
import TimeSpendGraph from "./TimeSpendGraph";
import LastInteractions from "./LastInteractions";
import { GuyLaptopBannerImg } from "icons";
import { UserContext } from "../../../contexts";
import { fetchMixData } from "../../../services/consultationService";
import { useConsultationSubscription } from "hooks";
import { BottomBanner, GridWrapper, StyledA, StyledSpan, StyledLink, FooterHeader } from './home.styles';


const DashboardHome = () => {
  const [cases, setCases] = useState([]);
  const [mixConsultations, setMixConsultations] = useState([]);
  const [userInfo] = useContext(UserContext);

  useConsultationSubscription();

  useEffect(() => {
    if (userInfo.id) {
      getData(userInfo.id);
    }
  }, [userInfo])

  async function getData(attorneyId) {
    let tmp = await fetchMixData(attorneyId);
    setCases(tmp);
    setMixConsultations(tmp);
  }

  return (
    <GridWrapper item container xs={12}>
      <HomeHeader cases={cases} />
      <Grid item xs={12} container justify="center">
        <Grid item xs={12} md={12} lg={8}>
          <Grid item xs={12} md={12} lg={11}>
            <TimeSpendGraph cases={cases} mixConsultations={mixConsultations} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Grid item xs={12}>
            <LastInteractions cases={cases} />
          </Grid>
        </Grid>
      </Grid>
      <FooterHeader item xs={12} container>
        <BottomBanner item container xs={12} justify="space-between" alignItems="center">
          <Grid item xs={3}>
            <img className="img" src={GuyLaptopBannerImg} alt="productive" />
          </Grid>
          <Grid item className="text" xs={6} sm={7} >
            Need help? Visit the
            <StyledLink to="/dashboard/help"> <StyledSpan>Help</StyledSpan> </StyledLink>
            page for Frequently Asked Questions.
            <StyledA target="_blank" href="https://legalq.io/attorney-terms-of-use/"> <StyledSpan>Terms of Use</StyledSpan> </StyledA>
            and
            <StyledA target="_blank" href="https://legalq.io/privacy-policy/"> <StyledSpan>Privacy Policy</StyledSpan> </StyledA>
          </Grid>
          {/* <Grid item xs={3}></Grid> */}
        </BottomBanner>
      </FooterHeader>
    </GridWrapper>
  );
};

export default DashboardHome;
