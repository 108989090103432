import { Button, ButtonProps } from "@material-ui/core";
import Icon from "components/common/Icon";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Button)<
  ButtonProps & {
    customColor: string;
    isOutline: boolean;
    isDisabled: boolean;
  }
>`
  display: flex;
  flex-direction: row;
  width: fit-content;
  min-width: 263px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.isOutline ? styledTheme.colors.white : props.customColor} !important;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 12px;
  color: ${(props) =>
    props.isOutline ? props.customColor : styledTheme.colors.white} !important;
  border: 1px solid ${(props) => props.customColor};
  cursor: ${(props) =>
    props.isDisabled ? "not-allowed" : "pointer"} !important;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  &:hover {
    background-color: ${(props) => props.customColor};
  }
`;

export const ButtonIcon = styled(Icon)`
  padding-right: 8px;
`;

export const ButtonLabel = styled.div<{
  customColor: string;
  isOutline: boolean;
}>`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.8px;
  color: ${(props) =>
    props.isOutline ? props.customColor : styledTheme.colors.white};
`;
