import React, { useRef, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { urlRegex, convertTextToUrl } from './utils'
const NoticeWrapper = styled(Grid)`
  text-align:center;
  width: 35%;
  margin:auto;
  font-size:12px;
  color: ${ ({ theme }) => theme.colors.secondaryDark };
  padding:10px 20px;
  background-color: ${ ({ theme }) => theme.colors.mainDark };
  border-radius:18px;
  @media(max-width:1279px){
    width: 50%;
  }
  word-break: break-all;
  margin-bottom:20px;

`

const NoticeMsg = ({text}) => {
  const textRef = useRef(null)

  useEffect(() => {
    textRef.current.innerHTML = text.replace(urlRegex, (matchedText) => convertTextToUrl(matchedText));
  }, [text])
  
  return (
    <NoticeWrapper>
      <div ref={textRef}>
        {text}
      </div>
      
    </NoticeWrapper>
  )
}

export default NoticeMsg