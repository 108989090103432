import { Button, Grid, Input } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled(Grid)`
  display: flex;
  flex-direction: row;

  padding-top: 22.5px;
  padding-bottom: 22.5px;
  padding-left: 48px;
`;

export const CardNumberWrapper = styled(Grid)`
  input{
    padding-top: 0;
    padding-bottom: 0;
    height: fit-content;
    line-height: 39px;
  }
`;

export const CardNumberLabel = styled.div`
  padding-top: 8px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: #9b9ca5 !important;
`;

export const CardNumberInput = styled(Input)`
  width: 100%;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 46px;
  color: #26272c !important;

  &:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  }
`;

export const BillingTimeWrapper = styled(Grid)`
  input{
    padding-top: 0;
    padding-bottom: 0;
    height: fit-content;
    line-height: 39px;
  }
  margin-left: 44px;
`;

export const BillingTimeLabel = styled.div`
  padding-top: 8px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: #9b9ca5;
`;

export const BillingTimeInput = styled(Input)`
  width: 100%;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 46px;
  color: #26272c !important;

  &:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  }
`;

export const SubscribeWrapper = styled(Grid)`
  margin-left: 44px;
`;

export const SubscribeButton = styled(Button)`
  border-radius: 8px;

  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  text-align: center;
  letter-spacing: 1.2px;
  text-transform: uppercase;

  background-color: #a0aec0;
  color: #ffffff !important;

  padding-top: 19px;
  padding-bottom: 17px;
  padding-left: 30.37px;
  padding-right: 30.37px;

  &:hover {
    background-color: #a0aec0;
    color: #ffffff !important;
  }
`;
