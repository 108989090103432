export enum SubscribeStatus {
  UN_SUBSCRIBED = "Unsubscribed",
  SUBSCRIBED = "Subscribed",
  SUBSCRIBE = "Subscribe",
  DETAILS = "Details",
  LOADING = "Loading",
}

export enum StripeSubscriptionStatus {
  CANCELED = "canceled",
  ACTIVE = 'active'
}
