import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import Icon from "components/common/Icon";
import { breakpoints } from "styles/layout";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)<{ blockWrapperQty: boolean }>`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  justify-content: space-between;
  padding-top: 22.5px;
  padding-bottom: 22.5px;
  ${(props) => (props.blockWrapperQty ? 
    breakpoints("grid-template-columns", [
      {
        xxl: "33% 33% 33%",
      },
      {
        xl: "33% 33% 33%",
      },
      {
        lg: "33% 33% 33%",
      },
      {
        md: "42% 58%",
      },
      {
        sm: "100%",
      },
      {
        xs: "100%",
      },
    ])
     : 
     breakpoints("grid-template-columns", [
      {
        xxl: "50% 50%",
      },
      {
        xl: "50% 50%",
      },
      {
        lg: "33% 33%",
      },
      {
        md: "42% 58%",
      },
      {
        sm: "100%",
      },
      {
        xs: "100%",
      },
    ]))
  }
`;

export const DistributionInformation = styled(Grid)`
  display: flex;

  ${breakpoints("padding-top", [
    {
      xxl: "0px",
    },
    {
      xl: "0px",
    },
    {
      lg: "8px",
    },
    {
      md: "8px",
    },
    {
      sm: "8px",
    },
    {
      xs: "8px",
    },
  ])}

  ${breakpoints("flex-direction", [
    {
      xxl: "row",
    },
    {
      xl: "row",
    },
    {
      lg: "column",
    },
    {
      md: "column",
    },
    {
      sm: "column",
    },
    {
      xs: "column",
    },
  ])}
`;

export const PackageSelection = styled(Grid)`
  ${breakpoints("padding-top", [
    {
      xxl: "0px",
    },
    {
      xl: "0px",
    },
    {
      lg: "8px",
    },
    {
      md: "8px",
    },
    {
      sm: "8px",
    },
    {
      xs: "8px",
    },
  ])}
`;

export const Title = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: ${styledTheme.colors.secondaryDark};
`;

export const PricingWithIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const PricingBold = styled.div<{ noSpacingTop: boolean }>`
  margin-top: ${(props) => (props.noSpacingTop ? "0px" : "8px")};
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: ${styledTheme.colors.dark};
`;

export const PricingNormal = styled.div<{minWidth: boolean, noSpacingTop: boolean }>`
  margin-top: ${(props) => (props.noSpacingTop ? "0px" : "8px")};
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${styledTheme.colors.dark};
  opacity: 0.5;
  padding-left: 4px;
  padding-right: 4px;
  min-width: ${(props) => (props.minWidth ? "50px" :  "" )};
`;

export const BlockWrapper = styled.div`

  ${breakpoints("padding-top", [
    {
      xxl: "0px",
    },
    {
      xl: "0px",
    },
    {
      lg: "8px",
    },
    {
      md: "20px",
    },
    {
      sm: "20px",
    },
    {
      xs: "20px",
    },
  ])}

  ${breakpoints("margin-right", [
    {
      xxl: "60px",
    },
    {
      xl: "0px",
    },
    {
      lg: "0px",
    },
    {
      md: "0px",
    },
    {
      sm: "0px",
    },
    {
      xs: "0px",
    },
  ])}
`;

export const PackageInformationWrapper = styled.div`
  padding-top: 8px;
`;

export const LargeSpacingLeft = styled.div`
  ${breakpoints("padding-left", [
    {
      xxl: "60px",
    },
    {
      xl: "60px",
    },
    {
      lg: "0px",
    },
    {
      md: "0px",
    },
    {
      sm: "0px",
    },
    {
      xs: "0px",
    },
  ])}
`;

export const HelpIcon = styled(Icon)`
  cursor: pointer;
  padding-left: 8px;

  img {
    width: 16px;
    height: 16px;
  }
`;
