import { AutoSchedulePackageDetail } from "constant/enum";
import { AUTO_SCHEDULE_FREE_LEAD_LIMIT } from "constant/common"
import { observer } from "mobx-react";
import useStore from "utils/useStore";
import AutoSchedulePackageDropdown from "../AutoSchedulePackageDropdown";
import {
  Container,
  BlockWrapper,
  PricingBold,
  Title,
  DistributionInformation,
  PackageSelection,
  LargeSpacingLeft,
  PricingNormal,
  TextWrapper,
  HelpIcon,
  PricingWithIcon,
  PackageInformationWrapper,
} from "./autoSchedulePackageDetail.styles";

const CardInformation = () => {
  const { attorneyStore } = useStore();
  const { attorneyDetail, leadDistributionStatistics } = attorneyStore;
  const weekLimit: number =
    AutoSchedulePackageDetail?.[attorneyDetail?.autoSchedulePackage]
      ?.weekLimit ?? 0;
  const estimatedCost: number =
    AutoSchedulePackageDetail?.[attorneyDetail?.autoSchedulePackage]
      ?.estimatedCost ?? 0;

  const totalAutoScheduleLeadCount: number = attorneyDetail?.totalAutoScheduleLeadCount || 0;
  return (
    <Container blockWrapperQty = {totalAutoScheduleLeadCount >= AUTO_SCHEDULE_FREE_LEAD_LIMIT}>
      <BlockWrapper>
        <AutoSchedulePackageDropdown />
      </BlockWrapper>
      {totalAutoScheduleLeadCount >= AUTO_SCHEDULE_FREE_LEAD_LIMIT && 
      <BlockWrapper>
          <Title minWidth={true}>Estimated price</Title>
          <TextWrapper>
            <PricingBold>${estimatedCost}</PricingBold>
            <PricingNormal noSpacingTop>/week</PricingNormal>
          </TextWrapper>
        </BlockWrapper>
      }
      <BlockWrapper>
        <Title>{totalAutoScheduleLeadCount >= AUTO_SCHEDULE_FREE_LEAD_LIMIT ? 'Leads in this week' : 'Free Leads'}</Title>
        <PackageInformationWrapper>
          <PricingWithIcon>
            <TextWrapper>
              <PricingNormal noSpacingTop>Used</PricingNormal>
              <PricingBold noSpacingTop>
                {totalAutoScheduleLeadCount >= AUTO_SCHEDULE_FREE_LEAD_LIMIT ?
                (totalAutoScheduleLeadCount > AUTO_SCHEDULE_FREE_LEAD_LIMIT ? leadDistributionStatistics?.length ?? 0 : 0) :
                (totalAutoScheduleLeadCount || 0)}
              </PricingBold>
              <PricingNormal minWidth={true} noSpacingTop>out of</PricingNormal>
              {totalAutoScheduleLeadCount >= AUTO_SCHEDULE_FREE_LEAD_LIMIT ?
                <PricingBold noSpacingTop>{weekLimit}</PricingBold> :
                <PricingBold noSpacingTop>{AUTO_SCHEDULE_FREE_LEAD_LIMIT}</PricingBold>
              }
            </TextWrapper>
            <HelpIcon iconName="help-gray.svg" />
          </PricingWithIcon>
        </PackageInformationWrapper>
      </BlockWrapper>
    </Container>
  );
};

export default observer(CardInformation);
