import React, { useState, useEffect, useRef } from "react";

const VoiceParticipant = ({ r, 
  participant,
  toggleSpeaker,
  isSpeakerOn,
  setIsSpeakerOn,
  toggleMute,
  isMuted,
  setMuted,
}) => {
  const [audioTracks, setAudioTracks] = useState([]);

  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);

      const audio = document.querySelector("#" + r + "Audio");
      if (r === 'remote') {
        audio.muted = false;
      } else {
        audio.muted = true;
      }

      audioTrack.on('enabled', () => {
        const micBtn = document.querySelector(".mic-btn");
        if(micBtn) {
          micBtn.classList.add('active')
        }

        setMuted(false)
      });
      audioTrack.on('disabled', () => {
        if (audioTrack && r === 'local') {
          setMuted(true)
          const micBtn = document.querySelector(".mic-btn");
          if(micBtn) {
            micBtn.classList.remove('active')
          }
        }
      });
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack && r === 'local') {
      if (isMuted)
        audioTrack.enable()
      else
        audioTrack.disable()
    }
  }, [toggleMute]);

  useEffect(() => {
    if (r === 'remote') {

      var audio = document.querySelector("#" + r + "Audio");
      audio.muted = !isSpeakerOn;
      setIsSpeakerOn(!isSpeakerOn);
      const speakerBtn = document.querySelector(".speaker-btn");
      if(speakerBtn) {
        if (isSpeakerOn)
          speakerBtn.classList.remove('active')
        else
          speakerBtn.classList.add('active')
      }
    }
  }, [toggleSpeaker]);

  return (
    <>
      <audio id={r + 'Audio'} ref={audioRef}  autoPlay={true} />
    </>
  );
};

export default VoiceParticipant;
