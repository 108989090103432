import { ChartData } from "react-chartjs-2";
import * as chartjs from "chart.js";
import { styledTheme } from "styles/StyledTheme";
import { IRangeSwitcherOption } from "components/common/RangeSwitcher";
import { TimeRange } from "components/common/RangeSwitcher/constants";

export const timeRangeOptions: IRangeSwitcherOption[] = [
  {
    label: "Day",
    value: TimeRange.DAY,
  },
  {
    label: "Week",
    value: TimeRange.WEEK,
  },
  {
    label: "Month",
    value: TimeRange.MONTH,
  },
];

export const chartData: ChartData<chartjs.ChartData> = {
  labels: [],
  datasets: [
    {
      label: "Minutes",
      fill: false,
      lineTension: 0.5,
      backgroundColor: "white",
      borderColor: styledTheme.colors.secondary,
      borderWidth: 2,
      data: [],
    },
  ],
};

export const NUMBERS_MONTH_OF_YEAR = 12;
