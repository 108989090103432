import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import { API, graphqlOperation } from "aws-amplify";
import { HubspotServiceOperation } from "constant/enum";
import { hubspotService } from "gql";

export async function updateHubspotUser(
  email: string,
  firstName: string,
  lastName: string
): Promise<void> {
  const input = {
    email,
    firstName,
    lastName,
  };

  await API.graphql({
    ...graphqlOperation(hubspotService, {
      input: {
        operation: HubspotServiceOperation.UPDATE_CONTACT_NAME,
        body: JSON.stringify(input),
      },
    }),
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
  });
}
