import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)`
  display: flex;
  flex-direction: column;

  margin-top: 32px;
`;

export const Header = styled.div`
  text-align: left;

  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: ${styledTheme.colors.primaryBlack};
  padding-bottom: 16px;
`;

export const TagWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TagSpacing = styled.div`
  margin-right: 12px;
  margin-bottom: 8px;
`;
