import { ConsultationStatus } from "constant/enum";
import { ICaseRequest } from "interfaces";
import { IActiveRequest } from "interfaces/acceptConsultationRequest";
import { observable, makeObservable, action } from "mobx";
import { fetchActiveRequests } from "services/consultationService";
import { IRootStore } from "./rootStore";

class ConsultationStore {
  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      pendingCases: observable,
      activeRequests: observable,
      selectedActiveRequestId: observable,
      setPendingCases: action,
      fetchActiveRequestsByAttorneyId: action,
      setSelectedActiveRequestId: action,
      setActiveRequests: action,
    });
    this.rootStore = rootStore;
  }

  public pendingCases: ICaseRequest[] = [];
  public activeRequests: IActiveRequest[] = [];
  public selectedActiveRequestId: string;

  public setPendingCases(pendingCases: ICaseRequest[]): void {
    this.pendingCases = pendingCases;
  }

  public async fetchActiveRequestsByAttorneyId(
    attorneyId: string
  ): Promise<void> {
    const requestData = await fetchActiveRequests(attorneyId);
    if (!requestData) {
      return;
    }
    const activeRequests: IActiveRequest[] = [
      ...requestData?.future,
      ...requestData?.immediate,
    ]
      ?.filter((activeRequest) => activeRequest?.consultation)
      ?.map((activeRequest) => ({
        type: activeRequest?.case?.consultationConnectionMethod,
        time: activeRequest?.dateTimeScheduled,
        firstName: activeRequest?.case?.client?.firstName,
        lastName: activeRequest?.case?.client?.lastName,
        areaOfLaw: activeRequest?.case?.areaOfLaw,
        venueState: activeRequest?.case?.venueState,
        status: activeRequest?.consultation?.status,
        text: activeRequest?.case?.legalAdviceDescription,
        id: activeRequest?.id,
        case: activeRequest?.case,
        consultation: activeRequest?.consultation,
        dateTimeScheduledRequest: activeRequest?.dateTimeScheduledRequest,
        dateTimeScheduledRequestBy: activeRequest?.dateTimeScheduledRequestBy,
        dateTimeScheduledRequestStatus:
          activeRequest?.dateTimeScheduledRequestStatus,
      }));

    const inProgressConsultation = activeRequests.filter(
      (consultation) =>
        (consultation?.status === ConsultationStatus.IN_PROGRESS ||
          consultation?.status === ConsultationStatus.PAUSE) &&
        consultation?.consultation?.status !== ConsultationStatus.CANCELED
    );

    const otherConsultation = activeRequests.filter(
      (consultation) =>
        consultation?.status !== ConsultationStatus.IN_PROGRESS &&
        consultation?.status !== ConsultationStatus.PAUSE &&
        consultation?.consultation?.status !== ConsultationStatus.CANCELED
    );

    this.activeRequests = [...inProgressConsultation, ...otherConsultation];
  }

  public setActiveRequests(activeRequests: IActiveRequest[]): void {
    this.activeRequests = [...activeRequests];
  }

  public setSelectedActiveRequestId(selectedActiveRequestId: string): void {
    this.selectedActiveRequestId = selectedActiveRequestId;
  }
}

export default ConsultationStore;
