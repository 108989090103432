import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow in={true} ref={ref} {...props} />;
});

export default function MyDialag({
  children, 
  actions, 
  title, 
  content,
  open,
  handleClose
}) {

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
      >
        {
          children ?
            // CUSTOM CONTENT
            { children } :
            // SIMPLE DIALOG
            <>
              <DialogTitle>{title}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {content}
                </DialogContentText>
              </DialogContent>
              {
                actions &&
                <DialogActions>
                  {/* <Button onClick={handleClose} color="primary">
                    Disagree
                  </Button> */}
                  {
                    actions.map((item, i) => (
                      <Button key={i} onClick={item.handler} color="primary">
                        {item.text}
                      </Button>
                    ))
                  }
                </DialogActions>
              }
            </>
        }
      </Dialog>
    </div>
  );
}