import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled(Grid)``;

export const TitleText = styled(Grid)`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 28px;
  text-align: center;
  color: #26272c;

  padding-bottom: 24px;
`;

export const BodyText = styled(Grid)`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #26272c;

  padding-bottom: 48px;
`;
