// for Active Campaigns
import { LawAreas } from '../constant';
import { API } from 'aws-amplify'


const syncUsingContext = (userContext) => {

  const {
    firstName,
    lastName,
    emailWork: email,
    firmAssociation,
    statesOfLicense: state,
    licenseNumber,
    currentProfessionalResponsibilityInvestigations,
    practiceAreas,
    communicationMethodsPreference: comms,
    cellPhone
  } = userContext;

  const payload = {
    contact: {
      email,
      firstName,
      lastName,
      fieldValues: [
        {field: '11', value: state && state.length > 0 ? state[0]: ''},
        {field: '14', value: licenseNumber},
        {field: '15', value: firmAssociation},
        {field: '18', value: currentProfessionalResponsibilityInvestigations},
        {field: '19', value: 'Accepted'},
        {field: '20', value: comms.length > 0 ? `||${comms.join('||')}||`: ''},
        {field: '21', value: practiceAreas.length > 0 ? `||${practiceAreas.map(item => LawAreas[item]).join('||')}||`: ''},
        {field: '22', value: cellPhone}
      ]
    }
  }

  syncContact(payload);

}

const syncContact = (payload) => {

  const body = {
    operation: 'sync',
    payload
  }

  return new Promise(async (resolve, reject) => {
    try {
      let data = await API.post('externalActiveCampaign', '/', {body});
      resolve(data);
    }
    catch(err) {
      reject(err);
    }
  })
}

const triggerEvent = (email) => {

  const body = {
    operation: 'track',
    email
  }

  return new Promise(async (resolve, reject) => {
    try {
      await API.post('externalActiveCampaign', '/', {body});
    }
    catch(err) {
      reject(err);
    }
  })
}

export default {
  syncContact,
  syncUsingContext,
  triggerEvent
}
