import React,{ useState } from 'react'
import { Popover } from "@material-ui/core";
import { styledTheme } from 'styles/StyledTheme'
import styled from "styled-components";

const StyledTypography=styled.div`
  padding: 11px;
  font-size: 14px;
  /* max-height: 250px; */
  max-width: 400px;
`

const StyledSpan= styled.span`
  ${({color}) => `color: ${styledTheme.colors[color]}`};
  font-weight: bold;
  position: relative;
  cursor: pointer;
`
const StyledPopover= styled(Popover)` 
  max-height: 250px;
`

const Description = ({ text, color, textLength = 120 }) => {

  const [anchorEl, setAnchorEl] = useState(null)
  const popoverId = 'descriptionPopover'
  const open = Boolean(anchorEl);

  return(
    <>
      {(text.length > textLength) ? (
        <>
          {text.substr(0, (textLength-20))}{" "}
          <StyledSpan
            color={color}
            id={popoverId}
            onClick={(e) => {
              e.preventDefault()
              setAnchorEl(e.currentTarget)
            }}
          >
            Show More
          </StyledSpan>
          <StyledPopover
            id={popoverId}
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
           >
            <StyledTypography>{text}<br /></StyledTypography>
          </StyledPopover>
        </>
      ) 
        : 
      <>  
          {text}    
      </>
      }
    </>
  )
}

export default Description