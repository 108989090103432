import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

export const SignUpWrapper = styled.div`
  padding: 30px 0;
  width: 80%;
  margin: auto;

  .MuiInputBase-input {
    text-align: left;
  }

  .MuiFormControl-root {
    width: 100%;
    text-align: center;
  }

  .MuiFormHelperText-root {
    text-align: left !important;
  }

  .MuiInput-underline:before {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  }
  .MuiFormHelperText-root {
    &:not(.Mui-error) {
      color: ${({ theme }) => theme.colors.primary};
    }
    font-weight: bold;
    padding-top: 5px;
    text-align: center;
  }
  .MuiInputLabel-formControl {
    top: auto;
    text-align: center;
    width: 100%;
    font-size: 16px;
    margin: 0px;
    color: #adadaa;
  }
  .MuiInputLabel-shrink {
    display: none;
  }
`;

export const FullNameHelp = styled(Grid)`
  text-align: center;
`;

export const TosSection = styled(Grid)`
  margin-top: 40px;
`;
