import { Select } from "@material-ui/core";
import styled from "styled-components";
import { breakpoints } from "styles/layout";

export const BlockWrapper = styled.div`
  ${breakpoints("padding-top", [
    {
      xxl: "0px",
    },
    {
      xl: "0px",
    },
    {
      lg: "8px",
    },
    {
      md: "8px",
    },
    {
      sm: "8px",
    },
    {
      xs: "8px",
    },
  ])}

  ${breakpoints("margin-right", [
    {
      xxl: "60px",
    },
    {
      xl: "0px",
    },
    {
      lg: "0px",
    },
    {
      md: "0px",
    },
    {
      sm: "0px",
    },
    {
      xs: "0px",
    },
  ])}
`;

export const Title = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #287ec7;
`;

export const PackageDropdownWrapper = styled.div`
  max-width: 310px;
  min-width: 230px;
  padding-top: 8px;
`;

export const AutoSchedulePackageSelection = styled(Select)`
  ${breakpoints("margin-right", [
    {
      xxl: "60px",
    },
    {
      xl: "60px",
    },
    {
      lg: "0px",
    },
    {
      md: "0px",
    },
    {
      sm: "0px",
    },
    {
      xs: "0px",
    },
  ])}
`;
