import Collapse from "@material-ui/core/Collapse";
import Icon from "components/common/Icon";
import PasswordInput from "components/common/InputGroup/PasswordInput";
import CommonInput from "components/common/InputGroup/CommonInput";
import { useForm, Controller } from "react-hook-form";
import { styledTheme } from "styles/StyledTheme";
import { MicrosoftConnectForm } from "./constants";
import ConnectButton from "../components/ConnectButton";
import {
  handleOutlookConnectButton,
  redirectMicrosoftHelperPage,
} from "./utils";
import {
  ButtonWrapper,
  CustomCheckbox,
  ConnectButtonWithPassword,
  InputWrapper,
  CustomFormHelperText,
  ContinueButton,
  ContinueButtonWrapper,
} from "./microsoftConnectButton.styles";
import { useState } from "react";
import useStore from "utils/useStore";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { NylasProvider, NylasStateSync } from "constant/enum";

const MicrosoftConnectButton = () => {
  const history = useHistory();
  const { attorneyStore, calendarStore } = useStore();
  const { attorneyDetail } = attorneyStore;
  const { nylasOutlookAccount, displayConnectProvider } = calendarStore;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { control, formState, getValues, trigger } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { errors } = formState;
  const isConnected: boolean =
    nylasOutlookAccount?.sync_state === NylasStateSync.RUNNING ||
    nylasOutlookAccount?.sync_state === NylasStateSync.PARTIAL;

  return (
    <ConnectButtonWithPassword>
      <ButtonWrapper
        onClick={() => {
          calendarStore.setOpenCalendarModal(false);
          calendarStore.setDisplayConnectHelper(true);
          calendarStore.setDisplayConnectProvider(NylasProvider.OUTLOOK);
        }}
        noSpacing
      >
        <CustomCheckbox disabled checked={isConnected} color="primary" />
        <ConnectButton
          isOutline
          color={styledTheme.colors.robinEggBlue}
          iconName="logos_microsoft-windows.svg"
          label={!isConnected ? "CONNECT WITH MICROSOFT" : "CONNECTED"}
        />
      </ButtonWrapper>
      <Collapse
        in={displayConnectProvider === NylasProvider.OUTLOOK && !isConnected}
      >
        <InputWrapper>
          <Controller
            name={MicrosoftConnectForm.OUTLOOK_EMAIL}
            control={control}
            render={({ field }) => (
              <CommonInput
                onChange={(event) => field.onChange(event.target.value)}
                value={field.value}
                placeholder="Microsoft Email"
                errorMessage={
                  errors?.[MicrosoftConnectForm.OUTLOOK_EMAIL]?.message
                }
              />
            )}
            rules={{
              required: {
                value: true,
                message: "Microsoft email is required",
              },
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <Controller
            name={MicrosoftConnectForm.APP_SPECIFIC_PASSWORD}
            control={control}
            render={({ field }) => (
              <PasswordInput
                onChange={(event) => field.onChange(event.target.value)}
                value={field.value}
                placeholder="App-specific password"
                errorMessage={
                  errors?.[MicrosoftConnectForm.APP_SPECIFIC_PASSWORD]?.message
                }
              />
            )}
            rules={{
              required: {
                value: true,
                message: "App-specific password is required",
              },
            }}
          />
          <CustomFormHelperText onClick={redirectMicrosoftHelperPage}>
            <label>APP SPECIFIC PASSWORD</label>
            <Icon iconName="help_round-gray.svg" />
          </CustomFormHelperText>
        </InputWrapper>
        <ContinueButtonWrapper>
          <ContinueButton
            variant="contained"
            onClick={() => {
              if (!isLoading) {
                handleOutlookConnectButton(
                  setIsLoading,
                  getValues,
                  trigger,
                  attorneyDetail,
                  history
                );
              }
            }}
          >
            {isLoading ? "Loading" : "Continue"}
          </ContinueButton>
        </ContinueButtonWrapper>
      </Collapse>
    </ConnectButtonWithPassword>
  );
};

export default observer(MicrosoftConnectButton);
