import { Container, Title, CloseButton } from "./header.styles";

interface IHeaderProps {
  onClose: () => void;
}

const Header = (props: IHeaderProps) => {
  const { onClose } = props;

  return (
    <Container>
      <Title>Customer’s notes</Title>
      <CloseButton onClick={onClose} iconName="close-gray.svg" />
    </Container>
  );
};

export default Header;
