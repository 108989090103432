import { Grid, Paper } from "@material-ui/core";
import StyledButton from "components/common/Custom-button";
import { GStyledGrid } from 'components/common/MaterialUIStyledComponents';
import ReadMore from 'components/common/ReadMore';
import { GStyledB, GStyledDiv, GStyledImg } from 'components/common/StyledComponents';
import { ConsultationStatus } from "constant/common";
import { ConsultationContext, UserContext } from "contexts";
import { AudioWhiteGreen, TextWhiteGreen, VideoWhiteGreen } from "icons";
import { observer } from 'mobx-react';
import moment from 'moment';
import { useFutureConsultationSetting } from "pages/Dashboard/Consultation/hooks";
import React, { useContext, useEffect, useState } from "react";
import Timer from 'react-compound-timer';
import { fetchFutureConsultation, fetchImmediateConsultation } from "services/consultationService";
import {
  connectTwilioAudioRoom,
  connectTwilioVideoRoom, createTwilioConversationClient
} from "services/twilioService";
import styled from "styled-components";
import { styledTheme } from 'styles/StyledTheme';
import { LawAreas } from '../../constant';
import useStore from '../../utils/useStore';
import { mixerActiveRequestStatus } from './ActiveRequestCard/utils';

const StyledPaper = styled(Paper)`
  position: relative;
  padding: 10px 15px;
  border-radius: 12px;
  margin-top: 10px;
  box-shadow: ${props => props?.isActive && '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)'};
`;

// functions

const ActiveRequestCard = ({
  details,
  setRoom,
  setConversationClient
}) => {
  const {onClickFutureConsultation} = useFutureConsultationSetting();
  const [showTimer, setShowTimer] = useState(false);
  const [currStatus, setCurrStatus] = useState(details.status);
  const { consultationStore } = useStore()
  const {
    type,
    time,
    firstName,
    lastName,
    areaOfLaw,
    venueState,
    status = "start",
    text = "",
    id,
    timing,
    consultation
  } = details;
  const name = firstName + ' ' + lastName;
  const {
    setActiveCase, 
    setActiveConsultation,
    setText,
    setVideo,
    setAudio,
    activeConsultation,
    setToast
  } = useContext(ConsultationContext);
  const [
    userInfo, 
    updateUserInfo
  ] = useContext(UserContext);

  useEffect(() => {
    if(time && (!timing || timing === 'future')) {
      if((new Date(time) - new Date()) <= 0)
        onTimerFinish();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  useEffect(() => {
    if(status === 'Scheduled')
      setShowTimer(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function onTimerFinish() {
    setShowTimer(false)
    if(status !== 'InProgress')
      setCurrStatus('ready')  
  }

  async function startConsultation() {
    if(activeConsultation) {
      setToast('Another consultation is in progress..');
      return;
    }

    setActiveCase({});

    setCurrStatus('in progress')
    // fetch type consultatation by ID
    const typedConsultation = await (
      time ? // if true, its future consultation
        fetchFutureConsultation(id) : 
        fetchImmediateConsultation(id)
    );
    const { case: caseInfo, consultation } = typedConsultation;

    // SET ACTIVE CONSULTATION
    setActiveConsultation(consultation)

    // SET ACTIVE CASE
    setActiveCase({
      type: caseInfo.consultationConnectionMethod
        ? caseInfo.consultationConnectionMethod.toLowerCase()
        : "text",
      updatedAt: new Date(),
      areaOfLaw: caseInfo.areaOfLaw,
      description: caseInfo.legalAdviceDescription,
      state: caseInfo.venueState,
      client: {...caseInfo.client, phoneno: details?.case?.client?.phone ? details?.case?.client?.phone : ''},
      status: "ready",
      text: caseInfo.legalAdviceDescription,
      payment: caseInfo.payment,
      id: caseInfo.id,
      timeLimit: consultation.purchasedLength,
      consultation
    })

    // CREATE DIALOG
    // Twilio
    if (type.toLowerCase() === "text") {
      const conversationClient = await createTwilioConversationClient(
        userInfo.id,
      );
      setText(true);
      setConversationClient(conversationClient);
    } else if (
        type.toLowerCase() === "audio" ||
        type.toLowerCase() === "voice"
    ) {
      const room = await connectTwilioAudioRoom(
        consultation.id,
        userInfo.id,
      );
      setAudio(true);
      setRoom(room);
    } else if (type.toLowerCase() === "video") {
      const room = await connectTwilioVideoRoom(
        consultation.id,
        userInfo.id,
      );
      setVideo(true);
      setRoom(room);
    } else {
      const conversationClient = await createTwilioConversationClient(
        consultation.id,
        userInfo.id,
      );
      setText(true);
      setConversationClient(conversationClient);
    }
  }
  return (
    <StyledPaper 
      isActive={consultationStore?.selectedActiveRequestId === details?.id} 
      onClick={()=> {
        if(details.status === ConsultationStatus.Scheduled){
          onClickFutureConsultation(details)
        } else {
          consultationStore.setSelectedActiveRequestId(undefined);
        }
    }}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <GStyledDiv  position='absolute' left='-15px' color={ styledTheme.colors.primary }>
            {type.toLowerCase() === "voice" && <GStyledImg width= '22px' src={AudioWhiteGreen} alt="audio" />}
            {type.toLowerCase() === "text" && <GStyledImg width= '22px' src={TextWhiteGreen} alt="text" />}
            {type.toLowerCase() === "video" && <GStyledImg width= '22px' src={VideoWhiteGreen} alt="video" />}
          </GStyledDiv>
          <GStyledGrid fontSize= '10px' color={ styledTheme.colors.infoDark } item xs={12}>
            <label>
              {`${type.toUpperCase()}`} <GStyledB fontSize= '12px' color={ styledTheme.colors.secondaryDark }> | {moment(time || new Date()).format('MMMM DD YYYY, h:mm a')}</GStyledB>{" "}
            </label>
          </GStyledGrid>
          <GStyledGrid item xs={12} fontSize= '12px' paddingTop= '5px'>
            <b>{name} {`${details?.case?.client?.phone ? `(${details?.case?.client?.phone})`:''}`}</b>
          </GStyledGrid>
        </Grid>

        <GStyledGrid item xs={12} position= 'absolute' right= '-20px'>
          {(currStatus === "start" || currStatus === "ready") && (
            <StyledButton
              variant="contained"
              style={{
                padding: "10px 27px",
                backgroundColor: styledTheme.colors.secondary,
                color: "white",
                boxShadow: styledTheme.shadow.blueBelow,
                fontSize: "12px",
              }}
              onClick={() => startConsultation()}
            >
              START SESSION
            </StyledButton>
          )}
          {currStatus === "in progress" && (
            <StyledButton
              variant="outlined"
              style={{
                padding: "10px 35px",
                backgroundColor: styledTheme.colors.white,
                color: styledTheme.colors.secondary,
                border: `2px solid ${ styledTheme.colors.secondary }`,
                fontSize: "12px",
                textTransform: 'capitalize',
                pointerEvents: 'none'
              }}
            >
              {mixerActiveRequestStatus(details, currStatus)}
            </StyledButton>
          )}
          {currStatus === 'Scheduled' && (
            <StyledButton
              variant="outlined"
              style={{
                padding: "10px 35px",
                backgroundColor: styledTheme.colors.white,
                color: styledTheme.colors.secondary,
                border: `2px solid ${ styledTheme.colors.secondary }`,
                fontSize: "12px",
                textTransform: 'capitalize',
                pointerEvents: 'none'
              }}
            >
              {mixerActiveRequestStatus(details, currStatus)}
            </StyledButton>
          )}
          {(currStatus === "InProgress" || currStatus === "Paused") &&(
            <StyledButton
              variant="contained"
              style={{
                padding: "10px 27px",
                backgroundColor: styledTheme.colors.secondary,
                color: "white",
                boxShadow: styledTheme.shadow.blueBelow,
                fontSize: "12px",
              }}
              onClick={startConsultation}
            >
              Resume Session
            </StyledButton>
          )}
        </GStyledGrid>
        {details?.case?.client?.email && <GStyledGrid item xs={10} fontSize= '13px' color={ styledTheme.colors.secondaryDark }>
          <b>Email: </b>
          {details?.case?.client?.email}
        </GStyledGrid>}
        <GStyledGrid item xs={12} fontSize= '13px' color={ styledTheme.colors.secondaryDark }>
          <b>Duration:</b> {consultation.purchasedLength} minutes
        </GStyledGrid>
        <GStyledGrid item xs={12} fontSize= '13px' color={ styledTheme.colors.secondaryDark }>
          <b>{LawAreas[areaOfLaw]} {`${(LawAreas[areaOfLaw] && venueState) ? "|" : ""}`} </b>
          {venueState}
        </GStyledGrid>
        <GStyledGrid item xs={12} fontSize= '12px' color={ styledTheme.colors.default } paddingTop= '10px'>
          <div style={{color: '#697989'}}>
            <ReadMore text={ text } color={ 'secondaryDark' } textLength={ 120 } />
          </div>
        </GStyledGrid>
        {/* COUNTDOWN */}
        {
          showTimer && currStatus !== 'ready' &&
          <GStyledDiv width='100%' fontSize='12px' color={ styledTheme.colors.primary } fontWeight='bold' textAlign='right'>
            Consultation will be begin in:&nbsp;
            <Timer
              initialTime={new Date(time).getTime() - new Date().getTime()}
              direction="backward"
              formatValue={(val) => (val + '').padStart(2, '0')}
              checkpoints={[
                {
                  time: 0,
                  callback: onTimerFinish,
                }
              ]}
            >
              {() => (
                <React.Fragment>
                  <Timer.Days />:
                  <Timer.Hours />:
                  <Timer.Minutes />:
                  <Timer.Seconds />
                </React.Fragment>
              )}
            </Timer>
          </GStyledDiv>
        }
      </Grid>
    </StyledPaper>
  );
};

export default observer(ActiveRequestCard);