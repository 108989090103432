import { Grid, TextareaAutosize } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import FileIcon from '@material-ui/icons/AttachFile'
import SendIcon from '@material-ui/icons/Send'
import styled from 'styled-components'
import { styledTheme } from 'styles/StyledTheme'

const MessageWrapper = styled.div`
  position: relative;
  overflow: auto;
  min-height: 300px;
  height: 300px;
  flex-grow: 1;
  background-color: ${styledTheme.colors.white};
`

const ChatFooter = styled(Grid)`
  background-color: #f5f4f4;
  padding: 10px;
  border-radius: 0 0 20px 20px;
  flex-basis: auto;
  fieldset {
    border-color: rgba(0, 0, 0, 0) !important;
  }
  .MuiOutlinedInput-adornedEnd {
    height: 40px;
    border-radius: 20px;
  }
  textarea {
    border: none;
    outline: none;
    padding: 10px 15px;
  }
`

const StyledTextareaAutosize = styled(TextareaAutosize)`
  flex: auto;
  background-color: white;
  resize: none;
`

const StyledFab = styled(Fab)`
  background-color: ${({ theme }) => theme.colors.secondaryDark};
  height: 41px;
  width: 41px;
  box-shadow: none;
`

const StyledSendIcon = styled(SendIcon)`
  color: white;
  font-size: 20px;
`
const StyledFileIcon = styled(FileIcon)`
  color: ${({ theme }) => theme.colors.secondaryDark};
  font-size: 20px;
  margin-left: 16px;
  transform: rotate(45deg);
  cursor: pointer;
`

const StyledGrid = styled(Grid)`
  width: 97%;
  margin: auto;
  background-color: white;
  border-radius: 30px;
`

const DropAreaConatainer = styled.div`
  display: flex;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  flex-direction: column;
  background-color: white;
  height: 100%;
  padding: 50px;
`
const DropArea = styled.div`
  border: 3px dashed #CBD5E0;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  cursor: pointer;
`
const DropAreaDescription = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #26272C;
`

const DropAreaSubText = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #96ACB9;
`

const SelectedFile = styled.div`
  padding: 16px 20px;
  background: #EDF2F7;
  border-radius: 16px;
  margin: 0 0 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    cursor: pointer;
  }
`

const FileName = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: black;
`
const LoadPreviousButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > button {
    border: none;
    border-radius: 16px;
    padding: 0.5rem 1rem;
    margin: 0;
  }
`

export {
  MessageWrapper,
  ChatFooter,
  StyledTextareaAutosize,
  StyledFab,
  StyledSendIcon,
  StyledFileIcon,
  StyledGrid,
  DropAreaConatainer,
  DropArea,
  DropAreaDescription,
  DropAreaSubText,
  SelectedFile,
  FileName,
  LoadPreviousButtonContainer
}
