import { CommonError } from "../types";
import { errorHandler } from "../utils/errorHandler";
import { api } from "./index";
import { AxiosResponse } from "axios";
import { ISignupCredential } from "../interfaces";

export async function upsertSignUpCredential(
  signupCredential: ISignupCredential
): Promise<void> {
  try {
    const response: AxiosResponse = await api.post(
      `/sign-up-credentials/upsert`,
      signupCredential
    );
    return response.data;
  } catch (error: CommonError) {
    errorHandler(error.response.data.error);
    throw error.response.data.error;
  }
}

export async function findSignUpCredentials(
  email: string
): Promise<ISignupCredential> {
  try {
    const response: AxiosResponse = await api.post(
      `/sign-up-credentials/find`,
      { email }
    );
    return response.data;
  } catch (error: CommonError) {
    errorHandler(error.response.data.error);
    return {} as ISignupCredential;
  }
}
