import { IAttorneyTimeFrame } from "interfaces";
import { observer } from "mobx-react";
import { useContext, useMemo, useState } from "react";
import useStore from "utils/useStore";
import TimePicker from "../../../TimePicker";
import AddButton from "../../../AddButton";
import TimeTag from "../../../TimeTag";
import {
  Container,
  Header,
  TagWrapper,
  TagSpacing,
} from "./overrideCard.styles";
import {
  addTimeFrameHandler,
  removeTimeFrameHandler,
} from "pages/Dashboard/Consultation/ConsultingAvailability/utils";
import { UserContext } from "contexts";
import moment from "moment";
import { AttorneyTimeFrameCategory } from "constant/enum";

const OverrideCard = () => {
  const { calendarStore } = useStore();
  const { attorneyTimeFrames, selectedDate } = calendarStore;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [userInfo] = useContext(UserContext);

  const filteredTimeFrames: IAttorneyTimeFrame[] = useMemo(
    () =>
      Array.isArray(attorneyTimeFrames)
        ? attorneyTimeFrames?.filter(
            (attorneyTimeFrame: IAttorneyTimeFrame) =>
              attorneyTimeFrame.category ===
                AttorneyTimeFrameCategory.OVERRIDE &&
              moment(attorneyTimeFrame.from).isSame(
                moment(selectedDate),
                "date"
              )
          )
        : [],
    [attorneyTimeFrames, selectedDate]
  );
        
  return (
    <Container>
      <Header>Override</Header>
      <TagWrapper>
        {Array.isArray(filteredTimeFrames) &&
          filteredTimeFrames?.map(
            (filteredTimeFrame: IAttorneyTimeFrame, index: number) => (
              <TagSpacing key={index}>
                <TimeTag
                  from={filteredTimeFrame?.from}
                  to={filteredTimeFrame?.to}
                  theme="light"
                  onClickDelete={() => {
                    removeTimeFrameHandler(
                      calendarStore,
                      filteredTimeFrame?.id,
                      userInfo?.id
                    );
                  }}
                />
              </TagSpacing>
            )
          )}
        {editMode && (
          <TimePicker
            fromValue={moment(selectedDate)}
            toValue={moment(selectedDate)}
            onAccept={(from: moment.Moment, to: moment.Moment) => {
              addTimeFrameHandler(
                calendarStore,
                userInfo?.id,
                selectedDate.getDay(),
                from,
                to,
                setEditMode,
                AttorneyTimeFrameCategory.OVERRIDE,
              );
            }}
            onCancel={() => setEditMode(false)}
          />
        )}
        {!editMode && (
          <TagSpacing>
            <AddButton onClick={() => setEditMode(true)} />
          </TagSpacing>
        )}
      </TagWrapper>
    </Container>
  );
};

export default observer(OverrideCard);
