import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import CustomHeading from "components/common/CustomHeading";
import ListMenu from "components/common/ListMenu";
import PercentageBar from "components/common/PercentageBar";
import {
  TextCircle,
  AudioWhiteGreen,
  VideoCircle,
} from "icons";
import styled from "styled-components";
import { getLastMonths } from "../../../services/dashboardService";
import { styledTheme } from '../../../styles/StyledTheme'

const moment = require("moment");

const RatingWrapper = styled(Grid)`
  border-radius: 12px;
  position: relative;

  .percentageWrapper {
    width: 90%;
    @media (max-width: 381px) {
      width: 100%;
    }
  }

  .icon {
    position: absolute;
    right: 20px;
    top: 10px;
  }
  .total {
    font-size: 10px;

    span {
      font-size: 25px;
      b {
        font-size: 10px;
      }
    }
  }
`;

function getTotalReviews(cases) {
  let total = 0;

  cases.forEach((c) => {
    if (c.ratings) total += 1;
  });

  return total;
}

function getRatingForType(ratingType, cases) {
  let total = 0;
  cases.forEach((c) => {
    if (c.ratings) {
      total += c.ratings[ratingType];
    }
  });

  if(total === 0)
    return {
      percentage: 0,
      value: 0,
    };

  return {
    percentage: ((total / cases.length) * 100) / 5,
    value: (total / cases.length).toFixed(1),
  };
}

const RatingVsChannels = ({ cases, allCases }) => {
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [totalCases, setTotalCases] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const [months, setMonths] = useState([]);
  const [filteredCases, setFilteredCases] = useState([]);

  const [overallRating, setOverallRating] = useState({
    text: { percentage: 0, value: 0 },
    audio: { percentage: 0, value: 0 },
    video: { percentage: 0, value: 0 },
  });
  const [knowledgeRating, setKnowledgeRating] = useState({
    text: { percentage: 0, value: 0 },
    audio: { percentage: 0, value: 0 },
    video: { percentage: 0, value: 0 },
  });
  const [friendlinessRating, setFriendlinessRating] = useState({
    text: { percentage: 0, value: 0 },
    audio: { percentage: 0, value: 0 },
    video: { percentage: 0, value: 0 },
  });

  useEffect(() => {
    let cases = filteredCases;
    setTotalReviews(getTotalReviews(cases));
    setMonths(getLastMonths(6));

    const textTypeCases = cases.filter(
      (c) => c.consultationConnectionMethod === "Text"
    );
    const audioTypeCases = cases.filter(
      (c) => c.consultationConnectionMethod === "Voice"
    );
    const videoTypeCases = cases.filter(
      (c) => c.consultationConnectionMethod === "Video"
    );

    const overallTextRating = getRatingForType("overallScore", textTypeCases);
    const overallAudioRating = getRatingForType("overallScore", audioTypeCases);
    const overallVideoRating = getRatingForType("overallScore", videoTypeCases);

    const knowledgeTextRating = getRatingForType(
      "knowledgeScore",
      textTypeCases
    );
    const knowledgeAudioRating = getRatingForType(
      "knowledgeScore",
      audioTypeCases
    );
    const knowledgeVideoRating = getRatingForType(
      "knowledgeScore",
      videoTypeCases
    );
    const friendlinessTextRating = getRatingForType(
      "friendlinessScore",
      textTypeCases
    );
    const friendlinessAudioRating = getRatingForType(
      "friendlinessScore",
      audioTypeCases
    );
    const friendlinessVideoRating = getRatingForType(
      "friendlinessScore",
      videoTypeCases
    );

    const totalTextRating =
      overallTextRating.value +
      knowledgeTextRating.value +
      friendlinessTextRating.value;
    const totalAudioRating =
      overallAudioRating.value +
      knowledgeAudioRating.value +
      friendlinessAudioRating.value;
    const totalVideoRating =
      overallVideoRating.value +
      knowledgeVideoRating.value +
      friendlinessVideoRating.value;
    
      /* eslint-disable no-unused-vars */
    let mostPopularType = "";

    if (
      totalTextRating > totalAudioRating &&
      totalTextRating > totalVideoRating
    ) {
      mostPopularType = "Text";
    } else if (
      totalAudioRating > totalTextRating &&
      totalAudioRating > totalVideoRating
    ) {
      mostPopularType = "Voice";
    } else if (
      totalVideoRating > totalTextRating &&
      totalVideoRating > totalAudioRating
    ) {
      mostPopularType = "Video";
    }

    const totalOverallPercentage =
      overallTextRating.percentage +
      overallAudioRating.percentage +
      overallVideoRating.percentage;

    const totalKnowledgePercentage =
      knowledgeTextRating.percentage +
      knowledgeAudioRating.percentage +
      knowledgeVideoRating.percentage;

    const totalFriendlinessPercentage =
      friendlinessTextRating.percentage +
      friendlinessAudioRating.percentage +
      friendlinessVideoRating.percentage;

    setOverallRating({
      text: {
        percentage: totalOverallPercentage ? (
          (overallTextRating.percentage * 100) /
          totalOverallPercentage
        ).toFixed(1) : 0,
        value: overallTextRating.value,
      },
      audio: {
        percentage:totalOverallPercentage ? (
          (overallAudioRating.percentage * 100) /
          totalOverallPercentage
        ).toFixed(1) : 0,
        value: overallAudioRating.value,
      },
      video: {
        percentage: totalOverallPercentage ? (
          (overallVideoRating.percentage * 100) /
          totalOverallPercentage
        ).toFixed(1) : 0,
        value: overallVideoRating.value,
      },
    });

    setKnowledgeRating({
      text: {
        percentage:totalKnowledgePercentage ? (
          (knowledgeTextRating.percentage * 100) /
          totalKnowledgePercentage
        ).toFixed(1) : 0,
        value: knowledgeTextRating.value,
      },
      audio: {
        percentage: totalKnowledgePercentage ?(
          (knowledgeAudioRating.percentage * 100) /
          totalKnowledgePercentage
        ).toFixed(1): 0,
        value: knowledgeAudioRating.value,
      },
      video: {
        percentage: totalKnowledgePercentage ?(
          (knowledgeVideoRating.percentage * 100) /
          totalKnowledgePercentage
        ).toFixed(1): 0,
        value: knowledgeVideoRating.value,
      },
    });

    setFriendlinessRating({
      text: {
        percentage: totalFriendlinessPercentage ? (
          (friendlinessTextRating.percentage * 100) /
          totalFriendlinessPercentage
        ).toFixed(1) : 0,
        value: friendlinessTextRating.value,
      },
      audio: {
        percentage: totalFriendlinessPercentage ? (
          (friendlinessAudioRating.percentage * 100) /
          totalFriendlinessPercentage
        ).toFixed(1) : 0,
        value: friendlinessAudioRating.value,
      },
      video: {
        percentage: totalFriendlinessPercentage ? (
          (friendlinessVideoRating.percentage * 100) /
          totalFriendlinessPercentage
        ).toFixed(1) : 0,
        value: friendlinessVideoRating.value,
      },
    });
  }, [filteredCases]);

  useEffect(() => {
    setFilteredCases(cases);
    setTotalCases(allCases.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cases])

  useEffect(() => {
    if(selectedMonth === 0) {
      setFilteredCases(cases);
      setTotalCases(allCases.length);
    }
    else {
      let tmp = cases.filter(item => {
        return moment().subtract(selectedMonth - 1, 'months').isSame(item.createdAt, 'month')
      })
      setFilteredCases(tmp);

      tmp = allCases.filter(item => {
        return moment().subtract(selectedMonth - 1, 'months').isSame(item.createdAt, 'month')
      })
      setTotalCases(tmp.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth])

  return (
    <Grid item xs={12} container>
      <Grid item xs={12} style={{ paddingBottom: "10px" }}>
        <CustomHeading>RATINGS VS CHANNEL</CustomHeading>
      </Grid>

      <RatingWrapper
        item
        xs={12}
        style={{ height: "auto", backgroundColor: "white" }}
      >
        <Grid item style={{ height: "80px" }} container xs={12} alignItems="center">
          <Grid
            item
            className="total"
            container
            xs={12}
            md={10}
            justify="center"
            alignItems="center"
          >
            <div
              className="d-flex justify-content-center align-items-end flex-wrap"
              style={{ color: styledTheme.colors.defaultDarker }}
            >
              <span className="pr-2">{totalCases}</span>
              <span>
                <b>TOTAL INTERACTIONS</b>
              </span>
            </div>
            <div
              className="pl-5 d-flex justify-content-center align-items-end flex-wrap"
              style={{ color: styledTheme.colors.secondaryDark }}
            >
              <span className="pr-2">{totalReviews}</span>
              <span>
                <b>TOTAL RATINGS</b>
              </span>
            </div>
          </Grid>
          <Grid className="icon">
            <ListMenu
              selectedIndex={selectedMonth}
              setSelectedIndex={(e) => setSelectedMonth(e)}
              options={['Lifetime', ...months]}
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          container
          style={{ margin: "0 auto 20px" }}
          className="percentageWrapper"
        >
          <PercentageBar
            title="OVERALL"
            text={{
              percent: overallRating.text.percentage,
              value: overallRating.text.value,
            }}
            voice={{
              percent: overallRating.audio.percentage,
              value: overallRating.audio.value,
            }}
            video={{
              percent: overallRating.video.percentage,
              value: overallRating.video.value,
            }}
          />
          <PercentageBar
            title="KNOWLEDGE"
            text={{
              percent: knowledgeRating.text.percentage,
              value: knowledgeRating.text.value,
            }}
            voice={{
              percent: knowledgeRating.audio.percentage,
              value: knowledgeRating.audio.value,
            }}
            video={{
              percent: knowledgeRating.video.percentage,
              value: knowledgeRating.video.value,
            }}
          />
          <PercentageBar
            title="COMMUNICATION"
            text={{
              percent: friendlinessRating.text.percentage,
              value: friendlinessRating.text.value,
            }}
            voice={{
              percent: friendlinessRating.audio.percentage,
              value: friendlinessRating.audio.value,
            }}
            video={{
              percent: friendlinessRating.video.percentage,
              value: friendlinessRating.video.value,
            }}
          />
          <Grid item xs={12} container>
            <Grid item md={2} />
            <Grid item xs={12} md={10} container style={{ paddingTop: "10px" }}>
              <Grid xs={4} item container alignItems="center">
                <TextCircle style={{ width: "22px" }} fill={ styledTheme.colors.secondaryDarker } />{" "}
                <span
                  style={{
                    fontSize: "11px",
                    textTransform: "uppercase",
                    marginLeft: "5px",
                    color: styledTheme.colors.defaultDark,
                  }}
                >
                  Text
                </span>
              </Grid>
              <Grid xs={4} item container alignItems="center" justify="center">
                <img
                  style={{ width: "22px" }}
                  src={AudioWhiteGreen}
                  alt="voice"
                />
                <span
                  style={{
                    fontSize: "11px",
                    textTransform: "uppercase",
                    marginLeft: "5px",
                    color: styledTheme.colors.defaultDark,
                  }}
                >
                  Voice
                </span>
              </Grid>
              <Grid
                xs={4}
                item
                container
                alignItems="center"
                justify="flex-end"
              >
                <VideoCircle style={{ width: "22px" }} fill={ styledTheme.colors.secondary } />{" "}
                <span
                  style={{
                    fontSize: "11px",
                    textTransform: "uppercase",
                    marginLeft: "5px",
                    color: styledTheme.colors.defaultDark,
                  }}
                >
                  Video
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* {popularType && (
          <Grid
            xs={12}
            md={12}
            style={{ paddingTop: "20px", paddingBottom: "10px" }}
            container
            alignItems="center"
            justify="center"
          >
            <AtentionCircle style={{ width: "25px" }} fill="#d8e1ea" />
            <span
              style={{ fontSize: "11px", marginLeft: "5px", color: theme.colors.infoDark }}
            >
              You are more popular in {popularType} Calls. Increase your rating
              accepting more.{" "}
            </span>
          </Grid>
        )} */}
      </RatingWrapper>
    </Grid>
  );
};

export default RatingVsChannels;
