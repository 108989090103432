import React from 'react'
import { Grid, Button } from '@material-ui/core'
import styled from 'styled-components'

const PromptWrapper = styled(Grid)`
  background-color: white;
`
const QuestionRequestBanner = ({text}) => {
  
  return (
    <PromptWrapper item xs={12}>
      <Grid item xs={12}>
        {text}
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={6}>
          <Button variant='outlined' color='primary'>
            ACCEPT
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant='contained' color='primary'>
            DENY
          </Button>
        </Grid>
      </Grid>
    </PromptWrapper>
  )
}

export default QuestionRequestBanner