import { Checkbox } from "@material-ui/core";
import CommonButton from "components/common/ButtonGroup/CommonButton";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const ConnectButtonWithPassword = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
`;

export const InputWrapper = styled.div`
  padding-top: 12px;
  padding-left: 66px;

  .MuiInputBase-root {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div<{ noSpacing: boolean }>`
  display: flex;
  flex-direction: row;
  padding-bottom: ${(props) => (props?.noSpacing ? "0px" : "24px")};
`;

export const CustomCheckbox = styled(Checkbox)`
  padding: 0px;
  padding-right: 6px;
  min-width: 66px;

  input:checked {
    color: ${styledTheme.colors.blue500};
  }
`;

export const CustomFormHelperText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;

  img {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    cursor: pointer;
  }

  label {
    margin-bottom: 0px;
    font-family: ${styledTheme.fonts.Inter};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${styledTheme.colors.secondaryDark};
    cursor: pointer;
  }

  div {
    display: flex;
  }
`;

export const ContinueButtonWrapper = styled.div`
  margin-top: 12px;
  margin-left: 66px;
`;

export const ContinueButton = styled(CommonButton)`
  width: 100%;
  background-color: ${styledTheme.colors.secondaryDark} !important;
  color: ${styledTheme.colors.white};
  border-radius: 12px;
`;
