import React from 'react'
import styled from 'styled-components'
import { Input} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
  display:"block"
  },
});

const Wrapper = styled.div`
  width:100%;
  .MuiInput-underline:before {
    border-bottom: ${ ({ theme }) => `1px solid ${ theme.colors.primary }` };       
  }
  .MuiInputBase-input{
    text-align:center;
    width:100%;
    font-size:16px;
    border-color: ${ ({ theme }) => theme.colors.primary };
  }
`
export default function CustomInput({children,value,onChange,...otherProps}) {
  const classes =useStyles();  

  return (
        <Wrapper>
            <Input className={classes.root} value={value} onChange={onChange} {...otherProps}>
              {
                children
              }
            </Input>
        </Wrapper>
    )
}
