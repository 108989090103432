import React from 'react'
import LoginSignup from 'pages/Landing/LoginSignup'
import featuredImage from 'images/featured.jpg'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { styledTheme } from '../../styles/StyledTheme'

const FlexWrapper = styled(Grid)`
  background-image: url(${featuredImage});
  width: 100%;
  background-size: 100% 100%;
  background-size: cover;
  position: relative;
  .banner{
    width:800px;
    padding: 20px 25px;
    background-color: rgba(255,255,255,0.9);
    position: absolute;
    left:350px;
    top:330px;
    font-size:28px;
    font-weight:bolder;
    text-align:left;
    @media (max-width:1869px){
      display: none;
    }
  }
`

const FeaturedFlex = props => {

  return (
    <FlexWrapper container >
      <div className="banner">
        <span>Join LegalQ's network of independent attorneys.</span>
        <div  style={{ color: styledTheme.colors.primary }}>
          Grow your practice. Help people move forward with confidence.
        </div>
      </div>
      <Grid item lg={6}>
      </Grid>
      <Grid item container justify="center" lg={6} style={{zIndex:"1"}}>
      <LoginSignup/>
      </Grid>
    </FlexWrapper>
  )
}

export default FeaturedFlex

