import { useContext, useEffect, useState } from 'react'
import { Paper, Grid, Button } from '@material-ui/core'
import { AudioWhiteBlue, TextWhiteBlue, VideoWhiteBlue } from 'icons'
import styled from 'styled-components';
import moment from 'moment'
import { updateAttorneyConsultationRequestWaitingTime } from '../../API/consultationRequest';
import { ConsultationContext, UserContext } from '../../contexts';
import { LawAreas } from '../../constant';
import { GStyledGrid } from './MaterialUIStyledComponents'
import { GStyledB, GStyledDiv, GStyledImg } from './StyledComponents'
import { styledTheme } from '../../styles/StyledTheme'
import ReadMore from 'components/common/ReadMore'

const StyledPaper = styled(Paper)`
    position:relative;
    padding:10px 15px;
    border-radius:12px;
    background-color:#D9F2FF;
    margin-bottom: 10px;
`

const StyledButton = styled(Button)`
  background-color: rgb(33, 111, 182);
  color: white;
  padding: 10px 25px;
  font-size: 12px;

  &:hover {
    background-color: rgb(33, 111, 182);
  }

  ${({disabled}) => disabled === true && `
    background-color: rgb(130 130 130) !important;
    color: white !important;
  `}
`;

const AppointmentRequestedCard = ({
  type, 
  time, 
  name, 
  area, 
  caseType, 
  text, 
  handleOnAccept,
  handleOnDeny,
  isFuture = false,
  consultation,
  isConsultationDisabled,
  expiredTime,
  attorneyConsultationRequestId
}) => {
  const [ disabled, setDisabled ] = useState(false);
  const {activeCase} = useContext(ConsultationContext); //eslint-disable-line
  const [userInfo] = useContext(UserContext);
  const expiredRequestTime = moment(expiredTime)
  function initAccept(){
    setDisabled(true);
    handleOnAccept();
  }

  useEffect(()=> {
    if(!expiredTime) return;
    const currentTime = moment.now()
    const waitTime = moment.duration(expiredRequestTime.diff(currentTime)).asMilliseconds()
    const waitTimer = setTimeout(()=>{
      handleOnDeny()
    }, waitTime)

    return ()=> clearTimeout(waitTimer)
  }, [expiredRequestTime])

  useEffect(()=> {
    if(attorneyConsultationRequestId && userInfo?.id){
      updateAttorneyConsultationRequestWaitingTime(attorneyConsultationRequestId, userInfo.id)
    }
  }, [attorneyConsultationRequestId, userInfo?.id])

  return (
    <StyledPaper>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <GStyledDiv position='absolute' left='-10px' color={ styledTheme.colors.primary }>
            {
              type === 'Voice' &&
              <GStyledImg width= '22px' src={AudioWhiteBlue} alt='voice' />
            }
            {
              type === 'Text' &&
              <GStyledImg width= '22px' src={TextWhiteBlue} alt='text' />
            }
            {
              type === 'Video' &&
              <GStyledImg width= '22px' src={VideoWhiteBlue} alt='video' />
            }
          </GStyledDiv>     
          <GStyledGrid item xs={12} fontSize='10px' color={ styledTheme.colors.primary }>
            <label>
              {`${type.toUpperCase()}`} <GStyledB fontSize= '12px'>| Start time: {isFuture ? time : 'Now'}</GStyledB> 
            </label>
          </GStyledGrid>
          <GStyledGrid item xs={12} fontSize= '12px' paddingTop= '5px'>
            <b>{name}</b>
          </GStyledGrid>
          <GStyledGrid item xs={12} fontSize='13px' color={ styledTheme.colors.primary }>
            <b>Duration:</b> {consultation.purchasedLength} minutes
          </GStyledGrid>
          <GStyledGrid item xs={12} fontSize='13px' color={ styledTheme.colors.primary }>
            <b>{LawAreas[area]}</b>  {`${(caseType && LawAreas[area]) ? "|" : ""}`} {caseType}
          </GStyledGrid>
          <GStyledGrid item xs={12} style={{maxWidth:"100%", marginBottom: isFuture ? 18 : 0}} container fontSize= '12px' color= '#697989'>
            <GStyledGrid item xs={10} marginTop='6px'>
              <ReadMore text={ text } color={ 'primary' } textLength={ 120 } />
            </GStyledGrid>
            <GStyledGrid item sm={1} />
            <GStyledGrid item sm={1} xs={2} alignItems='flex-start'>
              <Button
                style={{fontSize:"12px",color: styledTheme.colors.infoDark , left: '-10px', padding:"",lineSpacing:"1px" }}
                color='primary'
                onClick={handleOnDeny}
              >
                DENY
              </Button>
            </GStyledGrid>
          </GStyledGrid>
        </Grid>
        <GStyledGrid item xs={12} position= 'absolute' right= '-16px' textAlign= 'right'>
          <StyledButton
            disabled={disabled || (!isFuture && activeCase !== null) || isConsultationDisabled}
            variant="contained"
            onClick={()=> initAccept()}>
              ACCEPT
          </StyledButton>
        </GStyledGrid>
      </Grid>
      {
        isFuture &&
        <div style={{position: 'absolute',bottom: 0, width: '100%', display: 'flex', justifyContent: 'center'}}>
          <div style={{
            padding: '5px 8px', 
            fontSize: 12, 
            backgroundColor: 'rgb(74 140 201)', 
            color: 'white',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            minWidth: 78,
            textAlign: 'center'
          }}>Future Appointment Request</div>
        </div>
      }
    </StyledPaper>
  )
}

export default AppointmentRequestedCard