import { Grid } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import styled, { createGlobalStyle } from "styled-components";
import { styledTheme } from "styles/StyledTheme";
import SocialButton from "./components/SocialButton";

export const Wrapper = styled.div`
  width: 90%;
  margin: auto;

  [class*="Description__Title"] {
    margin-top: 32px;
  }

  [class*="Description__DescriptionText"] {
    max-width: 380px;
  }

  [class*="Description__HelpText"] {
    padding-top: 8px;
  }
`;

export const WrapperGlobal = createGlobalStyle`
  [class*="MuiGrid-root featuredFlexRegistrationstyles__FlexWrapper"]{
    min-width: 460px;
  } 
`;

export const RegistrationWrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 16px;

  @media (max-width: 700px) {
    width: 100%;
  }

  .MuiTypography-body1 {
    text-align: left;
    width: 100%;
  }

  .MuiFormControl-root {
    width: 100%;
    text-align: center;
  }

  .MuiInputBase-input {
    text-align: center;
    width: 100%;
    font-size: 18px;
    ${({ theme }) => `border-color: ${theme.colors.primary}`};
  }

  .MuiInput-underline:before {
    ${({ theme }) => `border-bottom: ${`1px solid ${theme.colors.primary}`}`};
  }

  .MuiFormHelperText-root {
    ${({ theme }) => `color: ${theme.colors.white}`};
    font-weight: bold;
    padding-top: 5px;
    text-align: center;
  }
`;

export const RegistrationHeaderText = styled.div`
  padding-top: 112px;
`;

export const CommonHelpText = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${styledTheme.colors.dark};
`;

export const MicrosoftButton = styled(SocialButton)`
  border-color: ${styledTheme.colors.robinEggBlue};
  border-width: 1px;
`;

export const SubmitSection = styled(Grid)`
  padding-top: 96px;
`;

export const CommonArrowForwardIcon = styled(ArrowForwardIcon)`
  margin-left: 11px;
`;

export const SkipButton = styled.div`
  width: 100%;
  text-align: center;

  label {
    font-family: ${styledTheme.fonts.Inter};
    font-style: normal;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${styledTheme.colors.gray400};
    cursor: pointer;
  }
`;
