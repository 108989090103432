/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRating = /* GraphQL */ `
  query GetRating($id: ID!) {
    getRating(id: $id) {
      id
      overallScore
      friendlinessScore
      knowledgeScore
      feedbackText
      createdAt
      updatedAt
      attorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
    }
  }
`;
export const listRatings = /* GraphQL */ `
  query ListRatings(
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        overallScore
        friendlinessScore
        knowledgeScore
        feedbackText
        createdAt
        updatedAt
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getEarning = /* GraphQL */ `
  query GetEarning($id: ID!) {
    getEarning(id: $id) {
      id
      createdAt
      updatedAt
      attorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      payment {
        id
        paymentMethod
        paymentStatus
        dateTime
        amount
        calculatedAttorneyAmount
        stripeToken
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        owner
        createdAt
        updatedAt
        promotionalCode {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listEarnings = /* GraphQL */ `
  query ListEarnings(
    $filter: ModelEarningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEarnings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getBankingInfo = /* GraphQL */ `
  query GetBankingInfo($id: ID!) {
    getBankingInfo(id: $id) {
      id
      accountNumber
      routingNumber
      bankName
      title
      createdAt
      updatedAt
      attorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const listBankingInfos = /* GraphQL */ `
  query ListBankingInfos(
    $filter: ModelBankingInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBankingInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountNumber
        routingNumber
        bankName
        title
        createdAt
        updatedAt
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        email
        phone
        profilePictureUrl
        promoCredits
        birthday
        gender
        permanentStripePaymentToken
        cognitoId
        acceptsTCAndPP
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      firstName
      lastName
      address1
      address2
      city
      state
      zip
      email
      phone
      profilePictureUrl
      promoCredits
      birthday
      gender
      permanentStripePaymentToken
      cognitoId
      acceptsTCAndPP
      createdAt
      updatedAt
      cases {
        items {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      clientPromotionalCodes {
        items {
          id
          isUsed
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          promotionalCode {
            id
            numberOfCredits
            code
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const getClientAgreement = /* GraphQL */ `
  query GetClientAgreement($id: ID!) {
    getClientAgreement(id: $id) {
      id
      privacyPolicyText
      termsAndConditionsText
      createdAt
      updatedAt
    }
  }
`;
export const listClientAgreements = /* GraphQL */ `
  query ListClientAgreements(
    $filter: ModelClientAgreementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientAgreements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        privacyPolicyText
        termsAndConditionsText
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAttorneyAgreement = /* GraphQL */ `
  query GetAttorneyAgreement($id: ID!) {
    getAttorneyAgreement(id: $id) {
      id
      privacyPolicyText
      termsAndConditionsText
      createdAt
      updatedAt
    }
  }
`;
export const listAttorneyAgreements = /* GraphQL */ `
  query ListAttorneyAgreements(
    $filter: ModelAttorneyAgreementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttorneyAgreements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        privacyPolicyText
        termsAndConditionsText
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAttorneys = /* GraphQL */ `
  query ListAttorneys(
    $filter: ModelAttorneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttorneys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        licenseNumbers
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAttorney = /* GraphQL */ `
  query GetAttorney($id: ID!) {
    getAttorney(id: $id) {
      id
      firstName
      lastName
      emailWork
      emailPersonal
      cellPhone
      avatarUrl
      firmAssociation
      statesOfLicense
      licenseNumber
      liabilityInsuranceProvider
      currentProfessionalResponsibilityInvestigations
      practiceAreas
      communicationMethodsPreference
      acceptsTCAndPP
      attorneyRating
      cognitoId
      stripeAccountId
      stripeCustomerId
      stripeSubscriptionId
      earnings {
        items {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      ratings {
        items {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      bankAccounts {
        items {
          id
          accountNumber
          routingNumber
          bankName
          title
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      isOnline
      lastOnline
      phoneNumberUsageConsent
      approved
      createdAt
      updatedAt
      cases {
        items {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listCases = /* GraphQL */ `
  query ListCases(
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getCase = /* GraphQL */ `
  query GetCase($id: ID!) {
    getCase(id: $id) {
      id
      venueState
      legalAdviceDescription
      areaOfLaw
      consultationConnectionMethod
      rating {
        id
        overallScore
        friendlinessScore
        knowledgeScore
        feedbackText
        createdAt
        updatedAt
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
      }
      futureConsultationDateTime
      earning {
        id
        createdAt
        updatedAt
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        email
        phone
        profilePictureUrl
        promoCredits
        birthday
        gender
        permanentStripePaymentToken
        cognitoId
        acceptsTCAndPP
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      owner
      payment {
        id
        paymentMethod
        paymentStatus
        dateTime
        amount
        calculatedAttorneyAmount
        stripeToken
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        owner
        createdAt
        updatedAt
        promotionalCode {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        paymentMethod
        paymentStatus
        dateTime
        amount
        calculatedAttorneyAmount
        stripeToken
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        owner
        createdAt
        updatedAt
        promotionalCode {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      paymentMethod
      paymentStatus
      dateTime
      amount
      calculatedAttorneyAmount
      stripeToken
      earning {
        id
        createdAt
        updatedAt
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      owner
      createdAt
      updatedAt
      promotionalCode {
        items {
          id
          isUsed
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          promotionalCode {
            id
            numberOfCredits
            code
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const getClientPromotionalCode = /* GraphQL */ `
  query GetClientPromotionalCode($id: ID!) {
    getClientPromotionalCode(id: $id) {
      id
      isUsed
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        email
        phone
        profilePictureUrl
        promoCredits
        birthday
        gender
        permanentStripePaymentToken
        cognitoId
        acceptsTCAndPP
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      promotionalCode {
        id
        numberOfCredits
        code
        status
        createdAt
        updatedAt
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listClientPromotionalCodes = /* GraphQL */ `
  query ListClientPromotionalCodes(
    $filter: ModelClientPromotionalCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientPromotionalCodes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isUsed
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        promotionalCode {
          id
          numberOfCredits
          code
          status
          createdAt
          updatedAt
          clientPromotionalCodes {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listPromotionalCodes = /* GraphQL */ `
  query ListPromotionalCodes(
    $filter: ModelPromotionalCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromotionalCodes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        numberOfCredits
        code
        status
        createdAt
        updatedAt
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPromotionalCode = /* GraphQL */ `
  query GetPromotionalCode($id: ID!) {
    getPromotionalCode(id: $id) {
      id
      numberOfCredits
      code
      status
      createdAt
      updatedAt
      clientPromotionalCodes {
        items {
          id
          isUsed
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          promotionalCode {
            id
            numberOfCredits
            code
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const getAttorneyConsultationRequest = /* GraphQL */ `
  query GetAttorneyConsultationRequest($id: ID!) {
    getAttorneyConsultationRequest(id: $id) {
      id
      requestedAttorneys
      createdAt
      updatedAt
      selectedAttorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      consultation {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        lastOnlineClient
        lastOnlineAttoney
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const listAttorneyConsultationRequests = /* GraphQL */ `
  query ListAttorneyConsultationRequests(
    $filter: ModelAttorneyConsultationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttorneyConsultationRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        requestedAttorneys
        createdAt
        updatedAt
        selectedAttorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        consultation {
          id
          connectionMethod
          dateTimeOccurred
          videoConnectionToken
          chatConnectionToken
          conversationTranscript
          status
          clientPrefferedConnectionMethod
          purchasedLength
          actualLength
          lastOnlineClient
          lastOnlineAttoney
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const listConsultations = /* GraphQL */ `
  query ListConsultations(
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        lastOnlineClient
        lastOnlineAttoney
        flow
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getConsultation = /* GraphQL */ `
  query GetConsultation($id: ID!) {
    getConsultation(id: $id) {
      id
      connectionMethod
      dateTimeOccurred
      videoConnectionToken
      chatConnectionToken
      conversationTranscript
      status
      clientPrefferedConnectionMethod
      purchasedLength
      actualLength
      lastOnlineClient
      lastOnlineAttoney
      clientJoined
      attorneyJoined
      paymentCaptured
      flow
      createdAt
      updatedAt
      owner
    }
  }
`;
export const getFutureConsultation = /* GraphQL */ `
  query GetFutureConsultation($id: ID!) {
    getFutureConsultation(id: $id) {
      id
      dateTimeScheduled
      notifiedByEmail
      createdAt
      updatedAt
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      consultation {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        lastOnlineClient
        lastOnlineAttoney
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const listFutureConsultations = /* GraphQL */ `
  query ListFutureConsultations(
    $filter: ModelFutureConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFutureConsultations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dateTimeScheduled
        notifiedByEmail
        createdAt
        updatedAt
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        consultation {
          id
          connectionMethod
          dateTimeOccurred
          videoConnectionToken
          chatConnectionToken
          conversationTranscript
          status
          clientPrefferedConnectionMethod
          purchasedLength
          actualLength
          lastOnlineClient
          lastOnlineAttoney
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getImmediateConsultation = /* GraphQL */ `
  query GetImmediateConsultation($id: ID!) {
    getImmediateConsultation(id: $id) {
      id
      createdAt
      updatedAt
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      consultation {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        lastOnlineClient
        lastOnlineAttoney
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const listImmediateConsultations = /* GraphQL */ `
  query ListImmediateConsultations(
    $filter: ModelImmediateConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImmediateConsultations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        consultation {
          id
          connectionMethod
          dateTimeOccurred
          videoConnectionToken
          chatConnectionToken
          conversationTranscript
          status
          clientPrefferedConnectionMethod
          purchasedLength
          actualLength
          lastOnlineClient
          lastOnlineAttoney
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getPastEngagement = /* GraphQL */ `
  query GetPastEngagement($id: ID!) {
    getPastEngagement(id: $id) {
      id
      textChatlog
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        email
        phone
        profilePictureUrl
        promoCredits
        birthday
        gender
        permanentStripePaymentToken
        cognitoId
        acceptsTCAndPP
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
    }
  }
`;
export const listPastEngagements = /* GraphQL */ `
  query ListPastEngagements(
    $filter: ModelPastEngagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPastEngagements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        textChatlog
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
