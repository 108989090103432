import { ChangeEvent, useEffect, useState } from "react";
import { Grid, Tooltip } from "@material-ui/core";
import uniq from "lodash/uniq";
import xor from "lodash/xor";
import { NotificationMethodEnum, EmailNotificationStatus, SMSNotificationStatus } from "constant/enum";
import {
  PaperWrapper,
  OptionWrapper,
  CommonFormControl,
  HeadingSpacing,
  CommonCheckbox,
  CommonFormControlLabel,
  BoxWrapper
} from "./notificationSetting.styles";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import { styledTheme } from "../../../../../styles/StyledTheme";
import {
  EmailNotificationActive,
  EmailNotificationInactive,
  SmsNotificationActive,
  SmsNotificationInactive,
} from "icons"
interface INotificationSettingProps {
  phoneNumber: string;
  notificationMethods: NotificationMethodEnum[];
  handleUpdateAttorney: ({ notificationMethods }) => void;
}

const NotificationSetting = (props: INotificationSettingProps) => {
  const { handleUpdateAttorney, phoneNumber } = props;
  const notificationMethods = Array.isArray(props?.notificationMethods)
    ? props.notificationMethods
    : [];

  const [notificationState, setNotificationState] =
    useState<NotificationMethodEnum[]>(notificationMethods);

  function handleChange(method: NotificationMethodEnum): void {
    let newState: NotificationMethodEnum[] = []
    if (notificationState?.includes(method)) {
      newState = notificationState?.filter((item) => item !== method)
    } else {
      newState = [...notificationState, method]
    }

    const uniqNewState = uniq(newState);
    handleUpdateAttorney({
      notificationMethods: uniqNewState,
    });
    setNotificationState(uniqNewState);
  }

  useEffect(() => {
    if (xor(notificationMethods, notificationState)?.length > 0) {
      setNotificationState(notificationMethods);
    }
  }, [notificationMethods]);

  return (
    <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
      <HeadingSpacing>Notification Setting</HeadingSpacing>
      <PaperWrapper square>
        <Grid className="wrapper">
          <CommonFormControl component="fieldset">
            <OptionWrapper>
              <Grid xl={12} lg={12} md={12} sm={12} xs={12} >
                {!phoneNumber && (
                  <Tooltip title="Update phone number to unlock!">
                    <BoxWrapper className="inactive">
                      <div className="icon-disabled">
                        <AddCircleOutlineRoundedIcon
                          style={{ color: styledTheme.colors.infoDark }}
                        />
                      </div>
                      <div className="text">
                        <img className="notification-icon" src={SmsNotificationInactive}/>
                        {SMSNotificationStatus.OFF}
                      </div>
                    </BoxWrapper>
                  </Tooltip>
                )}
                {!!phoneNumber && (
                  <BoxWrapper
                    className={notificationState?.includes(NotificationMethodEnum.SMS) ? "active" : "inactive"}
                    onClick={() => handleChange(NotificationMethodEnum.SMS)}
                  >
                    <div className="icon">
                      {
                        notificationState?.includes(NotificationMethodEnum.SMS) ? (
                          <RemoveCircleOutlineRoundedIcon
                            style={{ color: styledTheme.colors.secondaryDark }}
                          />
                        ) : (
                          <AddCircleOutlineRoundedIcon
                            style={{ color: styledTheme.colors.infoDark }}
                          />
                        )
                      }
                    </div>
                    <div className="text">
                      <img className="notification-icon" src={
                        notificationState?.includes(NotificationMethodEnum.SMS) 
                        ? SmsNotificationActive
                        : SmsNotificationInactive
                      }/>
                      {notificationState?.includes(NotificationMethodEnum.SMS) ? SMSNotificationStatus.ON : SMSNotificationStatus.OFF}
                    </div>
                  </BoxWrapper>
                )}
              </Grid>
              <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                <CommonFormControl>
                  <BoxWrapper
                    className={!notificationState?.includes(NotificationMethodEnum.EMAIL) ? "has-margin-top inactive" : " has-margin-top active"}
                    onClick={() => handleChange(NotificationMethodEnum.EMAIL)}
                  >
                    <div className="icon">
                      {
                        notificationState?.includes(NotificationMethodEnum.EMAIL) ? (
                          <RemoveCircleOutlineRoundedIcon
                            style={{ color: styledTheme.colors.secondaryDark }}
                          />
                        ) : (
                          <AddCircleOutlineRoundedIcon
                            style={{ color: styledTheme.colors.infoDark }}
                          />
                        )
                      }
                    </div>
                    <div className="text">
                      <img className="notification-icon" src={
                        notificationState?.includes(NotificationMethodEnum.EMAIL) 
                        ? EmailNotificationActive
                        : EmailNotificationInactive
                      }/>
                      {notificationState?.includes(NotificationMethodEnum.EMAIL) ? EmailNotificationStatus.ON : EmailNotificationStatus.OFF}
                    </div>
                  </BoxWrapper>
                </CommonFormControl>
              </Grid>
            </OptionWrapper>
          </CommonFormControl>
        </Grid>
      </PaperWrapper>
    </Grid>
  );
};

export default NotificationSetting;
