import { IAttorneyTimeFrame } from "interfaces";
import { IFilter } from "types";

export function getTimeFrameFilter(
  attorneyId: string
): IFilter<IAttorneyTimeFrame> {
  const timeFrameFilter: IFilter<IAttorneyTimeFrame> = {
    where: {
      attorneyId: {
        eq: attorneyId,
      },
    },
  };
  return timeFrameFilter;
}
