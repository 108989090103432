import { API, graphqlOperation } from "aws-amplify";
import { updateAttorney as UpdateAttorney } from "gql/mutations";
import { StripeSubscriptionStatus, SubscribeStatus } from "constant/enum";
import {
  ISubscriptionParams,
  IUnSubscriptionSurvey,
} from "interfaces/subscription";
import { IUserInfo } from "interfaces/user";
import stripeService from "services/stripeService";
import SubscriptionStore from "stores/subscriptionStore";
import { createUnSubscriptionSurvey } from "gql";
import { errorHandler } from "utils/errorHandler";

export async function checkSubscription(
  setStripeError: (stripeError: boolean) => void,
  setSubscribeStatus: (subscribeStatus: SubscribeStatus) => void,
  setSubscription: (subscription) => void,
  setCustomer: (stripeCustomer) => void,
  userInfo: IUserInfo
): Promise<void> {
  setStripeError(false);

  try {
    if (!userInfo.stripeSubscriptionId) {
      setSubscribeStatus(SubscribeStatus.UN_SUBSCRIBED);
    } else {
      const stripeSubscription =
        await stripeService.retrieveAttorneySubscription(
          userInfo.stripeSubscriptionId
        );
      setSubscription(stripeSubscription);

      if (stripeSubscription.status === StripeSubscriptionStatus.CANCELED) {
        setSubscribeStatus(SubscribeStatus.UN_SUBSCRIBED);
      } else {
        setSubscribeStatus(SubscribeStatus.SUBSCRIBED);
      }

      const stripeCustomer = await stripeService.retrieveCustomer(
        stripeSubscription.customer
      );
      setCustomer(stripeCustomer);
    }
  } catch (err) {
    console.error(err);
    errorHandler(err);
    setSubscribeStatus(SubscribeStatus.UN_SUBSCRIBED);
    setStripeError(true);
  }
}

export async function onSuccessfulSubscription(
  subscriptionParam: ISubscriptionParams,
  setSubscription: (subscription) => void,
  setCustomer: (stripeCustomer) => void,
  userInfo: IUserInfo,
  updateUserInfo: (userInfo) => void,
  setSubscribeStatus: (subscribeStatus) => void,
  onClose: () => void,
  subscriptionStore: SubscriptionStore
): Promise<void> {
  setSubscription(subscriptionParam.subscription);

  const stripeCustomer = await stripeService.retrieveCustomer(
    subscriptionParam.customer.id
  );
  setCustomer(stripeCustomer);

  await API.graphql(
    graphqlOperation(UpdateAttorney, {
      input: {
        id: userInfo.id,
        stripeCustomerId: subscriptionParam.customer.id,
        stripeSubscriptionId: subscriptionParam.subscription.id,
      },
    })
  );
  updateUserInfo({
    ...userInfo,
    stripeCustomerId: subscriptionParam.customer.id,
    stripeSubscriptionId: subscriptionParam.subscription.id,
  });
  setSubscribeStatus(SubscribeStatus.SUBSCRIBED);
  subscriptionStore.getSubscriptionInformation(userInfo?.stripeSubscriptionId);
  onClose();
}

export async function unSubscribeAttorney(
  subscriptionId: string,
  userInfo: IUserInfo,
  updateUserInfo: (userInfo) => void,
  subscriptionStore: SubscriptionStore,
  unSubscriptionSurvey: IUnSubscriptionSurvey
): Promise<void> {
  if (!subscriptionId) return;

  try {
    await stripeService.cancelAttorneySubscription(subscriptionId);
    await API.graphql(
      graphqlOperation(UpdateAttorney, {
        input: {
          id: userInfo.id,
          stripeCustomerId: null,
          stripeSubscriptionId: null,
        },
      })
    );
    updateUserInfo({
      ...userInfo,
      stripeCustomerId: null,
      stripeSubscriptionId: null,
    });
    await API.graphql(
      graphqlOperation(createUnSubscriptionSurvey, {
        input: {
          ...unSubscriptionSurvey,
        },
      })
    );
    subscriptionStore.getSubscriptionInformation(
      userInfo?.stripeSubscriptionId
    );
  } catch (err) {
    errorHandler(err);
    console.error(err);
  }
}
