import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)`
  padding-top: 32px;
`;

export const Header = styled(Grid)`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  color: ${styledTheme.colors.primaryGray};
  text-align: left;
`;

export const ContentWrapper = styled(Grid)``;

export const DayBlock = styled(Grid)``;

export const Warning = styled.div`
  text-align: left;

  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  padding-top: 24px;
  padding-bottom: 12px;
  color: ${styledTheme.colors.primaryGray};
`;
