import { Banner1Svg, Banner2Svg, Banner3Svg } from 'icons'

export const ARTICLES = [
  {
    title: 'What is LegalQ?',
    subtitle: 'Create your account in a few simple steps, log in and “Go Online” to offer your services.',
    content: 'LegalQ is a platform that enables attorneys to connect with people seeking legal advice. Attorneys use the platform to and grow their practice by meeting potential new clients.',
    img: Banner1Svg
  },
  {
    title: 'Our Statments',
    subtitle: 'Always be kind, courteous and provide the best legal counsel.',
    content: 'As part of LegalQ you have to give the user the best possible legal advice and experience you can.',
    img: Banner2Svg
  },
  {
    title: 'How does it work?',
    subtitle: 'Every client will have the opportunity to rate you on the overall experience, your knowledge, and your friendliness',
    content: 'Users download the LegalQ app, create an account, and receive a free 15 minute or prepay for a 60 minute limited scope legal consultations with an attorney. You\'ll be notified when a user submits a legal inquiry in your jurisdiction and practice area. You choose whether to accept the request. It\'s that simple.',
    img: Banner3Svg
  }
]
