import React, { useState } from 'react'
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Snackbar, CircularProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import { forgotPassword, forgotPasswordSubmit } from 'services/auth-service'

const ForgotPassDialog = ({
  open = true,
  onClose= () => {}
}) => {
  const [errors, setErrors] = useState({})
  const [errMsg, setErrMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [step, setStep] = useState(1)
  const [form, setForm] = useState({
    email: '',
    code: '',
    password: '',
    confirmPass: ''
  })

  const validate = () => {
    let errors = {};

    const {email, code, password, confirmPass} = form;

    if(step === 1) {
      !email && (errors.email = 'required')
    }
    else if(step === 2) {
      !code && (errors.code = 'required');

      (!password && (errors.password = 'required')) || 
      (password.length < 8 && (errors.password = 'at least 8 characters'));
      
      (!confirmPass && (errors.confirmPass = 'required')) || 
      (confirmPass !== password && (errors.confirmPass = 'password does not match'));
    }
    else {
      return true;
    }

    setErrors(errors)

    return Object.keys(errors).length === 0;
  }

  const handleSubmit = async () => {
    if(!validate()) return;
    setLoading(true);

    if(step === 1) {
      let result = await forgotPassword(form.email);
      if(result.error)
        setErrMsg(result.error);
      else
        setStep(step + 1)
    }
    else if(step === 2) {
      let result = await forgotPasswordSubmit(form);
      if(result.error)
        setErrMsg(result.error)
      else {
        setStep(step + 1);
      }
    }
    else {
      onClose();
    }

    setLoading(false);
  }

  const handleResend = async () => {
    setStatus('sending..');
    await forgotPassword(form.email)
    setStatus('code sent!');
    setTimeout(() => {
      setStatus('');
    }, 3000)
  }

  const onChange = (key, val) => {
    errors[key] = null;
    setErrors({...errors})
    setForm({...form, [key]: val})
  }

  return (
    <Dialog open={open} onClose={ () => onClose() }>
      <Snackbar anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }} open={errMsg !== ''} autoHideDuration={6000} onClose={e=>setErrMsg('')}>
        <Alert onClose={e=>setErrMsg('')} severity="error">
          {errMsg}
        </Alert>
      </Snackbar>
      <DialogTitle id="form-dialog-title">Forgot Password</DialogTitle>
      <DialogContent
        style={{width: 450, maxWidth: '100%'}}
        onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
      >
      {
        step === 1 &&
        <>
            {/* STEP 1 */}
            <DialogContentText>
              Please enter the email address
            </DialogContentText>
            <TextField
              error={errors.email}
              autoFocus
              margin="dense"
              // id="varification-code"
              label="Email address"
              type="text"
              onChange={e => onChange('email', e.target.value)}
              fullWidth
            />
        </>
      }
      {
        step === 2 && 
        <>
          {/* STEP 2 */}
          <DialogContentText>
            A verification code has been sent to your email
          </DialogContentText>
          <TextField
            error={errors.code}
            autoFocus
            margin="dense"
            // id="varification-code"
            label="Verification Code"
            type="text"
            onChange={e => onChange('code', e.target.value)}
            fullWidth
            />
          <TextField
            error={errors.password}
            autoFocus
            margin="dense"
            // id="varification-code"
            label="Password"
            type="password"
            onChange={e => onChange('password', e.target.value)}
            fullWidth
            />
          <TextField
            error={errors.confirmPass}
            autoFocus
            margin="dense"
            // id="varification-code"
            label="Confirm Password"
            type="password"
            onChange={e => onChange('confirmPass', e.target.value)}
            fullWidth
            />
        </>
      }
      {
        step === 3 && 
        <>
          {/* STEP 3 */}
          <DialogContentText>
            Password changed successfully
          </DialogContentText>
        </>
      }
      </DialogContent>
      <DialogActions style={{alignItems: 'center'}}>
        {
          loading ?
            <Grid
              container
              justify="flex-end"
              alignItems="center"
              style={{minHeight: 36}}
            >
              <CircularProgress size={24}/>
            </Grid> :
          <>
          {}
            {
              status && <span style={{fontSize: 14, color: 'gray'}}>{status}</span>
            }
            {
              step === 2 &&
              <Button onClick={handleResend} color="primary" disabled={!!status}>
                Resend Code
              </Button>
            }
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              {step === 3 ? 'Close' : 'Submit'}
            </Button>
          </>
        }
      </DialogActions>
    </Dialog>
  )
}

export default ForgotPassDialog