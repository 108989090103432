import { CalendarDayCategory } from "constant/enum/calendar";
import { IOption } from "interfaces";

export const dayOptions: IOption<string>[] = [
  {
    label: "All week",
    value: CalendarDayCategory.ALL_WEEK,
  },
  {
    label: "Weekdays",
    value: CalendarDayCategory.WEEK_DAYS,
  },
  {
    label: "Custom",
    value: CalendarDayCategory.CUSTOM,
  },
];

export const dayOfWeek: number[] = [0, 1, 2, 3, 4, 5, 6];
