import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)``;

export const TitleText = styled(Grid)`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${styledTheme.colors.red500};
  padding-bottom: 24px;
`;

export const BodyText = styled(Grid)`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${styledTheme.colors.primaryBlack};
  padding-bottom: 32px;
`;
