import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import useStore from "utils/useStore";
import {
  Container,
  AgreementPolicy,
  SubscribeButton,
  ButtonContainer,
  CancelButton,
  UpdateButton,
} from "./footer.styles";

interface IFooterProps {
  onSubmit: (event: MouseEvent) => void;
  onUpdate: (event: MouseEvent) => void;
  onClose: () => void;
  editMode: boolean;
}

const Footer = (props: IFooterProps) => {
  const { subscriptionStore } = useStore();
  const { onSubmit, onClose, onUpdate, editMode } = props;

  const { isLoading } = subscriptionStore;

  return (
    <Container>
      <AgreementPolicy>
        By subscribing you agree to the following&nbsp;
        <a
          href="https://legalq.io/attorney-terms-of-use"
          target="_blank"
          rel="noreferrer"
        >
          Terms and Conditions
        </a>
      </AgreementPolicy>
      <ButtonContainer>
        {!editMode && (
          <SubscribeButton blocked={isLoading} onClick={onSubmit}>
            {isLoading && (
              <Loader type="TailSpin" color="#FFFFFF" height={16} width={16} />
            )}
            <span>SUBSCRIBE NOW!</span>
          </SubscribeButton>
        )}
        {editMode && (
          <CancelButton onClick={onClose}>
            {isLoading && (
              <Loader type="TailSpin" color="#FFFFFF" height={16} width={16} />
            )}
            <span>CANCEL</span>
          </CancelButton>
        )}
        {editMode && (
          <UpdateButton blocked={isLoading} onClick={onUpdate}>
            {isLoading && (
              <Loader type="TailSpin" color="#FFFFFF" height={16} width={16} />
            )}
            <span>UPDATE INFO</span>
          </UpdateButton>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default observer(Footer);
