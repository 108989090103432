import { Grid, Tooltip } from "@material-ui/core";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import AttorneyGuidelines from "./components/AttorneyGuidelines";
import SubscriptionInformation from "./components/SubscriptionInformation";
import { styledTheme } from "../../../styles/StyledTheme";
import useStore from "utils/useStore";
import { StripeSubscriptionStatus } from "constant/enum";
import get from "lodash/get";
import StripeSubscription from "./components/StripeSubscription";
import { TooltipLabel } from "./myAccountHeader.styles";

const AccountWrapper = styled.div`
  & > div {
    display: flex;
    justify-content: space-between;
  }

  .Info {
    h5 {
      font-weight: bold;
    }
    a {
      font-size: 16px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  .controls {
    &.MuiGrid-spacing-xs-3 {
      margin: 0px !important;
    }
  }
`;

const MyAccountHeader = ({
  firstName,
  lastName,
  emailWork,
  openUnSubscriptionModal,
  onClickSubscribe = () => {},
  onClickUnSubscribe = () => {},
  onClickAcceptUnSubscribe = () => {},
  onClickRejectUnSubscribe = () => {},
  onClickEditCard = () => {},
}) => {
  const { subscriptionStore } = useStore();
  const stripeSubscription = subscriptionStore?.stripeSubscription;
  const isStrategicPartner = subscriptionStore?.isStrategicPartner;

  const useStyles = makeStyles({
    root: {},
    iconText: {
      backgroundColor: styledTheme.colors.secondaryDarker,
    },
    iconVoice: {
      backgroundColor: styledTheme.colors.secondaryDark,
    },
    iconVideo: {
      backgroundColor: styledTheme.colors.secondary,
    },
  });
  const classes = useStyles();
  const emailChunks = emailWork?.split("@");
  const displayEmail =
    get(emailChunks, "[0].length", "") > 10
      ? `${get(emailChunks, "[0]", "")?.substr(0, 10)}...@${get(
          emailChunks,
          "[1]",
          ""
        )}`
      : emailWork;

  return (
    <AccountWrapper className={classes.root}>
      <Grid container item xs={12} alignItems="center">
        <Grid
          className="controls"
          container
          item
          direction="row"
          justify="center"
          alignItems="center"
        >
          {/*TODO: enable when turn on subscription
          stripeSubscription?.status === StripeSubscriptionStatus.ACTIVE && !isStrategicPartner && (
            <SubscriptionInformation
              onClickAcceptUnSubscribe={onClickAcceptUnSubscribe}
              onClickRejectUnSubscribe={onClickRejectUnSubscribe}
              onClickUnSubscribe={onClickUnSubscribe}
              openUnSubscriptionModal={openUnSubscriptionModal}
              onClickEditCard={onClickEditCard}
            />
          )
          */}
          {/*TODO: enable when turn on subscription
          stripeSubscription.status !== StripeSubscriptionStatus.ACTIVE && !isStrategicPartner && (
            <PaymentGuidelines onClickSubscribe={onClickSubscribe} />
          )*/}
          {/*<AttorneyGuidelines />*/}
          <StripeSubscription />
        </Grid>
      </Grid>
    </AccountWrapper>
  );
};

export default observer(MyAccountHeader);
