import { ReactNode } from "react";
import { MatButton } from "./commonButton.styles";

interface ICommonButtonProps {
  children: ReactNode;
  variant: "text" | "outlined" | "contained";
  onClick: (event) => void;
}

const CommonButton = (props: ICommonButtonProps) => {
  const { children, variant, onClick, ...rest } = props;

  return (
    <MatButton {...rest} variant={variant} onClick={onClick}>
      {children}
    </MatButton>
  );
};

export default CommonButton;
