import { API, graphqlOperation } from "aws-amplify";
import { AttorneyConsultationRequestStatus } from "constant/enum";
import {
  ListAttorneyConsultationRequests,
  UpdateAttorneyConsultationRequest,
} from "gql";
import {
  IAttorneyConsultationRequest,
  IAttorneyConsultationRequestAttorney,
} from "interfaces";
import get from "lodash/get";
import { IFilter } from "types/query";
import { api } from "API";
import { GraphQLParamsEnum } from "constant/enum/common";
import { errorHandler } from "utils/errorHandler";
import {
  IConsultationRequestCaseDetail,
  ITypedConsultation,
} from "../interfaces/acceptConsultationRequest";
import { AxiosResponse } from "axios";

export async function getAttorneyConsultationRequestsByAttorneyId(
  attorneyId: string
): Promise<IAttorneyConsultationRequest[]> {
  try {
    let attorneyConsultationRequest: IAttorneyConsultationRequest[] = [];
    let isDone = false;
    let nextToken = "";

    while (!isDone) {
      const data = await API.graphql(
        graphqlOperation(ListAttorneyConsultationRequests, {
          limit: GraphQLParamsEnum.LIMIT_RESULT,
          filter: {
            requestedAttorneys: {
              contains: attorneyId,
            },
            status: {
              eq: AttorneyConsultationRequestStatus.PENDING,
            },
            autoScheduleMode: {
              ne: true,
            },
          },
          ...(nextToken ? { nextToken: nextToken } : {}),
        })
      );
      const currentConsultationRequest: IAttorneyConsultationRequest[] = get(
        data,
        "data.listAttorneyConsultationRequests.items",
        []
      );
      nextToken = get(data, "data.listAttorneyConsultationRequests.nextToken");
      attorneyConsultationRequest = [
        ...attorneyConsultationRequest,
        ...currentConsultationRequest,
      ];
      if (!nextToken) {
        isDone = true;
      }
    }
    return attorneyConsultationRequest;
  } catch (error) {
    return [];
  }
}

export async function updateAttorneyConsultationRequestById(
  id: string,
  attorneyConsultationRequest: IAttorneyConsultationRequest
): Promise<IAttorneyConsultationRequest> {
  const data = await API.graphql(
    graphqlOperation(UpdateAttorneyConsultationRequest, {
      input: {
        ...attorneyConsultationRequest,
        id,
      },
    })
  );
  return get(data, "data.updateAttorneyConsultationRequest", {});
}

export async function updateAttorneyConsultationRequestAttorneyById(
  id: string,
  attorneyConsultationRequestAttorney: IAttorneyConsultationRequestAttorney
): Promise<IAttorneyConsultationRequestAttorney[]> {
  try {
    if (!id) throw new Error("id-required");
    const response = await api.patch(
      `/attorney-consultation-request-attorneys/${id}`,
      attorneyConsultationRequestAttorney
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
    return error?.message;
  }
}

export async function getAttorneyConsultationRequestAttorneys(
  filter: IFilter<IAttorneyConsultationRequestAttorney> = {}
): Promise<IAttorneyConsultationRequestAttorney[]> {
  try {
    const response = await api.get(
      `/attorney-consultation-request-attorneys?filter=${JSON.stringify(
        filter
      )}`
    );
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function acceptConsultationRequest(
  attorneyId: string,
  clientId: string,
  cognitoId: string,
  caseDetails: IConsultationRequestCaseDetail
): Promise<ITypedConsultation> {
  try {
    const response: AxiosResponse = await api.patch(
      `/accept-consultation-request`,
      {
        caseDetails,
        cognitoId,
        clientId,
        attorneyId,
      }
    );
    return response.data;
  } catch (error) {
    errorHandler(error.response.data.error);
    throw error.response.data.error;
  }
}

export async function updateAttorneyConsultationRequestWaitingTime(
  attorneyConsultationRequestId: string,
  attorneyId: string
): Promise<void> {
  try {
    await api.patch(
      `/attorney-consultation-request/update-attorney-wait-time`,
      {
        attorneyConsultationRequestId: attorneyConsultationRequestId,
        attorneyId: attorneyId,
      }
    );
  } catch (error) {
    errorHandler(error);
  }
}
