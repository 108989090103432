import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled(Grid)`
  max-width: 891px;
`;

export const Title = styled(Grid)`
  padding-bottom: 24px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 28px;
  text-align: center;
  color: #e53e3e;
`;

export const Description = styled(Grid)`
  max-width: 771px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #26272c;
`;
