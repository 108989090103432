import React, { useState, useContext, useEffect } from 'react'
import { Grid, FormControl, FormControlLabel, FormHelperText, Checkbox, CircularProgress } from '@material-ui/core';
import styled from 'styled-components'
import { signIn, checkAuth } from 'services/auth-service'
import { API, graphqlOperation } from 'aws-amplify'
import { listAttorneys as ListAttorneys } from 'gql/queries'
import { UserContext, LoaderContext } from 'contexts'
import { useHistory } from 'react-router-dom'
import CustomButton from '../../../components/common/ContainedButton';
import CustomInput from '../../../components/common/CustomInput';
import VerificationCodeDialog from './VerificationCodeDialog'
import { GStyledGrid } from '../../../components/common/MaterialUIStyledComponents'
import ForgotPassDialog from './ForgotPassDialog'
import { styledTheme } from '../../../styles/StyledTheme'
import { errorHandler } from 'utils/errorHandler';
import { updateOnlineStatus } from 'components/Sidebar/utils';
import useStore from 'utils/useStore';
import { observer } from 'mobx-react';
import { getSessionStorage } from 'utils/common/storage';
import { StorageKeys } from 'constant/enum/storage';
import isUndefined from 'lodash/isUndefined'
import { toast } from 'react-toastify';
import { GraphQLParamsEnum } from 'constant/enum';

let analytics = window.analytics;

const Login = ({ setErrorMsg }) => {
  const [userInfo, setContextUserInfo] = useContext(UserContext) //eslint-disable-line
  const [isLoaderShown, setIsLoaderShown] = useContext(LoaderContext) //eslint-disable-line
  const history = useHistory()
  const [isRemeberChecked, setIsRememberChecked] = useState(true)
  const [isValidationForCodeOpen, setIsValidationForCodeOpen] = useState(false)
  const [isForgotFlowOpen, setIsForgotFlowOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [validationErrors, setValidationErrors] = useState({})
  const [password, setPassword] = useState('')
  const { attorneyStore } = useStore()

  const handleIsRemeberChange = (event) => {
    setIsRememberChecked(event.target.checked)
  }
  
  const fetchAttorneyNProcess = async (id, proceed = false) => {
    try {
      const attorneys = await API.graphql(graphqlOperation(ListAttorneys, {
        limit: GraphQLParamsEnum.LIMIT_RESULT,
        filter: {
          cognitoId: {
            eq: id
          }
        }
      }))
      if (attorneys.data.listAttorneys.items.length === 0) {
        if (id && proceed) {
          setContextUserInfo({
            ...userInfo,
            email,
            password
          })
          history.push('/registration/user-details')
        }
        return
      } else {
        const attorney = attorneys.data.listAttorneys.items[0];
        setContextUserInfo({ ...attorneys.data.listAttorneys.items[0] })
        const attorneyUpdatedStatus = getSessionStorage(
          StorageKeys.ATTORNEY_UPDATE_STATUS
        );
        if (attorney?.id && isUndefined(attorneyUpdatedStatus)) {
          updateOnlineStatus(attorneyStore, attorney?.id, true)
        }
        if (analytics) {
          analytics.identify(attorney.id, {
            name: attorney.firstName + " " + attorney.lastName,
            email: attorney.emailWork,
          });
        }
        if (proceed) {
          if (attorney.approved) {
            history.push('/dashboard/my-account')
          }
          else {
            history.push('/registration/confirmation')
          }
        }
        else {
          if (attorney.approved){
            history.push('/dashboard/my-account')
          }else{
            setContextUserInfo({})
          }
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  }

  useEffect(() => {
    checkAuth().then(user => fetchAttorneyNProcess(user.id)).catch(e => { })
  }, [])



  const validate = () => {
    let emailError = email === '', passwordError = password === ''
    setValidationErrors({
      ...validationErrors,
      email: emailError,
      password: passwordError
    })
    return !emailError && !passwordError
  }

  const resetFieldErrors = fieldName => {
    const dup = { ...validationErrors }
    delete dup[fieldName]
    setValidationErrors(dup)
  }

  const handlePostVerication = async () => {
    setIsLoaderShown(true)
    setIsValidationForCodeOpen(false)
    setContextUserInfo({
      ...userInfo,
      email,
      password
    })
    await signIn({
      username: email,
      password: password
    })
    history.push('/registration/user-details')
    setIsLoaderShown(false)
  }

  const handleLogin = async e => {
    try {
      setIsLoading(true)
      const user = await signIn({
        username: email,
        password: password
      })
      const checkAttorney = user.signInUserSession.idToken.payload['cognito:groups'].find(v => v === 'Attorney')
      if (checkAttorney !== 'Attorney') {
        setErrorMsg('Invalid username or password.')
        return
      }
      await fetchAttorneyNProcess(user.username, true)
    } catch (e) {
      setIsLoading(false)
      if (e.code === 'UserNotConfirmedException') {
        setIsValidationForCodeOpen(true)
        setErrorMsg('Please complete verification')
      }
      else if (e.code === 'UserNotFoundException')
        setErrorMsg('Invalid username or password.')
      else
        setErrorMsg('Invalid username or password.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleLoginSubmit = async e => {
    e.preventDefault()
    setErrorMsg('')

    if (!validate()) {
      toast.error('Invalid username or password.')
      return
    }
    handleLogin();
  }

  const initForgotPassFlow = () => {
    setIsForgotFlowOpen(true);
  }

  return (
    <LoginWrapper>
    <ForgotPassDialog
      open={isForgotFlowOpen}
      onClose={() => setIsForgotFlowOpen(false)}
    />
    <VerificationCodeDialog
      open={isValidationForCodeOpen}
      email={email}
      firstName={userInfo?.firstName}
      lastName={userInfo?.lastName}
      onVerificationCompleted={handlePostVerication}
      resendCode={true}
      onClose={() => setIsValidationForCodeOpen(false)}
    />
    <form onSubmit={handleLoginSubmit}>
      <Grid container>
        <EmailWrapper>
          <Grid item xs={12}>
            <FormControl className="formControl" error={validationErrors?.email}>
              <CustomInput value={email} onChange={e => { resetFieldErrors('email'); setEmail(e.target.value) }} type='email' id='email-input' aria-describedby='email-helper-text' placeholder='Complete email' />
              <StyledFormHelperText id='email-helper-text'>EMAIL</StyledFormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className="formControl pt-4" error={validationErrors?.password}>
              <CustomInput value={password} onChange={e => { resetFieldErrors('password'); setPassword(e.target.value) }} type='password' id='password-input' aria-describedby='password-helper-text' placeholder='Complete password' />
              <StyledFormHelperText id='password-helper-text'>PASSWORD</StyledFormHelperText>
            </FormControl>
          </Grid>
        </EmailWrapper>

        <Grid item xs={12} className="pt-3 float-left">
          <FormControlLabel
            control={<Checkbox checked={isRemeberChecked} onChange={handleIsRemeberChange} style={{ color: styledTheme.colors.primary }} name='checkedA' />}
            label='Remember me'
          />
        </Grid>

        <Grid item xs={12} className="pt-4 pl-5 pr-5">
          <CustomButton id="login-button" color='primary' width='100%' disabled={isLoading}
            style={{
              padding: "16px 0px",
              boxShadow: '0px 16px 30px -10px rgba(70, 166, 247, 0.5), 0px 20px 20px -20px #46A6F7',
              borderRadius: 12,
            }}
          >
            {(isLoading) ? <CircularProgress size={24} /> : <span>LOG IN</span>}
          </CustomButton>
        </Grid>

        <GStyledGrid
          item
          className="pt-4"
          fontSize='13px'
          fontWeight='bold'
          color={styledTheme.colors.primary}
          xs={12}
          style={{ cursor: 'pointer' }}
          onClick={() => initForgotPassFlow()}
        >
          Forgot password?
        </GStyledGrid>

      </Grid>
    </form>
    </LoginWrapper>
  )
}

export default observer(Login);

const LoginWrapper = styled.div`
  padding: 70px 0;
  .MuiTypography-body1 {
    ${({ theme }) => `color: ${theme.colors.primary}`};
    font-weight:bold;
  }

  .MuiInputBase-input{
    text-align: left !important;
  }

  .MuiFormHelperText-root {
    text-align:left !important;
  }
`
const StyledFormHelperText = styled(FormHelperText)`
  text-align: center;
`
const EmailWrapper = styled.div`
  width:100%;
  .formControl{
    width:80%;
    margin:auto; 
    .MuiInput-underline:before {
      ${({ theme }) => `border-bottom: ${`1px solid ${theme.colors.primary}`}`};
    }
    .MuiInputBase-input{
      text-align:center;
      width:100%;
      font-size:16px;
      ${({ theme }) => `border-color: ${theme.colors.primary}`};
    }
    .MuiFormHelperText-root {
      font-weight:bold;
      padding-top:10px;
    }
    .MuiFormHelperText-root:not(.Mui-error) {
      ${({ theme }) => `color: ${theme.colors.primary}`};
    }
  }
`