import { Container } from "./subscriptionPlan.styles";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { UserContext } from "contexts";
import { observer } from "mobx-react";
import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";
import { useStyles } from "./subscriptionPlan.styles";
import { useContext, useEffect, useState } from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import {
  checkSubscription,
  handleFormSubmit,
  handleUpdatePaymentMethod,
} from "../../utils";
import useStore from "utils/useStore";

interface ISubscriptionPlanProps {
  isOpen: boolean;
  onClose: () => void;
  editMode?: boolean;
}

export const SubscriptionPlan = (props: ISubscriptionPlanProps) => {
  const { subscriptionStore } = useStore();
  const [userInfo, updateUserInfo] = useContext(UserContext);
  const [subscribeStatus, setSubscribeStatus] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [stripeError, setStripeError] = useState(false);
  const [stripeCheck, setStripeCheck] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState();

  const { onClose, isOpen, editMode } = props;
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (userInfo && userInfo.id) {
      setStripeCheck(true);
    }
  }, [userInfo]);

  useEffect(() => {
    if (stripeCheck) {
      checkSubscription(
        setStripeError,
        setSubscribeStatus,
        setSubscription,
        setCustomer,
        userInfo
      );
    }
  }, [stripeCheck]);

  return (
    <Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <Header onClose={onClose} />
            <Body />
            <Footer
              editMode={editMode}
              onClose={onClose}
              onSubmit={(event) => {
                handleFormSubmit(
                  event,
                  userInfo,
                  elements,
                  stripe,
                  updateUserInfo,
                  setSubscriptionError,
                  setSubscription,
                  setCustomer,
                  setSubscribeStatus,
                  onClose,
                  subscriptionStore
                );
              }}
              onUpdate={(event) => {
                handleUpdatePaymentMethod(
                  event,
                  userInfo,
                  subscriptionStore,
                  elements,
                  stripe,
                  onClose
                );
              }}
            />
          </div>
        </Fade>
      </Modal>
    </Container>
  );
};

export default observer(SubscriptionPlan);
