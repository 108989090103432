import React, { useState } from "react";
import { EditIcon } from "icons";
import {
  Grid,
  FormControl,
  Input,
  FormHelperText,
  Button,
} from "@material-ui/core";
import styled from "styled-components";
import { GStyledLabel } from './StyledComponents'

const EditableWrapper = styled.div`
.MuiFormControl-root {
width:100%;
}
cursor:pointer;
  font-size: 16px;
  .MuiFormHelperText-root {
    font-size: 10px !important;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.defaultDark};
  }
  .MuiInput-underline:before {
  width:100%;
  }
`;

const IconWrapper = styled(Grid)`
  display:flex;
  justify-content: flex-end;
`;

const StyledHr = styled.hr`
  margin: 5px 0px;
`

const StyledImg = styled.img`
  height: 25px;
  width: 25px;
  transition: 0.25s;
  &:hover {
    background-color: rgb(236, 245, 250);
    border-radius:50px;
    transform: scale(1.1);
  }
`

// { value, setValue, Title }
const EditableInput = ({
  value,
  onSave,
  title,
  type = "text",
  placeholder,
  readOnly = false,
  hr = false,
  alwaysEnabled = false,
  errorMsg = null,
  multiline,
  onChange = () => { },
  ...rest
}) => {
  const [editMode, setEditMode] = useState(alwaysEnabled);
  const [currentValue, setCurrentValue] = useState(value);

  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  const handleToggleMode = () => {
    setEditMode(!editMode);
  };
  const handleSaveBtn = (e) => {
    handleToggleMode();
    onSave(currentValue);
  };

  return (
    <EditableWrapper {...rest}>
      {editMode && (
        <Grid container item xs={12}>
          <Grid item={true} xs={alwaysEnabled ? 12 : 9}>
            <FormControl>
              <Input
                type={type}
                placeholder={placeholder}
                value={currentValue}
                onChange={(e) => {
                  onChange(e.target.value);
                  setCurrentValue(e.target.value);
                }}
                error={errorMsg ? true : false}
                multiline={multiline}
              />
              <FormHelperText style={{ paddingTop: 10, color: errorMsg && '#d70404' }}>
                {title} {errorMsg && `(${errorMsg})`}
              </FormHelperText>
            </FormControl>
          </Grid>
          {
            !alwaysEnabled &&
            <Grid>
              <Button onClick={handleSaveBtn}>Save</Button>
            </Grid>
          }
        </Grid>
      )}
      {!editMode && (
        <Grid item xs={12}>
          <div style={readOnly ? { backgroundColor: '#e6e6e6', padding: '4px 12px' } : {}}>
            <Grid container item xs={12}>
              <Grid item xs={11}>
                {placeholder ?? value}
              </Grid>
              {
                !readOnly && !alwaysEnabled &&
                <IconWrapper item xs={1}>
                  <StyledImg src={EditIcon} alt="edit" onClick={handleToggleMode} />
                </IconWrapper>
              }
            </Grid>
            {(!readOnly || hr) && <StyledHr />}
          </div>
          <GStyledLabel paddingTop='10px'>{title}</GStyledLabel>
        </Grid>
      )}

    </EditableWrapper>
  );
};

export default EditableInput;
