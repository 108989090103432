import { Modal, Container, Fade, Backdrop } from "@material-ui/core";
import CalendarContent from "./components/CalendarContent";
import CalendarConnectFAQ from "./components/CalendarConnectFAQ";
import { useStyles } from "./setupCalendarModal.styles";
import { observer } from "mobx-react";
import useStore from "utils/useStore";

interface ISetupCalendarModalProps {
  isOpen: boolean;
  onClickClose: () => void;
}

const SetupCalendarModal = (props: ISetupCalendarModalProps) => {
  const { calendarStore } = useStore();
  const { displayConnectHelper } = calendarStore;
  const classes = useStyles();
  const { isOpen, onClickClose } = props;

  return (
    <Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={onClickClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <CalendarContent />
          </div>
        </Fade>
      </Modal>
      <CalendarConnectFAQ isOpen={displayConnectHelper} />
    </Container>
  );
};

export default observer(SetupCalendarModal);
