import { Container, Modal, Backdrop, Fade } from "@material-ui/core";
import Header from "./components/Header";
import MessageSection from "./components/MessageSection";
import { useStyles } from "./customerNotes.styles";

interface ICustomerNotesProps {
  isOpen: boolean;
  onClickClose: (event?) => void;
}

const CustomerNotes = (props: ICustomerNotesProps) => {
  const { isOpen, onClickClose } = props;
  const classes = useStyles();

  return (
    <Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={onClickClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <Header onClose={() => onClickClose()} />
            <MessageSection />
          </div>
        </Fade>
      </Modal>
    </Container>
  );
};

export default CustomerNotes;
