import { api } from "API";
import { AxiosResponse } from "axios";
import { IAttorneySetting } from "interfaces";
import { errorHandler } from "utils/errorHandler";

export async function upsertAttorneySetting(
  attorneySetting: Partial<IAttorneySetting>
): Promise<void> {
  try {
    await api.post(`/attorney-settings/upsert`, attorneySetting);
  } catch (error) {
    throw error?.response?.data?.error;
  }
}

export async function getAttorneySetting(
  attorneyId: string
): Promise<IAttorneySetting> {
  try {
    const response: AxiosResponse = await api.get(
      `/attorney-settings/${attorneyId}`
    );
    return response?.data;
  } catch (error) {
    errorHandler(error);
    return {} as IAttorneySetting;
  }
}
