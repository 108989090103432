/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStripePayment = /* GraphQL */ `
  mutation CreateStripePayment($input: PaymentInput) {
    createStripePayment(input: $input) {
      statusCode
      paymentIntentId
      error
    }
  }
`;
export const captureStripePayment = /* GraphQL */ `
  mutation CaptureStripePayment($input: PaymentCaptureInput) {
    captureStripePayment(input: $input) {
      statusCode
      error
    }
  }
`;
export const cancelStripePayment = /* GraphQL */ `
  mutation CancelStripePayment($input: PaymentCancelInput) {
    cancelStripePayment(input: $input) {
      statusCode
      error
    }
  }
`;
export const createRemoteClientPromotionalCode = /* GraphQL */ `
  mutation CreateRemoteClientPromotionalCode(
    $input: ClientPromotionalCodeInput
  ) {
    createRemoteClientPromotionalCode(input: $input) {
      statusCode
      id
      error
    }
  }
`;
export const createPromoCodePayment = /* GraphQL */ `
  mutation CreatePromoCodePayment($input: PromoCodePaymentInput) {
    createPromoCodePayment(input: $input) {
      statusCode
      id
      error
    }
  }
`;
export const requestAttorneyMatch = /* GraphQL */ `
  mutation RequestAttorneyMatch($input: RequestAttorneyMatchInput) {
    requestAttorneyMatch(input: $input) {
      statusCode
      matchedAttorneysNr
      error
    }
  }
`;
export const acceptConsultationRequest = /* GraphQL */ `
  mutation AcceptConsultationRequest($input: AcceptConsultationRequestInput) {
    acceptConsultationRequest(input: $input) {
      statusCode
      error
    }
  }
`;
export const confirmConsultation = /* GraphQL */ `
  mutation ConfirmConsultation($input: ConfirmConsultationInput) {
    confirmConsultation(input: $input) {
      statusCode
      id
      error
    }
  }
`;
export const setAttorneyTimestamp = /* GraphQL */ `
  mutation SetAttorneyTimestamp {
    setAttorneyTimestamp {
      statusCode
      id
      error
    }
  }
`;
export const getTwilioKeys = /* GraphQL */ `
  mutation GetTwilioKeys($input: GetTwilioKeysInput!) {
    getTwilioKeys(input: $input) {
      statusCode
      jwtToken
    }
  }
`;
export const getStripeKey = /* GraphQL */ `
  mutation GetStripeKey {
    getStripeKey {
      statusCode
      key
    }
  }
`;
export const getAnalyticsKeys = /* GraphQL */ `
  mutation GetAnalyticsKeys {
    getAnalyticsKeys {
      statusCode
      body
    }
  }
`;
export const sendConsultationSummaryEmail = /* GraphQL */ `
  mutation SendConsultationSummaryEmail(
    $input: SendConsultationSummaryEmailInput!
  ) {
    sendConsultationSummaryEmail(input: $input) {
      statusCode
      body
      error
    }
  }
`;
export const managePayment = /* GraphQL */ `
  mutation ManagePayment($input: ManagePaymentInput!) {
    managePayment(input: $input) {
      statusCode
      body
      error
    }
  }
`;
export const attorneySubscription = /* GraphQL */ `
  mutation AttorneySubscription($input: AttorneySubscriptionInput!) {
    attorneySubscription(input: $input) {
      statusCode
      body
      error
    }
  }
`;
export const createRating = /* GraphQL */ `
  mutation CreateRating(
    $input: CreateRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    createRating(input: $input, condition: $condition) {
      id
      overallScore
      friendlinessScore
      knowledgeScore
      feedbackText
      createdAt
      updatedAt
      attorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
    }
  }
`;
export const updateRating = /* GraphQL */ `
  mutation UpdateRating(
    $input: UpdateRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    updateRating(input: $input, condition: $condition) {
      id
      overallScore
      friendlinessScore
      knowledgeScore
      feedbackText
      createdAt
      updatedAt
      attorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
    }
  }
`;
export const deleteRating = /* GraphQL */ `
  mutation DeleteRating(
    $input: DeleteRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    deleteRating(input: $input, condition: $condition) {
      id
      overallScore
      friendlinessScore
      knowledgeScore
      feedbackText
      createdAt
      updatedAt
      attorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
    }
  }
`;
export const createEarning = /* GraphQL */ `
  mutation CreateEarning(
    $input: CreateEarningInput!
    $condition: ModelEarningConditionInput
  ) {
    createEarning(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      attorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      payment {
        id
        paymentMethod
        paymentStatus
        dateTime
        amount
        calculatedAttorneyAmount
        stripeToken
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        owner
        createdAt
        updatedAt
        promotionalCode {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateEarning = /* GraphQL */ `
  mutation UpdateEarning(
    $input: UpdateEarningInput!
    $condition: ModelEarningConditionInput
  ) {
    updateEarning(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      attorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      payment {
        id
        paymentMethod
        paymentStatus
        dateTime
        amount
        calculatedAttorneyAmount
        stripeToken
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        owner
        createdAt
        updatedAt
        promotionalCode {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteEarning = /* GraphQL */ `
  mutation DeleteEarning(
    $input: DeleteEarningInput!
    $condition: ModelEarningConditionInput
  ) {
    deleteEarning(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      attorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      payment {
        id
        paymentMethod
        paymentStatus
        dateTime
        amount
        calculatedAttorneyAmount
        stripeToken
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        owner
        createdAt
        updatedAt
        promotionalCode {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createBankingInfo = /* GraphQL */ `
  mutation CreateBankingInfo(
    $input: CreateBankingInfoInput!
    $condition: ModelBankingInfoConditionInput
  ) {
    createBankingInfo(input: $input, condition: $condition) {
      id
      accountNumber
      routingNumber
      bankName
      title
      createdAt
      updatedAt
      attorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const updateBankingInfo = /* GraphQL */ `
  mutation UpdateBankingInfo(
    $input: UpdateBankingInfoInput!
    $condition: ModelBankingInfoConditionInput
  ) {
    updateBankingInfo(input: $input, condition: $condition) {
      id
      accountNumber
      routingNumber
      bankName
      title
      createdAt
      updatedAt
      attorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteBankingInfo = /* GraphQL */ `
  mutation DeleteBankingInfo(
    $input: DeleteBankingInfoInput!
    $condition: ModelBankingInfoConditionInput
  ) {
    deleteBankingInfo(input: $input, condition: $condition) {
      id
      accountNumber
      routingNumber
      bankName
      title
      createdAt
      updatedAt
      attorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      firstName
      lastName
      address1
      address2
      city
      state
      zip
      email
      phone
      profilePictureUrl
      promoCredits
      birthday
      gender
      permanentStripePaymentToken
      cognitoId
      acceptsTCAndPP
      createdAt
      updatedAt
      cases {
        items {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      clientPromotionalCodes {
        items {
          id
          isUsed
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          promotionalCode {
            id
            numberOfCredits
            code
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      firstName
      lastName
      address1
      address2
      city
      state
      zip
      email
      phone
      profilePictureUrl
      promoCredits
      birthday
      gender
      permanentStripePaymentToken
      cognitoId
      acceptsTCAndPP
      createdAt
      updatedAt
      cases {
        items {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      clientPromotionalCodes {
        items {
          id
          isUsed
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          promotionalCode {
            id
            numberOfCredits
            code
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      firstName
      lastName
      address1
      address2
      city
      state
      zip
      email
      phone
      profilePictureUrl
      promoCredits
      birthday
      gender
      permanentStripePaymentToken
      cognitoId
      acceptsTCAndPP
      createdAt
      updatedAt
      cases {
        items {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      clientPromotionalCodes {
        items {
          id
          isUsed
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          promotionalCode {
            id
            numberOfCredits
            code
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createClientAgreement = /* GraphQL */ `
  mutation CreateClientAgreement(
    $input: CreateClientAgreementInput!
    $condition: ModelClientAgreementConditionInput
  ) {
    createClientAgreement(input: $input, condition: $condition) {
      id
      privacyPolicyText
      termsAndConditionsText
      createdAt
      updatedAt
    }
  }
`;
export const updateClientAgreement = /* GraphQL */ `
  mutation UpdateClientAgreement(
    $input: UpdateClientAgreementInput!
    $condition: ModelClientAgreementConditionInput
  ) {
    updateClientAgreement(input: $input, condition: $condition) {
      id
      privacyPolicyText
      termsAndConditionsText
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientAgreement = /* GraphQL */ `
  mutation DeleteClientAgreement(
    $input: DeleteClientAgreementInput!
    $condition: ModelClientAgreementConditionInput
  ) {
    deleteClientAgreement(input: $input, condition: $condition) {
      id
      privacyPolicyText
      termsAndConditionsText
      createdAt
      updatedAt
    }
  }
`;
export const createAttorneyAgreement = /* GraphQL */ `
  mutation CreateAttorneyAgreement(
    $input: CreateAttorneyAgreementInput!
    $condition: ModelAttorneyAgreementConditionInput
  ) {
    createAttorneyAgreement(input: $input, condition: $condition) {
      id
      privacyPolicyText
      termsAndConditionsText
      createdAt
      updatedAt
    }
  }
`;
export const updateAttorneyAgreement = /* GraphQL */ `
  mutation UpdateAttorneyAgreement(
    $input: UpdateAttorneyAgreementInput!
    $condition: ModelAttorneyAgreementConditionInput
  ) {
    updateAttorneyAgreement(input: $input, condition: $condition) {
      id
      privacyPolicyText
      termsAndConditionsText
      createdAt
      updatedAt
    }
  }
`;
export const deleteAttorneyAgreement = /* GraphQL */ `
  mutation DeleteAttorneyAgreement(
    $input: DeleteAttorneyAgreementInput!
    $condition: ModelAttorneyAgreementConditionInput
  ) {
    deleteAttorneyAgreement(input: $input, condition: $condition) {
      id
      privacyPolicyText
      termsAndConditionsText
      createdAt
      updatedAt
    }
  }
`;
export const createAttorney = /* GraphQL */ `
  mutation CreateAttorney(
    $input: CreateAttorneyInput!
    $condition: ModelAttorneyConditionInput
  ) {
    createAttorney(input: $input, condition: $condition) {
      id
      firstName
      lastName
      emailWork
      emailPersonal
      cellPhone
      avatarUrl
      firmAssociation
      statesOfLicense
      licenseNumber
      liabilityInsuranceProvider
      currentProfessionalResponsibilityInvestigations
      practiceAreas
      communicationMethodsPreference
      acceptsTCAndPP
      attorneyRating
      cognitoId
      stripeAccountId
      stripeCustomerId
      stripeSubscriptionId
      earnings {
        items {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      ratings {
        items {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      bankAccounts {
        items {
          id
          accountNumber
          routingNumber
          bankName
          title
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      isOnline
      lastOnline
      phoneNumberUsageConsent
      approved
      createdAt
      updatedAt
      cases {
        items {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateAttorney = /* GraphQL */ `
  mutation UpdateAttorney(
    $input: UpdateAttorneyInput!
    $condition: ModelAttorneyConditionInput
  ) {
    updateAttorney(input: $input, condition: $condition) {
      id
      firstName
      lastName
      emailWork
      emailPersonal
      cellPhone
      avatarUrl
      firmAssociation
      statesOfLicense
      licenseNumber
      liabilityInsuranceProvider
      currentProfessionalResponsibilityInvestigations
      practiceAreas
      communicationMethodsPreference
      acceptsTCAndPP
      attorneyRating
      cognitoId
      stripeAccountId
      stripeCustomerId
      stripeSubscriptionId
      earnings {
        items {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      ratings {
        items {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      bankAccounts {
        items {
          id
          accountNumber
          routingNumber
          bankName
          title
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      isOnline
      lastOnline
      phoneNumberUsageConsent
      approved
      createdAt
      updatedAt
      cases {
        items {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteAttorney = /* GraphQL */ `
  mutation DeleteAttorney(
    $input: DeleteAttorneyInput!
    $condition: ModelAttorneyConditionInput
  ) {
    deleteAttorney(input: $input, condition: $condition) {
      id
      firstName
      lastName
      emailWork
      emailPersonal
      cellPhone
      avatarUrl
      firmAssociation
      statesOfLicense
      licenseNumber
      liabilityInsuranceProvider
      currentProfessionalResponsibilityInvestigations
      practiceAreas
      communicationMethodsPreference
      acceptsTCAndPP
      attorneyRating
      cognitoId
      stripeAccountId
      stripeCustomerId
      stripeSubscriptionId
      earnings {
        items {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      ratings {
        items {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      bankAccounts {
        items {
          id
          accountNumber
          routingNumber
          bankName
          title
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      isOnline
      lastOnline
      phoneNumberUsageConsent
      approved
      createdAt
      updatedAt
      cases {
        items {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createCase = /* GraphQL */ `
  mutation CreateCase(
    $input: CreateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    createCase(input: $input, condition: $condition) {
      id
      venueState
      legalAdviceDescription
      areaOfLaw
      consultationConnectionMethod
      rating {
        id
        overallScore
        friendlinessScore
        knowledgeScore
        feedbackText
        createdAt
        updatedAt
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
      }
      futureConsultationDateTime
      earning {
        id
        createdAt
        updatedAt
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        email
        phone
        profilePictureUrl
        promoCredits
        birthday
        gender
        permanentStripePaymentToken
        cognitoId
        acceptsTCAndPP
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      owner
      payment {
        id
        paymentMethod
        paymentStatus
        dateTime
        amount
        calculatedAttorneyAmount
        stripeToken
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        owner
        createdAt
        updatedAt
        promotionalCode {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateCase = /* GraphQL */ `
  mutation UpdateCase(
    $input: UpdateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    updateCase(input: $input, condition: $condition) {
      id
      venueState
      legalAdviceDescription
      areaOfLaw
      consultationConnectionMethod
      rating {
        id
        overallScore
        friendlinessScore
        knowledgeScore
        feedbackText
        createdAt
        updatedAt
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
      }
      futureConsultationDateTime
      earning {
        id
        createdAt
        updatedAt
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        email
        phone
        profilePictureUrl
        promoCredits
        birthday
        gender
        permanentStripePaymentToken
        cognitoId
        acceptsTCAndPP
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      owner
      payment {
        id
        paymentMethod
        paymentStatus
        dateTime
        amount
        calculatedAttorneyAmount
        stripeToken
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        owner
        createdAt
        updatedAt
        promotionalCode {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteCase = /* GraphQL */ `
  mutation DeleteCase(
    $input: DeleteCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    deleteCase(input: $input, condition: $condition) {
      id
      venueState
      legalAdviceDescription
      areaOfLaw
      consultationConnectionMethod
      rating {
        id
        overallScore
        friendlinessScore
        knowledgeScore
        feedbackText
        createdAt
        updatedAt
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
      }
      futureConsultationDateTime
      earning {
        id
        createdAt
        updatedAt
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        email
        phone
        profilePictureUrl
        promoCredits
        birthday
        gender
        permanentStripePaymentToken
        cognitoId
        acceptsTCAndPP
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      owner
      payment {
        id
        paymentMethod
        paymentStatus
        dateTime
        amount
        calculatedAttorneyAmount
        stripeToken
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        owner
        createdAt
        updatedAt
        promotionalCode {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      paymentMethod
      paymentStatus
      dateTime
      amount
      calculatedAttorneyAmount
      stripeToken
      earning {
        id
        createdAt
        updatedAt
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      owner
      createdAt
      updatedAt
      promotionalCode {
        items {
          id
          isUsed
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          promotionalCode {
            id
            numberOfCredits
            code
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      paymentMethod
      paymentStatus
      dateTime
      amount
      calculatedAttorneyAmount
      stripeToken
      earning {
        id
        createdAt
        updatedAt
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      owner
      createdAt
      updatedAt
      promotionalCode {
        items {
          id
          isUsed
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          promotionalCode {
            id
            numberOfCredits
            code
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      paymentMethod
      paymentStatus
      dateTime
      amount
      calculatedAttorneyAmount
      stripeToken
      earning {
        id
        createdAt
        updatedAt
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          futureConsultationDateTime
          earning {
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          owner
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      owner
      createdAt
      updatedAt
      promotionalCode {
        items {
          id
          isUsed
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          promotionalCode {
            id
            numberOfCredits
            code
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createClientPromotionalCode = /* GraphQL */ `
  mutation CreateClientPromotionalCode(
    $input: CreateClientPromotionalCodeInput!
    $condition: ModelClientPromotionalCodeConditionInput
  ) {
    createClientPromotionalCode(input: $input, condition: $condition) {
      id
      isUsed
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        email
        phone
        profilePictureUrl
        promoCredits
        birthday
        gender
        permanentStripePaymentToken
        cognitoId
        acceptsTCAndPP
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      promotionalCode {
        id
        numberOfCredits
        code
        status
        createdAt
        updatedAt
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateClientPromotionalCode = /* GraphQL */ `
  mutation UpdateClientPromotionalCode(
    $input: UpdateClientPromotionalCodeInput!
    $condition: ModelClientPromotionalCodeConditionInput
  ) {
    updateClientPromotionalCode(input: $input, condition: $condition) {
      id
      isUsed
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        email
        phone
        profilePictureUrl
        promoCredits
        birthday
        gender
        permanentStripePaymentToken
        cognitoId
        acceptsTCAndPP
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      promotionalCode {
        id
        numberOfCredits
        code
        status
        createdAt
        updatedAt
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteClientPromotionalCode = /* GraphQL */ `
  mutation DeleteClientPromotionalCode(
    $input: DeleteClientPromotionalCodeInput!
    $condition: ModelClientPromotionalCodeConditionInput
  ) {
    deleteClientPromotionalCode(input: $input, condition: $condition) {
      id
      isUsed
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        email
        phone
        profilePictureUrl
        promoCredits
        birthday
        gender
        permanentStripePaymentToken
        cognitoId
        acceptsTCAndPP
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      promotionalCode {
        id
        numberOfCredits
        code
        status
        createdAt
        updatedAt
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createPromotionalCode = /* GraphQL */ `
  mutation CreatePromotionalCode(
    $input: CreatePromotionalCodeInput!
    $condition: ModelPromotionalCodeConditionInput
  ) {
    createPromotionalCode(input: $input, condition: $condition) {
      id
      numberOfCredits
      code
      status
      createdAt
      updatedAt
      clientPromotionalCodes {
        items {
          id
          isUsed
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          promotionalCode {
            id
            numberOfCredits
            code
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updatePromotionalCode = /* GraphQL */ `
  mutation UpdatePromotionalCode(
    $input: UpdatePromotionalCodeInput!
    $condition: ModelPromotionalCodeConditionInput
  ) {
    updatePromotionalCode(input: $input, condition: $condition) {
      id
      numberOfCredits
      code
      status
      createdAt
      updatedAt
      clientPromotionalCodes {
        items {
          id
          isUsed
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          promotionalCode {
            id
            numberOfCredits
            code
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deletePromotionalCode = /* GraphQL */ `
  mutation DeletePromotionalCode(
    $input: DeletePromotionalCodeInput!
    $condition: ModelPromotionalCodeConditionInput
  ) {
    deletePromotionalCode(input: $input, condition: $condition) {
      id
      numberOfCredits
      code
      status
      createdAt
      updatedAt
      clientPromotionalCodes {
        items {
          id
          isUsed
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            address1
            address2
            city
            state
            zip
            email
            phone
            profilePictureUrl
            promoCredits
            birthday
            gender
            permanentStripePaymentToken
            cognitoId
            acceptsTCAndPP
            createdAt
            updatedAt
          }
          promotionalCode {
            id
            numberOfCredits
            code
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createAttorneyConsultationRequest = /* GraphQL */ `
  mutation CreateAttorneyConsultationRequest(
    $input: CreateAttorneyConsultationRequestInput!
    $condition: ModelAttorneyConsultationRequestConditionInput
  ) {
    createAttorneyConsultationRequest(input: $input, condition: $condition) {
      id
      requestedAttorneys
      createdAt
      updatedAt
      selectedAttorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      consultation {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        lastOnlineClient
        lastOnlineAttoney
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const updateAttorneyConsultationRequest = /* GraphQL */ `
  mutation UpdateAttorneyConsultationRequest(
    $input: UpdateAttorneyConsultationRequestInput!
    $condition: ModelAttorneyConsultationRequestConditionInput
  ) {
    updateAttorneyConsultationRequest(input: $input, condition: $condition) {
      id
      requestedAttorneys
      createdAt
      updatedAt
      selectedAttorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      consultation {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        lastOnlineClient
        lastOnlineAttoney
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const deleteAttorneyConsultationRequest = /* GraphQL */ `
  mutation DeleteAttorneyConsultationRequest(
    $input: DeleteAttorneyConsultationRequestInput!
    $condition: ModelAttorneyConsultationRequestConditionInput
  ) {
    deleteAttorneyConsultationRequest(input: $input, condition: $condition) {
      id
      requestedAttorneys
      createdAt
      updatedAt
      selectedAttorney {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        phoneNumberUsageConsent
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      consultation {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        lastOnlineClient
        lastOnlineAttoney
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const createConsultation = /* GraphQL */ `
  mutation CreateConsultation(
    $input: CreateConsultationInput!
    $condition: ModelConsultationConditionInput
  ) {
    createConsultation(input: $input, condition: $condition) {
      id
      connectionMethod
      dateTimeOccurred
      videoConnectionToken
      chatConnectionToken
      conversationTranscript
      status
      clientPrefferedConnectionMethod
      purchasedLength
      actualLength
      lastOnlineClient
      lastOnlineAttoney
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateConsultation = /* GraphQL */ `
  mutation UpdateConsultation(
    $input: UpdateConsultationInput!
    $condition: ModelConsultationConditionInput
  ) {
    updateConsultation(input: $input, condition: $condition) {
      id
      connectionMethod
      dateTimeOccurred
      videoConnectionToken
      chatConnectionToken
      conversationTranscript
      status
      clientPrefferedConnectionMethod
      purchasedLength
      actualLength
      lastOnlineClient
      lastOnlineAttoney
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteConsultation = /* GraphQL */ `
  mutation DeleteConsultation(
    $input: DeleteConsultationInput!
    $condition: ModelConsultationConditionInput
  ) {
    deleteConsultation(input: $input, condition: $condition) {
      id
      connectionMethod
      dateTimeOccurred
      videoConnectionToken
      chatConnectionToken
      conversationTranscript
      status
      clientPrefferedConnectionMethod
      purchasedLength
      actualLength
      lastOnlineClient
      lastOnlineAttoney
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFutureConsultation = /* GraphQL */ `
  mutation CreateFutureConsultation(
    $input: CreateFutureConsultationInput!
    $condition: ModelFutureConsultationConditionInput
  ) {
    createFutureConsultation(input: $input, condition: $condition) {
      id
      dateTimeScheduled
      notifiedByEmail
      createdAt
      updatedAt
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      consultation {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        lastOnlineClient
        lastOnlineAttoney
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const updateFutureConsultation = /* GraphQL */ `
  mutation UpdateFutureConsultation(
    $input: UpdateFutureConsultationInput!
    $condition: ModelFutureConsultationConditionInput
  ) {
    updateFutureConsultation(input: $input, condition: $condition) {
      id
      dateTimeScheduled
      notifiedByEmail
      createdAt
      updatedAt
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      consultation {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        lastOnlineClient
        lastOnlineAttoney
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const deleteFutureConsultation = /* GraphQL */ `
  mutation DeleteFutureConsultation(
    $input: DeleteFutureConsultationInput!
    $condition: ModelFutureConsultationConditionInput
  ) {
    deleteFutureConsultation(input: $input, condition: $condition) {
      id
      dateTimeScheduled
      notifiedByEmail
      createdAt
      updatedAt
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      consultation {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        lastOnlineClient
        lastOnlineAttoney
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const createImmediateConsultation = /* GraphQL */ `
  mutation CreateImmediateConsultation(
    $input: CreateImmediateConsultationInput!
    $condition: ModelImmediateConsultationConditionInput
  ) {
    createImmediateConsultation(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      consultation {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        lastOnlineClient
        lastOnlineAttoney
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const updateImmediateConsultation = /* GraphQL */ `
  mutation UpdateImmediateConsultation(
    $input: UpdateImmediateConsultationInput!
    $condition: ModelImmediateConsultationConditionInput
  ) {
    updateImmediateConsultation(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      consultation {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        lastOnlineClient
        lastOnlineAttoney
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const deleteImmediateConsultation = /* GraphQL */ `
  mutation DeleteImmediateConsultation(
    $input: DeleteImmediateConsultationInput!
    $condition: ModelImmediateConsultationConditionInput
  ) {
    deleteImmediateConsultation(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
      consultation {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        lastOnlineClient
        lastOnlineAttoney
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const createPastEngagement = /* GraphQL */ `
  mutation CreatePastEngagement(
    $input: CreatePastEngagementInput!
    $condition: ModelPastEngagementConditionInput
  ) {
    createPastEngagement(input: $input, condition: $condition) {
      id
      textChatlog
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        email
        phone
        profilePictureUrl
        promoCredits
        birthday
        gender
        permanentStripePaymentToken
        cognitoId
        acceptsTCAndPP
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
    }
  }
`;
export const updatePastEngagement = /* GraphQL */ `
  mutation UpdatePastEngagement(
    $input: UpdatePastEngagementInput!
    $condition: ModelPastEngagementConditionInput
  ) {
    updatePastEngagement(input: $input, condition: $condition) {
      id
      textChatlog
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        email
        phone
        profilePictureUrl
        promoCredits
        birthday
        gender
        permanentStripePaymentToken
        cognitoId
        acceptsTCAndPP
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
    }
  }
`;
export const deletePastEngagement = /* GraphQL */ `
  mutation DeletePastEngagement(
    $input: DeletePastEngagementInput!
    $condition: ModelPastEngagementConditionInput
  ) {
    deletePastEngagement(input: $input, condition: $condition) {
      id
      textChatlog
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        email
        phone
        profilePictureUrl
        promoCredits
        birthday
        gender
        permanentStripePaymentToken
        cognitoId
        acceptsTCAndPP
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        clientPromotionalCodes {
          items {
            id
            isUsed
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
        }
        futureConsultationDateTime
        earning {
          id
          createdAt
          updatedAt
          attorney {
            id
            firstName
            lastName
            emailWork
            emailPersonal
            cellPhone
            avatarUrl
            firmAssociation
            statesOfLicense
            licenseNumber
            liabilityInsuranceProvider
            currentProfessionalResponsibilityInvestigations
            practiceAreas
            communicationMethodsPreference
            acceptsTCAndPP
            attorneyRating
            cognitoId
            stripeAccountId
            stripeCustomerId
            stripeSubscriptionId
            isOnline
            lastOnline
            phoneNumberUsageConsent
            approved
            createdAt
            updatedAt
          }
          case {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          payment {
            id
            paymentMethod
            paymentStatus
            dateTime
            amount
            calculatedAttorneyAmount
            stripeToken
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          zip
          email
          phone
          profilePictureUrl
          promoCredits
          birthday
          gender
          permanentStripePaymentToken
          cognitoId
          acceptsTCAndPP
          createdAt
          updatedAt
          cases {
            nextToken
          }
          clientPromotionalCodes {
            nextToken
          }
        }
        attorney {
          id
          firstName
          lastName
          emailWork
          emailPersonal
          cellPhone
          avatarUrl
          firmAssociation
          statesOfLicense
          licenseNumber
          liabilityInsuranceProvider
          currentProfessionalResponsibilityInvestigations
          practiceAreas
          communicationMethodsPreference
          acceptsTCAndPP
          attorneyRating
          cognitoId
          stripeAccountId
          stripeCustomerId
          stripeSubscriptionId
          earnings {
            nextToken
          }
          ratings {
            nextToken
          }
          bankAccounts {
            nextToken
          }
          isOnline
          lastOnline
          phoneNumberUsageConsent
          approved
          createdAt
          updatedAt
          cases {
            nextToken
          }
        }
        owner
        payment {
          id
          paymentMethod
          paymentStatus
          dateTime
          amount
          calculatedAttorneyAmount
          stripeToken
          earning {
            id
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          promotionalCode {
            nextToken
          }
        }
      }
    }
  }
`;
export const setConsultationTimestamp = /* GraphQL */ `
  mutation SetConsultationTimestamp($input: SetConsultationTimestampInput) {
    setConsultationTimestamp(input: $input) {
      statusCode
      id
      error
    }
  }
`;
