const MicrosoftHelper = () => {
  return (
    <>
      <li>
        Go to the
        <a href="https://account.microsoft.com/security" target="_blank">
          {" "}
          Security basics{" "}
        </a>
        page and sign in to your Microsoft account.
      </li>
      <li>
        Select <b>More security options</b>.
      </li>
      <li>
        Under <b>App passwords</b>, select <b>Create a new app password</b>. A
        new app password is generated and appears on your screen.
      </li>
    </>
  );
};

export default MicrosoftHelper;
