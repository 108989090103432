import { useContext, useEffect, useState } from "react";
import ROUTES from "routes";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { UserContext, LoaderContext } from "contexts";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { Provider } from "mobx-react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { useLocation } from "react-router-dom";
import smartlookClient from 'smartlook-client'
import useStore from "utils/useStore";

import { styledTheme } from "./styles/StyledTheme";
import initializeStore from "./stores/rootStore";
import "react-toastify/dist/ReactToastify.css";
import "styles/App.css";
import "react-datepicker/dist/react-datepicker.css";
import 'rc-time-picker/assets/index.css';
import 'react-calendar/dist/Calendar.css';
import { AMPLIFY_ENV, REACT_APP_SMARTLOOK_ID, REACT_APP_SENTRY_DSN } from "config";
import { AmplifyEnvironmentEnum } from "constant/enum/common";
import Hubspot from "components/Hubspot";
import { TrackingEvent } from "constant/enum";

const mobxStore = initializeStore();

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#216FB6",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

if (AMPLIFY_ENV === AmplifyEnvironmentEnum.MOBILE || AMPLIFY_ENV === AmplifyEnvironmentEnum.STAGING) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: AMPLIFY_ENV,
    tracesSampleRate: 1.0,
  });
}

const Routes = () => {
  const { attorneyStore } = useStore()
  const [userInfo] = useContext(UserContext);
  const { pathname } = useLocation();
  const { setPathPageView, setIdentity, setTrackEvent } = useTrackingCode();
  
  useEffect(()=> {
    if(userInfo?.id){
      attorneyStore.getAttorneyDetail()
    }
  }, [userInfo?.id])

  useEffect(() => {
    if (userInfo?.emailWork) {
      setIdentity(userInfo?.emailWork);
    }
  }, [userInfo?.emailWork])

  useEffect(() => {
    if (userInfo?.id && userInfo?.emailWork) {
      smartlookClient.identify(userInfo.id, {
        email: userInfo.emailWork,
        name: `${userInfo.firstName} ${userInfo?.lastName}`
      })
    }
  }, [userInfo?.id, userInfo?.emailWork])

  useEffect(() => {
    if (pathname) {
      setPathPageView(pathname);
      setTrackEvent({ eventId: TrackingEvent.SURF, value: pathname })
    }
    if (pathname) {
      smartlookClient.navigation(pathname);
    }
  }, [pathname])

  return (
    <Switch>
      {ROUTES.map((route, i) =>
        !route.auth ? <Route key={i} {...route} /> : <></>
      )}
    </Switch>
  )
}

const App = () => {
  const classes = useStyles();
  const [contextUserInfo, setContextUserInfo] = useState({});
  const [isLoaderShown, setIsLoaderShown] = useState(false);

  useEffect(() => {
    if(REACT_APP_SMARTLOOK_ID){
      smartlookClient.init(REACT_APP_SMARTLOOK_ID, {
        version: 'nextgen'
      });
      smartlookClient.restart();
    }
  }, [REACT_APP_SMARTLOOK_ID])

  return (
    <UserContext.Provider value={[contextUserInfo, setContextUserInfo]}>
      <LoaderContext.Provider value={[isLoaderShown, setIsLoaderShown]}>
        <StyledThemeProvider theme={styledTheme}>
          <ThemeProvider theme={outerTheme}>
            <Backdrop className={classes.backdrop} open={isLoaderShown}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <ToastContainer />
            <Hubspot />
            <Routes />
          </ThemeProvider>
        </StyledThemeProvider>
      </LoaderContext.Provider>
    </UserContext.Provider>
  );
};

const WithStore = () => {
  return (
    <Provider {...mobxStore}>
      <App />
    </Provider>
  );
};

export default WithStore;