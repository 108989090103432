import { GoogleButton as Button, Container } from "./googleButton.styles";

interface IGoogleButtonProps {
  onClick: () => void;
}

const GoogleButton = (props: IGoogleButtonProps) => {
  const { onClick } = props;

  return (
    <Container onClick={onClick}>
      <Button imageName="btn_google_signin_dark_normal_web@2x.png" />
    </Container>
  );
};

export default GoogleButton;
