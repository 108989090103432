import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Radio,
} from "@material-ui/core";
import { SignUpPlatform, StorageKeys } from "constant/enum";
import { LoaderContext, UserContext } from "contexts";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { signIn, signUp } from "services/auth-service";
import { styledTheme } from "styles/StyledTheme";
import { updateHubspotUser } from "utils/hubspot";
import { upsertSignUpCredential } from "../../../../API/signUpCredential";
import CustomButton from "../../../../components/common/Custom-button";
import CustomInput from "../../../../components/common/CustomInput";
import {
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICES_URL,
} from "../../../../constant/common";
import { SignUpWrapper, FullNameHelp, TosSection } from "./signup.styles";

const SignUp = ({ setErrorMsg }) => {
  const history = useHistory();
  const [contextUserInfo, setContextUserInfo] = useContext(UserContext);
  const [isLoaderShown, setIsLoaderShown] = useContext(LoaderContext); //eslint-disable-line
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [isAgree, setIsAgree] = useState(true);

  const handleNavigateToVerification = async () => {
    localStorage.removeItem(StorageKeys.ATTORNEY_ACCESS_TOKEN);
    setContextUserInfo({
      ...contextUserInfo,
      firstName,
      lastName,
      email,
      password,
    });

    try {
      setIsLoaderShown(true);
      await signUp(email, password, firstName, lastName);
      await upsertSignUpCredential({
        email: email,
        firstName: firstName,
        lastName: lastName,
        username: email,
        platform: SignUpPlatform.ATTORNEY,
      });
      updateHubspotUser(email, firstName, lastName);
      await signIn({
        username: email,
        password: password,
      });
      history.push("/registration/user-details");
    } catch (e) {
      setErrorMsg(e.message);
    } finally {
      setIsLoaderShown(false);
    }
  };

  const validate = () => {
    const firstNameError = firstName === "";
    const lastNameError = lastName === "";
    const emailError = email === "";
    const passwordError = password === "" || password.length < 6;

    setValidationErrors({
      ...validationErrors,
      firstName: firstNameError,
      lastName: lastNameError,
      email: emailError,
      password: passwordError,
    });
    return !firstNameError && !lastNameError && !emailError && !passwordError;
  };

  const resetFieldErrors = (fieldName) => {
    const dup = { ...validationErrors };
    delete dup[fieldName];
    setValidationErrors(dup);
  };

  const handleSignUpSubmit = async (e) => {
    e && e.preventDefault();
    if (!validate()) {
      return;
    }
    handleNavigateToVerification();
  };

  return (
    <SignUpWrapper>
      <form onSubmit={handleSignUpSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Grid item container xs={12}>
              <Grid item xs={6} className="pr-1 pt-4">
                <CustomInput
                  error={validationErrors?.firstName}
                  type="text"
                  id="firstname-input"
                  aria-describedby="fullname-helper-text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => {
                    resetFieldErrors("firstName");
                    setFirstName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6} className="pl-1 pt-4">
                <CustomInput
                  error={validationErrors?.lastName}
                  type="text"
                  id="lastname-input"
                  aria-describedby="fullname-helper-text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => {
                    resetFieldErrors("lastName");
                    setLastName(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <FullNameHelp item xs={12}>
              <FormHelperText
                id="fullname-helper-text"
                className={
                  validationErrors.firstName || validationErrors.lastName
                    ? "Mui-error"
                    : {}
                }
              >
                FULL NAME
              </FormHelperText>
            </FullNameHelp>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              className="formControl pt-4"
              error={validationErrors?.email}
            >
              <CustomInput
                type="email"
                id="email-input"
                aria-describedby="email-helper-text"
                placeholder="Complete email"
                value={email}
                onChange={(e) => {
                  resetFieldErrors("email");
                  setEmail(e.target.value);
                }}
              />
              <FormHelperText id="email-helper-text">EMAIL</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              className="formControl pt-4"
              error={validationErrors?.password}
            >
              <CustomInput
                value={password}
                onChange={(e) => {
                  resetFieldErrors("password");
                  setPassword(e.target.value);
                }}
                type="password"
                id="password-input"
                aria-describedby="password-helper-text"
                placeholder="Complete password"
                error={validationErrors?.password}
              />
              <FormHelperText
                style={{ textAlign: "center" }}
                id="password-helper-text"
              >
                PASSWORD (at least 8 characters)
              </FormHelperText>
            </FormControl>
          </Grid>

          <TosSection item xs={12}>
            <Radio
              checked={isAgree}
              onClick={() => setIsAgree(!isAgree)}
              color="primary"
            />
            <span>
              I agree with LegalQ’s{" "}
              <a href={TERMS_OF_SERVICES_URL} target="_blank" rel="noreferrer">
                <b>Terms of Service</b>
              </a>{" "}
              as well as{" "}
              <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
                <b>Privacy Policy</b>
              </a>{" "}
              and consent to their terms.
            </span>
          </TosSection>

          <Grid item xs={12} className="pt-2">
            <CustomButton
              style={{
                padding: "16px 0px",
                width: "100%",
                backgroundColor: styledTheme.colors.secondaryDark,
                boxShadow: `0px 16px 30px -10px ${styledTheme.colors.secondaryDark}, 0px 20px 20px -20px #5390D2`,
                borderRadius: 12,
                marginTop: 30,
              }}
              disabled={isLoaderShown || !isAgree}
            >
              {isLoaderShown ? (
                <CircularProgress size={24} />
              ) : (
                <span>Join LegalQ</span>
              )}
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </SignUpWrapper>
  );
};

export default SignUp;
