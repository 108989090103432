import React, { useState, useEffect } from "react";
import { Grid, Paper } from "@material-ui/core";
import styled from "styled-components";
import CustomHeading from "../../../components/common/CustomHeading";
import { getGeneralRating } from "../../../services/dashboardService";
import { GStyledGrid, GStyledRating } from '../../../components/common/MaterialUIStyledComponents'
import { styledTheme } from '../../../styles/StyledTheme'

const MyRatings = styled.span`
  font-size: 40px;
`;
const TotalRatings = styled.span`
  font-size: 16px;
  padding-left: 8px;
`;
const PaperStyler = styled(Paper)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  color: ${ ({ theme }) => theme.colors.secondaryDark };
  border-radius: 12px;
`;

const OverallRatings = ({ cases }) => {
  const [generalRating, setGeneralRating] = useState(0);

  useEffect(() => {
    setGeneralRating(getGeneralRating(cases));
  }, [cases]);

  return (
    <Grid item xs={12} container>
      <GStyledGrid item xs={12} paddingBottom= '10px'>
        <CustomHeading>OVERALL RATINGS</CustomHeading>
      </GStyledGrid>
      <Grid item xs={12}>
        <PaperStyler>
          <div>
            <MyRatings>{generalRating}</MyRatings>
            <TotalRatings>/5.0</TotalRatings>
          </div>
          <GStyledRating
            value={ parseFloat(generalRating) }
            precision={0.1}
            readOnly
            fontSize= '20px'
            color={ styledTheme.colors.secondary }
          ></GStyledRating>
        </PaperStyler>
      </Grid>
    </Grid>
  );
};

export default OverallRatings;
