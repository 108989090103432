import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import styled from 'styled-components'
import { AudioGreenWhite, TextGreenWhite, VideoGreenWhite } from 'icons'

const ReviewsCount = styled.span`
  font-size: 30px;
`
const MyRatings = styled.span`
  font-size: 30px;
`
const TotalRatings = styled.span`
  font-size: 14px;
`
const PaperStyler = styled(Paper)`
  position:relative;
  color:white;
  font-size:12px;
  background-color: ${ ({ theme }) => theme.colors.secondaryDarker };
  height:100%;
  border-radius:12px;
  .rating{
    font-size:10px;
  }
  .icon{
    position:absolute;
    left:-14px;
    top:40px;
    img{
      width:30px;
    }
  }
  .border{
    width:0px;
    height:80px;
  }
  .content{
    padding:15px 0px;
  }
`

const ChannelStat = ({reviewCount, myRating, icon}) => {

  return(
    <PaperStyler>
        <div className="icon">
        {
              icon === 'text' &&
                <img src={TextGreenWhite} alt='text' />
            }
            {
              icon === 'audio' &&
                <img src={AudioGreenWhite} alt='audio' />
            }
            {
              icon === 'video' &&
                <img src={VideoGreenWhite} alt='video' />
            }
        </div>
        <Grid item className="content" xs={12} container justify="center" alignItems="center">
          <Grid item xs={5} container direction="column" justify="center" alignItems="center">
          <ReviewsCount>
              {reviewCount}
            </ReviewsCount>
            <span className="rating">
            TOTAL REVIEWS
            </span>
          </Grid>
          <Grid item xs={1} container direction="column" justify="center" alignItems="center" >
            <div className="border">
            </div>
          </Grid>
          <Grid item xs={5} container direction="column" justify="center" alignItems="center" >
            <div>
            <MyRatings>{myRating}</MyRatings><TotalRatings>/5.0</TotalRatings>
            </div>
          <span className="rating">
            OVERALL RATING
            </span>
          </Grid>
        </Grid>
    </PaperStyler>
  )
}

export default ChannelStat