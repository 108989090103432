import capitalize from "lodash/capitalize";
import { Dispatch, SetStateAction } from "react";
export function isValidArray(item: string | string[]): string[] | boolean {
  item = Array.isArray(item) ? item : [""];
  if (item.length !== 0 && item[0] !== "" && item) {
    return item;
  }
  return false;
}

export function filterValidArrayFromArray(
  array: (string | string[])[]
): (string | string[])[] {
  array = array.filter(isValidArray);
  return array;
}

export function checkValidArray(array?: unknown[]): boolean {
  return array ? Array.isArray(array) && array.length > 0 : false;
}

export function getValidArray<T>(array?: T[]): T[] {
  return checkValidArray(array) ? array || [] : [];
}

export function getCapitalizeArray(array?: string[]): string[] {
  return getValidArray(array).map((e) => capitalize(e));
}
export function setState<T>(
  state: T,
  setThisState: Dispatch<SetStateAction<T>>
) {
  setThisState(state);
}
