import { Modal, Container, Fade, Backdrop } from "@material-ui/core";
import { BlueCard, GreenCard } from "icons";
import {
  GStyledH3,
  GStyledImg,
} from "../../../../components/common/StyledComponents";
import { PaperWrapper, CommonText, useStyles } from "./howItWorks.styles";

interface IHowItWorksProps {
  isOpen: boolean;
  onClickClose: () => void;
}

const HowItWorks = (props: IHowItWorksProps) => {
  const { isOpen, onClickClose } = props;
  const classes = useStyles();

  return (
    <Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={onClickClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <PaperWrapper>
              <GStyledH3 paddingBottom="30px" fontSize="18px" fontWeight="bold">
                How does it work?
              </GStyledH3>
              <CommonText>
                The blue cards are incoming requests, you can accept or deny
                them. <br />
                If you accept, it will appear in your <b> Active Requests</b>
              </CommonText>
              <GStyledImg
                width="50%"
                paddingBottom="40px"
                src={BlueCard}
                alt="AppointmentRequestDemo"
              />
              <CommonText>
                The green cards are your Active Requests.
                <br />
                Once you are ready to go you can click on <b>Start Session</b>
                <br />
                To resume incomplete requests you can click on{" "}
                <b>Resume Session</b>
              </CommonText>
              <GStyledImg width="50%" src={GreenCard} alt="ActiveRequestDemo" />
            </PaperWrapper>
          </div>
        </Fade>
      </Modal>
    </Container>
  );
};

export default HowItWorks;
