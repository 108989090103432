import { useContext, useEffect } from "react";
import { UserContext } from "contexts";
import { signOut } from "services/auth-service";
import { ReactTitle } from "react-meta-tags";
import campaignService from "services/campaignService";
import { useHistory } from "react-router-dom";
import { StorageKeys } from "constant/enum";
import isEmbedded from "utils/isEmbedded";
import {
  Container,
  Divider,
  Header,
  SubTitle,
  Wrapper,
  BackButton,
} from "./confirmation.styles";

const Confirmation = () => {
  const [userInfo] = useContext(UserContext);
  const history = useHistory();

  if (!!userInfo?.id && !!userInfo?.approved) {
    history.push("/");
  }

  useEffect(() => {
    campaignService.triggerEvent(userInfo.emailWork);
    localStorage.removeItem(StorageKeys.ATTORNEY_ACCESS_TOKEN);
    signOut();
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
  }, []);

  function handleBack() {
    if (isEmbedded()) {
      history.push("/embedded/signup");
    } else {
      history.push("/");
    }
  }

  return (
    <Wrapper>
      <ReactTitle title="Confirmation - LegalQ Attorney" />
      <Container>
        <Header>New Potential Clients in your inbox Soon!</Header>
        <Divider />
        <SubTitle>
          As soon as we approve your submission, your 3 <b>Free</b> leads will be on the way!
        </SubTitle>
        <div className="calendly-inline-widget" data-url="https://calendly.com/legalq/attorney-onboarding" style={{ minWidth: 320 , height: 630, marginTop: '25px' }}></div>
        <BackButton onClick={handleBack}>BACK TO HOME</BackButton>
     </Container>
    </Wrapper>
  );
};

export default Confirmation;
