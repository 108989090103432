import { Button, Grid } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)`
  width: 100%;
  max-width: 521px;
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonWrapper = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const KeepPlanButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
  margin-right: 16px;

  border-radius: 12px;
  color: rgba(#000000, 0.48);
  padding: 16px 42px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;

  &:hover {
    border-radius: 12px;
    color: rgba(#000000, 0.48);
  }
`;

export const UnSubscribeButton = styled(Button)`
  width: 100%;
  margin-top: 16px;

  background-color: #f56565;
  box-shadow: ${styledTheme.shadow.blueBelow};
  border-radius: 12px;
  color: #ffffff;
  padding: 16px 42px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  cursor: ${(props) => (props?.blocked ? "not-allowed" : "pointer")} !important;

  span {
    margin-left: 8px;
  }

  &:hover {
    background-color: #f56565;
    box-shadow: 0px 16px 30px -10px rgba(70, 166, 247, 0.5),
      0px 20px 20px -20px #46a6f7;
    border-radius: 12px;
    color: #ffffff;
  }
`;

export const MediumRightSpacing = styled.div`
  margin-right: 16px;
`;
