import { Grid } from "@material-ui/core";
import Icon from "components/common/Icon";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)`
  background-color: ${styledTheme.colors.secondaryDark};
  min-width: 44px;
  min-height: 44px;
  width: fit-content;
  border-radius: 16px;
  cursor: pointer;
`;

export const AddIcon = styled(Icon)`
  display: flex;
  height: 44px;
  justify-content: center;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
  }
`;
