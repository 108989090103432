import React, { useState } from "react";
import Login from "pages/Landing/LoginSignup/Login";
import Signup from "pages/Landing/LoginSignup/Signup";
import ErrorMsg from "pages/Landing/LoginSignup/ErrorMsg";
import { styledTheme } from "../../../styles/StyledTheme";
import {
  TabTitle,
  PaperWrapper,
  LoginSignupWithErrorWrapper,
  LogIn,
  Active,
  TabContent,
} from "./index.styles";

const LoginSignup = () => {
  const [selectedTab, setSelectedTab] = React.useState(1);
  const [errorMsg, setErrorMsg] = useState("");

  function handleChange(newValue): void {
    setSelectedTab(newValue);
  }

  return (
    <LoginSignupWithErrorWrapper>
      <PaperWrapper square>
        <LogIn container>
          <Active
            onClick={() => handleChange(0)}
            item
            xs={6}
            className={selectedTab === 0 ? "active" : ""}
          >
            <TabTitle
              style={
                selectedTab === 1
                  ? { color: styledTheme.colors.primaryGray }
                  : null
              }
            >
              Sign Up
            </TabTitle>
          </Active>
          <Active
            onClick={() => handleChange(1)}
            item
            xs={6}
            className={selectedTab === 1 ? "active" : ""}
          >
            <TabTitle
              style={
                selectedTab === 0
                  ? { color: styledTheme.colors.primaryGray }
                  : null
              }
            >
              Log In
            </TabTitle>
          </Active>
        </LogIn>

        <TabContent>
          {selectedTab === 1 && <Login setErrorMsg={setErrorMsg} />}
          {selectedTab === 0 && <Signup setErrorMsg={setErrorMsg} />}
        </TabContent>
      </PaperWrapper>
      {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
    </LoginSignupWithErrorWrapper>
  );
};

export default LoginSignup;
