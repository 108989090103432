// comma separated
export const commaFormat = (x) => {
  return (x + '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// thousand separator
export const nFormatter = (num, digits) => {

  if(num === 0) return 0;

  var si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "k" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "G" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  // eslint-disable-next-line
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }

  //.replace(rx, "$1")
  return (num / si[i].value).toFixed(digits) + si[i].symbol;
}

export const sleep = async (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}
