import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import moment from "moment";
import {
  Container,
  DatetimeIndicator,
  DateLabel,
  TimeLabel,
  CalendarIcon,
  ButtonGroup,
  RequestChangeButton,
  CancelButton,
  CalendarContainer,
  CommonLoader,
} from "./pickerSection.styles";
import { styledTheme } from "styles/StyledTheme";
import { IActiveRequest } from "interfaces/acceptConsultationRequest";

interface IPickerSectionProps {
  onChange: (date: Date) => void;
  onCancel: () => void;
  isLoading: boolean;
  selectedActiveRequest: IActiveRequest;
}

const PickerSection = (props: IPickerSectionProps) => {
  const { onChange, onCancel, selectedActiveRequest, isLoading } = props;
  const [selectedDate, setSelectedDate] = useState(
    moment(selectedActiveRequest?.time).toDate()
  );
  const [selectedDateTime, setSelectedDateTime] = useState(
    moment(selectedActiveRequest?.time)
  );

  useEffect(() => {
    setSelectedDate(moment(selectedActiveRequest?.time).toDate());
    setSelectedDateTime(moment(selectedActiveRequest?.time));
  }, [selectedActiveRequest?.time]);

  return (
    <Container>
      <DatetimeIndicator>
        <DateLabel>
          <DatePicker
            calendarContainer={CalendarContainer}
            selected={selectedDate}
            onChange={(date: Date) => {
              setSelectedDate(date);
              setSelectedDateTime(moment(date));
            }}
          />
        </DateLabel>
        <CalendarIcon iconName="calendar-green.svg" />
        <TimeLabel>
          <TimePicker
            value={selectedDateTime}
            onChange={(newTime: moment.Moment) => setSelectedDateTime(newTime)}
            showSecond={false}
            minuteStep={1}
          />
        </TimeLabel>
      </DatetimeIndicator>
      <ButtonGroup>
        <CancelButton disabled={isLoading} onClick={onCancel}>
          Cancel
        </CancelButton>
        <RequestChangeButton
          disabled={isLoading}
          onClick={() => onChange(selectedDateTime?.toDate())}
        >
          {isLoading && (
            <CommonLoader
              type="Oval"
              color={styledTheme.colors.white}
              height={14}
              width={14}
            />
          )}
          <span>Request changes</span>
        </RequestChangeButton>
      </ButtonGroup>
    </Container>
  );
};

export default PickerSection;
