import { Grid, Paper } from "@material-ui/core";
import Image from "components/common/Image";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const PaperWrapper = styled(Paper)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-width: 100%;
  min-height: 710px;

  text-align: center;
  border-radius: 10px;

  padding-left: 0;
  padding-right: 0;
  padding-bottom: 20px;
  padding-top: 15%;
`;

export const ClientSection = styled(Grid)`
  max-width: 422px;
`;

export const ClientGroup = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

export const ClientAvatar = styled(Image)`
  padding-bottom: 8px;
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
`;

export const ClientName = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: ${styledTheme.colors.primaryBlack};
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0px;

  label {
    margin-bottom: 0px;
    padding-right: 8px;
  }

  img {
    cursor: pointer;
  }
`;

export const ClientNoteButton = styled(Grid)`
  button {
    border-radius: 12px;
    border-color: ${styledTheme.colors.secondaryDark};
    color: ${styledTheme.colors.secondaryDark};
    font-family: ${styledTheme.fonts.Inter};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const QuickOverview = styled(Grid)`
  padding-top: 24px;
  cursor: default;

  span {
    color: ${styledTheme.colors.secondaryDark};
    font-family: ${styledTheme.fonts.Inter};
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
  }
`;

export const QuickOverviewCategory = styled.span`
  font-weight: 600;
`;

export const QuickOverviewState = styled.span`
  font-weight: normal;
`;

export const QuickOverviewSeparator = styled.span`
  font-weight: normal;
  padding-left: 4px;
  padding-right: 4px;
`;

export const ClientQuestion = styled.label`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${styledTheme.colors.gray600};
`;

export const ButtonGroup = styled(Grid)`
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
