import { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import moment, { Duration } from "moment";
import { ChartData } from "react-chartjs-2";
import * as chartjs from "chart.js";
import CustomHeading from "../../../../components/common/CustomHeading";
import { timeRangeOptions } from "./constants";
import { nFormatter } from "utils/helpers";
import RangeSwitcher from "components/common/RangeSwitcher";
import ConnectionMethodSelector from "./components/ConnectionMethodSelector";
import {
  PaperStyler,
  CardContainer,
  LabelWrapper,
  ConsultationMethod,
  GraphWrapper,
  OverallSection,
  OverallGroup,
  OverallLabel,
  OverallValue,
  LargeSpacing,
  TimeRangeSection,
} from "./timeSpendGraph.styles";
import { TimeRange } from "components/common/RangeSwitcher/constants";
import { ConnectionMethod } from "constant/enum";
import { useChartData } from "./hooks";
import {
  chartDataSourceSelector,
  getTotalTimeSpend,
  parseDuration,
  getTotalTimeSpendByMonth,
} from "./utils";
import { IMixData } from "interfaces/dashboard";

interface ITimeSpendGraphProps {
  mixConsultations: IMixData[];
}

const TimeSpendGraph = (props: ITimeSpendGraphProps) => {
  const [selectRange, setSelectedRange] = useState<TimeRange>(TimeRange.MONTH);
  const [connectionMethod, setConnectionMethod] = useState<ConnectionMethod[]>([
    ConnectionMethod.TEXT,
    ConnectionMethod.VOICE,
    ConnectionMethod.VIDEO,
  ]);
  const { mixConsultations } = props;
  const [totalTimeSpend, setTotalTimeSpend] = useState<Duration>();
  const [currentMonthTotalSpendTime, setCurrentMonthTotalSpendTime] =
    useState<number>(0);

  const { yearChart, dateChart, weekChart } = useChartData(
    mixConsultations,
    selectRange,
    connectionMethod
  );

  useEffect(() => {
    setTotalTimeSpend(moment.duration(getTotalTimeSpend(mixConsultations), "seconds"));
    setCurrentMonthTotalSpendTime(
      getTotalTimeSpendByMonth(mixConsultations, moment().month())
    );
  }, [mixConsultations]);
  

  const chartDataSource: ChartData<chartjs.ChartData> = chartDataSourceSelector(
    yearChart,
    dateChart,
    weekChart,
    selectRange
  );

  function handleChangeConnectionMethod(
    selectedText: boolean,
    selectedVoice: boolean,
    selectedVideo: boolean
  ): void {
    const newConnectionMethod: ConnectionMethod[] = [];
    if (selectedText) {
      newConnectionMethod.push(ConnectionMethod.TEXT);
    }
    if (selectedVoice) {
      newConnectionMethod.push(ConnectionMethod.VOICE);
    }
    if (selectedVideo) {
      newConnectionMethod.push(ConnectionMethod.VIDEO);
    }
    setConnectionMethod(newConnectionMethod);
  }

  const totalSpendTime = parseDuration(totalTimeSpend);
  const monthToDateSpendTime = parseDuration(
    moment.duration(currentMonthTotalSpendTime, "seconds")
  );

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <CustomHeading>TIME SPENT</CustomHeading>
      </Grid>
      <PaperStyler>
        <CardContainer xl={12} lg={12} md={12} sm={12} xs={12}>
          <ConsultationMethod item xl={2} lg={2} md={2} sm={2} xs={2}>
            <ConnectionMethodSelector onChange={handleChangeConnectionMethod} />
          </ConsultationMethod>
          <GraphWrapper item xl={10} lg={10} md={10} sm={10} xs={10}>
            <LabelWrapper item xl={12} lg={12} md={12} sm={12} xs={12}>
              <OverallSection item xl={6} lg={6} md={6} sm={6} xs={6}>
                <OverallGroup>
                  <OverallValue>
                    <span>{totalSpendTime.hours}</span>
                    <span>h</span>
                    <span>{totalSpendTime.minutes}</span>
                    <span>m</span>
                    <span>{totalSpendTime.seconds}</span>
                    <span>s</span>
                  </OverallValue>
                  <OverallLabel>TOTAL TIME</OverallLabel>
                </OverallGroup>
                <LargeSpacing>
                  <OverallGroup>
                    <OverallValue>
                      <span>{monthToDateSpendTime.hours}</span>
                      <span>h</span>
                      <span>{monthToDateSpendTime.minutes}</span>
                      <span>m</span>
                      <span>{monthToDateSpendTime.seconds}</span>
                      <span>s</span>
                    </OverallValue>
                    <OverallLabel>THIS MONTH</OverallLabel>
                  </OverallGroup>
                </LargeSpacing>
              </OverallSection>
              <TimeRangeSection item xl={6} lg={6} md={6} sm={6} xs={6}>
                <RangeSwitcher
                  onClick={(value) => setSelectedRange(value)}
                  options={timeRangeOptions}
                />
              </TimeRangeSection>
            </LabelWrapper>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Line
                data={chartDataSource}
                height={250}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  legend: {
                    display: false,
                    position: "bottom",
                  },
                  scales: {
                    xAxes: [
                      {
                        gridLines: {
                          color: "rgba(0, 0, 0, 0)",
                          drawBorder: false,
                          display: false,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          min: 0,
                          stepSize: 500,
                          callback: function (label) {
                            return nFormatter(label);
                          },
                        },
                        scaleLabel: {
                          display: true,
                          labelString: "Minutes",
                        },
                      },
                    ],
                  },
                }}
              />
            </Grid>
          </GraphWrapper>
        </CardContainer>
      </PaperStyler>
    </Grid>
  );
};

export default TimeSpendGraph;
