import { Grid } from "@material-ui/core";
import Icon from "components/common/Icon";
import TimePicker from "rc-time-picker";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;

  border: 1px solid ${styledTheme.colors.secondaryDark};
  padding: 6px 16px;
  border-radius: 16px;
  margin-bottom: 8px;
`;

export const CommonLabel = styled.div`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${styledTheme.colors.primaryBlack};
`;

export const CommonPicker = styled(TimePicker)`
  width: 110px;
  padding-left: 12px;
  padding-right: 12px;

  [class*="rc-time-picker-clear"] {
    display: none;
  }
`;

export const CloseIcon = styled(Icon)`
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
  }
`;

export const CheckIcon = styled(Icon)`
  width: 32px;
  height: 32px;
  cursor: pointer;

  img {
    width: 32px;
    height: 32px;
  }
`;
