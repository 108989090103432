export const LawAreas = {
  AutoAccident: "Auto Accident",
  ChildBirthInjury: "Child Birth Injury",
  DuiDwi: "DUI/DWI",
  CriminalDefence: "Criminal Defense",
  PersonalInjury: "Personal Injury",
  WorkersComp: "Workers Comp",
  NursingHomeAbuse: "Nursing Home Abuse",
  Tax: "Tax Law",
  ForeclosureDefense: "Foreclosure Defense",
  DebtAndBankruptcy: "Debt & Bankruptcy Law",
  EmploymentAndLabor: "Employment/Labor Law",
  EstatePlanning: "Estate Planning",
  FamilyAndDivorce: "Family & Divorce",
  Immigration: "Immigration Law",
  MedicalMalpractice: "Medical Malpractice",
  SocialSecurityDisability: "Social Security Disability Law",
  BusinessOrCorporate: "Business/Corporate Law",
  IdentityTheft: "Identity Theft",
  IntellectualProperty: "Intellectual Property",
  LandlordTenant: "Landlord/Tenant",
  RealEstate: "Real Estate Law",
  CivilLawsuit: "Civil Lawsuit",
  ConsumerProtection: "Consumer Protection",
  DebtCollection: "Debt Collection",
  TrafficViolations: "Traffic Violations",
  ProbateAndTrust: "Probate & Trust Law",
  GeneralPractice: "General Practice",
};

export const US_STATE_NAMES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
