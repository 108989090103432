import { Button, Grid } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Wrapper = styled.div`
  width: 90%;
  margin: auto;

  [class*="Description__Title"] {
    margin-top: 32px;
  }
`;

export const RegistrationWrapper = styled.div`
  width: 300px;
  margin: auto;
  padding-top: 48px;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const RegistrationHeaderText = styled.div`
  padding-top: 112px;
`;

export const SubmitSection = styled(Grid)`
  padding-top: 96px;
`;

export const StripeConnectButtonWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  padding-top: 0px !important;
`;

export const StripeConnectButton = styled(Button)`
  background-color: ${styledTheme.colors.secondaryDark} !important;
  border-radius: 12px !important;
  padding: 16px 66px !important;

  color: ${styledTheme.colors.white} !important;
  letter-spacing: 0.05em !important;

  font-weight: 500;
  font-size: 12px !important;
  line-height: 16px !important;
`;

export const SkipButton = styled.div`
  width: 100%;
  text-align: center;

  label {
    font-family: ${styledTheme.fonts.Inter};
    font-style: normal;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${styledTheme.colors.gray400};
    cursor: pointer;
  }
`;
