import { Container, TextAreaCommon } from "./textArea.styles";

interface ITextAreaProps {
  name: string;
  onChange: () => void;
  value: string;
}

const TextArea = (props: ITextAreaProps) => {
  const { name, onChange, value, ...rest } = props;

  return (
    <Container>
      <TextAreaCommon name={name} onChange={onChange} value={value} {...rest} />
    </Container>
  );
};

export default TextArea;
