import { NylasStateSync } from "constant/enum";
import { useContext, useEffect } from "react";
import useStore from "utils/useStore";
import { useLocation } from "react-router-dom";
import { NylasProvider } from "constant/enum/nylas";
import queryString from "querystring";
import { UserContext } from "contexts";
import moment from "moment";
export function useNylasCalendar(
  setIsOpenSetupCalendar: (isOpenSetupCalendar: boolean) => void
): void {
  const [userInfo] = useContext(UserContext);
  const { calendarStore } = useStore();
  const { nylasGoogleAccount, nylasICloudAccount, nylasOutlookAccount } =
    calendarStore;
  const location = useLocation();
  let isTwentyFourHoursCompleted = false;

  useEffect(() => {
    const params = queryString.parse(location.search.replace("?", ""));
    if (
      params?.code &&
      params?.state &&
      (params?.state === NylasProvider.GMAIL ||
        params?.state === NylasProvider.ICLOUD ||
        params?.state === NylasProvider.OUTLOOK) &&
      userInfo?.id
    ) {
      setIsOpenSetupCalendar(true);
    }
  }, [location, userInfo?.id]);
 
  const checkDateExpirey = (expireDate, userId) => {
    if (userInfo?.id === userId) {
      let currentTime = moment().format('YYYY-MM-DD')
      let result = moment(currentTime).isSame(moment(expireDate))
      return result
    } else {
      return false
    }
  }
  const setDate = () => {
    let obj = {
      userId: userInfo?.id,
      expireDate: moment().format('YYYY-MM-DD')
    }
    if (userInfo.id) {
      localStorage.setItem("model24", JSON.stringify(obj))
    }
    return true
  }
  const checkDateValidation = () => {
    const getDate = JSON.parse(localStorage.getItem("model24"))
    if (getDate) {
      let {expireDate, userId} = getDate
      let resultCheck = checkDateExpirey(expireDate, userId)
      return resultCheck ? false :  setDate()
    } else {
      return setDate()
    }
  }

  useEffect(() => {
    isTwentyFourHoursCompleted = checkDateValidation()
  },[userInfo.id])
  useEffect(() => {
    const isNylasGoogleAccountStop: boolean =
      nylasGoogleAccount.sync_state === NylasStateSync.INVALID ||
      nylasGoogleAccount.sync_state === NylasStateSync.STOPPED ||
      (!!userInfo?.id && !userInfo?.nylasGoogleAccessToken);

    const isNylasICloudAccountStop: boolean =
      nylasICloudAccount.sync_state === NylasStateSync.INVALID ||
      nylasICloudAccount.sync_state === NylasStateSync.STOPPED ||
      (!!userInfo?.id && !userInfo?.nylasICloudAccessToken);

    const isNylasOutlookAccountStop: boolean =
      nylasOutlookAccount.sync_state === NylasStateSync.INVALID ||
      nylasOutlookAccount.sync_state === NylasStateSync.STOPPED ||
      (!!userInfo?.id && !userInfo?.nylasOutlookAccessToken);

    if (
      isNylasGoogleAccountStop &&
      isNylasICloudAccountStop &&
      isNylasOutlookAccountStop &&
      isTwentyFourHoursCompleted

    ) {
      setIsOpenSetupCalendar(true);
    }
  }, [userInfo, nylasGoogleAccount, nylasICloudAccount, nylasOutlookAccount]);
}
