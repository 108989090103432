import { upsertAttorneySetting } from "API/attorneySetting";
import { CalendarDayCategory } from "constant/enum/calendar";
import CalendarStore from "stores/calendarStore";
import uniq from "lodash/uniq";
import { getTimeFrameFilter } from "pages/Dashboard/Consultation/ConsultingAvailability/utils";

export async function handleSelectDayRadio(
  calendarStore: CalendarStore,
  calendarDayCategory: CalendarDayCategory,
  attorneyId: string
): Promise<void> {
  const allWeek: number[] = [0, 1, 2, 3, 4, 5, 6];
  const weekDays: number[] = [1, 2, 3, 4, 5];
  calendarStore.setSelectDayCategory(calendarDayCategory);

  if (calendarDayCategory === CalendarDayCategory.ALL_WEEK) {
    calendarStore.setSelectDayOfWeek(allWeek);
    upsertAttorneySetting({
      attorneyId: attorneyId,
      calendarDayOfWeek: allWeek,
    });
  }

  if (calendarDayCategory === CalendarDayCategory.WEEK_DAYS) {
    calendarStore.setSelectDayOfWeek(weekDays);
    upsertAttorneySetting({
      attorneyId: attorneyId,
      calendarDayOfWeek: weekDays,
    });
  }

  upsertAttorneySetting({
    attorneyId: attorneyId,
    calendarDayCategory: calendarDayCategory,
  });
}

export async function handleSelectDayOfWeek(
  calendarStore: CalendarStore,
  dayOption: number,
  attorneyId: string
): Promise<void> {
  const { selectDayCategory, selectDayOfWeek } = calendarStore;
  if (selectDayCategory !== CalendarDayCategory.CUSTOM) {
    return;
  }

  let newSelectDayOfWeek: number[] = [];
  if (selectDayOfWeek?.includes(dayOption)) {
    newSelectDayOfWeek = uniq(
      selectDayOfWeek?.filter((day: number) => day !== dayOption)
    )?.sort();
  } else {
    newSelectDayOfWeek = uniq([...selectDayOfWeek, dayOption])?.sort();
  }
  calendarStore.setSelectDayOfWeek(newSelectDayOfWeek);
  await upsertAttorneySetting({
    attorneyId: attorneyId,
    calendarDayOfWeek: newSelectDayOfWeek,
  });
  const filter = getTimeFrameFilter(attorneyId);
  calendarStore.getAttorneyTimeFrames(filter);
}
