import React, { useState, useEffect, useContext } from 'react'
import { Grid, Paper } from '@material-ui/core'
import TextConsultationHeader from './TextConsultationHeader'
import ChatBody from './ChatBody/ChatBody'
import styled from 'styled-components'
import { UserContext, ConsultationContext} from 'contexts'
import {sleep} from '../../../../utils/helpers'
import { errorHandler } from 'utils/errorHandler'

const GridWrapper = styled(Grid)`
  padding-top:10px;
  //  height:80%;
  @media(max-width:1279px){
    height:auto;
  }
`

const StyledPaper = styled(Paper)`
  height: 100%;
  background-color: ${ ({ theme }) => theme.colors.white };
  border-radius: 18px;
  display: flex;
  flex-direction: column;
`

const StyledGrid = styled(Grid)`
  flex-grow: auto;
  display: flex;
  flex-direction: column;
`

const TextConsultation = ({
  details,
  conversationClient,
}) => {

  const [conversation, setConversation] = useState(undefined)

  const [
    userInfo, 
    updateUserInfo
  ] = useContext(UserContext);
  const cContext = useContext(ConsultationContext);


  let {
    id: clientId,
    client : { firstName, lastName, id, cognitoId},
    client,
    areaOfLaw,
    state,
    timeLimit,
    consultation,
  } = details ;

  useEffect(() => {
    if (conversationClient) {
      (async () => {
        let conversation;
        try {
          conversation = await conversationClient.getConversationByUniqueName(clientId);
          setConversation(conversation);
        } catch (err) {
          console.error('find conversation err', err)
          if (err.toString().includes('Not Found')) {
            conversation = await conversationClient.createConversation({
              uniqueName: clientId,
            });
            setConversation(conversation);
            await conversation.join();
            console.log('created new conversation', conversation.sid)
          }
        }
      })();
    }
  }, [conversationClient])

  useEffect(() => {
    if (conversation) {
      (async () => {
        let clientFound = false;

        while (!clientFound){
          try {
            await conversationClient.getUser(client.id);
            clientFound = true;
          } catch (err) {
            errorHandler(err);
            await sleep(2000);
            if (err.toString().includes('Not Found')) {
              console.log('client not found', client.id)
            }
          }
        }
        try {
          await conversation.add(client.id);
        } catch (error) {
          //INFO: ignore if client have already in room
          if (!error?.message?.includes("Member already exists")) {
            errorHandler(error)
          }
        }
      })();
    }
  }, [conversation])

  return(
    <GridWrapper item xs={12} md={12} lg={11}>
      <StyledPaper>
        <div>
          <TextConsultationHeader details={details || {}} />
        </div>
        <StyledGrid item xs={12}>
          <ChatBody conversation={conversation} />
        </StyledGrid>
      </StyledPaper>
    </GridWrapper>
  )
}

export default TextConsultation