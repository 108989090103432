import { useEffect, useContext, useState } from "react";
import { UserContext } from "contexts";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import useStore from "utils/useStore";
import {
  Container,
  PlanInformationWrapper,
  CardInformationWrapper,
} from "./subscriptionInformation.styles";
import CardInformation from "./components/CardInformation";
import PlanInformation from "./components/PlanInformation";
import UnsubscribeModal from "./components/UnsubscribeModal";
import { checkSubscription } from "../../utils";

interface ISubscriptionInformationProps {
  onClickUnSubscribe: () => void;
  onClickRejectUnSubscribe: () => void;
  onClickAcceptUnSubscribe: () => void;
  onClickEditCard: () => void;
  openUnSubscriptionModal: boolean;
}

const SubscriptionInformation = (props: ISubscriptionInformationProps) => {
  const {
    openUnSubscriptionModal,
    onClickUnSubscribe,
    onClickRejectUnSubscribe,
    onClickAcceptUnSubscribe,
    onClickEditCard,
  } = props;

  const [userInfo] = useContext(UserContext);

  const [subscribeStatus, setSubscribeStatus] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [stripeError, setStripeError] = useState(false);
  const [stripeCheck, setStripeCheck] = useState(false);
  const { subscriptionStore } = useStore();
  const { stripeCustomer, stripeSubscription } = subscriptionStore;

  useEffect(() => {
    if (userInfo && userInfo.id) {
      setStripeCheck(true);
    }
  }, [userInfo]);

  useEffect(() => {
    async function fetchData() {
      if (stripeCheck) {
        checkSubscription(
          setStripeError,
          setSubscribeStatus,
          setSubscription,
          setCustomer,
          userInfo
        );
      }
    }
    fetchData();
  }, [stripeCheck]);

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Container item xl={12} lg={12} md={12} sm={12} xs={12}>
        <PlanInformationWrapper item xl={4} lg={4} md={4} sm={4} xs={4}>
          <CardInformation onClickEditCard={onClickEditCard} />
        </PlanInformationWrapper>
        <CardInformationWrapper item xl={8} lg={8} md={8} sm={8} xs={8}>
          <PlanInformation
            stripeCustomer={stripeCustomer}
            stripeSubscription={stripeSubscription}
            onClickUnSubscribe={onClickUnSubscribe}
          />
        </CardInformationWrapper>
      </Container>
      <UnsubscribeModal
        isOpen={openUnSubscriptionModal}
        onClickRejectUnSubscribe={onClickRejectUnSubscribe}
        onClickAcceptUnSubscribe={onClickAcceptUnSubscribe}
      />
    </Grid>
  );
};

export default observer(SubscriptionInformation);
