import React from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import ConsulationButton from '../../../../components/common/ConsulationButton';

const PromptWrapper = styled(Grid)`
  background-color: #38A775;
  padding:20px;
  text-align:center;
  color: ${ ({ theme }) => theme.colors.white };
  border-radius:12px;
`

const Div = styled.div`
  width: 90%;
  margin: auto;
`

const CurrentQuestionBanner = ({text, isBtnsFull=false}) => {
  
  return (
    <PromptWrapper item xs={12}>
      <Grid item xs={12}>
        {text}
      </Grid>
      <Grid item container xs={12}  justify="center" alignItems="center" >
        <Grid style={{marginBottom:"-50px"}} item xs={isBtnsFull?12:6}>    
          <Div>
            <ConsulationButton>
              Question Solved End 
            </ConsulationButton>
          </Div>
        </Grid>
        <Grid style={{marginBottom:"-50px"}}  item xs={isBtnsFull?12:6}>
          <Div style={{width:"90%",margin:"auto"}}>
            <ConsulationButton > 
              OFFER A NEW QUESTION
            </ConsulationButton>    
          </Div>
        </Grid>
      </Grid>
    </PromptWrapper>
  )
}

export default CurrentQuestionBanner