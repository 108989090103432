import SubscriptionStore from "./subscriptionStore";
import AttorneyStore from "./attorneyStore";
import ConsultationStore from "./consultationStore";
import CalendarStore from "./calendarStore";

export interface IRootStore {
  subscriptionStore: SubscriptionStore;
  attorneyStore: AttorneyStore;
  consultationStore: ConsultationStore;
  calendarStore: CalendarStore;
}

class RootStore {
  subscriptionStore: SubscriptionStore;
  attorneyStore: AttorneyStore;
  consultationStore: ConsultationStore;
  calendarStore: CalendarStore;

  constructor() {
    this.subscriptionStore = new SubscriptionStore(this);
    this.attorneyStore = new AttorneyStore(this);
    this.consultationStore = new ConsultationStore(this);
    this.calendarStore = new CalendarStore(this);
  }
}

export default function initializeStore(): IRootStore {
  const store = new RootStore();
  return store;
}
