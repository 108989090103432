import { REACT_APP_FRONTEND_URL, REACT_APP_NYLAS_CLIENT_ID } from "config";
import { ConnectButtonProvider } from "constant/enum";

export function handlePressConnectButton(
  provider: ConnectButtonProvider
): void {
  if (provider === ConnectButtonProvider.GOOGLE) {
    const googleOAuthUrl = `https://api.nylas.com/oauth/authorize?client_id=${REACT_APP_NYLAS_CLIENT_ID}&redirect_uri=${REACT_APP_FRONTEND_URL}${window.location.pathname}&response_type=code&scopes=calendar&state=gmail`;
    window.location.href = googleOAuthUrl;
  }
}
