import React from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import {ContactUs} from 'icons'
import styled from 'styled-components'
import HowItWorks from './HowItWorks'
import Payment from './Payment'
import Reviews from './Reviews'
import Account from './Account'
import StyledButton from "components/common/Custom-button";
import { styledTheme } from '../../../styles/StyledTheme'

const GridWrapper = styled(Grid)`
  height:100vh;
  @media(max-width:1279px){
    height:auto;
    margin-top:40px;  
  }
  .contactUs{
    display:flex;
    justify-content:flex-end;
    align-items:flex-start;
    @media(max-width:1279px){
      margin-top:20px;  
    }
    @media(max-width:599px){
      justify-content:center;
    }
    .contactImage{
      width:300px;
      height:auto;
      cursor: pointer;
      @media(max-width:599px){
        width:80%;
      }
    }
  }
`
const TabTitle = styled(Grid)`
  color: ${ ({ theme }) => theme.colors.infoDark };
  font-size: 14px;
  cursor: pointer;
  text-align:center;
  display:flex;
  justify-content:center;
  flex-direction:column;
`
const StyledIFrame = styled.iframe`
  width: 100%;
  border: 0;
  min-height: 300px;
`
  const inActiveBorder={
    borderTop: `1px solid ${ styledTheme.colors.infoDark }`,
    width:"60%",
    margin:"auto"
  }

  const activeBorder = {
    borderTop: `1px solid ${ styledTheme.colors.primary }`,
    width:"60%",
    margin:"auto"
  }

  const activeTab = {
    color: styledTheme.colors.primary
  }

const inactiveTab = {}

const Help = () => {
  const [selectedTab, setSelectedTab] = React.useState('howItWorks')
  const handleTabSelection = tab => setSelectedTab(tab)
  const history = useHistory();

  return(
    <GridWrapper item xs={12} container >
      <Grid item xs={12} lg={8}>
        <Grid item xs={12} container >    
          <TabTitle xs={3} item onClick={() => handleTabSelection('howItWorks')} style={selectedTab === 'howItWorks'? activeTab: inactiveTab} >
            <div>HOW IT WORKS </div>
            <div className="mt-2" style={selectedTab === 'howItWorks'? activeBorder: inActiveBorder}></div>
          </TabTitle>        
          <TabTitle xs={3} item onClick={() => handleTabSelection('payment')} style={selectedTab === 'payment'? activeTab: inactiveTab} >
            <div>PAYMENT </div>
            <div className="mt-2" style={selectedTab === 'payment'? activeBorder: inActiveBorder}></div>
            </TabTitle>
          <TabTitle xs={3} item onClick={() => handleTabSelection('reviews')} style={selectedTab === 'reviews'? activeTab: inactiveTab} >
            <div>REVIEWS </div>
            <div className="mt-2" style={selectedTab === 'reviews'? activeBorder: inActiveBorder}></div>
          </TabTitle>
          <TabTitle xs={3} item onClick={() => handleTabSelection('account')} style={selectedTab === 'account'? activeTab: inactiveTab} >
            <div>ACCOUNT </div>
            <div className="mt-2" style={selectedTab === 'account' ? activeBorder: inActiveBorder}></div>
          </TabTitle>
        </Grid>
        <Grid className="mt-4">
          {
            selectedTab === 'howItWorks'?
              <HowItWorks /> : selectedTab === 'payment'?
                <Payment /> : selectedTab === 'reviews'?
                  <Reviews /> : selectedTab === 'account'?
                    <Account /> : ''
          }
        </Grid>
        <br/>
      </Grid>
      <Grid xs={12} lg={4} item className="contactUs" >     
        <div>
          <ContactUs className="contactImage" onClick={() => window.open('https://legalq.io/contact-legalq/')} />
          <br/>
          <StyledButton
            variant="contained"
            style={{
              padding: "10px 27px",
              backgroundColor: styledTheme.colors.primary ,
              color: "white",
              boxShadow: `2px 25px 20px -18px ${ styledTheme.colors.primary }`,
              fontSize: "12px",
              width: '100%',
              marginTop: 10
            }}
            onClick={() => window.location.href = '/dashboard/my-account?appcue=8e3d7483-a141-4edf-b70b-5af323b99767'}
          >
            Dashboard Walkthrough
          </StyledButton>
        </div>
      </Grid>
    </GridWrapper>
  )
}

export default Help