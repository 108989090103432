import React from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'

const StyledButton = styled(Button)`
  padding: 10px 27px;
  color: white;
  font-size: 12px;
  ${({backgroundcolor, theme}) => backgroundcolor && `background-color: ${theme.colors[backgroundcolor]}`};
  ${({width}) => width && `width: ${width}`};
  ${({backgroundcolor, theme, }) => backgroundcolor && `box-shadow: ${ `2px 25px 20px -18px ${theme.colors[backgroundcolor]}`}`};
  &.MuiButton-contained.Mui-disabled {
    box-shadow: unset !important;
  }
  &:hover{
    ${({backgroundcolor, theme}) => backgroundcolor && `background-color: ${theme.colors.hover[backgroundcolor]}`};
  }
`

const ButtonWrapper = styled.div`
    width:100%;
    margin:auto;
`

const CustomButton =  ({
  children,
  color,
  width,
  disabled,
  ...otherProps
}) => {

  return(
    <ButtonWrapper>
      <StyledButton
        type= 'submit' 
        variant= 'contained' 
        backgroundcolor= { color } 
        width= { width } 
        disabled = { disabled }
        { ...otherProps }
      >
        { children }
      </StyledButton>
    </ButtonWrapper>
  )
}

export default CustomButton