import Icon from "components/common/Icon";
import { observer } from "mobx-react";
import useStore from "utils/useStore";
import { openExternalUrl } from "./utils";
import AppleHelper from "../AppleHelper";
import MicrosoftHelper from "../MicrosoftHelper";
import {
  Container,
  ReminderSection,
  HeadingIcon,
  HeadingTitle,
  Guidelines,
  ActionSection,
  SignInButton,
  OutlineButton,
  LearnMore,
} from "./modalContent.styles";
import { NylasProvider } from "constant/enum";

const ModalContent = () => {
  const { calendarStore } = useStore();

  return (
    <Container>
      <ReminderSection>
        <HeadingIcon>
          <Icon iconName="lock-blue.svg" />
        </HeadingIcon>
        <div>
          <HeadingTitle>Create a password specifically for LegalQ</HeadingTitle>
          <Guidelines>
            {calendarStore.displayConnectProvider === NylasProvider.ICLOUD && (
              <AppleHelper />
            )}
            {calendarStore.displayConnectProvider === NylasProvider.OUTLOOK && (
              <MicrosoftHelper />
            )}
          </Guidelines>
        </div>
      </ReminderSection>
      <ActionSection>
        <SignInButton
          onClick={() => {
            calendarStore.setDisplayConnectHelper(false);
            calendarStore.setOpenCalendarModal(true);
          }}
        >
          SIGN IN
        </SignInButton>
        <OutlineButton
          onClick={() => {
            calendarStore.setDisplayConnectProvider(undefined);
            calendarStore.setDisplayConnectHelper(false);
            calendarStore.setOpenCalendarModal(true);
          }}
        >
          CANCEL
        </OutlineButton>
        <LearnMore
          onClick={() => openExternalUrl(calendarStore.displayConnectProvider)}
        >
          Learn more
        </LearnMore>
      </ActionSection>
    </Container>
  );
};

export default observer(ModalContent);
