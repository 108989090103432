import { Modal, Container, Fade, Backdrop } from "@material-ui/core";
import ModalContent from "./components/ModalContent";
import { useStyles } from "./calendarConnectFAQ.styles";

interface ISetupCalendarModalProps {
  isOpen: boolean;
}

const SetupCalendarModal = (props: ISetupCalendarModalProps) => {
  const classes = useStyles();
  const { isOpen } = props;

  return (
    <Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <ModalContent />
          </div>
        </Fade>
      </Modal>
    </Container>
  );
};

export default SetupCalendarModal;
