import { GetValues } from "types";
import { UseFormTrigger, FieldValues } from "react-hook-form";
import { toast } from "react-toastify";
import { getNylasConnectionCode } from "API/nylas";
import { MicrosoftConnectForm } from "./constants";
import { NylasProvider } from "constant/enum";
import { IAttorney, INylasConnection } from "interfaces";
import { RouteComponentProps } from "react-router-dom";

export async function handleOutlookConnectButton(
  setIsLoading: (isLoading: boolean) => void,
  getValues: GetValues,
  trigger: UseFormTrigger<FieldValues>,
  attorney: IAttorney,
  history: RouteComponentProps["history"]
): Promise<void> {
  trigger(MicrosoftConnectForm.APP_SPECIFIC_PASSWORD);
  const formValues = getValues();
  const outlookEmail: string = formValues?.[MicrosoftConnectForm.OUTLOOK_EMAIL];

  const appSpecificPassword: string =
    formValues?.[MicrosoftConnectForm.APP_SPECIFIC_PASSWORD];

  if (!appSpecificPassword || !outlookEmail) {
    return;
  }

  setIsLoading(true);
  try {
    const nylasConnection: INylasConnection = {
      name: `${attorney?.firstName} ${attorney?.lastName}`,
      emailAddress: outlookEmail,
      nylasProvider: NylasProvider.OUTLOOK,
      appSpecificPassword: appSpecificPassword,
    };
    const code = await getNylasConnectionCode(nylasConnection);
    if (code) {
      const callbackUrl = `${window.location.pathname}?code=${code}&state=${NylasProvider.OUTLOOK}`;
      history.replace(callbackUrl);
    }
  } catch (error) {
    toast.error(
      "Please check the username is correct, and that you are using an app-specific password."
    );
  }
  setIsLoading(false);
}

export function redirectMicrosoftHelperPage(): void {
  if (window) {
    window.open(
      "https://support.microsoft.com/en-us/account-billing/using-app-passwords-with-apps-that-don-t-support-two-step-verification-5896ed9b-4263-e681-128a-a6f2979a7944"
    );
  }
}
