import { ChartData } from "react-chartjs-2";
import * as chartjs from "chart.js";
import { styledTheme } from "styles/StyledTheme";
import { IMixData } from "interfaces/dashboard";
import {
  getLastDays,
  getLastDaysWithLabel,
  getLastMonths,
  getLastMonthsWithLabel,
  getLastWeeks,
  getLastWeeksWithLabel,
} from "utils/common";
import { NUMBERS_MONTH_OF_YEAR } from "../constants";
import {
  getTotalTimeSpendByDay,
  getTotalTimeSpendByMonth,
  getTotalTimeSpendByWeek,
} from "../utils";
import { useEffect, useState } from "react";
import { IRangeObject } from "interfaces";
import { Moment } from "moment";
import { TimeRange } from "components/common/RangeSwitcher/constants";
import { chartData } from "../constants";
import { ConnectionMethod } from "constant/enum";

export function useChartData(
  mixConsultations: IMixData[],
  selectRange: TimeRange,
  connectionMethod: ConnectionMethod[]
): {
  yearChart: ChartData<chartjs.ChartData>;
  dateChart: ChartData<chartjs.ChartData>;
  weekChart: ChartData<chartjs.ChartData>;
} {
  const [yearChart, setYearChart] = useState(chartData);
  const [weekChart, setWeekChart] = useState(chartData);
  const [dateChart, setDateChart] = useState(chartData);

  function processYearData(): ChartData<chartjs.ChartData> {
    const lastYearWithLabel = getLastMonthsWithLabel(NUMBERS_MONTH_OF_YEAR);
    const lastYear = getLastMonths(NUMBERS_MONTH_OF_YEAR);
    const amountLastYear = lastYear.map((month: number) =>
      Math.floor(
        getTotalTimeSpendByMonth(mixConsultations, month, connectionMethod) / 60
      )
    );

    const chartData: ChartData<chartjs.ChartData> = {
      labels: lastYearWithLabel,
      datasets: [
        {
          label: "Minutes",
          fill: false,
          lineTension: 0.5,
          backgroundColor: "white",
          borderColor: styledTheme.colors.secondary,
          borderWidth: 2,
          data: amountLastYear,
        },
      ],
    };

    return chartData;
  }

  function processWeekData(): ChartData<chartjs.ChartData> {
    const lastWeekWithLabel: string[] = getLastWeeksWithLabel(8);
    const lastWeeks = getLastWeeks(8);
    const amountLastDays = lastWeeks.map((weekRange: IRangeObject<Moment>) =>
      Math.floor(
        getTotalTimeSpendByWeek(mixConsultations, weekRange, connectionMethod) / 60
      )
    );

    const chartData: ChartData<chartjs.ChartData> = {
      labels: lastWeekWithLabel,
      datasets: [
        {
          label: "Minutes",
          fill: false,
          lineTension: 0.5,
          backgroundColor: "white",
          borderColor: styledTheme.colors.secondary,
          borderWidth: 2,
          data: amountLastDays,
        },
      ],
    };
    return chartData;
  }

  function processDateData(): ChartData<chartjs.ChartData> {
    const lastDateWithLabel = getLastDaysWithLabel(14);
    const lastDate = getLastDays(14);
    const amountLastDate = lastDate.map((day: number) =>
      Math.floor(getTotalTimeSpendByDay(mixConsultations, day, connectionMethod) / 60)
    );

    const chartData: ChartData<chartjs.ChartData> = {
      labels: lastDateWithLabel,
      datasets: [
        {
          label: "Minutes",
          fill: false,
          lineTension: 0.5,
          backgroundColor: "white",
          borderColor: styledTheme.colors.secondary,
          borderWidth: 2,
          data: amountLastDate,
        },
      ],
    };
    return chartData;
  }

  useEffect(() => {
    if (mixConsultations?.length > 0 && selectRange === TimeRange.MONTH) {
      const yearChartData = processYearData();
      setYearChart(yearChartData);
    }
  }, [mixConsultations, selectRange, connectionMethod]);

  useEffect(() => {
    if (mixConsultations?.length > 0 && selectRange === TimeRange.DAY) {
      const dateChartData = processDateData();
      setDateChart(dateChartData);
    }
  }, [mixConsultations, selectRange, connectionMethod]);

  useEffect(() => {
    if (mixConsultations?.length > 0 && selectRange === TimeRange.WEEK) {
      const weekChartData = processWeekData();
      setWeekChart(weekChartData);
    }
  }, [mixConsultations, selectRange, connectionMethod]);

  return { yearChart, dateChart, weekChart };
}
