import { ConnectButtonProvider, NylasStateSync } from "constant/enum";
import useStore from "utils/useStore";
import GoogleButton from "components/common/CalendarConnect/components/GoogleButton";
import { handlePressConnectButton } from "./utils";
import { ButtonWrapper, CustomCheckbox } from "./googleConnectSection.styles";
import { observer } from "mobx-react";

const GoogleConnectButton = () => {
  const { calendarStore } = useStore();
  const { nylasGoogleAccount } = calendarStore;

  return (
    <ButtonWrapper>
      <CustomCheckbox
        disabled
        checked={
          nylasGoogleAccount?.sync_state === NylasStateSync.RUNNING ||
          nylasGoogleAccount?.sync_state === NylasStateSync.PARTIAL
        }
        color="primary"
      />
      <GoogleButton
        onClick={() => handlePressConnectButton(ConnectButtonProvider.GOOGLE)}
      />
    </ButtonWrapper>
  );
};

export default observer(GoogleConnectButton);
