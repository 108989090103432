import { Button, Grid } from "@material-ui/core";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import { breakpoints } from "styles/layout";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)`
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoints("align-items", [
    {
      xxl: "center",
    },
    {
      xl: "center",
    },
    {
      lg: "center",
    },
    {
      md: "flex-start",
    },
    {
      sm: "flex-start",
    },
    {
      xs: "flex-start",
    },
  ])}
`;

export const SubscriptionStatus = styled.div<{ isActive: boolean }>`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) =>
    props?.isActive ? styledTheme.colors.secondary : styledTheme.colors.red500};
`;

export const SubscribeWrapper = styled(Grid)`
  padding-top: 0px;
`;

export const SubscribeButton = styled(Button)<{ isActive: boolean }>`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  text-align: center;
  letter-spacing: 1.2px;
  text-transform: initial;

  background-color: ${(props) =>
    props?.isActive
      ? styledTheme.colors.white
      : styledTheme.colors.secondaryDark} !important;

  color: ${(props) =>
    props?.isActive
      ? styledTheme.colors.gray700
      : styledTheme.colors.white} !important;

  border: 1px solid
    ${(props) =>
      props?.isActive
        ? styledTheme.colors.gray200
        : styledTheme.colors.secondaryDark};

  box-shadow: ${styledTheme.shadow.blueBelow};

  border-radius: 12px;
  min-height: 52px;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;

  &:hover {
    background-color: ${(props) =>
      props?.isActive
        ? styledTheme.colors.white
        : styledTheme.colors.secondaryDark} !important;

    color: ${(props) =>
      props?.isActive
        ? styledTheme.colors.gray700
        : styledTheme.colors.white} !important;
  }
`;

export const OvalLoader = styled(Loader)`
  margin-right: 4px;
`;
