import React from 'react'
import styled from "styled-components";
import { Grid, Button } from '@material-ui/core'
import Rating from "@material-ui/lab/Rating";

export const GStyledGrid = ({ 
  textAlign, 
  backgroundColor,
  paddingBottom,
  marginBottom,
  marginTop,
  paddingTop,
  textTransform,
  maxWidth,
  alignItems,
  justifyContent,
  maxHeight,
  flexDirection,
  ...rest
}) => {
  return(
    <StyledGrid 
      { ...rest } 
      textalign= { textAlign } 
      backgroundcolor= { backgroundColor } 
      paddingbottom= { paddingBottom }  
      marginbottom= { marginBottom }
      margintop= { marginTop }
      paddingtop= { paddingTop }
      texttransform= { textTransform }
      maxwidth= { maxWidth }
      alignitems= { alignItems }
      justifycontent= { justifyContent }
      maxheight= { maxHeight }
      flexdirection= { flexDirection }
    />
  )
}

const StyledGrid = styled(Grid)`
  ${({color}) => color && `color: ${color}`};
  ${({height}) => height && `height: ${height}`};
  ${({maxheight}) => maxheight && `max-height: ${maxheight}`};
  ${({width}) => width && `width: ${width}`};
  ${({textalign}) => textalign && `text-align: ${textalign}`};
  ${({backgroundcolor}) => backgroundcolor && `background-color: ${backgroundcolor}`};   
  ${({paddingbottom}) => paddingbottom && `padding-bottom: ${paddingbottom}`};
  ${({marginbottom}) => marginbottom && `margin-bottom: ${marginbottom}`};
  ${({margintop}) => margintop && `margin-top: ${margintop}`};
  ${({paddingtop}) => paddingtop && `padding-top: ${paddingtop}`};
  ${({position}) => position && `position: ${position}`};
  ${({fontSize}) => fontSize && `font-size: ${fontSize}`};
  ${({fontWeight}) => fontWeight && `font-weight: ${fontWeight}`};
  ${({right}) => right && `right: ${right}`};
  ${({texttransform}) => texttransform && `text-transform: ${texttransform}`};
  ${({maxwidth}) => maxwidth && `max-width: ${maxwidth}`};
  ${({display}) => display && `display: ${display}`};
  ${({flexdirection}) => flexdirection && `flex-direction: ${flexdirection}`};
  ${({alignitems}) => alignitems && `align-items: ${alignitems}`};
  ${({justifycontent}) => justifycontent && `justify-content: ${justifycontent}`};  
`

export const GStyledRating = styled(Rating)`
  ${({color}) => color && `color: ${color}`};
  ${({fontSize}) => fontSize && `font-size: ${fontSize}`};
`

export const GStyledButton = ({
  backgroundColor,
  ...rest
}) => {
  return(
    <StyledButton 
      { ...rest } 
      backgroundcolor= { backgroundColor } 
    />
  )
}

const StyledButton = styled(Button)`
  ${({width}) => width && `width: ${width}`};
  ${({fontSize}) => fontSize && `font-size: ${fontSize}`};
  ${({backgroundcolor}) => backgroundcolor && `background-color: ${backgroundcolor}`};   
  ${({padding}) => padding && `padding: ${padding}`};
`
