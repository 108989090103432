import { observer } from "mobx-react";
import useStore from "utils/useStore";
import DayBlock from "./components/DayBlock";
import {
  Container,
  ContentWrapper,
  Header,
  Warning,
} from "./timeSection.styles";

const TimeSection = () => {
  const { calendarStore } = useStore();
  const { selectDayOfWeek } = calendarStore;

  return (
    <Container>
      <Header>Time</Header>
      <ContentWrapper>
        {Array.isArray(selectDayOfWeek) &&
          selectDayOfWeek?.map((dayIndex: number, index: number) => (
            <DayBlock key={index} dayIndex={dayIndex} />
          ))}
        {!selectDayOfWeek?.length && (
          <Warning>Please select day of week</Warning>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default observer(TimeSection);
