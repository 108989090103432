import get from "lodash/get";
import { API, graphqlOperation } from "aws-amplify";
import { StripeSubscriptionStatus } from "constant/enum";
import { IStripeSubscription } from "interfaces/subscription";
import { StrategicPartnerMethod } from "constant/enum";
import { ValidateStrategicPartner } from "gql";
import { IUserInfo } from "interfaces/user";

export async function validateStrategicPartner(
  emailWork: string
): Promise<boolean> {
  let isStrategicPartner = false;

  if (emailWork) {
    try {
      const response = await API.graphql(
        graphqlOperation(ValidateStrategicPartner, {
          input: {
            email: emailWork,
            operation: StrategicPartnerMethod.VALIDATE,
          },
        })
      );
      const body = JSON.parse(get(response, "data.strategicPartner.body"));
      isStrategicPartner = body?.isStrategicPartner;
    } catch (error) {
      isStrategicPartner = false;
    }
  }
  return isStrategicPartner;
}

export async function checkSubscriptionPermission(
  emailWork: string,
  stripeSubscription: IStripeSubscription
): Promise<boolean> {
  const isSubscribed: boolean =
    stripeSubscription?.status === StripeSubscriptionStatus.ACTIVE;
  return (await validateStrategicPartner(emailWork)) || isSubscribed;
}

export async function checkAttorneyFullPermission(
  requestedAttorneys: string[],
  userInfo: IUserInfo,
  stripeSubscription: IStripeSubscription
): Promise<boolean> {
  const isAllow: boolean =
    requestedAttorneys?.includes(userInfo?.id) &&
    (await checkSubscriptionPermission(
      userInfo?.emailWork,
      stripeSubscription
    ));
  return isAllow;
}
