import ScheduleCard from "./components/ScheduleCard";
import OverrideCard from "./components/OverrideCard";
import { Container, ReactCalendar, Divider } from "./calendar.styles";
import { observer } from "mobx-react";
import useStore from "utils/useStore";

export const Calendar = () => {
  const { calendarStore } = useStore();

  return (
    <Container>
      <ReactCalendar
        value={calendarStore?.selectedDate}
        onChange={(date: Date) => calendarStore?.setSelectedDate(date)}
        calendarType="US"
        locale="en-US"
      />
      <Divider />
      <ScheduleCard />
      <OverrideCard />
    </Container>
  );
};

export default observer(Calendar);
