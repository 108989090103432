import { Paper } from "@material-ui/core";
import EditableInput from "components/common/EditableInput";
import EditableSelect from "components/common/EditableSelect";
import styled from "styled-components";
import { BreakPoints } from "styles/layout";

export const PaperWrapper = styled(Paper)`
  min-height: 330px;
  font-size: 22px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 18px;

  .wrapper {
    width: 80%;
    margin: auto;
    padding: 20px 0px;

    p {
      font-size: 16px;
      margin: 0px !important;
    }

    hr {
      margin-bottom: 0px !important;
    }

    label {
      font-size: 10px;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.defaultDark};
    }
  }
  .scrollable {
    @media (min-width: ${BreakPoints.LG}px) {
      overflow-y: auto;
      height: 330px;
      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      &::-webkit-scrollbar-track {
        background: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
        &:horizontal {
          border-radius: 4px;
          background: #96acb9;
        }
      }
    }
  }
`;

export const EditableInputCommon = styled(EditableInput)`
  label {
    padding-top: 0px !important;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #9b9ca5;
    text-transform: uppercase;
  }

  hr {
    margin-top: 7px;
  }

  div {
    background-color: white !important;
    padding-left: 0px !important;
    padding-right: 0px !important;

    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: #26272c;
  }
`;

export const EditableSelectCommon = styled(EditableSelect)`
  div {
    background-color: white !important;
    padding-left: 0px !important;
    padding-right: 0px !important;

    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: #26272c;
  }

  label {
    margin-top: 0px !important;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #9b9ca5;
    text-transform: uppercase;
  }

  hr {
    margin-top: 7px;
  }
`;
