import { useContext, useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import UserStatus from "./UserStatus";
import SidebarMenu from "./SidebarMenu";
import { Link, useLocation } from "react-router-dom";
import {
  Logo,
  HomeIcon,
  HelpIcon,
  RatingIcon,
  LogoutIcon,
  ConsultationIcon,
  HomeActiveIcon,
  ConsultationActiveIcon,
  RatingActiveIcon,
  HelpActiveIcon,
  MyAccountIcon,
  MyAccountActiveIcon
} from "icons";
import { signOut } from "services/auth-service";
import { clearStorage } from "services/consultationService"
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import { UserContext } from "contexts";
import stripeService from 'services/stripeService';
import useStore from "utils/useStore";
import { observer } from "mobx-react";
import { errorHandler } from "utils/errorHandler";
import { StorageKeys } from "constant/enum/storage";

const LogoImage = styled.img`
  width: 200px;
  height: 35px;
`;
const SidebarGrid = styled.div`
  text-align: center;
  .logout:hover{
    color:black;   
  }
`;
const Menus = styled(Grid)`
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  img {
    height: 35px;
    width: 35px;
  }
`;
const Logout = styled(Grid)`
  color: grey;
  padding: 5px 40px;
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    height: 35px;
    width: 35px;
  }
  @media screen and (max-height: 820px) {
    padding: 5px 35px;
  }
`;

const StyledLink = styled(Link)`
  font-weight: ${props => props?.isActive ? 'bold' : 'normal'};
  color: ${props => props?.isActive ? '#287EC7' : '#BBC9D5'};
`;

const Sidebar = () => {
  const [userInfo, setUserInfo] = useContext(UserContext);
  const menuDisabled = useContext(UserContext)[4];
  const [isAttorneySubscribed, setAttorneySubscribed] = useState(false);
  const { firstName, lastName } = userInfo;
  const location = useLocation();
  const { subscriptionStore, attorneyStore } = useStore();
  const isStrategicPartner = subscriptionStore?.isStrategicPartner

  async function handleLogout() {
    try {
      setUserInfo({});
      await signOut();
      clearStorage('logout')
      sessionStorage.removeItem(StorageKeys.ATTORNEY_UPDATE_STATUS);
      localStorage.removeItem(StorageKeys.ATTORNEY_ACCESS_TOKEN);
      setTimeout(() => {
        window.location.href = "/"
      }, 400)
    } catch (e) {
      errorHandler(e)
      console.error(e);
    }
  }

  useEffect(() => {
    async function fetchData() {
      const isAttorneySubscribed = await stripeService.checkIsAttorneySubscribed(userInfo);
      setAttorneySubscribed(isAttorneySubscribed)
    }
    fetchData();
  }, [userInfo])

  return (
    <SidebarGrid
      style={menuDisabled ? { pointerEvents: 'none' } : {}}
    >
      <Divider />
      <Link to="/dashboard">
        <div style={{ paddingTop: 10 }}>
          <LogoImage src={Logo} />
        </div>
      </Link>
      <UserStatus firstName={firstName} lastName={lastName} />
      <List>
        <Menus>
          <SidebarMenu>
            <StyledLink isActive={location?.pathname === '/dashboard'} to="/dashboard">
              <img src={location?.pathname === '/dashboard' ? HomeActiveIcon : HomeIcon} alt="home" style={{ paddingRight: "15px" }} />{" "}
              HOME
            </StyledLink>
          </SidebarMenu>
          <SidebarMenu>
            <StyledLink isActive={location?.pathname === '/dashboard/my-account'} to="/dashboard/my-account">
              <img src={location?.pathname === '/dashboard/my-account' ? MyAccountActiveIcon : MyAccountIcon} alt="my-account" style={{ paddingRight: "15px" }} />{" "}
              MY ACCOUNT
            </StyledLink>
          </SidebarMenu>

          <SidebarMenu>
            <StyledLink isActive={location?.pathname === '/dashboard/ratings'} to="/dashboard/ratings">
              <img
                src={location?.pathname === '/dashboard/ratings' ? RatingActiveIcon : RatingIcon}
                alt="rating"
                style={{ paddingRight: "15px" }}
              />{" "}
              RATINGS
            </StyledLink>
          </SidebarMenu>

          <SidebarMenu>
            <StyledLink isActive={location?.pathname === '/dashboard/consultations'} to="/dashboard/consultations">
              {(isAttorneySubscribed || isStrategicPartner) ?
                <Badge badgeContent={attorneyStore?.numberOfPendingRequests ?? 0} color="secondary">
                  <img
                    src={location?.pathname === '/dashboard/consultations' ? ConsultationActiveIcon : ConsultationIcon}
                    alt={'Consultations'}
                    style={{ width: 20 }}
                  />
                </Badge>
                :
                <Tooltip title={'You need to subscribe to enable consultations'}>
                  <Badge badgeContent={'!'} color="secondary">
                    <img
                      src={location?.pathname === '/dashboard/consultations' ? ConsultationActiveIcon : ConsultationIcon}
                      alt={'Consultations'}
                      style={{ width: 20 }}
                    />
                  </Badge>
                </Tooltip>
              }
              <span style={{ paddingLeft: "15px" }}>
                CONSULTATIONS
              </span>
            </StyledLink>
          </SidebarMenu>

          <SidebarMenu>
            <StyledLink isActive={location?.pathname === '/dashboard/help'} to="/dashboard/help">
              <img src={location?.pathname === '/dashboard/help' ? HelpActiveIcon : HelpIcon} alt="help" style={{ paddingRight: "15px" }} />{" "}
              HELP
            </StyledLink>
          </SidebarMenu>
          <hr style={{ marginTop: "0rem", marginBottom: "5px" }} />
          <Logout
            onClick={() => handleLogout()}
          >
            <img style={{ width: "20px" }} src={LogoutIcon} alt="logout" />
            <div className="logout" style={{ paddingLeft: "15px" }}>Logout</div>
          </Logout>
        </Menus>
      </List>
      {
        menuDisabled && <div style={{ fontSize: 12, color: 'gray' }}>Cannot use menu during active consultation</div>
      }
    </SidebarGrid>
  );
};

export default observer(Sidebar);
