import { Grid } from "@material-ui/core";
import Icon from "components/common/Icon";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)<{ theme: "light" | "dark" }>`
  width: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;

  background-color: ${(props) =>
    props?.theme === "light"
      ? styledTheme.colors.white
      : styledTheme.colors.backgroundBlue};
  border-radius: 16px;
`;

export const TagLabel = styled.div`
  width: fit-content;
  white-space: nowrap;

  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${styledTheme.colors.primaryBlack};
  cursor: default;
`;

export const RemoveIcon = styled(Icon)`
  display: flex;
  padding-left: 12px;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
  }
`;
