import React from 'react'
import Grid from '@material-ui/core/Grid'
import FeaturedArticleColumn from 'pages/Landing/FeaturedArticles/FeaturedArticleColumn'
import { ARTICLES } from 'pages/Landing/FeaturedArticles/constants'
import styled from 'styled-components';

const GridWrapper = styled.div`
  width:70%;
  margin:auto;
  background-color:#ECF5FA;
  margin-bottom:100px;
  @media (max-width:600px){
    margin-top:0px;
    width:100%;
  }
`

const FeaturedArticles = props => {
  return (
    <GridWrapper>
      <Grid container justify="space-around">
      <FeaturedArticleColumn {...ARTICLES[0]} />
      <FeaturedArticleColumn {...ARTICLES[2]} />
      {/* {
        ARTICLES.map((article, index) => <FeaturedArticleColumn key={index} {...article} />)
      } */}
      </Grid>

    </GridWrapper>
    
  )
}

export default FeaturedArticles
