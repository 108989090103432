// components
import { Grid } from "@material-ui/core";
import CustomHeading from "components/common/CustomHeading";
import { getValidArray } from "utils/common/array";
import { jsonParse } from "utils/common/json";
import { US_STATE_NAMES } from "../../../../../constant";
import {
  EditableInputCommon,
  EditableSelectCommon,
  PaperWrapper,
} from "./personalInformation.styles";

const PersonalInformation = ({
  licenseNumbers,
  statesOfLicense = [],
  firmAssociation,
  handleUpdateAttorney,
  phoneNumber,
}) => {
  const licenseNumbersJSON = jsonParse(licenseNumbers, {});
  return (
    <Grid item lg={4} md={6} xs={12}>
      <CustomHeading style={{ marginBottom: "20px" }}>
        PERSONAL INFORMATION
      </CustomHeading>

      <PaperWrapper square>
        <Grid className="wrapper">
          <Grid item xs={12} style={{ paddingTop: "30px" }}>
            <EditableInputCommon
              value={phoneNumber}
              placeholder={phoneNumber || "eg: ‎+12027953213"}
              onSave={(newValue) =>
                handleUpdateAttorney({
                  phoneNumber: newValue,
                })
              }
              title="PHONE NUMBER"
            />
          </Grid>

          <Grid item xs={12} style={{ paddingTop: "30px" }}>
            <EditableInputCommon
              value={firmAssociation}
              onSave={(newValue) =>
                handleUpdateAttorney({
                  firmAssociation: newValue,
                })
              }
              title="FIRM ASSOCIATION"
            />
          </Grid>
          {/* //* INFO: Render multi section of state & license number */}
          {getValidArray(statesOfLicense).map((state, index) => (
            <Grid container item xs={12} key={index}>
              <Grid item xs={6}>
                <EditableSelectCommon
                  value={[state]}
                  options={
                    US_STATE_NAMES.reduce((obj, item) => {
                      return {
                        ...obj,
                        [item]: item,
                      };
                    }, {}) as []
                  }
                  title="STATE OF LICENSE"
                  multiple={false}
                  checkbox={false}
                  handleChange={(newValue) =>
                    handleUpdateAttorney({
                      statesOfLicense: newValue,
                    })
                  }
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={6}>
                <EditableInputCommon
                  value={licenseNumbersJSON?.[state]}
                  onSave={(newValue) =>
                    handleUpdateAttorney({
                      licenseNumbers: {
                        ...licenseNumbersJSON,
                        [state]: newValue,
                      },
                    })
                  }
                  title="LICENSE NUMBER"
                  readOnly={true}
                  hr
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </PaperWrapper>
    </Grid>
  );
};

export default PersonalInformation;
