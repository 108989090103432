import React, { useRef, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import styled, { keyframes } from 'styled-components'
import { urlRegex, convertTextToUrl } from './utils'

const msgAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
  }
  100% {
    transform: scale(1);
  }
`

const SentTextWrapper = styled(Grid)`
  margin-top: 16px; 
  text-align: right;
  .time{
    font-size:8px;
    font-weight:bold;
    color:#BCC7D3;
    margin-top: 10px ;
  }
`
const SentTextBody = styled.div`
  background-color: ${({ theme }) => theme.colors.mainLight};
  padding:10px 20px;
  font-size:12px;
  border-radius:12px;
  width:auto ;
  word-break: break-all;
`

const Image = styled.img`
  width: 17rem;
  border-radius: 16px 16px 0 16px;
`

const BodyWrapper = styled.div`
  width: fit-content;
  margin-left: auto;
  animation: 0.15s ${msgAnimation} ease-out;
`;

const SentText = ({ data, onViewImage }) => {
  const { msg, time, contentType, imageUrl } = data;

  const textRef = useRef(null)

  useEffect(() => {
    if (msg) {
      textRef.current.innerHTML = msg.replace(urlRegex, (matchedText) => convertTextToUrl(matchedText));
    }
  }, [msg])

  return (
    <SentTextWrapper item xs={12}>
      <BodyWrapper>
        {
          contentType === 'media'
            ? <Image src={imageUrl} onClick={() => onViewImage(imageUrl)} />
            : <SentTextBody ref={textRef}>{msg}</SentTextBody>
        }
        <div className="time">{time}</div>
      </BodyWrapper>
    </SentTextWrapper>
  )
}

export default SentText