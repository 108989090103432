import { useHistory, useLocation } from "react-router-dom";
import queryString from "querystring";
import { useContext, useEffect } from "react";
import { NylasProvider } from "constant/enum/nylas";
import { getNylasAccessToken } from "API/nylas";
import { updateAttorneyById } from "API/attorney";
import { INylasAccessToken } from "interfaces";
import { UserContext } from "contexts";
import { toast } from "react-toastify";
import useStore from "utils/useStore";

export function useNylas(
  callback?: () => void,
  preCallback?: () => void
): void {
  const { calendarStore } = useStore();
  const [userInfo, updateUserInfo] = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();

  async function updateNylasGoogleAccessToken(code: string): Promise<void> {
    const nylasAccessToken: INylasAccessToken = await getNylasAccessToken(code);
    if (nylasAccessToken?.access_token) {
      try {
        if (preCallback) {
          preCallback();
        }
        await updateAttorneyById({
          id: userInfo?.id,
          nylasGoogleAccessToken: nylasAccessToken?.access_token,
        });
        await calendarStore.fetchNylasGoogleAccount(
          nylasAccessToken?.access_token
        );
        history.replace(history?.location?.pathname);
        toast.success("Successfully connected Google calendar");
        updateUserInfo({
          ...userInfo,
          nylasGoogleAccessToken: nylasAccessToken?.access_token,
        });
        if (callback) {
          callback();
        }
      } catch (error) {
        toast.error("Error while link Google calendar, please contact us");
      }
    }
  }

  async function updateNylasAppleAccessToken(code: string): Promise<void> {
    const nylasAccessToken: INylasAccessToken = await getNylasAccessToken(code);
    if (nylasAccessToken?.access_token) {
      try {
        if (preCallback) {
          preCallback();
        }
        await updateAttorneyById({
          id: userInfo?.id,
          nylasICloudAccessToken: nylasAccessToken?.access_token,
        });
        await calendarStore.fetchNylasICloudAccount(
          nylasAccessToken?.access_token
        );
        history.replace(history?.location?.pathname);
        toast.success("Successfully connected iCloud calendar");
        updateUserInfo({
          ...userInfo,
          nylasICloudAccessToken: nylasAccessToken?.access_token,
        });
        if (callback) {
          callback();
        }
      } catch (error) {
        toast.error("Error while link Google calendar, please contact us");
      }
    }
  }

  async function updateNylasOutlookAccessToken(code: string): Promise<void> {
    const nylasAccessToken: INylasAccessToken = await getNylasAccessToken(code);
    if (nylasAccessToken?.access_token) {
      try {
        if (preCallback) {
          preCallback();
        }
        await updateAttorneyById({
          id: userInfo?.id,
          nylasOutlookAccessToken: nylasAccessToken?.access_token,
        });
        await calendarStore.fetchNylasOutlookAccount(
          nylasAccessToken?.access_token
        );
        history.replace(history?.location?.pathname);
        toast.success("Successfully connected Outlook calendar");
        updateUserInfo({
          ...userInfo,
          nylasOutlookAccessToken: nylasAccessToken?.access_token,
        });
        if (callback) {
          callback();
        }
      } catch (error) {
        toast.error("Error while link Outlook calendar, please contact us");
      }
    }
  }

  useEffect(() => {
    const params = queryString.parse(location.search.replace("?", ""));
    if (
      params?.code &&
      params?.state &&
      params?.state === NylasProvider.GMAIL &&
      userInfo?.id
    ) {
      updateNylasGoogleAccessToken(params?.code as string);
    }

    if (
      params?.code &&
      params?.state &&
      params?.state === NylasProvider.ICLOUD &&
      userInfo?.id
    ) {
      updateNylasAppleAccessToken(params?.code as string);
    }

    if (
      params?.code &&
      params?.state &&
      params?.state === NylasProvider.OUTLOOK &&
      userInfo?.id
    ) {
      updateNylasOutlookAccessToken(params?.code as string);
    }
  }, [location, userInfo?.id]);

  useEffect(() => {
    calendarStore.fetchNylasGoogleAccount(userInfo?.nylasGoogleAccessToken);
  }, [location, userInfo?.nylasGoogleAccessToken]);

  useEffect(() => {
    calendarStore.fetchNylasICloudAccount(userInfo?.nylasICloudAccessToken);
  }, [location, userInfo?.nylasICloudAccessToken]);

  useEffect(() => {
    calendarStore.fetchNylasOutlookAccount(userInfo?.nylasOutlookAccessToken);
  }, [location, userInfo?.nylasOutlookAccessToken]);
}
