import { ChartData } from "react-chartjs-2";
import * as chartjs from "chart.js";
import moment, { Duration, Moment } from "moment";

import { TimeRange } from "components/common/RangeSwitcher/constants";
import { IRangeObject } from "interfaces";
import { IMixData } from "interfaces/dashboard";
import { ConnectionMethod } from "constant/enum";
import { ITimeSpendSeparate } from "../interfaces";

export function getTotalTimeSpend(mixConsultations: IMixData[]): number {
  const amountEarning = mixConsultations.reduce(
    (total: number, mixConsultation: IMixData) => {
      if (mixConsultation.consultation.actualLength) {
        return (
          total +
          moment
            .duration(mixConsultation.consultation.purchasedLength, "minutes")
            .subtract(mixConsultation.consultation.actualLength, "seconds")
            .asSeconds()
        );
      }
      return total;
    },
    0
  );
  return Number(amountEarning.toFixed(2));
}

export function getTotalTimeSpendByMonth(
  mixConsultations: IMixData[],
  month: number,
  connectionMethod?: ConnectionMethod[]
): number {
  const now = new Date();
  now.setMonth(month);

  const filteredEarning = Array.isArray(connectionMethod)
    ? mixConsultations.filter((mixConsultation: IMixData) =>
        connectionMethod.includes(mixConsultation.consultationConnectionMethod)
      )
    : mixConsultations;

  const amountEarning = filteredEarning.reduce(
    (total: number, earning: IMixData) => {
      if (
        earning.consultation.actualLength &&
        moment(earning.consultation.createdAt).isSame(moment(now), "month")
      ) {
        return (
          total +
          moment
            .duration(earning.consultation.purchasedLength, "minutes")
            .subtract(earning.consultation.actualLength, "second")
            .asSeconds()
        );
      }
      return total;
    },
    0
  );

  return Number(amountEarning.toFixed(2));
}

export function getTotalTimeSpendByWeek(
  mixConsultations: IMixData[],
  weekRange: IRangeObject<Moment>,
  connectionMethod?: ConnectionMethod[]
): number {
  const filteredEarning = Array.isArray(connectionMethod)
    ? mixConsultations.filter((mixConsultation: IMixData) =>
        connectionMethod.includes(mixConsultation.consultationConnectionMethod)
      )
    : mixConsultations;
  const amountEarning = filteredEarning.reduce(
    (total: number, mixConsultation: IMixData) => {
      if (
        mixConsultation.consultation.actualLength &&
        moment(mixConsultation.consultation.createdAt).isSameOrAfter(
          weekRange.from,
          "days"
        ) &&
        moment(mixConsultation.consultation.createdAt).isSameOrBefore(
          weekRange.to,
          "days"
        )
      ) {
        return (
          total +
          moment
            .duration(mixConsultation.consultation.purchasedLength, "minutes")
            .subtract(mixConsultation.consultation.actualLength, "seconds")
            .asSeconds()
        );
      }
      return total;
    },
    0
  );
  return Number(amountEarning.toFixed(2));
}

export function getTotalTimeSpendByDay(
  mixConsultations: IMixData[],
  day: number,
  connectionMethod?: ConnectionMethod[]
): number {
  const now = new Date();
  now.setDate(day);

  const filteredEarning = Array.isArray(connectionMethod)
    ? mixConsultations.filter((mixConsultation: IMixData) =>
        connectionMethod.includes(mixConsultation.consultationConnectionMethod)
      )
    : mixConsultations;

  const amountEarning = filteredEarning.reduce(
    (total: number, mixConsultation: IMixData) => {
      if (
        mixConsultation.consultation.actualLength &&
        moment(mixConsultation.consultation.createdAt).isSame(
          moment(now),
          "days"
        )
      ) {
        return (
          total +
          moment
            .duration(mixConsultation.consultation.purchasedLength, "minutes")
            .subtract(mixConsultation.consultation.actualLength, "second")
            .asSeconds()
        );
      }
      return total;
    },
    0
  );

  return Number(amountEarning.toFixed(2));
}

export function chartDataSourceSelector(
  yearChart: ChartData<chartjs.ChartData>,
  dateChart: ChartData<chartjs.ChartData>,
  weekChart: ChartData<chartjs.ChartData>,
  selectRange: TimeRange
): ChartData<chartjs.ChartData> {
  switch (selectRange) {
    case TimeRange.MONTH:
      return yearChart;
    case TimeRange.WEEK:
      return weekChart;
    case TimeRange.DAY:
      return dateChart;
  }
}

export function parseDuration(totalTimeSpend: Duration): ITimeSpendSeparate {
  if (!totalTimeSpend || !totalTimeSpend.isValid()) {
    return {
      hours: "0",
      minutes: "0",
      seconds: "0",
    };
  }
  const hours = Math.floor(totalTimeSpend.asMinutes() / 60);
  const minutes = moment
    .utc(totalTimeSpend.asMilliseconds())
    .format("mm:ss")
    .split(":");
  return {
    hours: String(hours),
    minutes: minutes[0],
    seconds: minutes[1],
  };
}
