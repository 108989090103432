import moment from "moment";
import {
  DateTimeScheduledRequestBy,
  DateTimeScheduledRequestStatus,
} from "constant/enum";
import { IActiveRequest } from "interfaces/acceptConsultationRequest";

export function getSubmitButtonLabel(
  selectedActiveRequest: IActiveRequest
): string {
  if (
    selectedActiveRequest?.dateTimeScheduledRequestBy ===
      DateTimeScheduledRequestBy.ATTORNEY &&
    selectedActiveRequest?.dateTimeScheduledRequestStatus ===
      DateTimeScheduledRequestStatus.PENDING
  ) {
    return `Requested reschedule to ${moment(
      selectedActiveRequest?.dateTimeScheduledRequest
    ).format("lll")}`;
  }

  if (
    selectedActiveRequest?.dateTimeScheduledRequestBy ===
      DateTimeScheduledRequestBy.CLIENT &&
    selectedActiveRequest?.dateTimeScheduledRequestStatus ===
      DateTimeScheduledRequestStatus.PENDING
  ) {
    return `Accept change to ${moment(
      selectedActiveRequest?.dateTimeScheduledRequest
    ).format("lll")}`;
  }

  return "Request changes";
}

export function isDisableSubmitButton(
  selectedActiveRequest: IActiveRequest,
  isLoading
): boolean {
  if (
    selectedActiveRequest?.dateTimeScheduledRequestBy ===
      DateTimeScheduledRequestBy.ATTORNEY &&
    selectedActiveRequest?.dateTimeScheduledRequestStatus ===
      DateTimeScheduledRequestStatus.PENDING
  ) {
    return true;
  }
  if (isLoading) {
    return true;
  }
  return false;
}

export function isDisableReschedule(
  selectedActiveRequest: IActiveRequest
): boolean {
  if (
    selectedActiveRequest?.dateTimeScheduledRequestBy ===
      DateTimeScheduledRequestBy.CLIENT &&
    selectedActiveRequest?.dateTimeScheduledRequestStatus ===
      DateTimeScheduledRequestStatus.PENDING
  ) {
    return true;
  }
  return false;
}
