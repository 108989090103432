import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import styled from "styled-components";

export const Container = styled(Grid)``;

export const SubscriptionModal = styled(Modal)``;

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 26,
    borderRadius: 16,
    maxWidth: 430,
  },
}));
