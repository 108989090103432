import {
  Container,
  /*TODO: integrate later
  NoteWrapper,
  TimeStamp,
  NoteContent,
  */
} from "./messageSection.styles";

const MessageSection = () => {
  return (
    <Container>
      {/*TODO: integrate later
      <NoteWrapper>
        <TimeStamp>10/07/2021</TimeStamp>
        <NoteContent>
          Eget porttitor nulla donec id eget. Duis aenean odio tortor venenatis,
          placerat augue fringilla sed tempus. Morbi nullam tempus tellus purus
          cursus. Rutrum fusce facilisis commodo.
        </NoteContent>
      </NoteWrapper>
      <NoteWrapper>
        <TimeStamp>10/07/2021</TimeStamp>
        <NoteContent>
          Eget porttitor nulla donec id eget. Duis aenean odio tortor venenatis,
          placerat augue fringilla sed tempus. Morbi nullam tempus tellus purus
          cursus. Rutrum fusce facilisis commodo.
        </NoteContent>
      </NoteWrapper>
      <NoteWrapper>
        <TimeStamp>10/07/2021</TimeStamp>
        <NoteContent>
          Eget porttitor nulla donec id eget. Duis aenean odio tortor venenatis,
          placerat augue fringilla sed tempus. Morbi nullam tempus tellus purus
          cursus. Rutrum fusce facilisis commodo.
        </NoteContent>
      </NoteWrapper>
      */}
    </Container>
  );
};

export default MessageSection;
