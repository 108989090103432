import { Grid } from "@material-ui/core";
import styled from "styled-components";
import isEmbedded from "utils/isEmbedded";

const Title = styled.h4`
  margin-top: ${isEmbedded() ? "10px" : "40px"};
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
`;

const DescriptionText = styled.p`
  margin: auto;
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-weight: 400;
`;

const HelpText = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
`;

const Description = ({ title = "", description = "", helpText }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Title>{title}</Title>
      </Grid>
      <Grid item xs={12}>
        <DescriptionText>{description}</DescriptionText>
      </Grid>
      {helpText && (
        <Grid item xs={12}>
          <HelpText>{helpText}</HelpText>
        </Grid>
      )}
    </Grid>
  );
};

export default Description;
