import { Button } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const RequestChangeButton = styled(Button)`
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 12px;
  min-width: 248px;

  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.8px;
  color: ${styledTheme.colors.white};

  background-color: ${styledTheme.colors.secondaryDark} !important;
  box-shadow: 0px 16px 30px -10px rgba(118, 217, 142, 0.5),
    0px 20px 20px -20px #5390d2;

  &:disabled {
    background-color: ${styledTheme.colors.primaryGray} !important;
    box-shadow: none;
    color: ${styledTheme.colors.white};
    text-transform: unset;
  }
`;

export const LoaderWrapper = styled.div`
  padding-right: 4px;
`;

export const CancelButton = styled(Button)`
  margin-top: 12px;
  min-width: 248px;
  padding-top: 16px;
  padding-bottom: 16px;

  font-family: ${styledTheme.colors.red400};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${styledTheme.colors.red400};
  background-color: transparent !important;
`;