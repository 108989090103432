import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    paddingTop: 80,
    paddingBottom: 64,
    paddingLeft: 110.5,
    paddingRight: 110.5,
    borderRadius: 16,
    maxWidth: 652,
  },
}));
