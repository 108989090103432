import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Wrapper = styled.div`
  width: 90%;
  height: 90%;
  margin: auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

export const Header = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: ${styledTheme.colors.white};
  width: 319px;
  margin-bottom: 0px;
`;

export const Divider = styled.div`
  border: 1px solid ${styledTheme.colors.white};
  width: 36px;
  background-color: ${styledTheme.colors.white};
  margin: 24px 0;
`;

export const SubTitle = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${styledTheme.colors.white};
  width: 319px;
  margin-bottom: 0px;
`;

export const BackButton = styled.p`
  margin-top: 110px;
  padding-bottom: 94px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${styledTheme.colors.gray400};
  cursor: pointer;
`;
