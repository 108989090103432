import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { PastInteractionCard } from "components/common";
import StyledButton from "../../../components/common/Custom-button";
import CustomHeading from "../../../components/common/CustomHeading";
import { GStyledGrid } from '../../../components/common/MaterialUIStyledComponents'
import { styledTheme } from '../../../styles/StyledTheme'

const ITEMS_PER_PAGE = 5;

const LastInteractions = ({ cases }) => {

  const [ page, setPage ] = useState(1)

  return (
    <Grid item container xs={12} justify="center">
      <Grid item xs={12}>
        <CustomHeading>Recent Interactions</CustomHeading>
      </Grid>
      {(cases.slice(0, ITEMS_PER_PAGE * page)).map((interaction) => {
        return <PastInteractionCard key= { interaction.id } {...interaction} />;
      })}
      <GStyledGrid
        item
        xs={12}
        width= '100%'
        textAlign= 'center'
        paddingTop= '10px'
      >
        {
          cases.length > page * ITEMS_PER_PAGE &&
          <StyledButton
            style={{
              padding: "10px 30px",
              color: styledTheme.colors.secondaryDark,
              borderRadius: "5px",
              border: `2px solid ${ styledTheme.colors.secondaryDark }`,
              fontSize: "10px",
            }}
            onClick={() => setPage(page + 1)}
            variant="outlined"
          >
            SEE MORE
          </StyledButton>
        }
      </GStyledGrid>
    </Grid>
  );
};

export default LastInteractions;
