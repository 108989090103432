import { Grid } from "@material-ui/core";
import AppleConnectButton from "components/common/CalendarConnect/AppleConnectButton";
import GoogleConnectButton from "components/common/CalendarConnect/GoogleConnectButton";
import MicrosoftConnectButton from "components/common/CalendarConnect/MicrosoftConnectButton";
import CalendarConnectFAQ from "components/common/CalendarConnect/SetupCalendarModal/components/CalendarConnectFAQ";
import { useNylas } from "hooks";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useStore from "utils/useStore";
import RegistrationHeader from "../RegistrationHeader";
import {
  RegistrationWrapper,
  SubmitSection,
  Wrapper,
  WrapperGlobal,
  RegistrationHeaderText,
  SkipButton,
} from "./calendarSetup.styles";

const CalendarSetupScreen = () => {
  const { calendarStore } = useStore();
  const history = useHistory();

  useNylas(
    () => {
      handleSkip();
    },
    () => {
      toast.info("Checking calendar");
    }
  );

  async function handleSkip(): Promise<void> {
    history.push("/registration/stripe-setup");
  }

  function handleBack(): void {
    history.push("/registration/user-details");
  }

  return (
    <Wrapper>
      <WrapperGlobal />
      <RegistrationHeader
        step={2}
        title="Connect your calendar"
        description="LegalQ conveniently schedules consultations on your calendar based on your availability"
        header={
          <RegistrationHeaderText>Attorney Registration</RegistrationHeaderText>
        }
        helpText="*LegalQ will not read your email or send emails on your behalf"
        onClickBack={() => handleBack()}
      />
      <form>
        <RegistrationWrapper>
          <Grid item xs={12}>
            <GoogleConnectButton />
          </Grid>
          <Grid item xs={12}>
            <AppleConnectButton />
          </Grid>
          <Grid item xs={12}>
            <MicrosoftConnectButton />
          </Grid>
        </RegistrationWrapper>
        <CalendarConnectFAQ isOpen={calendarStore.displayConnectHelper} />
        <SubmitSection item xs={12}>
          <Grid container wrap="nowrap">
            <SkipButton onClick={handleSkip}>
              <label>Skip and add calendar later</label>
            </SkipButton>
          </Grid>
        </SubmitSection>
      </form>
    </Wrapper>
  );
};

export default observer(CalendarSetupScreen);
