import { CardElement } from "@stripe/react-stripe-js";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { observer } from "mobx-react";
import useStore from "utils/useStore";
import {
  Container,
  SubscriptionPlan,
  SubscriptionPrice,
  InputField,
  CardElementContainer,
  iframeStyles,
  ErrorMessage,
} from "./body.styles";

const Body = () => {
  const { subscriptionStore } = useStore();

  function handleCardDetailsChange(event: StripeCardElementChangeEvent): void {
    if (event?.error) {
      subscriptionStore.setSubscriptionError(event.error.message);
      return;
    }
    subscriptionStore.setSubscriptionError("");
  }

  return (
    <Container>
      <SubscriptionPlan>Subscription plan</SubscriptionPlan>
      <SubscriptionPrice>$199/month</SubscriptionPrice>

      <InputField item xl={12} lg={12} md={12} sm={12} xs={12}>
        <CardElementContainer>
          <CardElement
            options={{
              iconStyle: "solid",
              hidePostalCode: true,
              style: iframeStyles,
            }}
            onChange={handleCardDetailsChange}
          />
        </CardElementContainer>
        <ErrorMessage>{subscriptionStore?.subscriptionError}</ErrorMessage>
      </InputField>
      {/*TODO: integrate later
      <LargeSpacingTop>
        <InputField>
          <InputCommon placeholder="ABCD1234" />
          <InputLabelCommon>Promo code (Optional)</InputLabelCommon>
        </InputField>
      </LargeSpacingTop>
      */}
    </Container>
  );
};

export default observer(Body);
