import { StorageKeys } from "constant/enum/storage";
import { setSessionStorage } from "utils/common/storage";
import { updateAttorney } from "services/aws-queries-helper";
import AttorneyStore from "stores/attorneyStore";
import { AMPLIFY_ENV, REACT_APP_VERSION } from "config";
import { AmplifyEnvironmentEnum } from "constant/enum";

export async function updateOnlineStatus(
  attorneyStore: AttorneyStore,
  attorneyId: string,
  onlineStatus: boolean
): Promise<void> {
  if (attorneyId) {
    await updateAttorney(attorneyId, { isOnline: onlineStatus });
    attorneyStore.syncOnlineStatus(attorneyId);
    setSessionStorage(StorageKeys.ATTORNEY_UPDATE_STATUS, true);
  }
}

export function getVersionCode(): string {
  switch (AMPLIFY_ENV) {
    case AmplifyEnvironmentEnum.TESTING:
      return `v${REACT_APP_VERSION} [DEVELOPMENT]`;
    case AmplifyEnvironmentEnum.STAGING:
      return `v${REACT_APP_VERSION} [STAGING]`;
    default:
      return `v${REACT_APP_VERSION}`;
  }
}
