import { Grid } from "@material-ui/core";
import { UserContext } from "contexts";
import { observer } from "mobx-react";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useStore from "utils/useStore";
import RegistrationHeader from "../RegistrationHeader";
import { handleOpenRegistrationSubscription } from "./stripe";
import {
  RegistrationWrapper,
  StripeConnectButton,
  StripeConnectButtonWrapper,
  SubmitSection,
  Wrapper,
  RegistrationHeaderText,
  SkipButton,
} from "./stripeSetup.styles";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const analytics = (window as any).analytics;

const StripeSetupScreen = () => {
  const [userInfo] = useContext(UserContext);
  const { subscriptionStore } = useStore();
  const history = useHistory();

  async function handleSkip(): Promise<void> {	
    if (analytics) {	
      const email = userInfo.emailWork;	
      analytics.identify(userInfo.id, {	
        name: userInfo.firstName + " " + userInfo.lastName,	
        email,	
      });	
    }	
    history.push("/registration/confirmation");	
  }

  function onClickStripeButton(): void {
    if (!subscriptionStore?.stripeAccountExisted) {
      handleOpenRegistrationSubscription();
    }
  }

  async function syncStripeAccountStatus(): Promise<void> {
    const stripeAccountExisted =
      await subscriptionStore.syncStripeAccountStatus();
    if (stripeAccountExisted) {
      toast.success("Successfully connected Stripe");
      await handleSkip();
    }
  }

  function handleBack(): void {
    history.push("/registration/calendar-setup");
  }

  useEffect(() => {
    syncStripeAccountStatus();
  }, []);

  return (
    <Wrapper>
      <RegistrationHeader
        step={3}
        title="Get your Free Leads Now!"
        description="Link your billing account so we can send you your first 3 free leads"
        header={
          <RegistrationHeaderText>Attorney Registration</RegistrationHeaderText>
        }
        helpText={undefined}
        onClickBack={() => handleBack()}
      />
      <form>
        <RegistrationWrapper>
          <StripeConnectButtonWrapper
            item
            xs={12}
            className="pt-3 d-flex flex-row"
          >
            <StripeConnectButton onClick={onClickStripeButton}>
              {subscriptionStore?.stripeAccountExisted
                ? "STRIPE CONNECTED"
                : "CONTINUE WITH STRIPE"}
            </StripeConnectButton>
          </StripeConnectButtonWrapper>
        </RegistrationWrapper>
      </form>
    </Wrapper>
  );
};

export default observer(StripeSetupScreen);
