import styled from "styled-components";

export const GStyledH2 = styled.h2`
  ${({color}) => color && `color: ${color}`};
  ${({marginTop}) => marginTop && `margin-top: ${marginTop}`};
`

export const GStyledH3 = styled.h3`
  ${({fontSize}) => fontSize && `font-size: ${fontSize}`};
  ${({fontWeight}) => fontWeight && `font-weight: ${fontWeight}`};
  ${({color}) => color && `color: ${color}`};
  ${({paddingBottom}) => paddingBottom && `padding-bottom: ${paddingBottom}`}; 
`
export const GStyledH5 = styled.h5`
  ${({color}) => color && `color: ${color}`};
  ${({fontSize}) => fontSize && `font-size: ${fontSize}`};
`

export const GStyledDiv = styled.div`
  ${({border}) => border && `border: ${border}`};
  ${({width}) => width && `width: ${width}`};
  ${({marginBottom}) => marginBottom && `margin-bottom: ${marginBottom}`};
  ${({textAlign}) => textAlign && `text-align: ${textAlign}`};
  ${({color}) => color && `color: ${color}`};
  ${({fontSize}) => fontSize && `font-size: ${fontSize}`};
  ${({fontWeight}) => fontWeight && `font-weight: ${fontWeight}`};
  ${({display}) => display && `font-size: ${display}`};
  ${({alignItems}) => alignItems && `align-items: ${alignItems}`};
  ${({flexDirection}) => flexDirection && `flex-direction: ${flexDirection}`};
  ${({justifyContent}) => justifyContent && `justify-content: ${justifyContent}`};
  ${({position}) => position && `position: ${position}`};
  ${({padding}) => padding && `padding: ${padding}`}; 
  ${({left}) => left && `left: ${left}`}; 
  ${({backgroundColor}) => backgroundColor && `background-color: ${backgroundColor}`};
  ${({borderTopLeftRadius}) => borderTopLeftRadius && `border-top-left-radius: ${borderTopLeftRadius}`}; 
  ${({borderTopRightRadius}) => borderTopRightRadius && `border-top-right-radius: ${borderTopRightRadius}`}; 
  ${({minWidth}) => minWidth && `min-width: ${minWidth}`}; 
  ${({bottom}) => bottom && `bottom: ${bottom}`}; 
`

export const GStyledAvatar = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
`

export const GStyledP = styled.p`
  ${({color}) => color && `color: ${color}`};
  ${({fontSize}) => fontSize && `font-size: ${fontSize}`};
  ${({marginTop}) => marginTop && `margin-top: ${marginTop}`};
`

export const GStyledB = styled.b`
  ${({fontSize}) => fontSize && `font-size: ${fontSize}`};
  ${({marginRight}) => marginRight && `margin-right: ${marginRight}`};
  ${({fontWeight}) => fontWeight && `font-weight: ${fontWeight}`};
  ${({color}) => color && `color: ${color}`};
`

export const GStyledImg = styled.img`
  ${({width}) => width && `width: ${width}`};
  ${({height}) => height && `height: ${height}`};
  ${({marginLeft}) => marginLeft && `margin-left: ${marginLeft}`};
  ${({marginTop}) => marginTop && `margin-top: ${marginTop}`};
  ${({paddingBottom}) => paddingBottom && `padding-bottom: ${paddingBottom}`}; 
  ${({transition}) => transition && `transition: ${transition}`};
`

export const GStyledSpan = styled.span`
  ${({fontSize}) => fontSize && `font-size: ${fontSize}`};
  ${({color}) => color && `color: ${color}`};
`

export const GStyledLabel = styled.label`
  ${({fontSize}) => fontSize && `font-size: ${fontSize}`};
  ${({color}) => color && `color: ${color}`};
  ${({paddingTop}) => paddingTop && `  padding-top: ${paddingTop}`}; 
`
