import React from 'react'
import Grid from '@material-ui/core/Grid'
import { LogoWhite } from 'icons'
import styled from 'styled-components'

const FooterWrapper = styled(Grid)`
  font-size: 14px;
  background-color: ${ ({ theme }) => theme.colors.dark };
  height: auto;
  padding:30px 0;
  @media (max-width:576px){
    padding-left:20px;
    .MuiGrid-grid-xs-12{
      padding-top:10px;
    } 
  }
`
const FooterLinksWrapper = styled(Grid)`
  color: white;
`
const StyledA = styled.a`
  color: white;
`

const Footer = props => {

  return (
    <FooterWrapper container item xs={12} justify="center" alignItems="center">
      <Grid item xs={1} />
      <Grid container item xs={10} spacing={1}>
        <Grid item xs={12} sm={4} lg={3}>
          <img src={LogoWhite} alt='legal q' />
        </Grid>
        <FooterLinksWrapper container item xs={12} sm={4} lg={3}>
        </FooterLinksWrapper>
        <FooterLinksWrapper container item xs={12} sm={4} lg={3}>
          <Grid item xs={12}>
            <StyledA href="https://legalq.io/privacy-policy/" target="_blank">PRIVACY POLICY</StyledA>
          </Grid>
          <Grid item xs={12}>
            <StyledA href="https://legalq.io/attorney-terms-of-use/" target="_blank">TERMS OF USE</StyledA>
          </Grid>
        </FooterLinksWrapper>
      </Grid>
    </FooterWrapper>
  )
}

export default Footer
