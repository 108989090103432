import { Grid, Paper } from "@material-ui/core";
import featuredImage from "images/featured.jpg";
import styled from "styled-components";
import isEmbedded from "utils/isEmbedded";

export const FlexWrapper = styled(Grid)`
  background-image: ${isEmbedded()
    ? "linear-gradient(180deg,#1b6ab3 0%,#07345e 100%)"
    : `url(${featuredImage})`};
  width: 100%;
  background-size: initial;
  background-repeat: no-repeat;
  justify-content: center;

  [class*="MuiPaper-elevation1"] {
    box-shadow: ${isEmbedded()
      ? "none"
      : "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"} !important;
    background: ${isEmbedded()
      ? "transparent"
      : "linear-gradient(180deg, #1b6ab3 0%, #07345e 100%)"};
  }
`;

export const RegistrationPaper = styled(Paper)`
  width: 100%;
  padding: ${isEmbedded() ? "6px 0px" : "64px 0px"};
  margin-top: ${isEmbedded() ? "0px" : "100px"};
  margin-bottom: ${isEmbedded() ? "0px" : "60px"};
  text-align: center;

  @media (max-width: 500px) {
    margin-top: 30px;
  }
`;
