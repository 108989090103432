import React, { useState, useEffect } from 'react'
import {  Grid, Modal } from '@material-ui/core'
import { GStyledGrid } from 'components/common/MaterialUIStyledComponents'
import ContainedButton from 'components/common/ContainedButton'
import styled from 'styled-components'
import CheckMic from 'pages/Dashboard/Consultation/CheckMicnWebcam/TestAudio'
import CheckCam from 'pages/Dashboard/Consultation/CheckMicnWebcam/TestVideo'

const ModalDataWrapper = styled(Grid)`
  background-color: white;
  outline: none;
  border-radius: 5px;
  justify-content: center;
  min-height: 35%;
`

const CheckMicandWebCam = ({ modal, setModal }) => {

  const [audioView, setAudioView] = useState(false)
  const [videoView, setVideoView] = useState(true)
  const [modalLoad, setModalLoad] = useState(false)

  useEffect(() => {
    return (() => { 
      setAudioView(false)
      setVideoView(true)
      setModalLoad(false)
    })
  },[])

  return(
    <Grid item xs={12} md={12} container alignItems='center'>
      <Modal
        open={modal}
        style={{display:'flex',alignItems:'center',justifyContent:'center'}}
        onClose={ () => { 
          if (modalLoad){
            setAudioView(false)
            setModal(false)
          }            
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalDataWrapper item xs={12} sm={8} md={6} lg={4} style={{ textAlign:'center'}} >
          { videoView &&  <CheckCam setModalLoad={ setModalLoad }/> }
          { audioView && modalLoad && <CheckMic setModalLoad={ setModalLoad } /> }
          <GStyledGrid maxHeight='15%' marginTop='10px' marginBottom='20px' textAlign='center' alignItems='center'  display='flex' flexDirection='row'>
            <ContainedButton 
              color={ modalLoad ? 'primary' : 'default' }
              onClick={() => {
                setAudioView(true)
                setVideoView(false)
              }}
            >
              audio
            </ContainedButton>
            <ContainedButton 
              color='primary'
              onClick={() => {
                if (modalLoad){
                  setAudioView(false)
                  setVideoView(true)
                }   
              }} 
            >
              Video
            </ContainedButton>
          </GStyledGrid>
        </ModalDataWrapper> 
      </Modal>
    </Grid>
  )
}

export default CheckMicandWebCam