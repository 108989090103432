import { INylasAccessToken, INylasAccount, INylasConnection } from "interfaces";
import { REACT_APP_NYLAS_API } from "config";
import { AxiosResponse } from "axios";
import { api } from "./index";

export async function getNylasAccessToken(
  code: string
): Promise<INylasAccessToken> {
  try {
    const response = await api.post(`/proxy/nylas/oauth/token`, { code: code });
    return response.data;
  } catch (error) {
    return {} as INylasAccessToken;
  }
}

export async function getNylasAccountByAccessToken(
  accessToken: string
): Promise<INylasAccount> {
  if (!accessToken) return {} as INylasAccount;
  try {
    const response = await api.get(`${REACT_APP_NYLAS_API}/account`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    return {} as INylasAccount;
  }
}

export async function getNylasConnectionCode(
  nylasConnection: INylasConnection
): Promise<string> {
  const response: AxiosResponse = await api.post(
    `/proxy/nylas/connect/authorize`,
    nylasConnection
  );
  return response?.data?.code;
}
