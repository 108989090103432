import { useState, useContext, useEffect } from 'react'
import { useTimer } from 'react-compound-timer'
import { IconButton } from '@material-ui/core'
import { PauseWhiteGreen } from 'icons'
import PlayWhiteGreen from 'icons/PlayWhiteGreen.svg'
import { ConsultationContext } from 'contexts'
import styled, { keyframes, css } from 'styled-components'
import { styledTheme } from '../../styles/StyledTheme'
import { ConsultationStatus } from 'constant/common'
import PusherService from '../../utils/pusher'
import { PusherChannel } from '../../constant/enum'
import { ConsultationFlow } from "constant/enum";
import { observer } from 'mobx-react'
import useStore from 'utils/useStore'
import { CONSULTATION_STABLE_TIME } from 'constant'

const pusherService = new PusherService()
const blinkAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`

const TimerWrapper = styled.div`
  display:flex;
  align-items:center;
  margin-bottom: 5px;
  h5 {
    margin-bottom: 0;
    ${({ warning }) => warning === 'true' && css`
        animation: 1s ${blinkAnimation} linear 0s infinite;
        color: #ffb8b8 !important;
      `
  }
  }
  .MuiIconButton-root {
    padding: 0;
  }
`

const MyTimer = ({ timeLimit = 15, initialTime = 0, consultationId }) => {
  const [play, setPlay] = useState(true)
  const [warning, setWarning] = useState(false)
  const {
    setToast,
    handleComplete,
    updateActiveConsultation,
    isClientOnline,
    activeCase
  } = useContext(ConsultationContext);
  const { attorneyStore } = useStore()
  const [timerLimit, setTimerLimit] = useState(timeLimit || 15)

  useEffect(() => {
    setTime((timerLimit * 60000) - initialTime * 1000)
  }, [initialTime, timerLimit])

  useEffect(()=> {
    const attorneyJoinConsultationTimer = setTimeout(() => {
      attorneyStore.setAttorneyJoinConsultation(true);
    }, CONSULTATION_STABLE_TIME)

    return () => {
      clearTimeout(attorneyJoinConsultationTimer)
    }
  }, [])

  useEffect(()=> {
    const channel = pusherService
    .getPusher()
    .subscribe(PusherChannel.CLIENT_ONBOARDING_CONSULTATION_RECHARGE);

    if(consultationId){
      pusherService.bindWithChunking(channel, consultationId, (consultation)=> {
          setTimerLimit(consultation?.purchasedLength);
          setWarning(false);
        }
      )
    }

    return () => {
      channel.unsubscribe()
    }
  }, [consultationId])

  function onStateChange(state) {
    // 0 pause, 1 play
    if (state === 0) {
      setToast('Consultation timer paused')
      updateActiveConsultation(ConsultationStatus.Paused)
    } else {
      setToast('Consultation timer resumed')
      updateActiveConsultation(ConsultationStatus.InProgress)
    }
  }

  useEffect(() => {
    if (initialTime > (timerLimit * 60)) {
      handleComplete(true)
    } else if (initialTime / 60 >= timerLimit - 3) {
      setWarning(true)
    }
    updateActiveConsultation(ConsultationStatus.InProgress)
  }, [])

  const {
    value, controls: { setTime, pause, resume }
  } = useTimer({
    onResume: () => onStateChange(1),
    onPause: () => onStateChange(0),
    formatValue: (val) => (val + '').padStart(2, '0'),
    initialTime: (timerLimit * 60000) - initialTime * 1000,
    direction: 'backward',
    checkpoints:
      [
        {
          time: 3 * 60000,
          callback: () => {
            setWarning(true)
            setToast('Consultation will end in 3 minutes, unless paused.')
          }
        },
        {
          time: 0,
          callback: () => {
            if(activeCase?.consultation?.flow !== ConsultationFlow.ONBOARDING){
              handleComplete(true);
            }
          }  
        }
      ]
  })

  useEffect(() => {
    if (isClientOnline) {
      onStateChange(1)
      setPlay(true)
      resume()
    } else {
      onStateChange(0)
      setPlay(false)
      pause()
    }
  }, [isClientOnline])

  return (
    <TimerWrapper warning={(warning && play) + ''}>
      <h5 style={{ color: styledTheme.colors.white }}>
        {value.m < 10 ? '0' : ''}{value.m}:{value.s < 10 ? '0' : ''}{value.s}
      </h5>
      <IconButton onClick={() => {
        if(isClientOnline){
          play ? pause() : resume()
          setPlay(!play)
        }
        if(!isClientOnline){
          setToast('Client has lost connection to LegalQ service, please try later.');
        }
      }}
      >
        <img
          style={{ width: '22px', height: '22px', marginLeft: '5px', marginTop: '3px' }}
          src={play ? PauseWhiteGreen : PlayWhiteGreen} alt='pause'
        />
      </IconButton>
    </TimerWrapper>
  )
}

export default observer(MyTimer)
