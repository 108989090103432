import AWSConfig from "aws-exports-helper";
const envParts = AWSConfig.aws_user_files_s3_bucket.split("-");

export const AMPLIFY_ENV = envParts[envParts.length - 1];
export const REACT_APP_SENTRY_DSN = process.env?.REACT_APP_SENTRY_DSN;
export const REACT_APP_HUBSPOT_ID = process.env?.REACT_APP_HUBSPOT_ID;
export const REACT_APP_SMARTLOOK_ID = process.env?.REACT_APP_SMARTLOOK_ID;
export const REACT_APP_VERSION = "2.10.0";
export const REACT_APP_NYLAS_API = "https://api.nylas.com";
export const REACT_APP_NYLAS_CLIENT_ID = process.env?.REACT_APP_NYLAS_CLIENT_ID;
export const REACT_APP_FRONTEND_URL = process.env?.REACT_APP_FRONTEND_URL;
export const REACT_APP_STRIPE_REGISTRATION_PAYMENT_URL = process.env?.REACT_APP_STRIPE_REGISTRATION_PAYMENT_URL;
export const REACT_APP_STRIPE_DASHBOARD_PAYMENT_URL = process.env?.REACT_APP_STRIPE_DASHBOARD_PAYMENT_URL;