import { Auth } from "aws-amplify";
import { errorHandler } from "utils/errorHandler";

const signIn = async ({ username, password }) => {
  await signOut()
  const response = await Auth.signIn({
    username: username.toLowerCase(),
    password
  });
  return response;
};

const signUp = async (email, password, firstName, lastName) => {
  await signOut()
  return await Auth.signUp({
    username: email.toLowerCase(),
    password,
    attributes: { 
      email: email.toLowerCase(), 
    },
    clientMetadata: {
      firstName: firstName,
      lastName: lastName ,
      type: 'onboardingAttorney'
    }
  });
};

const confirmSignUp = async (email, code) => {
  await Auth.confirmSignUp(
    email.toLowerCase(),
    code,
    {
      forceAliasCreation: true,
      clientMetadata: {
        type: "attorney"
      }
    }
  );
  return
};

const signOut = async () => {
  const response = await Auth.signOut();
  localStorage.removeItem("token");
  return response;
};

const checkAuth = async (history = null) => {
  try {
    const response = await Auth.currentAuthenticatedUser();
    const { attributes, signInUserSession, username } = response;
    return {
      id: username,
      attributes,
      jwtToken: signInUserSession.accessToken.jwtToken,
      signInUserSession,
    };
  } catch (error) {
    if (history) history.push("/");
    else throw new Error(error);
  }
};

const resendConfirmationCode = async (email, firstName, lastName) => {
  try {
    await Auth.resendSignUp(email.toLowerCase(), {
      ...(firstName ? { firstName: firstName } : {}),
      ...(lastName ? { lastName: lastName } : {}),
    });
  } catch (err) {
    errorHandler(err);
  }
};

const changePassword = async (oldPass, newPass) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPass, newPass);
    return {success: 'Password changed successfully'};
  } catch(err) {
    return {error: err && err.code ? "Invalid current password!": "Something went wrong, please try later!"};
  }
}

const forgotPassword = async (email) => {
  try {
    // eslint-disable-next-line
    const result = await Auth.forgotPassword(email.toLowerCase());
    return {success: 'Verification Code sent'};
  } catch(err) {
    if(err && err.code === 'UserNotFoundException')
      return {error: "Email does not exist"};
    else if(err && err.code === 'LimitExceededException')
      return {error: "Too many tries, please try after some time"};
    return {error: "Something went wrong, please try later!"};
  }
}

const forgotPasswordSubmit = async (payload) => {
  try {
    const {email, code, password} = payload;
    // eslint-disable-next-line
    const result = await Auth.forgotPasswordSubmit(email.toLowerCase(), code, password);
    return {success: 'Password changed successfully'};
  } catch(err) {
    if(err && err.code === 'CodeMismatchException')
      return {error: "Invalid verification code"};
    return {error: "Something went wrong, please try later!"};
  }
}

export {
  signIn,
  signOut,
  checkAuth,
  signUp,
  confirmSignUp,
  resendConfirmationCode,
  changePassword,
  forgotPassword,
  forgotPasswordSubmit
};
