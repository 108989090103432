import { useContext, useEffect, useMemo } from "react";
import { Grid } from "@material-ui/core";
import orderBy from 'lodash/orderBy'
import { ActiveRequestCard } from "components/common";
import CustomHeading from "../../../components/common/CustomHeading";
import { UserContext } from "../../../contexts";
import { GStyledDiv } from '../../../components/common/StyledComponents'
import { GStyledGrid } from '../../../components/common/MaterialUIStyledComponents'
import useStore from "utils/useStore";
import { observer } from "mobx-react";

const ActiveRequests = ({
  setRoom,
  setConversationClient,
}) => {
  const { consultationStore }  = useStore()
  const [userInfo] = useContext(UserContext);
  const { activeRequests } = consultationStore
 
  useEffect(() => {
    if (userInfo?.id) {
      consultationStore?.fetchActiveRequestsByAttorneyId(userInfo?.id)
    }
  }, [userInfo])

  const sortedActiveRequests = useMemo(() => orderBy(activeRequests, ['time'], ['asc']), [activeRequests])

  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <CustomHeading>ACTIVE REQUESTS</CustomHeading>
      </Grid>
      <GStyledGrid item xs={12} paddingBottom='30px'>
        {Array.isArray(sortedActiveRequests) && sortedActiveRequests.map((request) => (
          <ActiveRequestCard
            key={request.id}
            details={request}
            setRoom={setRoom}
            setConversationClient={setConversationClient}
          />
        ))}

        {
          (!sortedActiveRequests || sortedActiveRequests.length === 0) &&
          <GStyledDiv textAlign='center' color='#c7c7c7'>
            <br />
            Its all clear, no active requests
          </GStyledDiv>
        }
      </GStyledGrid>
    </Grid>
  );
};

export default observer(ActiveRequests);
