import { Grid, Paper } from "@material-ui/core";
import styled from "styled-components";
import { breakpoints } from "styles/layout";
import { styledTheme } from "styles/StyledTheme";

export const PaperStyler = styled(Paper)`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`;

export const CardContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  padding-left: 40px;
  padding-right: 50px;
  padding-top: 41px;
`;

export const ConsultationMethod = styled(Grid)`
  padding-right: 32px;
  border-right: 1px solid ${styledTheme.colors.borderColor};
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const GraphWrapper = styled(Grid)`
  padding-left: 32px;
`;

export const LabelWrapper = styled(Grid)`
  display: flex;
  text-align: center;
  padding-bottom: 24px;

  ${breakpoints("flex-direction", [
    {
      xxl: "row",
    },
    {
      xl: "column",
    },
    {
      lg: "row",
    },
    {
      md: "row",
    },
    {
      sm: "row",
    },
    {
      xs: "row",
    },
  ])}

  ${breakpoints("align-items", [
    {
      xxl: "center",
    },
    {
      xl: "flex-start",
    },
    {
      lg: "center",
    },
    {
      md: "center",
    },
    {
      sm: "center",
    },
    {
      xs: "center",
    },
  ])}
`;

export const OverallSection = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

export const OverallGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const OverallLabel = styled.div`
  font-size: 12px;
  color: ${styledTheme.colors.primaryBlack};
  font-weight: bold;
  text-align: left;
`;

export const OverallValue = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  text-align: center;

  color: ${styledTheme.colors.secondaryDark};

  span:nth-child(2n + 1) {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
  }

  span:nth-child(2n) {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
  }
`;

export const LargeSpacing = styled.div`
  margin-left: 40px;
`;

export const TimeRangeSection = styled(Grid)`
  display: flex;
  justify-content: flex-end;

  ${breakpoints("padding-top", [
    {
      xxl: "0px",
    },
    {
      xl: "12px",
    },
    {
      lg: "0px",
    },
    {
      md: "0px",
    },
    {
      sm: "0px",
    },
    {
      xs: "0px",
    },
  ])}

  ${breakpoints("align-self", [
    {
      xxl: "center",
    },
    {
      xl: "flex-start",
    },
    {
      lg: "center",
    },
    {
      md: "center",
    },
    {
      sm: "center",
    },
    {
      xs: "center",
    },
  ])}
`;
