import { observer } from "mobx-react";
import GoogleConnectButton from "components/common/CalendarConnect/GoogleConnectButton";
import MicrosoftConnectButton from "components/common/CalendarConnect/MicrosoftConnectButton";
import AppleConnectButton from "components/common/CalendarConnect/AppleConnectButton";
import {
  Container,
  MainText,
  SubText,
  ConnectWrapper,
} from "./calendarContent.styles";

const CalendarContent = () => {
  return (
    <Container>
      <MainText>Setup calendar</MainText>
      <SubText>Please select at least one account for receiving updates</SubText>
      <ConnectWrapper>
        <GoogleConnectButton />
        <AppleConnectButton />
        <MicrosoftConnectButton />
      </ConnectWrapper>
    </Container>
  );
};

export default observer(CalendarContent);
