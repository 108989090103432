import React from 'react'
import styled from 'styled-components'
import { Logo } from 'icons'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import isEmbedded from "utils/isEmbedded";

const HeaderImage = styled.img`
  width: ${isEmbedded() ? '0px' : '200px'};
  height: ${isEmbedded() ? '0px' : '50px'};
`
const HeaderWrapper =  styled(Grid)`
width: ${isEmbedded() ? '0px' : ''};
height: ${isEmbedded() ? '0px' : ''};
  @media (max-width:576px){
    .custom-container{
      width:50%;
    }
  }
`
const Header = props => {
  return (
    <HeaderWrapper container>
      <Grid item xs={12}  className="py-3">
        <div className="custom-container">
          <Link to="/">
            <HeaderImage
              src={Logo}
            />
          </Link>
        </div>
      </Grid>
    </HeaderWrapper>
  )
}

export default Header
