import { Button, Grid } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ReminderSection = styled.div`
  padding-bottom: 80px;
`;

export const HeadingIcon = styled.div`
  img {
    width: 120px;
    height: 120px;
  }

  display: flex;
  justify-content: center;
  padding-bottom: 40px;
`;

export const HeadingTitle = styled.h1`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: ${styledTheme.colors.primary};

  margin-bottom: 0px;
  padding-bottom: 24px;
`;

export const Guidelines = styled.ol`
  padding-left: 18px;
  margin-bottom: 0px;

  li {
    font-family: ${styledTheme.fonts.Inter};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${styledTheme.colors.primaryBlack};
  }
`;

export const ActionSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SignInButton = styled(Button)`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  letter-spacing: 0.8px;

  background-color: ${styledTheme.colors.secondaryDark};
  box-shadow: ${styledTheme.shadow.blueBelow};

  border-radius: 12px;
  color: ${styledTheme.colors.white};
  min-width: 240px;
  margin-bottom: 16px;

  &:hover {
    background-color: ${styledTheme.colors.secondaryDark};
  }
`;

export const OutlineButton = styled(Button)`
  color: rgba(0, 0, 0, 0.48);
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  padding-top: 16px;
  padding-bottom: 16px;
  letter-spacing: 0.8px;
  border-radius: 12px;

  background-color: ${styledTheme.colors.white} !important;
  border: 1px solid ${styledTheme.colors.primaryGray};
  min-width: 240px;

  margin-bottom: 32px;
`;

export const LearnMore = styled.a`
  cursor: pointer;
  text-align: center;

  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-decoration: underline !important;
  color: ${styledTheme.colors.secondaryDark};
`;
