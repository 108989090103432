import { NylasStateSync } from "constant/enum";
import { UserContext } from "contexts";
import { observer } from "mobx-react";
import { useContext } from "react";
import { styledTheme } from "styles/StyledTheme";
import useStore from "utils/useStore";
import {
  Container,
  LinkedLabel,
  BadgeSection,
  BadgeContainer,
  BadgeIcon,
} from "./calendarsLinked.styles";

interface ICalendarsLinkedProps {
  onClick: () => void;
}

const CalendarsLinked = (props: ICalendarsLinkedProps) => {
  const { onClick } = props;
  const { calendarStore } = useStore();
  const { nylasGoogleAccount, nylasICloudAccount, nylasOutlookAccount } =
    calendarStore;

  return (
    <Container onClick={onClick}>
      <LinkedLabel>Calendars linked</LinkedLabel>
      <BadgeSection>
        {(nylasGoogleAccount?.sync_state === NylasStateSync.RUNNING ||
          nylasGoogleAccount?.sync_state === NylasStateSync.PARTIAL) && (
          <BadgeContainer
            id="google-calendar-linked"
            color={styledTheme.colors.red600}
          >
            <BadgeIcon iconName="gg_google.svg" />
          </BadgeContainer>
        )}
        {(nylasICloudAccount?.sync_state === NylasStateSync.RUNNING ||
          nylasICloudAccount?.sync_state === NylasStateSync.PARTIAL) && (
          <BadgeContainer color={styledTheme.colors.black}>
            <BadgeIcon iconName="ant-design_apple-filled.svg" />
          </BadgeContainer>
        )}
        {(nylasOutlookAccount?.sync_state === NylasStateSync.RUNNING ||
          nylasOutlookAccount?.sync_state === NylasStateSync.PARTIAL) && (
          <BadgeContainer color={styledTheme.colors.white}>
            <BadgeIcon iconName="logos_microsoft-windows.svg" />
          </BadgeContainer>
        )}
      </BadgeSection>
    </Container>
  );
};

export default observer(CalendarsLinked);
