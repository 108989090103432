import React, { useState, useEffect } from "react";
import { Grid, Paper } from "@material-ui/core";
import styled from "styled-components";
import CustomHeading from "../../../components/common/CustomHeading";
import Rating from "@material-ui/lab/Rating";
import { getGeneralRating } from "../../../services/dashboardService";
import { styledTheme } from '../../../styles/StyledTheme'

const MyRatings = styled.span`
  font-size: 40px;
`;

const PaperStyler = styled(Paper)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 20px;
  color: ${ ({ theme }) => theme.colors.secondary };
  border-radius: 18px;
  .text {
    text-align: center;
    padding-top: 25px;
    h3 {
      font-size: 25px;
      padding-bottom: 0px;
    }
    p {
      font-size: 10px;
    }
  }
`;

function getRatingForType(ratingType, cases) {
  let total = 0;
  cases.forEach((c) => {
    if (c.ratings) {
      total += c.ratings[ratingType];
    }
  });

  if(total === 0) return 0;

  return (total / cases.length).toFixed(1);
}

const GlobalRatings = ({ cases }) => {
  const [generalRating, setGeneralRating] = useState(0);
  const [overallRating, setOverallRating] = useState(0);
  const [knowledgeRating, setKnowledgeRating] = useState(0);
  const [friendlinessRating, setFriendlinessRating] = useState(0);

  useEffect(() => {
    setGeneralRating(getGeneralRating(cases));
    setOverallRating(getRatingForType("overallScore", cases));
    setKnowledgeRating(getRatingForType("knowledgeScore", cases));
    setFriendlinessRating(getRatingForType("friendlinessScore", cases));
  }, [cases]);

  return (
    <Grid item xs={12} container>
      <Grid item xs={12} style={{ paddingBottom: "10px" }}>
        <CustomHeading>AVERAGE RATING</CustomHeading>
      </Grid>
      <Grid item xs={12}>
        <PaperStyler>
          <div>
            <MyRatings>{generalRating}</MyRatings>
          </div>
          <Rating
            style={{ fontSize: "28px", color: styledTheme.colors.secondary, paddingTop: "10px" }}
            name="read-only"
            value={ parseFloat(generalRating) }
            precision={0.1}
            readOnly
          ></Rating>
          <Grid item className="text" xs={12} container>
            <Grid item xs={4}>
              <h3>{overallRating}</h3>
              <p style={{ color: styledTheme.colors.infoDark, fontWeight: "bold" }}>OVERALL</p>
            </Grid>
            <Grid xs={4} item>
              <h3>{knowledgeRating}</h3>
              <p style={{ color: styledTheme.colors.infoDark, fontWeight: "bold" }}>KNOWLEDGE</p>
            </Grid>
            <Grid xs={4} item>
              <h3>{friendlinessRating}</h3>
              <p style={{ color: styledTheme.colors.infoDark, fontWeight: "bold" }}>
                COMMUNICATION
              </p>
            </Grid>
          </Grid>
        </PaperStyler>
      </Grid>
    </Grid>
  );
};

export default GlobalRatings;
