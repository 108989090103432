import { Grid } from '@material-ui/core'
import { API, graphqlOperation } from 'aws-amplify'
import { ConsultationContext, UserContext } from 'contexts'
import { onUpdateConsultation } from 'gql/subscriptions'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import NoticeMsg from '../NoticeMsg'
import ReceivedText from '../ReceivedText'
import SentText from '../SentText'
import DropAreaComponent from './DropArea'
import {
  ChatFooter, LoadPreviousButton, LoadPreviousButtonContainer, MessageWrapper, StyledFab, StyledFileIcon,
  StyledGrid, StyledSendIcon, StyledTextareaAutosize
} from './styled.components'
import lodash from 'lodash'
import PendingMediaMessage from '../PendingMediaMessage'
import { toast } from 'react-toastify'

const ChatBody = ({ showPrompts = true, conversation }) => {
  const [userInfo] = useContext(UserContext); //eslint-disable-line
  const {
    activeConsultation,
    onConsultationComplete,
    setLastSeen,
    lastSeen,
    setActualLength,
    setPurchasedLength
  } = useContext(ConsultationContext); //eslint-disable-line
  const messageRef = useRef(null)

  const [msgs, setMsgs] = useState([])
  const [pendingMediaMessages, setPendingMediaMessages] = useState([])
  const [msgInput, setMsgInput] = useState('')
  const [isOpenLightbox, setIsOpenLightbox] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [images, setImages] = useState([])
  const [isShowUploadFile, setIsShowUploadFile] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [isLoadingPreviousMessages, setIsLoadingPreviousMessages] = useState(false)

  const onDrop = useCallback(acceptedFiles => {
    setSelectedFiles(acceptedFiles)
    setIsShowUploadFile(true)
  }, [])
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, image/jpg',
    noClick: true,
    noKeyboard: true,
    maxFiles: 20
  })

  async function mapMessages(oldMessages) {
    const messages = await Promise.all((oldMessages.items || [])
      .map(async message => {
        const data = {
          msg: message.body,
          time: moment(message.dateCreated.getTime()).format('hh:mm'),
          type: message.author === userInfo.id ? 'sent' : 'received',
          contentType: message?.type
        }

        if (message?.type === 'media') {
          const mediaLink = await message?.media?.getContentTemporaryUrl()
          data.imageUrl = mediaLink
        }

        return data
      })
    )
    return messages;
  }

  useEffect(() => {
    (async () => {
      if (conversation) {
        conversation.on('messageAdded', (m) => onMessageAdded(m))

        const oldMessages = await conversation.getMessages()
        messageRef.current = oldMessages
        const messages = await mapMessages(oldMessages)

        setMsgs([
          ...messages
        ])
      }
    })()
  }, [conversation])

  useEffect(() => {
    if (!isLoadingPreviousMessages) {
      const wrapper = document.querySelector('#message-wrapper')
      wrapper.scrollTo(0, wrapper.scrollHeight)
    }
  }, [msgs])

  useEffect(() => {
    const subscription = API.graphql(graphqlOperation(onUpdateConsultation))
      .subscribe({
        next: async (consultation) => {
          const updatedConsultation =
            consultation.value.data.onUpdateConsultation
          const { actualLength, purchasedLength } = updatedConsultation
          setActualLength(actualLength)
          setPurchasedLength(purchasedLength)
          if (
            updatedConsultation &&
            updatedConsultation.id === activeConsultation.id
          ) {
            if (updatedConsultation.status === 'Completed') {
              onConsultationComplete(updatedConsultation.actualLength)
            } else {
              setLastSeen(
                lastSeen !== updatedConsultation.lastOnlineClient
                  ? updatedConsultation.lastOnlineClient
                  : null
              )
            }
          }
        }
      }
      )

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (Array.isArray(msgs) && msgs) {
      const images = msgs
        .filter(mess => mess.contentType === 'media' && mess.imageUrl)
        .map(mess => mess.imageUrl)

      if (images) {
        setImages(images)
      }
    }
  }, [msgs])

  const onMessageAdded = async (newMessage) => {
    let mediaLink
    if (newMessage?.type === 'media') {
      mediaLink = await newMessage?.media?.getContentTemporaryUrl()
    }

    setMsgs(prevMsgs => {
      return [
        ...prevMsgs,
        {
          msg: newMessage.body,
          time: moment(newMessage.dateCreated.getTime()).format('hh:mm'),
          type: newMessage.author === userInfo.id ? 'sent' : 'received',
          imageUrl: mediaLink,
          contentType: newMessage.type
        }
      ]
    })
    setPendingMediaMessages([])
  }

  useEffect(() => {
    pendingMediaMessages.map(file => URL.revokeObjectURL(file))
  }, [selectedFiles])

  const handleSendMessage = (e) => {
    if (conversation) {
      if (msgInput !== '') {
        conversation.sendMessage(msgInput)
      }
      if (selectedFiles.length > 0) {
        const previews = selectedFiles.map(file => URL.createObjectURL(file))
        setPendingMediaMessages(previews)
        selectedFiles.forEach((file) => {
          const formData = new FormData()
          formData.append('file', file)
          conversation.sendMessage(formData)
        })
      }
    }
    setMsgInput('')
    setSelectedFiles([])
    setIsShowUploadFile(false)
  }

  function handleViewImage(imageUrl) {
    const foundIndex = images.findIndex(item => item === imageUrl)
    if (foundIndex >= 0) {
      setPhotoIndex(foundIndex)
      setIsOpenLightbox(true)
    }
  }

  function showUploadFileContainer() {
    setIsShowUploadFile(!isShowUploadFile);
    setSelectedFiles([]);
  }

  function handleRemoveSelectedFile(fileName) {
    const newSelectedFiles = selectedFiles.filter(item => item.name !== fileName)
    setSelectedFiles(newSelectedFiles)
  }

  async function handleLoadPreviousMessages() {
    setIsLoadingPreviousMessages(true)
    try {
      if (messageRef.current?.hasPrevPage) {
        const prevMessages = await messageRef.current?.prevPage()
        messageRef.current = prevMessages
        const messages = await mapMessages(prevMessages)
        setMsgs([...messages, ...msgs])
      } else {
        toast.warn('No more messages to load')
      }
    } catch (error) {
      toast.warn('No more messages to load')
    } finally {
      setIsLoadingPreviousMessages(false)
    }
  }

  return (
    <>
      <Grid container item direction='column' xs={12} style={{ flexGrow: 1 }}>
        <MessageWrapper id='message-wrapper' {...getRootProps()}>
          <div style={{ padding: 10 }}>
            <LoadPreviousButtonContainer>
              <button onClick={handleLoadPreviousMessages}>{isLoadingPreviousMessages ? 'Loading...' : 'Load previous messages'}</button>
            </LoadPreviousButtonContainer>
            {msgs.map((msg, i) => {
              if (msg.type === 'sent') {
                return <SentText key={i} data={msg} onViewImage={handleViewImage} />
              } else if (msg.type === 'received') {
                return <ReceivedText key={i} data={msg} onViewImage={handleViewImage} />
              } else if (msg.type === 'notice') {
                return <NoticeMsg key={i} text={msg.msg} />
              } else {
                return <></>
              }
            })}
            {pendingMediaMessages.map((imageUrl, i) => <PendingMediaMessage key={imageUrl} mediaUrl={imageUrl} />)}
          </div>
          <input {...getInputProps()} />
          {
            (isShowUploadFile || isDragActive)
              ? <DropAreaComponent openFilePicker={open} files={selectedFiles} selectedFiles={selectedFiles} onRemove={handleRemoveSelectedFile} />
              : null
          }
        </MessageWrapper>
      </Grid>

      <ChatFooter item xs={12}>
        <StyledGrid container alignItems='center' onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}>
          <StyledFileIcon onClick={showUploadFileContainer} />
          <StyledTextareaAutosize
            onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
            rowsMax={4}
            value={msgInput}
            onChange={(e) => setMsgInput(e.target.value)}
            variant='outlined'
            placeholder="Type your message"
          />
          <StyledFab onClick={handleSendMessage}>
            <StyledSendIcon />
          </StyledFab>
        </StyledGrid>
      </ChatFooter>

      {isOpenLightbox && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpenLightbox(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          reactModalStyle={{
            overlay: {
              zIndex: 9999
            }
          }}
        />
      )}
    </>
  )
}

export default ChatBody
