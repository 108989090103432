import {
  createAttorneyTimeFrame,
  deleteAttorneyTimeFrameById,
  updateOfflineAttorneyTimeFrame,
} from "API/attorneyTimeFrame";
import { IAttorneyTimeFrame } from "interfaces";
import CalendarStore from "stores/calendarStore";
import { toast } from "react-toastify";
import { errorHandler } from "utils/errorHandler";
import moment from "moment";
import { AttorneyTimeFrameCategory } from "constant/enum";
import { getTimeFrameFilter } from ".";
import { IFilter } from "types";

export async function addTimeFrameHandler(
  calendarStore: CalendarStore,
  attorneyId: string,
  dayIndex: number,
  from: moment.Moment,
  to: moment.Moment,
  setEditMode: (editMode: boolean) => void,
  category: AttorneyTimeFrameCategory
): Promise<void> {
  if (!attorneyId) {
    toast.error("Invalid request, please try again.");
    return;
  }
  try {
    const attorneyTimeFrame: IAttorneyTimeFrame = {
      attorneyId: attorneyId,
      dayIndex: dayIndex,
      from: from.toDate(),
      to: to.toDate(),
      category: category,
    };
    await createAttorneyTimeFrame(attorneyTimeFrame);
    const filter: IFilter<IAttorneyTimeFrame> = getTimeFrameFilter(attorneyId);
    calendarStore.getAttorneyTimeFrames(filter);
    setEditMode(false);
  } catch (error) {
    toast.error(
      error?.message ?? "Error while update time, please contact us."
    );
    errorHandler(error);
  }
}

export async function removeTimeFrameHandler(
  calendarStore: CalendarStore,
  attorneyTimeFrameId: string,
  attorneyId: string
): Promise<void> {
  if (!attorneyId) {
    toast.error("Invalid request, please try again.");
    return;
  }
  try {
    await deleteAttorneyTimeFrameById(attorneyTimeFrameId);
    const filter: IFilter<IAttorneyTimeFrame> = getTimeFrameFilter(attorneyId);
    calendarStore.getAttorneyTimeFrames(filter);
  } catch (error) {
    toast.error(
      error?.message ?? "Error while delete time, please contact us."
    );
    errorHandler(error);
  }
}

export async function updateOfflineTimeFrame(
  calendarStore: CalendarStore,
  attorneyId: string,
  dayIndex: number,
  from: moment.Moment,
  to: moment.Moment,
  isOffline: boolean
): Promise<void> {
  if (!attorneyId) {
    toast.error("Invalid request, please try again.");
    return;
  }
  try {
    const attorneyTimeFrame: IAttorneyTimeFrame = {
      attorneyId: attorneyId,
      dayIndex: dayIndex,
      from: from.toDate(),
      to: to.toDate(),
      category: AttorneyTimeFrameCategory.OFFLINE,
      isOffline: isOffline,
    };
    await updateOfflineAttorneyTimeFrame(attorneyTimeFrame);
    const filter: IFilter<IAttorneyTimeFrame> = getTimeFrameFilter(attorneyId);
    calendarStore.getAttorneyOfflineTimeFrames(filter);
  } catch (error) {
    toast.error(error?.message ?? "Something wrong, please try again.");
    errorHandler(error);
  }
}
