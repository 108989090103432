import { ButtonProps } from "@material-ui/core";
import { ButtonText, CalendarSocialButton, Icon } from "./socialButton.styles";

interface ISocialButtonProps {
  title: string;
  backgroundColor?: string;
  icon?: string;
  btnTextStyle?: unknown;
}

const SocialButton = (props: ISocialButtonProps & ButtonProps) => {
  const { title, backgroundColor, icon, btnTextStyle, ...rest } = props;

  return (
    <CalendarSocialButton
      variant="contained"
      backgroundColor={backgroundColor}
      startIcon={<Icon src={icon} />}
      {...rest}
    >
      <ButtonText style={btnTextStyle}>{title}</ButtonText>
    </CalendarSocialButton>
  );
};

export default SocialButton;
