import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { breakpoints } from "styles/layout";
import { styledTheme } from "styles/StyledTheme";
import CommonButton from "components/common/ButtonGroup/CommonButton";

export const Container = styled(Grid)`
  display: flex;
  flex-direction: row;
  background-color: ${styledTheme.colors.white};

  border-radius: 16px;
  padding-top: 30.5px;
  padding-bottom: 30.5px;
  padding-left: 60px;
  padding-right: 60px;

  ${breakpoints("flex-direction", [
    {
      xxl: "row",
    },
    {
      xl: "row",
    },
    {
      lg: "column",
    },
    {
      md: "column",
    },
    {
      sm: "column",
    },
    {
      xs: "column",
    },
  ])}
`;

export const SubscriptionStatus = styled.div<{ isActive: boolean }>`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) =>
    props?.isActive ? styledTheme.colors.secondary : styledTheme.colors.red500};
`;

export const ClaimFreeLeadsText = styled.div`
  font-size: 10px;
  color: gray;
`;

export const AutoSchedulePackageWrapper = styled(Grid)`
  border-right: 1px solid ${styledTheme.colors.info};

  ${breakpoints("border-color", [
    {
      xxl: styledTheme.colors.info,
    },
    {
      xl: styledTheme.colors.white,
    },
    {
      lg: styledTheme.colors.white,
    },
    {
      md: styledTheme.colors.white,
    },
    {
      sm: styledTheme.colors.white,
    },
    {
      xs: styledTheme.colors.white,
    },
  ])}

  ${breakpoints("padding-right", [
    {
      xxl: "48px",
    },
    {
      xl: "48px",
    },
    {
      lg: "48px",
    },
    {
      md: "0px",
    },
    {
      sm: "0px",
    },
    {
      xs: "0px",
    },
  ])}
`;

export const SubscriptionTextActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubscriptionButtonsWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const SubscriptionWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoints("justify-content", [
    {
      xxl: "center",
    },
    {
      xl: "center",
    },
    {
      lg: "center",
    },
    {
      md: "center",
    },
    {
      sm: "center",
    },
    {
      xs: "center",
    },
  ])}

  ${breakpoints("flex-direction", [
    {
      xxl: "row",
    },
    {
      xl: "column",
    },
    {
      lg: "column",
    },
    {
      md: "column",
    },
    {
      sm: "column",
    },
    {
      xs: "column",
    },
  ])}

  ${breakpoints("align-items", [
    {
      xxl: "center",
    },
    {
      xl: "center",
    },
    {
      lg: "flex-start",
    },
    {
      md: "flex-start",
    },
    {
      sm: "flex-start",
    },
    {
      xs: "flex-start",
    },
  ])}
`;

export const IntegrateCalendarButton = styled(CommonButton)`
  background: ${styledTheme.colors.backgroundBlue};
  border-radius: 12px;
  min-height: 52px;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;

  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${styledTheme.colors.secondaryDark};

  ${breakpoints("margin-left", [
    {
      xxl: "8px",
    },
    {
      xl: "8px",
    },
    {
      lg: "16px",
    },
    {
      md: "16px",
    },
    {
      sm: "16px",
    },
    {
      xs: "16px",
    },
  ])}
`;
