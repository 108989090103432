import { Paper, Grid } from "@material-ui/core";
import styled from "styled-components";
import { breakpoints } from "../../../styles/layout";
import { styledTheme } from "styles/StyledTheme";
import isEmbedded from "utils/isEmbedded";

export const TabTitle = styled.a`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
`;

export const PaperWrapper = styled(Paper)`
  height: 100%;
`;

export const SignupWithErrorWrapper = styled.div`
  text-align: center;
  height: auto;
  ${breakpoints("width", [
    {
      xs: "100%",
    },
  ])}
  z-index:1;
  
  .MuiPaper-root {
    background-color: ${styledTheme.colors.white};
  }

  .MuiGrid-grid-xs-6 {
    a:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }

  [class*="MuiPaper-elevation1"] {
    box-shadow: ${isEmbedded()
      ? "none"
      : "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"} !important;
  }
`;

export const LogIn = styled.div`
  margin: auto;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
  background-color: ${styledTheme.colors.gray200};
  justify: flex-start;
  .MuiGrid-item {
    padding: 15px;
    border-radius: 12px 12px 0px 0px;
    cursor: pointer;
  }
`;

export const Active = styled(Grid)`
  background-color: ${styledTheme.colors.white};
`;

export const TabContent = styled.div`
  min-height: 630px;
`;
