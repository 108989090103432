import { Grid } from "@material-ui/core";
import AutoSchedulePackageDetail from "./components/AutoSchedulePackageDetail";
import SubscriptionAction from "./components/SubscriptionAction";
import SetupCalendarModal from "components/common/CalendarConnect/SetupCalendarModal";
import { useNylasCalendar } from "hooks";
import { observer } from "mobx-react";
import useStore from "utils/useStore";
import { AUTO_SCHEDULE_FREE_LEAD_LIMIT } from "constant/common"
import {
  Container,
  AutoSchedulePackageWrapper,
  SubscriptionWrapper,
  SubscriptionButtonsWrapper,
  SubscriptionTextActionWrapper,
  IntegrateCalendarButton,
  SubscriptionStatus,
  ClaimFreeLeadsText
} from "./stripeSubscription.styles";

const StripeSubscription = () => {
  const { calendarStore, subscriptionStore, attorneyStore } = useStore();
  const { attorneyDetail } = attorneyStore;

  const totalAutoScheduleLeadCount: number = attorneyDetail?.totalAutoScheduleLeadCount || 0;

  useNylasCalendar((isOpenSetupCalendar: boolean) =>
    calendarStore.setOpenCalendarModal(isOpenSetupCalendar)
  );

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Container item xl={12} lg={12} md={12} sm={12} xs={12}>
        <AutoSchedulePackageWrapper item xl={8} lg={8} md={12} sm={12} xs={12}>
          <AutoSchedulePackageDetail />
        </AutoSchedulePackageWrapper>
        <SubscriptionWrapper item xl={4} lg={4} md={12} sm={12} xs={12}>
          <SubscriptionTextActionWrapper>
            <SubscriptionStatus isActive={subscriptionStore?.stripeAccountExisted}>
              {subscriptionStore?.stripeAccountExisted
                ? "Subscription Active"
                : "No active subscription"}
            </SubscriptionStatus>
            {(!subscriptionStore?.stripeAccountExisted) && totalAutoScheduleLeadCount < AUTO_SCHEDULE_FREE_LEAD_LIMIT &&
              <ClaimFreeLeadsText>
                {'Please connect with stripe to claim the free leads'}
              </ClaimFreeLeadsText>
            }
            <SubscriptionButtonsWrapper>
              <SubscriptionAction />
              <IntegrateCalendarButton
                onClick={() => calendarStore.setOpenCalendarModal(true)}
                >
                Integrate Calendar
              </IntegrateCalendarButton>
            </SubscriptionButtonsWrapper>
          </SubscriptionTextActionWrapper>
        </SubscriptionWrapper>
      </Container>
      <SetupCalendarModal
        isOpen={calendarStore.openCalendarModal}
        onClickClose={() => calendarStore.setOpenCalendarModal(false)}
      />
    </Grid>
  );
};

export default observer(StripeSubscription);
