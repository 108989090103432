/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

//INFO: using any for accept all datatype
export function getSessionStorage(
  storageKey: string,
  defaultValues?: any
): any {
  try {
    const storageValues = sessionStorage.getItem(storageKey);
    return JSON.parse(storageValues) || defaultValues;
  } catch (error) {
    return defaultValues;
  }
}

//INFO: using any for accept all datatype
export function setSessionStorage(storageKey: string, inputObject: any): void {
  sessionStorage.setItem(storageKey, JSON.stringify(inputObject));
}

export function getLocalStorage(storageKey: string, defaultValues?: any): any {
  try {
    const storageValues = localStorage.getItem(storageKey);
    return JSON.parse(storageValues) || defaultValues;
  } catch (error) {
    return defaultValues;
  }
}

//INFO: using any for accept all datatype
export function setLocalStorage(storageKey: string, inputObject: any): void {
  localStorage.setItem(storageKey, JSON.stringify(inputObject));
}
