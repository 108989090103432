import { Container, Wrapper } from "./unsubscribeModal.styles";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { useForm, FormProvider } from "react-hook-form";
import Body from "./components/Body";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { useStyles } from "./unsubscribeModal.styles";
import { handleUnSubscription } from "./utils";
import { IUnSubscriptionSurvey } from "interfaces/subscription";
import { observer } from "mobx-react";
import useStore from "utils/useStore";

interface IUnSubscriptionPlanProps {
  isOpen: boolean;
  onClickAcceptUnSubscribe: (
    unSubscriptionSurvey: IUnSubscriptionSurvey
  ) => void;
  onClickRejectUnSubscribe: () => void;
}

export const UnSubscriptionPlan = (props: IUnSubscriptionPlanProps) => {
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {},
  });
  const { getValues, reset } = methods;
  const { onClickAcceptUnSubscribe, onClickRejectUnSubscribe, isOpen } = props;
  const { subscriptionStore } = useStore();
  const classes = useStyles();

  return (
    <Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={() => {
          onClickRejectUnSubscribe();
          reset();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <FormProvider {...methods}>
            <Wrapper className={classes.paper}>
              <Header />
              <Body />
              <Footer
                onClickAcceptUnSubscribe={() =>
                  handleUnSubscription(
                    getValues,
                    reset,
                    onClickAcceptUnSubscribe,
                    subscriptionStore
                  )
                }
                onClickRejectUnSubscribe={() => {
                  onClickRejectUnSubscribe();
                  reset();
                }}
              />
            </Wrapper>
          </FormProvider>
        </Fade>
      </Modal>
    </Container>
  );
};

export default observer(UnSubscriptionPlan);
