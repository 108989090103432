import { Grid } from "@material-ui/core";
import Confirmation from "pages/Confirmation";
import UserDetails from "pages/Registration/UserDetails";
import CalendarSetup from "../CalendarSetup";
import StripeSetup from "../StripeSetup";
import {
  FlexWrapper,
  RegistrationPaper,
} from "./featuredFlexRegistration.styles";

const FeaturedFlexRegistration = ({ pathname }) => {
  const userDetails: boolean = /user-details/g.test(pathname);
  const confirmation: boolean = /confirmation/g.test(pathname);
  const calendarSetup: boolean = /calendar-setup/g.test(pathname);
  const stripeSetup: boolean = /stripe-setup/g.test(pathname);

  return (
    <FlexWrapper container>
      <Grid item xs={10} sm={8} style={{ maxWidth: "680px" }}>
        <RegistrationPaper>
          {userDetails && <UserDetails />}
          {calendarSetup && <CalendarSetup />}
          {stripeSetup && <StripeSetup />}
          {confirmation && <Confirmation />}
        </RegistrationPaper>
      </Grid>
    </FlexWrapper>
  );
};

export default FeaturedFlexRegistration;
