import React, { useState } from "react";
import Signup from "pages/Landing/LoginSignup/Signup";
import ErrorMsg from "pages/Landing/LoginSignup/ErrorMsg";
import { styledTheme } from "../../../styles/StyledTheme";
import {
  TabTitle,
  PaperWrapper,
  SignupWithErrorWrapper,
  LogIn,
  Active,
  TabContent,
} from "./signup.styles";

const LandingSignup = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  
  return (
    <SignupWithErrorWrapper>
      <PaperWrapper square>
        <LogIn>
          <Active item xs={6}>
            <TabTitle
              style={
                selectedTab === 1
                  ? { color: styledTheme.colors.primaryGray }
                  : null
              }
            >
              Sign Up
            </TabTitle>
          </Active>
        </LogIn>

        <TabContent>
          {selectedTab === 0 && <Signup setErrorMsg={setErrorMsg} />}
        </TabContent>
      </PaperWrapper>
      {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
    </SignupWithErrorWrapper>
  );
};

export default LandingSignup;
