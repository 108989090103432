import { observer } from "mobx-react";
import Loader from "react-loader-spinner";
import { Grid } from "@material-ui/core";
import useStore from "utils/useStore";
import {
  Container,
  UnSubscribeButton,
  KeepPlanButton,
  ButtonWrapper,
  MediumRightSpacing,
} from "./footer.styles";

interface IFooterProps {
  onClickAcceptUnSubscribe: () => void;
  onClickRejectUnSubscribe: () => void;
}

const Footer = (props: IFooterProps) => {
  const { subscriptionStore } = useStore();
  const { onClickAcceptUnSubscribe, onClickRejectUnSubscribe } = props;
  const { isLoading } = subscriptionStore;

  return (
    <Container>
      <ButtonWrapper>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
          <MediumRightSpacing>
            <KeepPlanButton onClick={onClickRejectUnSubscribe}>
              Keep plan
            </KeepPlanButton>
          </MediumRightSpacing>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
          <UnSubscribeButton blocked={isLoading} onClick={onClickAcceptUnSubscribe}>
            {isLoading && (
              <Loader type="TailSpin" color="#FFFFFF" height={16} width={16} />
            )}
            <span>Cancel subscription</span>
          </UnSubscribeButton>
        </Grid>
      </ButtonWrapper>
    </Container>
  );
};

export default observer(Footer);
