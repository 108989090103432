import { Select, Input, MenuItem, Checkbox } from "@material-ui/core";
import { useEffect, useState } from "react";

interface IMultipleSelectProps {
  multiple: boolean;
  value: unknown[];
  handleChange: (newValue) => void;
  valueMapping: unknown;
  options: unknown[];
  checkbox: boolean;
  onClose: () => void;
  open: boolean;
}

const MultipleSelect = (props: IMultipleSelectProps) => {
  const {
    multiple,
    value,
    handleChange = (newValue) => {},
    valueMapping = null,
    options = [],
    checkbox = false,
    onClose = () => {},
    open = false,
  } = props;
  const [currentValue, setCurrentValue] = useState(value);

  const _handleChange = () => {
    handleChange(currentValue);
    onClose && onClose();
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <Select
      open={open}
      multiple={multiple}
      value={currentValue}
      onChange={(e) => setCurrentValue(e.target.value as any)}
      onClose={_handleChange}
      input={<Input />}
      color="primary"
      renderValue={(selected: number[]) =>
        valueMapping && multiple
          ? selected.map((item) => valueMapping[item]).join(", ")
          : selected.join(", ")
      }
    >
      {Object.keys(options).map((optionKey) => (
        <MenuItem key={optionKey} value={optionKey}>
          {checkbox && (
            <Checkbox
              color="primary"
              checked={currentValue.indexOf(optionKey) > -1}
            />
          )}
          {options[optionKey]}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MultipleSelect;
