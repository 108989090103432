import moment from "moment";
import { useState, useEffect } from "react";
import {
  Container,
  CommonLabel,
  CommonPicker,
  CheckIcon,
  CloseIcon,
} from "./timePicker.styles";

interface ITimePicker {
  onAccept: (from: moment.Moment, to: moment.Moment) => void;
  onCancel: () => void;
  fromValue?: moment.Moment;
  toValue?: moment.Moment;
}

const TimePicker = (props: ITimePicker) => {
  const { onAccept, onCancel, fromValue, toValue } = props;
  const [from, setFrom] = useState<moment.Moment>(moment());
  const [to, setTo] = useState<moment.Moment>(moment());

  useEffect(() => {
    if (fromValue) {
      setFrom(fromValue);
    }
  }, [fromValue]);

  useEffect(() => {
    if (toValue) {
      setTo(toValue);
    }
  }, [toValue]);

  return (
    <Container>
      <CommonLabel>From</CommonLabel>
      <CommonPicker
        value={from}
        onChange={(newTime: moment.Moment) => setFrom(newTime)}
        showSecond={false}
        minuteStep={1}
        use12Hours
      />
      <CommonLabel>To</CommonLabel>
      <CommonPicker
        value={to}
        onChange={(newTime: moment.Moment) => setTo(newTime)}
        showSecond={false}
        minuteStep={1}
        use12Hours
      />
      <CloseIcon onClick={onCancel} iconName="line-md_remove-gray.svg" />
      <CheckIcon
        onClick={() => onAccept && onAccept(from, to)}
        iconName="checker_round-green.svg"
      />
    </Container>
  );
};

export default TimePicker;
