import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${styledTheme.colors.backgroundBlue};
  padding: 4px;
  border-radius: 8px;
`;

export const Button = styled.div<{ isActive: boolean }>`
  min-width: 64px;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: ${(props) =>
    props?.isActive
      ? styledTheme.colors.white
      : styledTheme.colors.backgroundBlue};
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 6px;
  }
`;

export const Label = styled.div<{ isActive: boolean }>`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) =>
    props?.isActive
      ? styledTheme.colors.secondaryDark
      : styledTheme.colors.gray500};
`;
