import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)`
  max-height: 60vh;
  padding-right: 24px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const NoteWrapper = styled(Grid)`
  max-width: 316px;
  cursor: default;
  &:not(:last-child){
    padding-bottom: 24px;
  }
`;

export const TimeStamp = styled(Grid)`
  font-family: ${styledTheme.colors.primaryGray};
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  font-style: normal;
  color: ${styledTheme.colors.primaryGray};

  padding-bottom: 8px;
`;

export const NoteContent = styled(Grid)`
  padding: 16px 20px;
  background-color: ${styledTheme.colors.gray100};
  border-radius: 0px 16px 16px 16px;

  font-family: ${styledTheme.colors.primaryBlack};
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  font-style: normal;
`;
