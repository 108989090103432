import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)``;

export const DayLabel = styled.div`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: left;
  padding-top: 24px;
  padding-bottom: 12px;
  color: ${styledTheme.colors.black};
`;

export const TagWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TagSpacing = styled.div`
  margin-right: 12px;
  margin-bottom: 8px;
`;

export const PickerWrapper = styled(Grid)``;
