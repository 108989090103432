import React from "react";
import { Paper, Grid, Box } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { AudioWhiteGreen, TextWhiteGreen, VideoWhiteGreen } from "icons";
import styled from "styled-components";
import { LawAreas } from "../../constant";
import { styledTheme } from '../../styles/StyledTheme'
import ReadMore from 'components/common/ReadMore'

const moment = require("moment");

const StyledPaper = styled(Paper)`
margin-top: 10px;
position: relative;
padding: 10px 15px;
border-radius: 12px;
background-color: ${({ theme }) => theme.colors.infoLight};
.icons {
  position: absolute;
  left: -10px;
  img {
    width: 20px;
  }
}
label {
  font-size: 10px;
  color: ${({ theme }) => theme.colors.infoDark};
}
.name {
  font-size: 11px;
}
.area {
  font-size: 12px;
  color: ${({ theme }) => theme.colors.defaultDarker};
}
.amount {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    font-size: 25px;
  }
}
.rating {
  display: flex;
  position: relative;
  /* align-items: flex-start; */
  justify-content: flex-end;
}
`;

function getRating(ratings) {
  const rating =
    ((ratings.overallScore +
      ratings.knowledgeScore +
      ratings.friendlinessScore) *
      5) /
    15;

  return rating;
}

const PastInteractionCard = ({
  consultationConnectionMethod,
  length,
  time,
  client,
  payment = { amount: 0 },
  areaOfLaw,
  legalAdviceDescription,
  ratings,
  style,
  createdAt
}) => {

  return (
    <StyledPaper className="w-100" style={style}>
      <Grid container item xs={12} alignItems='flex-start'>
        <Grid item xs={8}>
          <div className="icons">
            {consultationConnectionMethod.toLowerCase() === "voice" && (
              <img src={AudioWhiteGreen} alt="voice" />
            )}
            {consultationConnectionMethod.toLowerCase() === "text" && (
              <img src={TextWhiteGreen} alt="text" />
            )}
            {consultationConnectionMethod.toLowerCase() === "video" && (
              <img src={VideoWhiteGreen} alt="video" />
            )}
          </div>
          <Grid item xs={12} className="label">
            <label>
              <b>
                {`${consultationConnectionMethod.toUpperCase()} ${length}`} |
              </b>{" "}
              {moment(time || new Date()).format('MMMM DD YYYY, h:mm a')}
            </label>
          </Grid>
          <Grid item xs={12} className="name">
            <b>
              {client.firstName} {client.lastName} ({client.phone})
            </b>
          </Grid>
          <Grid item xs={12} className="name">
            <b>
              Email: {client.email}
            </b>
          </Grid>
          <Grid item xs={12} className="area">
            {LawAreas[areaOfLaw]} {`${(LawAreas[areaOfLaw] && legalAdviceDescription) ? "|" : ""}`} <ReadMore
              text={legalAdviceDescription}
              color={'secondaryDark'}
              textLength={120}
            />
          </Grid>
        </Grid>
        <Grid item xs={4} container alignItems="flex-start">
          {
            payment?.calculatedAttorneyAmount > 0 && (
              <Grid item xs={12} className="amount" style={{ color: styledTheme.colors.secondaryDarker }}>
                <div style={{ paddingRight: "5px" }}>$</div>
                <span>{payment.calculatedAttorneyAmount.toFixed(2)}</span>
              </Grid>
            )
          }
          <Grid item xs={12}>
            <div className="rating">
              {!ratings && <label>No Feedback yet</label>}
              {ratings && (
                <Box component="fieldset" borderColor="transparent">
                  <Rating
                    className="star"
                    style={{ fontSize: "20px", color: styledTheme.colors.secondary }}
                    name="read-only"
                    value={getRating(ratings)}
                    precision={0.1}
                    readOnly
                  />
                </Box>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default PastInteractionCard;