import { API, graphqlOperation } from "aws-amplify";
import { updateAttorney as UpdatingAttorney } from "gql/mutations";
import { createAttorney as CreatingAttorney } from "graphql/mutations";
import { checkAuth } from "services/auth-service";
import { NotificationMethodEnum } from "../constant/enum/attorney";
export const CreateAttorney = async (userInfo) => {
  const user = await checkAuth();

  const input = {
    firstName: userInfo.firstName ? userInfo.firstName : "",
    lastName: userInfo.lastName ? userInfo.lastName : "",
    emailWork: user.attributes ? user.attributes.email : "",
    firmAssociation: userInfo.firmAssociation ? userInfo.firmAssociation : "",
    statesOfLicense: userInfo.state ? userInfo.state : "",
    licenseNumbers: userInfo.licenseNumbers ? userInfo.licenseNumbers : "",
    liabilityInsuranceProvider: userInfo.liabilityInsuranceProvider
      ? userInfo.liabilityInsuranceProvider
      : "",
    currentProfessionalResponsibilityInvestigations:
      userInfo.currentProfessionalResponsibilityInvestigations
        ? userInfo.currentProfessionalResponsibilityInvestigations
        : "",
    practiceAreas: userInfo.practiceAreas ? userInfo.practiceAreas : [],
    communicationMethodsPreference: userInfo.commMethods
      ? userInfo.commMethods
      : [],
    acceptsTCAndPP: userInfo.tos ? userInfo.tos : "",
    cognitoId: user.id ? user.id : "",
    cellPhone: userInfo.cellPhone ? userInfo.cellPhone : "",
    phoneNumberUsageConsent: userInfo.phoneNumberUsageConsent
      ? userInfo.phoneNumberUsageConsent
      : "",
    notificationMethods: [
      NotificationMethodEnum.EMAIL,
      NotificationMethodEnum.SMS,
    ],
    ...(userInfo?.autoSchedulePackage
      ? { autoSchedulePackage: userInfo?.autoSchedulePackage }
      : {}),
    ...(userInfo?.isOnline ? { isOnline: userInfo?.isOnline } : {}),
    ...(userInfo?.totalAutoScheduleLeadCount
      ? { totalAutoScheduleLeadCount: userInfo?.totalAutoScheduleLeadCount }
      : {}),
  };

  const {
    data: { createAttorney },
  } = await API.graphql(
    graphqlOperation(CreatingAttorney, {
      input,
    })
  );
  return createAttorney;
};

export const UpdateAttorney = async (userInfo) => {
  const input = {
    ...(userInfo?.firstName ? { firstName: userInfo?.firstName } : {}),
    ...(userInfo?.lastName ? { lastName: userInfo?.lastName } : {}),
    ...(userInfo?.emailWork ? { emailWork: userInfo?.emailWork } : {}),
    ...(userInfo?.firmAssociation
      ? { firmAssociation: userInfo?.firmAssociation }
      : {}),
    ...(userInfo?.statesOfLicense
      ? { statesOfLicense: userInfo?.statesOfLicense }
      : {}),
    ...(userInfo?.licenseNumbers
      ? { licenseNumbers: userInfo?.licenseNumbers }
      : {}),
    ...(userInfo?.liabilityInsuranceProvider
      ? { liabilityInsuranceProvider: userInfo?.liabilityInsuranceProvider }
      : {}),
    ...(userInfo?.currentProfessionalResponsibilityInvestigations
      ? {
          currentProfessionalResponsibilityInvestigations:
            userInfo?.currentProfessionalResponsibilityInvestigations,
        }
      : {}),
    ...(userInfo?.practiceAreas
      ? { practiceAreas: userInfo?.practiceAreas }
      : {}),
    ...(userInfo?.communicationMethodsPreference
      ? {
          communicationMethodsPreference:
            userInfo?.communicationMethodsPreference,
        }
      : {}),
    ...(userInfo?.acceptsTCAndPP
      ? { acceptsTCAndPP: userInfo?.acceptsTCAndPP }
      : {}),
    ...(userInfo?.cognitoId ? { cognitoId: userInfo?.cognitoId } : {}),
    ...(userInfo?.cellPhone ? { cellPhone: userInfo?.cellPhone } : {}),
    ...(userInfo?.phoneNumberUsageConsent
      ? { phoneNumberUsageConsent: userInfo?.phoneNumberUsageConsent }
      : {}),
    ...(userInfo?.autoSchedulePackage
      ? { autoSchedulePackage: userInfo?.autoSchedulePackage }
      : {}),
    ...(userInfo?.isOnline ? { isOnline: userInfo?.isOnline } : {}),
    ...(userInfo?.totalAutoScheduleLeadCount
      ? { totalAutoScheduleLeadCount: userInfo?.totalAutoScheduleLeadCount }
      : {}),
  };
  const {
    data: { updateAttorney = {} },
  } = await API.graphql(
    graphqlOperation(UpdatingAttorney, {
      input: {
        id: userInfo.id,
        ...input,
      },
    })
  );
  return updateAttorney;
};
