import { api, auth } from "API";
import { ILeadDistribution } from "interfaces/attorney/leadDistribution";
import { errorHandler } from "utils/errorHandler";

export async function fetchLeadDistributionStatistics(): Promise<
  ILeadDistribution[]
> {
  try {
    const response = await api.get(`/lead-distributions/statistics`, {
      headers: await auth(),
    });
    return response.data;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}
