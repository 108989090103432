import styled from "styled-components";
import { breakpoints } from "styles/layout";

export const Container = styled.div`
  min-width: 66px;
`;

export const ButtonWrapper = styled.div`
  padding-bottom: 16px;

  [class*="__LabelWrapper"] {
    ${breakpoints("display", [
      {
        xxl: "block",
      },
      {
        xl: "none",
      },
      {
        lg: "block",
      },
      {
        md: "block",
      },
      {
        sm: "block",
      },
      {
        xs: "block",
      },
    ])}
  }
`;
