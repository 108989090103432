import { useState } from "react";
import { Grid } from "@material-ui/core";
import CustomHeading from "components/common/CustomHeading";
import { LawAreas } from "../../../../../constant";
import MultipleSelect from "components/common/MultipleSelect";
import {
  PaperWrapper,
  Container,
  TagList,
  TagWrapper,
  SelectWrapper,
} from "./practiceArea.styles";
import AreaTag from "./components/AreaTag";
import AddTag from "./components/AddTag";
import { handleRemoveTag } from "./utils";

interface IPracticeAreaProps {
  practiceAreas: any;
  handleUpdateAttorney: ({ practiceAreas }) => void;
}

const PracticeArea = (props: IPracticeAreaProps) => {
  const { practiceAreas = [], handleUpdateAttorney } = props;
  const [openSelect, setOpenSelect] = useState<boolean>(false);

  const sortedLawAreas = Object.keys(LawAreas)
  .sort()
  .reduce((accumulator, key) => {
    accumulator[key] = LawAreas[key];
    return accumulator;
  }, {}); 

  return (
    <Grid item xl={9} lg={9} md={12} sm={12} xs={12}>
      <CustomHeading style={{ marginBottom: "20px" }}>
        Practice Area(s)
      </CustomHeading>
      <PaperWrapper square>
        <Container>
          <TagList item xl={12} lg={12} md={12} sm={12} xs={12}>
            {Array.isArray(practiceAreas) &&
              practiceAreas?.map((practiceArea: string, index: number) => (
                <TagWrapper key={index}>
                  <AreaTag
                    label={LawAreas[practiceArea]}
                    onDelete={() =>
                      handleRemoveTag(
                        handleUpdateAttorney,
                        practiceAreas,
                        practiceArea
                      )
                    }
                  />
                </TagWrapper>
              ))}
            <TagWrapper>
              <AddTag onClick={() => setOpenSelect(!openSelect)} />
            </TagWrapper>
            <SelectWrapper>
              <MultipleSelect
                {...({} as any)}
                onClose={() => setOpenSelect(!openSelect)}
                open={openSelect}
                value={practiceAreas}
                valueMapping={LawAreas}
                options={sortedLawAreas as any}
                multiple={true}
                checkbox={true}
                handleChange={(newValue) => {
                  handleUpdateAttorney({
                    practiceAreas: newValue,
                  });
                }}
              />
            </SelectWrapper>
          </TagList>
        </Container>
      </PaperWrapper>
    </Grid>
  );
};

export default PracticeArea;
