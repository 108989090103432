import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GStyledGrid } from "../../../components/common/MaterialUIStyledComponents";

export const BottomBanner = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.secondaryDark};
  height: 80px;
  position: relative;
  border-radius: 10px;

  .img {
    position: absolute;
    top: -7px;
    height: 120px;
  }

  .text {
    color: white;
  }

  a {
    cursor: pointer;
  }
`;

export const GridWrapper = styled(Grid)`
  @media (max-width: 1279px) {
    height: auto;
    margin-top: 40px;
  }
`;

export const StyledA = styled.a`
  cursor: pointer;
  color: white;
  white-space: pre;
`;

export const StyledSpan = styled.span`
  text-decoration: underline;
`;

export const StyledLink = styled(Link)`
  color: white;
  white-space: pre;
`;

export const FooterHeader = styled(GStyledGrid)`
  padding-top: 52px;
`;
