import { Paper, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { breakpoints } from '../../../styles/layout'
import { styledTheme } from "styles/StyledTheme";

export const TabTitle = styled.a`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
`
export const PaperWrapper = styled(Paper)`
  height: 100%;
`
export const LoginSignupWithErrorWrapper = styled.div`
  text-align: center;
  height: auto;
  width: 400px;
  z-index:1;
  ${breakpoints("width", [
     {
        xs: "100%",
     },
  ])}
  .MuiPaper-root {
    background-color: ${styledTheme.colors.white};
  }
  .MuiGrid-grid-xs-6 { 
    a:hover{
      text-decoration:none;
      cursor:pointer;
    }
  }
`
export const LogIn = styled(Grid)`
  margin:auto;
  font-weight:bold;
  justify-items: flex-start;
  color: ${styledTheme.colors.primary};
  font-size:18px;
  background-color: ${styledTheme.colors.gray200};
    .MuiGrid-item {
      padding:15px;
      border-radius: 12px 12px 0px 0px;
      cursor: pointer;
    }
`
export const Active = styled(Grid)`
  &.active {
    background-color: ${styledTheme.colors.white};
  }
`
export const TabContent = styled.div`
  min-height: 630px;
  .MuiGrid-grid-xs-6 { 
    a:hover{
      text-decoration:none;
        cursor:pointer;
    }
  }
`