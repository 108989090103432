import { useState, useEffect } from "react";
import { MenuItem } from "@material-ui/core";
import { updateAttorneyById } from "API/attorney";
import { AutoSchedulePackage } from "constant/enum";
import { autoScheduleOptions } from "./constants";
import {
  Title,
  PackageDropdownWrapper,
  AutoSchedulePackageSelection,
} from "./autoSchedulePackageSelection.styles";
import { observer } from "mobx-react";
import useStore from "utils/useStore";

const AutoSchedulePackageDropdown = () => {
  const { attorneyStore } = useStore();
  const { attorneyDetail } = attorneyStore;
  const [selectedPackage, setSelectedPackage] = useState<AutoSchedulePackage>(
    autoScheduleOptions[0]?.value
  );

  async function onUpdateAutoScheduleOption(
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ): Promise<void> {
    setSelectedPackage(event?.target?.value as AutoSchedulePackage);
    if (attorneyDetail?.id) {
      await updateAttorneyById({
        id: attorneyDetail.id,
        autoSchedulePackage: event.target.value as AutoSchedulePackage,
      });
      attorneyStore.getAttorneyDetail();
    }
  }

  useEffect(() => {
    if (attorneyDetail?.autoSchedulePackage) {
      setSelectedPackage(attorneyDetail?.autoSchedulePackage);
    }
  }, [attorneyDetail?.autoSchedulePackage]);

  return (
    <>
      <Title>Monthly plan</Title>
      <PackageDropdownWrapper>
        <AutoSchedulePackageSelection
          value={selectedPackage}
          defaultValue={selectedPackage}
          onChange={onUpdateAutoScheduleOption}
        >
          {Array.isArray(autoScheduleOptions) &&
            autoScheduleOptions?.map((autoScheduleOption, index: number) => (
              <MenuItem key={index} value={autoScheduleOption?.value}>
                {autoScheduleOption?.label}
              </MenuItem>
            ))}
        </AutoSchedulePackageSelection>
      </PackageDropdownWrapper>
    </>
  );
};

export default observer(AutoSchedulePackageDropdown);
