import { useForm, Controller } from "react-hook-form";
import { styledTheme } from "styles/StyledTheme";
import Icon from "components/common/Icon";
import PasswordInput from "components/common/InputGroup/PasswordInput";
import CommonInput from "components/common/InputGroup/CommonInput";
import Collapse from "@material-ui/core/Collapse";
import { useHistory } from "react-router-dom";
import ConnectButton from "../components/ConnectButton";
import { handleAppleConnectButton, redirectAppleHelperPage } from "./utils";
import { AppleConnectForm } from "./constants";
import { useState } from "react";
import { observer } from "mobx-react";
import useStore from "utils/useStore";
import { NylasProvider, NylasStateSync } from "constant/enum";
import {
  ConnectButtonWithPassword,
  InputWrapper,
  ButtonWrapper,
  CustomCheckbox,
  CustomFormHelperText,
  ContinueButtonWrapper,
  ContinueButton,
} from "./appleConnectButton.styles";

const AppleConnectButton = () => {
  const history = useHistory();
  const { attorneyStore, calendarStore } = useStore();
  const { attorneyDetail } = attorneyStore;
  const { nylasICloudAccount, displayConnectProvider } = calendarStore;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { control, formState, getValues, trigger } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { errors } = formState;
  const isConnected: boolean =
    nylasICloudAccount?.sync_state === NylasStateSync.RUNNING ||
    nylasICloudAccount?.sync_state === NylasStateSync.PARTIAL;

  return (
    <ConnectButtonWithPassword>
      <ButtonWrapper
        onClick={() => {
          calendarStore.setOpenCalendarModal(false);
          calendarStore.setDisplayConnectHelper(true);
          calendarStore.setDisplayConnectProvider(NylasProvider.ICLOUD);
        }}
        noSpacing
      >
        <CustomCheckbox disabled checked={isConnected} color="primary" />
        <ConnectButton
          color={styledTheme.colors.black}
          iconName="ant-design_apple-filled.svg"
          label={!isConnected ? "CONNECT WITH APPLE" : "CONNECTED"}
        />
      </ButtonWrapper>
      <Collapse
        in={displayConnectProvider === NylasProvider.ICLOUD && !isConnected}
      >
        <InputWrapper>
          <Controller
            name={AppleConnectForm.ICLOUD_EMAIL}
            control={control}
            render={({ field }) => (
              <CommonInput
                onChange={(event) => field.onChange(event.target.value)}
                value={field.value}
                placeholder="iCloud Email"
                errorMessage={errors?.[AppleConnectForm.ICLOUD_EMAIL]?.message}
              />
            )}
            rules={{
              required: {
                value: true,
                message: "iCloud email is required",
              },
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <Controller
            name={AppleConnectForm.APP_SPECIFIC_PASSWORD}
            control={control}
            render={({ field }) => (
              <PasswordInput
                onChange={(event) => field.onChange(event.target.value)}
                value={field.value}
                placeholder="App-specific password"
                errorMessage={
                  errors?.[AppleConnectForm.APP_SPECIFIC_PASSWORD]?.message
                }
              />
            )}
            rules={{
              required: {
                value: true,
                message: "App-specific password is required",
              },
            }}
          />
          <CustomFormHelperText onClick={redirectAppleHelperPage}>
            <label>APP SPECIFIC PASSWORD</label>
            <Icon iconName="help_round-gray.svg" />
          </CustomFormHelperText>
        </InputWrapper>
        <ContinueButtonWrapper>
          <ContinueButton
            variant="contained"
            onClick={() => {
              if (!isLoading) {
                handleAppleConnectButton(
                  setIsLoading,
                  getValues,
                  trigger,
                  attorneyDetail,
                  history
                );
              }
            }}
          >
            {isLoading ? "Loading" : "Continue"}
          </ContinueButton>
        </ContinueButtonWrapper>
      </Collapse>
    </ConnectButtonWithPassword>
  );
};

export default observer(AppleConnectButton);
