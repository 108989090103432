import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  color: white;
  width: 100%;
  padding: 10px;
  text-align: center;
  background-color: rgba(0,0,0,0.6);
  z-index: 99999999999999;
`;

const Banner = ({children}) => {

  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

export default Banner;
