import { useFormContext, Controller, useWatch } from "react-hook-form";
import { UnsubscribeFormValues } from "../../constants";
import { Container, SmallSpacing } from "./body.styles";
import { unsubscribeOptions } from "./constants";
import Option from "./components/Option";
import TextArea from "./components/TextArea";
import { IOption } from "interfaces";

const Body = () => {
  const { control, setValue } = useFormContext();
  const selectedReason: string = useWatch({
    control,
    name: UnsubscribeFormValues.REASON,
  });

  return (
    <Container>
      {Array.isArray(unsubscribeOptions) &&
        unsubscribeOptions?.map((option: IOption<string>, index: number) => {
          return (
            <SmallSpacing key={index}>
              <Option
                checked={selectedReason === option?.value}
                onChange={(event) =>
                  setValue(UnsubscribeFormValues.REASON, event.target.value)
                }
                option={option}
              />
            </SmallSpacing>
          );
        })}
      <Controller
        name={UnsubscribeFormValues.OTHER_REASON}
        control={control}
        render={({ field }) => <TextArea {...field} />}
      />
    </Container>
  );
};

export default Body;
