import { Switch, Container, OffLabel, OnLabel } from "./antSwitch.styles";

interface IAntSwitchProps {
  offLabel: string;
  onLabel: string;
  value?: boolean;
  onChange?: (value?: boolean) => void;
}

const AntSwitch = (props: IAntSwitchProps) => {
  const { offLabel, onLabel, onChange, value } = props;

  return (
    <Container>
      <OffLabel>{offLabel}</OffLabel>
      <Switch
        checked={value}
        onChange={(event) => {
          if (onChange) {
            onChange(event?.target?.checked);
          }
        }}
        inputProps={{ "aria-label": "ant design" }}
      />
      <OnLabel>{onLabel}</OnLabel>
    </Container>
  );
};

export default AntSwitch;
