import styled from "styled-components";
import { Paper, Box, Grid } from "@material-ui/core";
import Icon from "components/common/Icon";

export const PaperWrapper = styled(Paper)`
  min-height: 310px;
  font-size: 22px;
  background-color: #f0f5f8;
  box-shadow: none;
  margin-left: 12px;
`;

export const BoxWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 121px;
  border-radius: 12px;
  color: white;
  width: 100%;
  border: 2px solid;

  .icon {
    position: absolute;
    left: -14px;
    cursor: pointer;
  }

  .MuiSvgIcon-root {
    width: 30px;
    height: 0%;
    color: black;
    background-color: white;
    border-radius: 100%;
  }
  
  .text {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    cursor: default;
  }

  .channel-icon {
    margin-right: 8px;
  }
`;

export const LargeSpacing = styled(Grid)`
  margin-bottom: 24px;
`;

export const StyledIcon = styled(Icon)`
  img { 
    width: 44px;
    height: 44px;
  }
`;