import { Grid, Input, Select } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { GStyledButton } from "components/common/MaterialUIStyledComponents";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";
import isEmbedded from "utils/isEmbedded";

export const RequiredField = styled.span`
  color: ${styledTheme.colors.red500};
`;

export const RegistrationWrapper = styled.div`
  width: 90%;
  margin: auto;

  .MuiButton-contained {
    box-shadow: 0px 10px 15px -5px rgba(33, 111, 182, 0.71);
  }

  .MuiFormControl-root {
    width: 100%;
    text-align: center;
  }

  .MuiInputBase-input {
    text-align: center;
    width: 100%;
    border-color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.white};

    font-family: ${styledTheme.fonts.Inter};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${styledTheme.colors.gray600};
  }

  .MuiInput-underline:before {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  }

  .MuiFormHelperText-root {
    &:not(.Mui-error) {
      color: ${({ theme }) => theme.colors.white};
    }
    font-weight: 600;
    padding-bottom: 8px;
    text-align: left;
  }

  [class*="Description__Title"] {
    margin-top: 32px;
  }
`;

export const SubmitSection = styled(Grid)`
  padding-top: 33px;
`;

export const SubmitButton = styled(GStyledButton)`
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 12px;
  background: ${styledTheme.colors.secondaryDark};
  color: ${styledTheme.colors.white};

  &:hover {
    background: ${styledTheme.colors.secondaryDark};
  }

  &:disabled {
    background-color: ${styledTheme.colors.gray500};
  }
`;

export const CommonArrowForwardIcon = styled(ArrowForwardIcon)`
  margin-left: 11px;
`;

export const RegistrationHeaderText = styled.div`
  padding-top: 60px;
`;

export const CommonInput = styled(Input)`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${styledTheme.colors.gray600};
  background-color: ${styledTheme.colors.white};
  border-radius: 6px;
  min-height: 48px;
`;

export const CommonSelect = styled(Select)`
  min-height: 48px;
  border-radius: 6px;
  background-color: ${styledTheme.colors.white};
  border-radius: 6px;

  h6 {
    margin-bottom: 0px;
  }
`;

export const SignIn = styled.div`
  padding-top: 20px;

  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${styledTheme.colors.white};
  opacity: 0.8;
  cursor: default;

  a {
    color: ${styledTheme.colors.white};
    font-weight: bold;
    text-decoration: underline !important;
    cursor: pointer;
  }
`;

export const UserAgreement = styled.div`
  padding-top: 48px;

  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #b4b4b5;
  cursor: default;

  label {
    max-width: 312px;
  }

  a {
    color: ${styledTheme.colors.white};
    font-weight: bold;
    text-decoration: underline !important;
    cursor: pointer;
  }
`;
