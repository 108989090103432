import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled(Grid)`
  padding: 24px;
  background-color: #f0f0f0;
  border-radius: 16px;
`;

export const TextAreaCommon = styled.textarea`
  border: none;
  background-color: #f0f0f0;
  border-radius: 16px;

  min-height: 148px;
  width: 100%;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #26272c;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c4c4c4;
  }

  &:focus {
    outline: none;
  }
`;
