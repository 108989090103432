import { useState } from "react";
import Icon from "components/common/Icon";
import {
  Container,
  CustomInput,
  IconStyle,
  ErrorMessage,
} from "./passwordInput.styles";

interface IPasswordInputProps {
  placeholder: string;
  onChange?: (event) => void;
  value?: string;
  errorMessage?: any;
}

const PasswordInput = (props: IPasswordInputProps) => {
  const { placeholder, onChange, value, errorMessage } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <Container>
      <CustomInput
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      <IconStyle>
        <Icon
          onClick={() => setShowPassword(!showPassword)}
          iconName={
            showPassword ? "akar-icons_eye-closed.svg" : "akar-icons_eye.svg"
          }
        />
      </IconStyle>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};

export default PasswordInput;
