import { useContext, useMemo, useState } from "react";
import { observer } from "mobx-react";
import useStore from "utils/useStore";
import {
  dayIndexMapping,
  addTimeFrameHandler,
  removeTimeFrameHandler,
} from "../../../../../../utils";
import TimeTag from "../../../../../TimeTag";
import TimePicker from "../../../../../TimePicker";
import AddButton from "../../../../../AddButton";
import {
  Container,
  DayLabel,
  TagWrapper,
  TagSpacing,
  PickerWrapper,
} from "./dayBlock.styles";
import { UserContext } from "contexts";
import moment from "moment";
import { IAttorneyTimeFrame } from "interfaces";
import { AttorneyTimeFrameCategory } from "constant/enum";

interface IDayBlockProps {
  dayIndex: number;
}

const DayBlock = (props: IDayBlockProps) => {
  const [userInfo] = useContext(UserContext);
  const { calendarStore } = useStore();
  const [editMode, setEditMode] = useState<boolean>(false);
  const { dayIndex } = props;
  const { attorneyTimeFrames } = calendarStore;

  const filteredAttorneyTimeFrames: IAttorneyTimeFrame[] = useMemo(
    () =>
      Array.isArray(attorneyTimeFrames)
        ? attorneyTimeFrames?.filter(
            (attorneyTimeFrame: IAttorneyTimeFrame) =>
              attorneyTimeFrame?.dayIndex === dayIndex &&
              attorneyTimeFrame?.category === AttorneyTimeFrameCategory.COMMON
          )
        : [],
    [attorneyTimeFrames, dayIndex]
  );

  return (
    <Container>
      <DayLabel>{dayIndexMapping(dayIndex, true)}</DayLabel>
      <TagWrapper>
        {Array.isArray(filteredAttorneyTimeFrames) &&
          filteredAttorneyTimeFrames?.map(
            (attorneyTimeFrame: IAttorneyTimeFrame, index: number) => (
              <TagSpacing key={index}>
                <TimeTag
                  onClickDelete={() =>
                    removeTimeFrameHandler(
                      calendarStore,
                      attorneyTimeFrame?.id,
                      userInfo?.id
                    )
                  }
                  from={attorneyTimeFrame?.from}
                  to={attorneyTimeFrame?.to}
                  theme="dark"
                />
              </TagSpacing>
            )
          )}
      </TagWrapper>
      <PickerWrapper>
        {editMode && (
          <TimePicker
            onAccept={(from: moment.Moment, to: moment.Moment) => {
              addTimeFrameHandler(
                calendarStore,
                userInfo?.id,
                dayIndex,
                from,
                to,
                setEditMode,
                AttorneyTimeFrameCategory.COMMON
              );
            }}
            onCancel={() => setEditMode(false)}
          />
        )}
        {!editMode && <AddButton onClick={() => setEditMode(true)} />}
      </PickerWrapper>
    </Container>
  );
};

export default observer(DayBlock);
