import { GetValues } from "types";
import { UseFormTrigger, FieldValues } from "react-hook-form";
import { toast } from "react-toastify";
import { getNylasConnectionCode } from "API/nylas";
import { AppleConnectForm } from "./constants";
import { NylasProvider } from "constant/enum";
import { IAttorney, INylasConnection } from "interfaces";
import { RouteComponentProps } from "react-router-dom";

export async function handleAppleConnectButton(
  setIsLoading: (isLoading: boolean) => void,
  getValues: GetValues,
  trigger: UseFormTrigger<FieldValues>,
  attorney: IAttorney,
  history: RouteComponentProps["history"]
): Promise<void> {
  trigger(AppleConnectForm.APP_SPECIFIC_PASSWORD);
  const formValues = getValues();
  const iCloudEmail: string = formValues?.[AppleConnectForm.ICLOUD_EMAIL];

  const appSpecificPassword: string =
    formValues?.[AppleConnectForm.APP_SPECIFIC_PASSWORD];

  if (!appSpecificPassword || !iCloudEmail) {
    return;
  }

  setIsLoading(true);
  try {
    const nylasConnection: INylasConnection = {
      name: `${attorney?.firstName} ${attorney?.lastName}`,
      emailAddress: iCloudEmail,
      nylasProvider: NylasProvider.ICLOUD,
      appSpecificPassword: appSpecificPassword,
    };
    const code = await getNylasConnectionCode(nylasConnection);
    if (code) {
      const callbackUrl = `${window.location.pathname}?code=${code}&state=${NylasProvider.ICLOUD}`;
      history.replace(callbackUrl);
    }
  } catch (error) {
    toast.error(
      "Please check the username is correct, and that you are using an app-specific password."
    );
  }
  setIsLoading(false);
}

export function redirectAppleHelperPage(): void {
  if (window) {
    window.open("https://support.apple.com/en-us/HT204397");
  }
}
