import { Container, Title, Description } from "./header.styles";

const Header = () => {
  return (
    <Container>
      <Title>Unsubscribe to monthly plan?</Title>
      <Description>
        We are sad to see you go! You will lose access to accepting the
        consultations. If you’d still like to cancel, it would be helpful for us
        to know why.
      </Description>
    </Container>
  );
};

export default Header;
