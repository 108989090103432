import { useState, useEffect ,useContext} from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import CustomHeading from "components/common/CustomHeading";
import RatingVsChannels from "./RatingVsChannels";
import GlobalRatings from "./GlobalRatings";
import ChannelRating from "./ChannelRating";
import DetailedReview from "./DetailedReview";
import { Button } from "@material-ui/core";
import { UserContext } from "../../../contexts";

import {
  getReviewCount,
  getRatingByChannel,
} from "../../../services/dashboardService";
import { fetchRatings } from "services/consultationService";
import { styledTheme } from '../../../styles/StyledTheme'

const GridWrapper = styled(Grid)`
  @media (max-width: 1279px) {
    height: auto;
    margin-top: 40px;
    .life {
      padding-top: 10px;
    }
  }
`;

const StyledButton = styled(Button)`
font-size:15px;
background-color: ${ ({ theme }) => theme.colors.primary };
box-shadow: 2px 25px 20px -18px rgba(40,126,199,1);
margin-bottom: 20px;
`;

const Rating = () => {
  const [cases, setCases] = useState([]);
  const [ratedCases, setRatedCases] = useState([]);
  const [textTypeCases, setTextTypeCases] = useState([]);
  const [audioTypeCases, setAudioTypeCases] = useState([]);
  const [videoTypeCases, setVideoTypeCases] = useState([]);
  const [ page, setPage ] = useState(1)
  const [userInfo] = useContext(UserContext);

  const ITEMS_PER_PAGE = 5;

  function getUsersRated(type) {
    let totalReviewed = getReviewCount(ratedCases.filter(item => item.consultationConnectionMethod === type));
    let totalCases = cases.filter(item => item.consultationConnectionMethod === type).length;

    if(!totalCases)
      return 0;

    return (totalReviewed * 100) / totalCases;
  }

  useEffect(() => {
    if (userInfo.id) {
      getData(userInfo.id);
    }
  }, [userInfo]);
  
  useEffect(() => {
    if(ratedCases.length === 0) return;
    setTextTypeCases(
      ratedCases.filter((c) => c.consultationConnectionMethod === "Text")
    );
    setAudioTypeCases(
      ratedCases.filter((c) => c.consultationConnectionMethod === "Voice")
    );
    setVideoTypeCases(
      ratedCases.filter((c) => c.consultationConnectionMethod === "Video")
    );
  }, [ratedCases]);

  async function getData(attorneyId) {
    const ratings = await fetchRatings(attorneyId);
    setCases(ratings);
    setRatedCases(ratings.filter(item => item.ratings !== null));
    // setTextTypeCases(
    //   cases.filter((c) => c.consultationConnectionMethod === "text")
    // );
    // setAudioTypeCases(
    //   cases.filter((c) => c.consultationConnectionMethod === "voice")
    // );
    // setVideoTypeCases(
    //   cases.filter((c) => c.consultationConnectionMethod === "video")
    // );
  }
  

  return (
    <GridWrapper item xs={12} container style={{ height: "100%" }}>
      <Grid item xs={12} md={12} lg={8}>
        <Grid item xs={12} md={12} lg={11}>
          <RatingVsChannels cases={ratedCases} allCases={cases} />
        </Grid>
        <Grid item xs={12} md={12} lg={11} container style={{ paddingTop: "30px" }}>
          <Grid item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={11} lg={11}>
              <GlobalRatings cases={ratedCases} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={6} container>
            <Grid item xs={12} md={12} lg={12}>
              <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                <CustomHeading>RATING PER CHANNEL</CustomHeading>
              </Grid>
              <Grid item xs={12}>
                <ChannelRating
                  reviewCount={getReviewCount(textTypeCases)}
                  myRating={getRatingByChannel(textTypeCases)}
                  icon="text"
                  reviewedPercent={
                    cases.length > 0 ? getUsersRated('Text') : 0
                  }
                  color={ styledTheme.colors.secondaryDarker }
                />
              </Grid>
              <Grid item xs={12} className="pt-3">
                <ChannelRating
                  reviewCount={getReviewCount(audioTypeCases)}
                  myRating={getRatingByChannel(audioTypeCases)}
                  icon="voice"
                  reviewedPercent={
                    cases.length > 0 ? getUsersRated('Voice') : 0
                  }
                  color={ styledTheme.colors.secondaryDark }
                />
              </Grid>
              <Grid item xs={12} className="pt-3">
                <ChannelRating
                  reviewCount={getReviewCount(videoTypeCases)}
                  myRating={getRatingByChannel(videoTypeCases)}
                  icon="video"
                  reviewedPercent={
                    cases.length > 0 ? getUsersRated('Video') : 0
                  }
                  color={ styledTheme.colors.secondary }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <TopRatedBanner
          xs={12}
          md={12}
          lg={11}
          container
          alignItems="center"
          justify="center"
        >
          <Grid xs={2} />
          <Cup style={{ height: "65px" }} className="icon" />
          <Grid item xs={5}>
            <span style={{ fontSize: "14px", fontWeight: "bold" }}>
              You are in the Top 10!
            </span>
          </Grid>
          <Grid item xs={5}>
            <span style={{ fontSize: "12px" }}>
              The top partner has 4.9 of overall rating
            </span>
          </Grid>
        </TopRatedBanner> */}
      </Grid>
      <Grid xs={12} md={12} lg={4} item container style={{alignSelf: 'flex-start'}} className="life">
        <CustomHeading>RATINGS HISTORY</CustomHeading>
        {(ratedCases.slice(0, ITEMS_PER_PAGE * page)).map((interaction, index) => {
          return <DetailedReview key={index} {...interaction} />;
        })}
        {
            ratedCases.length > page * ITEMS_PER_PAGE &&
            <div style={{margin: 'auto'}}>
              <br/>
              <StyledButton 
                variant='contained' 
                color='primary' 
                onClick={() => setPage(page + 1)}
              >show more</StyledButton>
            </div>
          }
      </Grid>
    </GridWrapper>
  );
};

export default Rating;
