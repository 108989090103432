import Helmet from "react-helmet";
import { REACT_APP_HUBSPOT_ID } from "../../config";

const Hubspot = () => {
  return (
    <Helmet>
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src={`//js.hs-scripts.com/${REACT_APP_HUBSPOT_ID}.js`}
      ></script>
    </Helmet>
  );
};

export default Hubspot;
