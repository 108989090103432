import { Badge, Button, ButtonGroup, Grid } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { UserContext } from "contexts";
import { updateAttorney as UpdateAttorney } from "gql/mutations";
import { onCreateAttorneyConsultationRequest } from "gql/subscriptions";
import { setAttorneyTimestamp } from "graphql/mutations";
import profileImg from "images/avatar.svg";
import { observer } from "mobx-react";
import { useContext, useEffect, useState } from "react";
import { addToStorage } from "services/consultationService";
import styled from 'styled-components';
import { checkAttorneyFullPermission } from "utils/common";
import { errorHandler } from "utils/errorHandler";
import useStore from "utils/useStore";
import ConfirmModal from '../../components/ConfirmModal';
// ======================
import { styledTheme } from '../../styles/StyledTheme';
import { updateOnlineStatus } from "./utils";

const bell = new Audio();
bell.src = "https://legalq-global-assets.s3.amazonaws.com/audios/notification.mp3";

// STYLED COMPONENTS
const UserStatusWrapper = styled.div`
  padding-top: 25px;

  ${({onlineStatus}) => (
    onlineStatus === 'online' && `
      & hr {
        border-color: white;
      }
      & .user-email {
        color: white;
      }
    `
  )}

  hr {
    border-width: 1.5px;
    width: 70%;
  }
`;
const UserName = styled.h5`
  font-size: 21px;
  margin-bottom: 8px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 21px;
  font-family: 'Rubik';
`;
const UserEmail = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 0px;
  margin-bottom: 16px;
  color: #26272C;
`;

const OnlineStatusBtnsStyled = styled(Grid)`
  text-align: center;
  color: white;
  &:hover {
    cursor: pointer;
  }
`;
const GoOnline = styled(Grid)`
  background-color: ${ ({ theme }) => theme.colors.secondary };
  padding: 40px 0px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  font-family: 'Inter';
  `;
const GoOffline = styled(Grid)`
  background-color: ${ ({ theme }) => theme.colors.default };
  padding: 40px 0px;
  transition: all 0.25s;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  font-family: 'Inter';
  &:hover {
    background-color: #ff5757;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  ${({onlineStatus, theme}) => (
    onlineStatus === 'online' && `
      &::before {
        content: "";
        position: absolute;
        height: 50%;
        width: 100%;
        background-color: ${ theme.colors.secondary };
        bottom: 0;
        left: 0;
      }
    `
  )}
`;

// eslint-disable-next-line
const OnlineBadge = withStyles((theme) => ({
  root: {
    "&.online .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      width: "18px",
      height: "18px",
      borderRadius: "9px",
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      }
    },
    "&.offline .MuiBadge-badge": {
      backgroundColor: "grey",
      color: "grey",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      width: "18px",
      height: "18px",
      borderRadius: "9px",
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        border: "1px solid currentColor",
        content: '""',
      }
    }
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  
  #upload-overlay {
    height: 130px;
    width: 130px;
    border-radius: 50%;
  }
  &:hover > #upload-overlay {
    opacity: 1;
  }
`;

const ProfileAvatar = styled.img`
  height: 130px;
  width: 130px;
  border-radius: 50%;
  object-fit: cover;
  ${({ onlineStatus, theme }) => (onlineStatus === 'online' && `border: ${`4px solid ${theme.colors.secondaryDark}`};`)}
`;

const UploadOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  opacity: 0;
  transition: all 0.25s;
`;

const ProgressWrapper = styled.div`
  background-color: rgba(0,0,0,0.6);
  width: 35px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UploadActions = styled.div`
  position: absolute;
  bottom: -4px;
  z-index: 2;
  width: 123.74px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OnlineStatusText = styled.span`
font-size: 12px;
font-family: 'Inter';

font-weight: 500;
font-size: 18px;
line-height: 28px;
margin-top: 16px;
display: inline-block;
`;

// FUNCTION AND PROPERTIES
let handleNewRequest = () => {};
let subscription  = null;
let intervalId = null;

const UserStatus = ({ firstName, lastName }) => {
  const [
    userInfo,
    updateUserInfo,
    onlineStatus,
    setOnlineStatus
  ] = useContext(UserContext);
  const {subscriptionStore, attorneyStore} = useStore()
  const {stripeSubscription} = subscriptionStore
  const [newProfilePic, setProfilePic] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [userAvatar, setUserAvatar] = useState(null);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const useStyles = makeStyles({
    online: {
      color: "white",
      backgroundColor: styledTheme.colors.secondary,
    },
    offline: {
      "& span": {
        color: "#B8B8BE;",
      },
    },
  });
  useEffect(() => {
    const { id = null } = userInfo;

    if (id && subscription === null) {
      // Subscribe for new cases
      subscription = API.graphql(
        graphqlOperation(onCreateAttorneyConsultationRequest)
      ).subscribe({
        next: (update) => handleNewRequest(update),
        error: error => {
          console.warn(error);
        }
      });

      // ping server
      if(!intervalId)
        intervalId = setInterval(() => {
          API.graphql(graphqlOperation(setAttorneyTimestamp));
        }, 10000)
    }

    if(!userAvatar)
      fetchUserAvatar();

    return () => {
      if(subscription) {
        subscription.unsubscribe();
        subscription = null;
      }
      if(intervalId) {
        intervalId && clearInterval(intervalId);
        intervalId = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  function initFileUpload() {
    document.querySelector('.file-uploader').click();
  }

  function onFilePick(e) {
    try {
      // eslint-disable-next-line
      var ext = e.target.files[0].name.match(/\.([^\.]+)$/)[1];
      switch (ext.toLowerCase()) {
        case 'jpg':
        case 'png':
          break;
        default:
          return;
      }
  
      setProfilePic(e.target.files[0]);
      document.querySelector('#profile-avatar').src = URL.createObjectURL(e.target.files[0]);
    }
    catch(error) {
      errorHandler(error)
    }
  }

  function cancelUpload() {
    setProfilePic(null);
    const tmp = userAvatar;
    setUserAvatar(null);
    setTimeout(() => {
      setUserAvatar(tmp);
    }, 100)
    document.querySelector('.file-uploader').value = null;
  }

  async function handleUpload() {
    setUploading(true);
    // eslint-disable-next-line
    var ext = newProfilePic.name.match(/\.([^\.]+)$/)[1];
    const storageResult = await Storage.put(`attorneys/profile-images/${userInfo.id}.${ext}`, newProfilePic, {
      contentType: 'image/jpeg'
    });

    await API.graphql(
      graphqlOperation(UpdateAttorney, {
        input: {
          id: userInfo.id,
          avatarUrl: storageResult.key
        },
      })
    );

    updateUserInfo({...userInfo, avatarUrl: storageResult.key})

    setUploading(false);
    document.querySelector('.file-uploader').value = null;
    setProfilePic(null);
  }

  async function fetchUserAvatar() {
    if(!userInfo || !userInfo.avatarUrl) return;
    setUserAvatar(await Storage.get(userInfo.avatarUrl))
  }

  handleNewRequest = async(update) => {
    if(!onlineStatus || window.location.pathname.indexOf('/consultations') !== -1) return;

    const { 
      id: requestId, 
      case: caseDetails, 
      consultation, 
      requestedAttorneys,
      expiredTime
    } = update.value.data.onCreateAttorneyConsultationRequest;

    caseDetails.requestId = requestId;
    caseDetails.consultation = consultation;
    caseDetails.expiredTime = expiredTime;
    const allowConsultation = await checkAttorneyFullPermission(requestedAttorneys, userInfo, stripeSubscription)

    if (allowConsultation) {
      const caseTime = new Date(caseDetails?.futureConsultationDateTime);
      const currTime = new Date();
      caseDetails.isFuture = caseTime > currTime;
      bell.play();
      addToStorage(caseDetails);
    }
  }

  const classes = useStyles();

  return (
    <UserStatusWrapper onlineStatus={onlineStatus? 'online': 'offline'}>
      <ConfirmModal 
        isOpen={openStatusModal} 
        titleText="Going Offline?" 
        bodyText="You will not receive new consultation requests notification until you login again" 
        cancelButtonText="Cancel"
        acceptButtonText="Yes, log me off"
        disabled={loading}
        onClickCancel={() => setOpenStatusModal(false)}
        onClickAccept={async() => {
          setLoading(true);
          await updateOnlineStatus(attorneyStore, userInfo.id, !onlineStatus)
          setOnlineStatus(!onlineStatus)
          setOpenStatusModal(false);
          setLoading(false);
        }}
      />
      <input
        className="file-uploader"
        type="file"
        hidden
        accept="image/x-png,image/jpeg"
        onChange={onFilePick}
      />
      <ImageWrapper onlineStatus={onlineStatus? 'online': 'offline'}>
        <OnlineBadge
          className={onlineStatus? 'online': 'offline'}
          overlap="circle"
          color="secondary"
          variant="dot"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <ImageContainer>
            <ProfileAvatar id="profile-avatar" src={userAvatar || profileImg} onlineStatus={onlineStatus? 'online': 'offline'} />
            {
              !newProfilePic ?
              <UploadOverlay id="upload-overlay" onClick={initFileUpload}>
                Update
              </UploadOverlay> :
              <UploadActions>
                {
                  uploading ?
                  <ProgressWrapper>
                    <CircularProgress style={{color: 'white'}} size={20} />
                  </ProgressWrapper> :
                  <ButtonGroup>
                    <Button disabled={uploading} size="small" variant="contained" color="primary" onClick={handleUpload}>
                      save
                    </Button>
                    <Button size="small" variant="contained" onClick={cancelUpload}>
                      cancel
                    </Button>
                  </ButtonGroup>
                }
              </UploadActions>
            }
          </ImageContainer>
        </OnlineBadge>
      </ImageWrapper>
      <Grid
        style={{ paddingTop: "10px", paddingBottom: "15px" }}
        className={onlineStatus ? classes.online : classes.offline}
      >
        <UserName>
          {firstName} {lastName}
        </UserName>
        <UserEmail className="user-email">{userInfo.emailWork}</UserEmail>
        <hr style={{ marginTop: "0rem", marginBottom: "2px" }} />
        <OnlineStatusText>You are {onlineStatus ? 'Online' : 'Offline'}</OnlineStatusText>
      </Grid>
      <OnlineStatusBtnsStyled onClick={() => {
        if(onlineStatus){
          setOpenStatusModal(true);
        }else{
          updateOnlineStatus(attorneyStore, userInfo.id, !onlineStatus)
          setOnlineStatus(!onlineStatus)
        }
      }}>
        {!onlineStatus && <GoOnline>Go Online</GoOnline>}
        {onlineStatus && <GoOffline>Go Offline</GoOffline>}
      </OnlineStatusBtnsStyled>
    </UserStatusWrapper>
  );
};

export default observer(UserStatus);
