import React, { useState, useEffect, useRef } from "react";

const VideoParticipant = ({ r, 
  participant,
  toggleSpeaker,
  isSpeakerOn,
  setIsSpeakerOn,
  toggleMute,
  isMuted,
  setMuted,
}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [clientEnableVideo, setClientEnableVideo] = useState(true);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0]
    if (videoTrack) {
      videoTrack.attach(videoRef.current)
      videoTrack.on('disabled', () => {
        // USER DISABLED VIDEO
        videoTrack.detach()
        setClientEnableVideo(false);
      })
      videoTrack.on('enabled', () => {
        // USER ENABLED VIDEO
        videoTrack.attach(videoRef.current)
        setClientEnableVideo(true);
      })
    }
  }, [videoTracks])

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);

      const audio = document.querySelector("#" + r + "Audio");
      if (r === 'remote') {
        audio.muted = false;
      } else {
        audio.muted = true;
      }

      audioTrack.on('enabled', () => {
        const micBtn = document.querySelector(".mic-btn");
        if(micBtn) {
          micBtn.classList.add('active')
        }

        setMuted(false)
      });
      audioTrack.on('disabled', () => {
        if (audioTrack && r === 'local') {
          setMuted(true)
          const micBtn = document.querySelector(".mic-btn");
          if(micBtn) {
            micBtn.classList.remove('active')
          }
        }
      });
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack && r === 'local') {
      if (isMuted)
        audioTrack.enable()
      else
        audioTrack.disable()
    }
  }, [toggleMute]);

  useEffect(() => {
    if (r === 'remote') {
      var audio = document.querySelector("#" + r + "Audio");
      audio.muted = !audio.muted;
      setIsSpeakerOn(!isSpeakerOn);
      const speakerBtn = document.querySelector(".speaker-btn");
      if(speakerBtn) {
        if (isSpeakerOn)
          speakerBtn.classList.remove('active')
        else
          speakerBtn.classList.add('active')
      }
    }
  }, [toggleSpeaker]);

  return (
    <>
      <video id={r + 'Video'} ref={videoRef} 
        style={{
          width: '100%',
          height: '100%',
          visibility: clientEnableVideo ? "visible" : "hidden",
        }} 
      />
      <audio id={r + 'Audio'} ref={audioRef}  autoPlay={true} />
    </>
  );
};

export default VideoParticipant;
