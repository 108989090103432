import { Button, Grid } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)`
  padding-left: 34px;
  padding-right: 34px;
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AgreementPolicy = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #96acb9;

  a {
    color: #96acb9;
    text-decoration: underline !important;
    font-weight: bold;
  }
`;

export const SubscribeButton = styled(Button)<{ blocked: boolean }>`
  width: fit-content;
  margin-top: 16px;

  background: #287ec7;
  box-shadow: ${styledTheme.shadow.blueBelow};
  border-radius: 12px;
  color: #ffffff;
  padding: 16px 42px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  cursor: ${(props) => (props?.blocked ? "not-allowed" : "pointer")} !important;

  &:hover {
    background: #287ec7;
    box-shadow: 0px 16px 30px -10px rgba(70, 166, 247, 0.5),
      0px 20px 20px -20px #46a6f7;
    border-radius: 12px;
    color: #ffffff;
  }

  span {
    margin-left: 8px;
  }
`;

export const UpdateButton = styled(Button)`
  width: 100%;
  margin-top: 16px;

  background: #287ec7;
  box-shadow: ${styledTheme.shadow.blueBelow};
  border-radius: 12px;
  color: #ffffff;
  padding: 16px 42px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  cursor: ${(props) => (props?.blocked ? "not-allowed" : "pointer")} !important;

  &:hover {
    background: #287ec7;
    box-shadow: 0px 16px 30px -10px rgba(70, 166, 247, 0.5),
      0px 20px 20px -20px #46a6f7;
    border-radius: 12px;
    color: #ffffff;
  }

  span {
    margin-left: 8px;
  }
`;

export const CancelButton = styled(Button)`
  width: fit-content;
  margin-top: 16px;
  margin-right: 16px;

  border-radius: 12px;
  color: rgba(0, 0, 0, 0.48);
  padding: 16px 42px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;

  &:hover {
    border-radius: 12px;
    color: rgba(0, 0, 0, 0.48);
  }
`;
