import {
  Container,
  PlanSection,
  SettingSection,
  Pricing,
  Title,
  RecoveryIcon,
  SettingIcon,
} from "./cardInformation.styles";

interface ICardInformationProps {
  onClickEditCard: () => void;
}

const CardInformation = (props: ICardInformationProps) => {
  const { onClickEditCard } = props;
  return (
    <Container>
      <PlanSection>
        <Title>Monthly plan</Title>
        <Pricing>$199/month</Pricing>
      </PlanSection>
      <SettingSection>
        <RecoveryIcon iconName="round-recover-white.svg" />
        <SettingIcon
          onClick={onClickEditCard}
          iconName="round-setting-white.svg"
        />
      </SettingSection>
    </Container>
  );
};

export default CardInformation;
