import { Input } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled.div`
  position: relative;
`;

export const CustomInput = styled(Input)`
  input {
    padding-right: 32px;
  }
`;

export const ErrorMessage = styled.p`
  color: ${styledTheme.colors.red400};
  margin-bottom: 0px;
`;
