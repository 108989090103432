import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const Container = styled(Grid)`
  padding: 40px;
  padding-bottom: 22px;
`;

export const PaperWrapper = styled(Paper)`
  font-size: 22px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 18px;

  .wrapper {
    width: 80%;
    margin: auto;
    padding: 20px 0px;

    p {
      font-size: 16px;
      margin: 0px !important;
    }

    hr {
      margin-bottom: 0px !important;
    }

    label {
      font-size: 10px;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.defaultDark};
    }
  }
`;

export const Title = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: #26272c;
  padding-bottom: 24px;
`;

export const TagList = styled(Grid)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TagWrapper = styled.div`
  margin-right: 16px;
  margin-bottom: 18px;
`;

export const SelectWrapper = styled.div`
  & > div {
    width: 0;
    height: 0;
    opacity: 0;
  }
`;
