import { IActiveRequest } from "interfaces/acceptConsultationRequest";
import { Fragment } from "react";
import Loader from "react-loader-spinner";
import { styledTheme } from "styles/StyledTheme";
import { getSubmitButtonLabel, isDisableSubmitButton } from "../../utils";
import {
  RequestChangeButton,
  LoaderWrapper,
  CancelButton,
} from "./rescheduleRequest.styles";

interface IRescheduleRequestProps {
  selectedActiveRequest: IActiveRequest;
  isLoading: boolean;
  openPickerSection: boolean;
  setOpenPickerSection: (openPickerSection: boolean) => void;
  setOpenCancelModal: (openCancelModal) => void;
}

const RescheduleRequest = (props: IRescheduleRequestProps) => {
  const {
    selectedActiveRequest,
    isLoading,
    openPickerSection,
    setOpenPickerSection,
    setOpenCancelModal,
  } = props;

  return (
    <Fragment>
      {!openPickerSection && (
        <Fragment>
          <RequestChangeButton
            disabled={isDisableSubmitButton(selectedActiveRequest, isLoading)}
            onClick={() => setOpenPickerSection(true)}
          >
            {isLoading && (
              <LoaderWrapper>
                <Loader
                  type="Oval"
                  color={styledTheme.colors.white}
                  height={14}
                  width={14}
                />
              </LoaderWrapper>
            )}
            {getSubmitButtonLabel(selectedActiveRequest)}
          </RequestChangeButton>
          <CancelButton onClick={() => setOpenCancelModal(true)}>
            Cancel appointment
          </CancelButton>
        </Fragment>
      )}
    </Fragment>
  );
};

export default RescheduleRequest;
