import { Button, Grid } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

export const CancelWrapper = styled(Grid)`
  margin-right: 8px;
`;

export const AcceptWrapper = styled(Grid)`
  margin-left: 8px;
`;

export const CancelButton = styled(Button)`
  width: 100%;
  background: rgba(255, 255, 255, 0.48);
  border-radius: 8px;
  padding: 18px;

  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.48);
`;

export const AcceptButton = styled(Button)`
  width: 100%;
  background: #287ec7;
  border-radius: 8px;
  padding: 18px;

  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #ffffff;

  &:hover {
    background: #287ec7;
  }

  &:disabled {
    background-color: #a0aec0;
  }
`;
