export function handleRemoveTag(
  handleUpdateAttorney: ({ practiceAreas }) => void,
  tags: string[],
  selectedTag: string
): void {
  const newTags: string[] = Array.isArray(tags)
    ? tags?.filter((tag) => tag !== selectedTag)
    : [];
  handleUpdateAttorney({
    practiceAreas: newTags,
  });
}
