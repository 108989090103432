import { useState } from "react";
import { TimeRange } from "./constants";
import { Container, Button, Label } from "./rangeSwitcher.styles";

export interface IRangeSwitcherOption {
  label: string;
  value: TimeRange;
}

interface IRangeSwitcherProps {
  options: IRangeSwitcherOption[];
  onClick?: (value: TimeRange) => void;
}

const RangeSwitcher = (props: IRangeSwitcherProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(2);
  const { options, onClick } = props;

  return (
    <Container>
      {Array.isArray(options) &&
        options?.map((option: IRangeSwitcherOption, index: number) => (
          <Button
            onClick={() => {
              onClick && onClick(option.value);
              setSelectedIndex(index);
            }}
            isActive={selectedIndex === index}
            key={index}
          >
            <Label isActive={selectedIndex === index}>{option.label}</Label>
          </Button>
        ))}
    </Container>
  );
};

export default RangeSwitcher;
