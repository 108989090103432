import { AutoSchedulePackageDetail, AutoSchedulePackage } from "constant/enum";
import { IOption } from "interfaces";

export const autoScheduleOptions: IOption<AutoSchedulePackage>[] = [
  {
    label: AutoSchedulePackageDetail.PackageZero.label,
    value: AutoSchedulePackage.PackageZero,
  },
  {
    label: AutoSchedulePackageDetail.PackageOne.label,
    value: AutoSchedulePackage.PackageOne,
  },
  {
    label: AutoSchedulePackageDetail.PackageTwo.label,
    value: AutoSchedulePackage.PackageTwo,
  },
  {
    label: AutoSchedulePackageDetail.PackageThree.label,
    value: AutoSchedulePackage.PackageThree,
  },
];
