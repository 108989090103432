import { IOption } from "interfaces";
import { Container, RadioCommon, LabelCommon } from "./option.styles";

interface IOptionProps {
  option: IOption<string>;
  checked?: boolean;
  onChange: (event) => void;
}

const Option = (props: IOptionProps) => {
  const { option, checked, onChange } = props;

  return (
    <Container>
      <LabelCommon>
        <RadioCommon
          checked={checked}
          onChange={onChange}
          value={option?.value}
          color="primary"
        />
        {option?.label}
      </LabelCommon>
    </Container>
  );
};

export default Option;
