import { Grid, IconButton } from '@material-ui/core'
import { Storage } from "aws-amplify"
import Description from 'components/common/Description'
import { GStyledAvatar, GStyledH3, GStyledH5, GStyledImg, GStyledP } from 'components/common/StyledComponents'
import Timer from "components/common/Timer"
import { ConsultationContext } from 'contexts'
import { BtFinishChat } from 'icons'
import ClientProfilePic from 'images/avatar.svg'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { LawAreas } from "../../../../constant"
import { styledTheme } from '../../../../styles/StyledTheme'

const HeaderWrapper = styled(Grid)`
  background-color: ${ ({ theme }) => theme.colors.secondaryDark };
  margin: 0px !important;
  border-radius : 18px 18px 0px 0px;
  padding:10px;

  .timeWrapper{
    .MuiIconButton-root {
    padding:0px;
    }
  }
  .earning{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
  }
 `
 const LeftHeading = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
` 

const StyledDiv = styled.div`
  border: ${ ({ theme }) => `1px solid ${ theme.colors.secondary }` };
  width: 60px;
  margin-bottom: 5px;
  ${({textAlign}) => textAlign && `text-align: ${textAlign}`};
`

const StyledLabel = styled.label`
  color: ${ ({ theme }) => theme.colors.secondary };
  font-size: 12px;
  ${({textAlign}) => textAlign && `text-align: ${textAlign}`};
  ${({paddingLeft}) => paddingLeft && `padding-left: ${paddingLeft}`}; 
`
const TextConsultationHeader = ({details}) => {

  const {handleComplete, isClientOnline, activeConsultation, actualLength} = useContext(ConsultationContext);
  const [avatar, setAvatar] = useState(null);
  if(!details.client)
    details.client = {}

  const {
    client : { firstName, lastName, profilePictureUrl},
    areaOfLaw,
    state,
    description,
    consultation,
    timeLimit
  } = details ;
  useEffect(() => {
    if(profilePictureUrl)
      attachAvatar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilePictureUrl])

  async function attachAvatar() {
    setAvatar(await Storage.get(profilePictureUrl, {
        level: "public"
      }
    ))
  }
  
  return (
    <HeaderWrapper container item xs={12}>
      <LeftHeading item xs={12} md={6}>
        <Grid item xs={4}>
          <GStyledAvatar src={avatar || ClientProfilePic} alt='sophie' />
        </Grid>
        <Grid item xs={8}>
          <GStyledH3
            fontSize= '16px'
            fontWeight= 'bold'
            color={ styledTheme.colors.white }
          >
            {firstName} {lastName} <span style={{fontSize: 12}}>({isClientOnline ? 'Online' : 'Offline'})</span>
          </GStyledH3>
          <GStyledH5 
            fontSize= '12px'
            color={ styledTheme.colors.secondary }
          >
            <b>{LawAreas[areaOfLaw]}</b> {`${(LawAreas[areaOfLaw] && state) ? "|":""}`} {state}
          </GStyledH5>
          <GStyledP 
            fontSize= '10px'
            color={ styledTheme.colors.white } 
          >
            <Description text={description} color={ 'white' } textLength={ 120 } />
          </GStyledP>
        </Grid>
      </LeftHeading>
      <Grid item xs={12} md={6} container className="timeWrapper" justify="center" alignItems="center">
        {details.payment?.calculatedAttorneyAmount === 0 ? <Grid item xs={6}/> : <Grid item xs={2}/>}
        <Grid item xs={4}  container direction="column">
        <Timer initialTime={actualLength} timeLimit={timeLimit} isClientOnline={isClientOnline} consultationId={consultation.id} />
        <StyledDiv border= { `1.5px solid ${ styledTheme.colors.secondary }` } width= '60px' marginBottom= '5px' textAlign= 'left' />
          <StyledLabel color= { styledTheme.colors.secondary } fontSize= '12px' textAlign= 'left' paddingLeft= '18px'>TIME</StyledLabel>  
        </Grid>
        {details.payment?.calculatedAttorneyAmount !== 0 && (
          <Grid item xs={4}  container direction="column">
            <div className="earning">
              <GStyledH5 color={ styledTheme.colors.white } style={{ color: styledTheme.colors.white }}>
                <b style={{fontSize:"13px",marginRight:"3px"}}> $</b>{details.payment?.calculatedAttorneyAmount.toFixed(2)}
              </GStyledH5>
              <StyledDiv border={ `1px solid ${ styledTheme.colors.secondary }` } width='60px' marginBottom='5px' />
              <StyledLabel color={ styledTheme.colors.secondary } fontSize= '12px'>EARNINGS</StyledLabel>  
            </div>
          </Grid>
        )}
        <Grid item xs={2} container >
          <IconButton onClick={handleComplete}>
            <GStyledImg
              width='40px'
              height= '40px'
              src={BtFinishChat}
              alt='disconnect'
            />
          </IconButton>
        </Grid>
      </Grid>
    </HeaderWrapper>
  )
}

export default TextConsultationHeader