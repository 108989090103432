import styled from "styled-components";
import { Grid, Switch as MatSwitch } from "@material-ui/core";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

export const OffLabel = styled.div`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${styledTheme.colors.primaryBlack};

  padding-right: 8px;
  cursor: default;
`;

export const OnLabel = styled.div`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${styledTheme.colors.primaryBlack};

  padding-left: 8px;
  cursor: default;
`;

export const Switch = styled(MatSwitch)`
  width: 34px;
  height: 20px;
  padding: 0px;
  display: flex;

  &:active {
    & .MuiSwitch-thumb {
      width: 15px;
    }

    & .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(9px);
    }
  }

  & .MuiSwitch-switchBase {
    padding: 2px;

    &.Mui-checked {
      transform: translateX(14px);
      color: #fff;

      & + .MuiSwitch-track {
        opacity: 1;
        background-color: #1890ff;
      }
    }
  }

  & .MuiSwitch-thumb {
    box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
    width: 16px;
    height: 16px;
    border-radius: 8px;
    transition-duration: 200ms;
  }

  & .MuiSwitch-track {
    border-radius: 10px;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
  }
`;
