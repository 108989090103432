import React from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-top:50px;
  @media (max-width:1000px){
    .articles{
    padding-left: 0rem!important;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center; 
    } 
  }
`
const Image = styled.img`
  height:130px;
`
const Title = styled.h2`
  font-size:28px;
  width:100%;
  padding-top:60px;
  font-weight:bolder;
  @media (max-width:1000px){
    text-align:center;
  }

`
const Content = styled.p`
  max-width: 90%;
  font-size:18;
  padding-top:25px;
  font-weight:light;
  padding-bottom:50px;

`
const Border = styled.div`
  border: ${ ({ theme }) => `2px solid ${ theme.colors.primary }` };
  background-color: ${ ({ theme }) =>  theme.colors.primary };
  width: 40px;
  margin-top:20px;
  margin-left:-10px;
  @media (max-width:1000px){
    margin-left:0px; 
  }
`
const FeaturedArticleColumn = ({ img, title = 'Some Title', content = 'Some Content' }) => {

  return (
    <Grid item container lg={5} sm={12} spacing={2}>
      <Wrapper>
      <Grid item xs className="articles pl-5 ">
        <Image src={img} alt='missing' />
        <Title>{title}</Title>
        <Border />
        <Content>{content}</Content>
      </Grid>
      </Wrapper>
    </Grid>
  )
}

export default FeaturedArticleColumn
