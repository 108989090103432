import { getAttorneyConsultationRequestsByAttorneyId } from "API/consultationRequest";
import { AttorneyConsultationRequestStatus } from "constant/enum";
import {
  IAttorney,
  IAttorneyConsultationRequest,
  IAttorneyConsultationRequestAttorney,
  ICase,
  ICaseRequest,
} from "interfaces";
import AttorneyStore from "stores/attorneyStore";
import get from "lodash/get";
import { IFilter } from "types";
import uniqBy from "lodash/uniqBy";
import moment from "moment";
import ConsultationStore from "stores/consultationStore";

export async function syncConsultationRequests(
  attorneyStore: AttorneyStore,
  consultationStore: ConsultationStore,
  userInfo: IAttorney,
): Promise<void> {
  if (!userInfo?.id) return;

  const consultationRequests: IAttorneyConsultationRequest[] =
    await getAttorneyConsultationRequestsByAttorneyId(userInfo?.id);
  attorneyStore.setAttorneyConsultationRequests(consultationRequests);
  const casesRequested: ICaseRequest[] = Array.isArray(consultationRequests)
    ? consultationRequests?.map(
        (consultationRequest: IAttorneyConsultationRequest) => {
          let caseTime = new Date(
            consultationRequest?.case?.futureConsultationDateTime
          );
          let currTime = new Date();

          return {
            ...consultationRequest.case,
            requestId: consultationRequest.id,
            requestStatus: consultationRequest.status,
            consultation: consultationRequest.consultation,
            expiredTime: consultationRequest.expiredTime,
            isFuture: caseTime > currTime,
          };
        }
      )
    : [];

  const pendingCases: ICaseRequest[] = uniqBy(
    casesRequested?.filter((caseRequested: ICaseRequest) => {
      const currentTime = moment.now();
      const expiredRequestTime = moment(caseRequested?.expiredTime);
      const waitTime = moment
        .duration(expiredRequestTime.diff(currentTime))
        .asMilliseconds();
      return (
        caseRequested.requestStatus ===
          AttorneyConsultationRequestStatus.PENDING && waitTime > 0
      );
    }),
    (caseRequested: ICaseRequest) => get(caseRequested, "requestId", "")
  );
  const attorneyRequestsStatus: IAttorneyConsultationRequestAttorney[] =
    await syncAttorneyAcceptStatus(attorneyStore, pendingCases, userInfo.id);

  const validCases: ICaseRequest[] = pendingCases?.filter(
    (caseRequested: ICaseRequest) => {
      const foundAttorneyConsultationRequestAttorney =
        attorneyRequestsStatus?.find(
          (requestDetail) =>
            requestDetail?.attorneyConsultationRequestId ===
            get(caseRequested, "requestId", "")
        );
      return (
        foundAttorneyConsultationRequestAttorney?.status ===
        AttorneyConsultationRequestStatus.PENDING
      );
    }
  );
  consultationStore.setPendingCases(validCases)
  attorneyStore.setNumberOfPendingRequests(validCases?.length);
}

export async function syncAttorneyAcceptStatus(
  attorneyStore: AttorneyStore,
  pendingCases: ICaseRequest[],
  attorneyId: string
): Promise<IAttorneyConsultationRequestAttorney[]> {
  const requestIds: string[] = Array.isArray(pendingCases)
    ? pendingCases?.map((pendingCase: ICaseRequest) =>
        get(pendingCase, "requestId", "")
      )
    : [];

  const filter: IFilter<IAttorneyConsultationRequestAttorney> = {
    where: {
      and: [
        {
          attorneyConsultationRequestId: {
            inq: requestIds,
          },
        },
        {
          attorneyId: {
            eq: attorneyId,
          },
        },
      ],
    },
  };

  const attorneyRequestsStatus =
    await attorneyStore.fetchAttorneyConsultationRequestAttorneys(filter);
  return attorneyRequestsStatus;
}

export function getAttorneyConsultationAttorneyId(
  attorneyStore: AttorneyStore,
  requestId: string
): string {
  const foundAttorneyConsultation =
    attorneyStore?.attorneyConsultationRequestAttorney?.find(
      (requestDetail) =>
        requestDetail?.attorneyConsultationRequestId === requestId
    );
  return foundAttorneyConsultation?.id;
}

export function getAttorneyConsultationRequest(
  attorneyStore: AttorneyStore,
  requestId: string
): IAttorneyConsultationRequest {
  const foundAttorneyConsultation =
    attorneyStore?.attorneyConsultationRequests?.find(
      (requestDetail) => requestDetail?.id === requestId
    );
  return foundAttorneyConsultation;
}
