import React from 'react'
import { Grid, IconButton } from '@material-ui/core'
import { BTMicGreenTransparent, BTSpeakerGreenTransparent, BTEndCallGreenWhite } from 'icons'
import CurrentQuestionBanner from '../Audio/CurrentQuestionBanner'
import ChatBody from '../Text/ChatBody/ChatBody'
import { GStyledGrid } from '../../../../components/common/MaterialUIStyledComponents'
import { styledTheme } from '../../../../styles/StyledTheme'

const VideoSlider = ({handleToggleSliderView, isSliderOpen}) => {

  return(
    <GStyledGrid xs={12} height='100%'>
      <GStyledGrid xs={12} height='30%' backgroundColor={ styledTheme.colors.secondaryDark } color='white'>
        <Grid xs={12} container>
          <Grid xs={6} item>
            01:25
            <hr />
            <label>TIME</label>
          </Grid>
          <Grid xs={6} item>
            $ 49
            <hr />
            <label>EARNINGS</label>
          </Grid>
        </Grid>
        <Grid xs={12} container>
          <Grid xs={4} item>
            <img src={BTMicGreenTransparent} alt='mute' />
          </Grid>
          <Grid xs={4} item>
            <IconButton onClick={handleToggleSliderView}><img src={BTEndCallGreenWhite} alt='disconnect' /></IconButton>
          </Grid>
          <Grid xs={4} item>
            <img src={BTSpeakerGreenTransparent} alt='speaker' />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <CurrentQuestionBanner isBtnsFull={true} text='Client question lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod?' />
        </Grid>
      </GStyledGrid>
      <GStyledGrid xs={12} height= '70%' backgroundColor='#F5F4F4'>
        <ChatBody showPrompts={false} />
      </GStyledGrid>
    </GStyledGrid>
  )
}

export default VideoSlider