export const BELL_URL =
  "https://legalq-global-assets.s3.amazonaws.com/audios/notification.mp3";

export const ConsultationStatus = {
  Pending: 'Pending',
  Scheduled: 'Scheduled',
  Ready: 'Ready',
  InProgress: 'InProgress',
  Completed: 'Completed',
  Paused: 'Paused'
}

export const TERMS_OF_SERVICES_URL = "https://legalq.io/consumer-terms-of-use";
export const PRIVACY_POLICY_URL = "https://legalq.io/privacy-policy";
export const USER_AGREEMENT_URL = "https://legalq.io/consumer-terms-of-use";

export const AUTO_SCHEDULE_FREE_LEAD_LIMIT = 3
