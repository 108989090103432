import {
  DateTimeScheduledRequestStatus,
} from "constant/enum";
import { IActiveRequest } from "interfaces/acceptConsultationRequest";

export function mixerActiveRequestStatus(
  activeRequest: IActiveRequest,
  currentLabel: string
): string {
  if (
    activeRequest?.dateTimeScheduledRequestStatus ===
    DateTimeScheduledRequestStatus.PENDING
  ) {
    return "Reschedule";
  }
  return currentLabel;
}
