import { Checkbox } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const ButtonWrapper = styled.div<{ noSpacing: boolean }>`
  display: flex;
  flex-direction: row;
  padding-bottom: ${(props) => (props?.noSpacing ? "0px" : "24px")};
`;

export const CustomCheckbox = styled(Checkbox)`
  padding: 0px;
  min-width: 66px;

  input:checked {
    color: ${styledTheme.colors.blue500};
  }
`;
