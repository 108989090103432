import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "../../../../styles/StyledTheme";

export const Container = styled(Grid)<{
  backgroundColor: string;
  isActive: boolean;
  borderColor: string;
  inActiveBorderColor: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : styledTheme.colors.secondaryDark};
  border: 1px solid
    ${(props) =>
      props.isActive ? props.borderColor : props.inActiveBorderColor};
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
`;

export const IconWrapper = styled(Grid)<{ iconWidth: string }>`
  width: ${(props) =>
    props.iconWidth ? props.iconWidth : styledTheme.colors.white};
`;

export const LabelWrapper = styled(Grid)<{ color: string }>`
  padding-left: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.color ? props.color : styledTheme.colors.white)};
`;
