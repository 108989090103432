import { Button, Grid } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)``;

export const MainText = styled(Grid)`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: ${styledTheme.colors.primary};
  padding-bottom: 16px;
`;

export const SubText = styled(Grid)`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${styledTheme.colors.primaryBlack};
`;

export const ConnectWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 48px;
  padding-bottom: 48px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SubmitButton = styled(Button)`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 103.5px;
  padding-right: 103.5px;
  letter-spacing: 0.8px;
  background-color: ${styledTheme.colors.secondaryDark};
  box-shadow: ${styledTheme.shadow.blueBelow};
  border-radius: 12px;
  color: ${styledTheme.colors.white};

  &:hover {
    background-color: ${styledTheme.colors.secondaryDark};
  }
`;
