import { Storage } from "aws-amplify";
import Icon from "components/common/Icon";
import { UserContext } from "contexts";
import profileImage from "images/avatar.svg";
import { IActiveRequest } from "interfaces/acceptConsultationRequest";
import { observer } from "mobx-react";
import { Fragment, useContext, useEffect, useState } from "react";
import useStore from "utils/useStore";
import { LawAreas } from "../../../../constant";
import CancelModal from "./components/CancelModal";
import CustomerNotes from "./components/CustomerNotes";
import PickerSection from "./components/PickerSection";
import RejectScheduleModal from "./components/RejectScheduleModal";
import RescheduleAccept from "./components/RescheduleAccept";
import RescheduleRequest from "./components/RescheduleRequest";
import {
  ButtonGroup,
  ClientAvatar,
  ClientGroup,
  ClientName,
  ClientQuestion,
  ClientSection,
  PaperWrapper,
  QuickOverview,
  QuickOverviewCategory,
  QuickOverviewSeparator,
  QuickOverviewState,
} from "./futureConsultationDetail.styles";
import {
  handleAcceptRescheduleConsultation,
  handleCancelFutureConsultationRequest,
  handleRejectRescheduleConsultation,
  handleSubmitFutureConsultationSchedule,
  isDisableReschedule,
} from "./utils";

const FutureConsultationDetail = () => {
  const [avatar, setAvatar] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { consultationStore } = useStore();
  const [openNoteModal, setOpenNoteModal] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [openRejectRescheduleModal, setOpenRejectRescheduleModal] =
    useState<boolean>(false);
  const [openPickerSection, setOpenPickerSection] = useState<boolean>(false);
  const { selectedActiveRequestId, activeRequests } = consultationStore;
  const [userInfo] = useContext(UserContext);
  const selectedActiveRequest = activeRequests?.find(
    (activeRequest: IActiveRequest) =>
      activeRequest?.id === selectedActiveRequestId
  );

  useEffect(() => {
    (async () => {
      if (selectedActiveRequest?.case?.client?.profilePictureUrl) {
        setAvatar(
          (await Storage.get(
            selectedActiveRequest?.case?.client?.profilePictureUrl,
            {
              level: "public",
            }
          )) as string
        );
      }
    })();
  }, [selectedActiveRequest?.case?.client?.profilePictureUrl]);

  return (
    <Fragment>
      <PaperWrapper>
        <ClientSection xl={12} lg={12} md={12} sm={12} xs={12}>
          <ClientGroup>
            <ClientAvatar source={avatar || profileImage} />
            <ClientName>
              <label>{`${selectedActiveRequest?.case?.client?.firstName} ${selectedActiveRequest?.case?.client?.lastName}`}</label>
              <Icon iconName="recover-green.svg" />
            </ClientName>
            {/*TODO: integrate later
            <ClientNoteButton>
              <CommonButton
                onClick={() => setOpenNoteModal(true)}
                variant="outlined"
              >
                View client’s note
              </CommonButton>
            </ClientNoteButton>
            */}
            <QuickOverview>
              <QuickOverviewCategory>
                {LawAreas[selectedActiveRequest?.case?.areaOfLaw]}
              </QuickOverviewCategory>
              {selectedActiveRequest?.case?.areaOfLaw &&
                selectedActiveRequest?.case?.venueState && (
                  <QuickOverviewSeparator>|</QuickOverviewSeparator>
                )}
              <QuickOverviewState>
                {selectedActiveRequest?.case?.venueState}
              </QuickOverviewState>
            </QuickOverview>
            <ClientQuestion>
              {selectedActiveRequest?.case?.legalAdviceDescription}
            </ClientQuestion>
          </ClientGroup>
          {!openPickerSection && (
            <ButtonGroup>
              {!isDisableReschedule(selectedActiveRequest) && (
                <RescheduleRequest
                  isLoading={isLoading}
                  openPickerSection={openPickerSection}
                  selectedActiveRequest={selectedActiveRequest}
                  setOpenCancelModal={setOpenCancelModal}
                  setOpenPickerSection={setOpenPickerSection}
                />
              )}
              {isDisableReschedule(selectedActiveRequest) && (
                <RescheduleAccept
                  isLoading={isLoading}
                  selectedActiveRequest={selectedActiveRequest}
                  onClickAccept={async () => {
                    await handleAcceptRescheduleConsultation(
                      setIsLoading,
                      selectedActiveRequest.id
                    );
                    setOpenPickerSection(false);
                  }}
                  setOpenRejectRescheduleModal={setOpenRejectRescheduleModal}
                />
              )}
            </ButtonGroup>
          )}
          {openPickerSection && (
            <PickerSection
              isLoading={isLoading}
              onChange={async (date: Date) => {
                await handleSubmitFutureConsultationSchedule(
                  consultationStore,
                  setIsLoading,
                  date
                );
                setOpenPickerSection(false);
              }}
              onCancel={() => setOpenPickerSection(false)}
              selectedActiveRequest={selectedActiveRequest}
            />
          )}
        </ClientSection>
      </PaperWrapper>
      <CustomerNotes
        isOpen={openNoteModal}
        onClickClose={() => setOpenNoteModal(false)}
      />
      <CancelModal
        isOpen={openCancelModal}
        onClickAccept={() =>
          handleCancelFutureConsultationRequest(
            consultationStore,
            setOpenCancelModal,
            setIsLoading,
            selectedActiveRequest?.consultation?.id,
            selectedActiveRequest?.case?.id,
            userInfo?.id
          )
        }
        onClickCancel={() => setOpenCancelModal(false)}
        onClickClose={() => setOpenCancelModal(false)}
        acceptButtonText="Cancel anyway"
        bodyText={`Are you sure want to cancel ${selectedActiveRequest?.case?.client?.firstName} ${selectedActiveRequest?.case?.client?.lastName}'s consultation request?`}
        titleText="Cancel appointment?"
        cancelButtonText="Back"
        disabled={isLoading}
      />
      <RejectScheduleModal
        isOpen={openRejectRescheduleModal}
        onClickAccept={async () => {
          await handleRejectRescheduleConsultation(
            setIsLoading,
            selectedActiveRequest.id
          );
          setOpenRejectRescheduleModal(false);
        }}
        onClickCancel={() => setOpenRejectRescheduleModal(false)}
        onClickClose={() => setOpenRejectRescheduleModal(false)}
        acceptButtonText="Reject anyway"
        bodyText={`Are you sure want to reject ${selectedActiveRequest?.case?.client?.firstName} ${selectedActiveRequest?.case?.client?.lastName}'s reschedule request?`}
        titleText="Reject reschedule?"
        cancelButtonText="Back"
        disabled={isLoading}
      />
    </Fragment>
  );
};

export default observer(FutureConsultationDetail);
