import { FormControl, FormControlLabel, Radio } from "@material-ui/core";
import { IOption } from "interfaces";
import { observer } from "mobx-react";
import useStore from "utils/useStore";
import { CalendarDayCategory } from "constant/enum/calendar";
import { dayOfWeek, dayOptions } from "./constants";
import {
  Container,
  RadioLabel,
  RadioOptions,
  SelectSection,
  SelectButton,
} from "./daySection.styles";
import { handleSelectDayRadio, handleSelectDayOfWeek } from "./utils";
import { dayIndexMapping } from "../../../../utils";
import { useContext } from "react";
import { UserContext } from "contexts";

const DaySection = () => {
  const [userInfo] = useContext(UserContext);
  const { calendarStore } = useStore();
  const { selectDayCategory, selectDayOfWeek } = calendarStore;

  return (
    <Container xl={12} lg={12} md={12} sm={12} xs={12}>
      <FormControl component="fieldset">
        <RadioLabel component="legend">Day</RadioLabel>
        <RadioOptions
          aria-label="gender"
          defaultValue="female"
          name="radio-buttons-group"
        >
          {dayOptions.map((option: IOption<string>, index: number) => (
            <FormControlLabel
              key={index}
              label={option?.label}
              value={option?.value}
              control={<Radio color="primary" />}
              checked={selectDayCategory === option?.value}
              onChange={(event) => {
                handleSelectDayRadio(
                  calendarStore,
                  (event.target as HTMLInputElement)
                    ?.value as CalendarDayCategory,
                  userInfo?.id
                );
              }}
            />
          ))}
        </RadioOptions>
      </FormControl>
      <SelectSection>
        {dayOfWeek.map((option: number, index: number) => (
          <SelectButton
            onClick={() =>
              handleSelectDayOfWeek(calendarStore, option, userInfo?.id)
            }
            isActive={selectDayOfWeek?.includes(option)}
            key={index}
          >
            {dayIndexMapping(option)}
          </SelectButton>
        ))}
      </SelectSection>
    </Container>
  );
};

export default observer(DaySection);
