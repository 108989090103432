import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import AppointmentsRequested from "./AppointmentsRequested";
import ActiveRequests from "./ActiveRequests";
import PastInteractions from "./PastInteractions";

const AppointmentsColumn = ({
  setText,
  setVideo,
  setAudio,
  setRoom,
  setConversationClient,
  isConsultationDisabled,
}) => {
  const [consultationLoading, setConsultationLoading] = useState(false);

  function confirmUserReloading(event) {
    event.preventDefault();
    event.returnValue = '';
  }

  useEffect(() => {
    if (consultationLoading) {
      window?.addEventListener('beforeunload', confirmUserReloading)
      return () => window?.removeEventListener('beforeunload', confirmUserReloading)
    }
  }, [consultationLoading])


  return (
    <Grid container item xs={12} justify="center">
      <Grid item xs={12}>
        <AppointmentsRequested
          setText={setText}
          setVideo={setVideo}
          setAudio={setAudio}
          setRoom={setRoom}
          setConversationClient={setConversationClient}
          setConsultationLoading={setConsultationLoading}
          isConsultationDisabled={isConsultationDisabled}
        />
        <ActiveRequests
          setRoom={setRoom}
          setConversationClient={setConversationClient}
        />
        <PastInteractions />
      </Grid>
    </Grid>
  );
};

export default AppointmentsColumn;
