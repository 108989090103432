import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
// components
import { Grid, Snackbar } from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import TestIcon from '@material-ui/icons/AssignmentTurnedIn';
import { API, graphqlOperation } from "aws-amplify";
import Icon from "components/common/Icon";
import MyDialog from 'components/shared/MyDialog';
import { ConsultationFlow } from "constant/enum";
import { ConsultationContext, UserContext } from "contexts";
import { setConsultationTimestamp, updateConsultation } from "graphql/mutations";
import { getPayment } from "graphql/queries";
import { useNylas } from 'hooks';
import { observer } from "mobx-react";
import moment from 'moment';
import ModalView from 'pages/Dashboard/Consultation/CheckMicnWebcam';
import { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import stripeService from "services/stripeService";
import {
  connectTwilioAudioRoom,
  connectTwilioVideoRoom,
  disconnectTwilioVideoRoom
} from "services/twilioService";
import styled from 'styled-components';
import { styledTheme } from 'styles/StyledTheme';
import { checkSubscriptionPermission } from "utils/common";
import { errorHandler } from "utils/errorHandler";
import useStore from "utils/useStore";
import { updateAttorneyConsultationRequestWaitingTime } from '../../../API/consultationRequest';
import { GStyledGrid } from '../../../components/common/MaterialUIStyledComponents';
import { breakpoints } from '../../../styles/layout';
import { CLIENT_DISCONNECT_THRESHOLD, UPDATE_TIMESTAMP_INTERVAL } from '../.././../constant/timer';
import FutureConsultationDetail from '../Consultation/FutureConsultationDetail';
import AppointmentsColumn from "./AppointmentsColumn";
import AudioConsultation from "./Audio";
import AudioConsultationRequest from "./Audio/Request";
import ConflictCheck from "./ConflictCheck";
import ConsultingAvailability from './ConsultingAvailability';
import { useActiveRequestSubscription } from './hooks';
import HowItWorks from "./HowItWorks";
import TextConsultation from "./Text";
import { getConsultation, markAttorneyJoined, markConsultationPaymentCaptured } from "./utils";
import VideoConsultation from "./Video";

// styled components
const ConsultationFrame = styled(Grid)`
  margin: 0px auto;
  margin-bottom: 40px;

  ${breakpoints("padding", [
      {
        xxl: '0px 20px'
      },
      {
        xl: '0px 20px'
      }
    ])}

  & > div {
    ${breakpoints("max-height", [
      {
        xxl: 'calc(100vh - 16px)'
      },
      {
        xl: 'calc(100vh - 16px)'
      }
    ])}
  }

  @media (max-width: 500px) {
    padding: 0px !important;
    ${({ active }) => active === 'true' && 'min-height: 89vh !important;'}
  }
`;

const GStyledGridLayout = styled(GStyledGrid)`
  min-height: calc(100vh - 50px);
`;

const StyledDiv = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-end; 
@media (max-width: 600px) {
    margin-top: 40px;
  }
`
const StyledBtn = styled.button`
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  border:none;
  background-color:#e6e6e6;
  color:white;
  padding:10px;
  border-radius: 50px; 
  transition: 0.4s; 
  .test2{
    display:none; 
  }
  :hover{
    padding:10px 20px;
  }
  :hover .test1{
    display:none;
  }
  :hover .test2{
    padding: 2px;
    display:inline-block;
    font-weight: bold;
    ${() => `color: ${styledTheme.colors.primary}`}
  }
`

const HowItWorksLabel = styled.div`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${styledTheme.colors.secondaryDark};
  cursor: pointer;

  display: flex;
  align-items: center;

  span{
    padding-right: 8px;
  }

  img{
    width: 16px;
    height: 16px;
  }
`;

const TransitionUp = (props) => {
  return <Slide {...props} direction="up" />;
}
let activityInterval, pingInterval;
let pingClient = () => { };
let logActivity = () => { };

const Consultation = ({ pathname }) => {
  const [userInfo, x1, x2, x3, x4, setMenuDisabled] = useContext(UserContext); //eslint-disable-line
  const [activeCase, setActiveCase] = useState(null)
  const [open, setOpen] = useState(false)
  const [consultationDisabled, setConsultationDisabled] = useState(false)
  const [dialogData, setDialogData] = useState({})
  const [activeConsultation, setActiveConsultation] = useState(null)
  const [pastCases, setPastCases] = useState(null);
  const [toast, setToast] = useState();
  const [isClientOnline, setIsClientOnline] = useState(null);
  const [isSelfOnline, setIsSelfOnline] = useState(true);
  const [lastSeen, setLastSeen] = useState(null); // based on client's activity
  const [isOpenHowItWorks, setIsOpenHowItWorks] = useState(false)
  const { subscriptionStore, attorneyStore, consultationStore } = useStore()
  const { search } = useLocation();
  const urlQuery = new URLSearchParams(search);

  const conflictCheck = /conflick-check/g.test(pathname);
  const [text, setText] = useState(false);
  const [audio, setAudio] = useState(false);
  const [video, setVideo] = useState(false);
  const [room, setRoom] = useState(null);
  const [conversationClient, setConversationClient] = useState(null);
  const [participant, setParticipant] = useState(null);
  const audioRequest = /audio-request/g.test(pathname);
  const [modal, setModal] = useState(false)
  const { activeRequests } = consultationStore
  useActiveRequestSubscription()
  useNylas()
  
  let home = !conflictCheck && !text && !audio && !audioRequest && !video;

  async function fetchData() {
    const isAttorneySubscribed = await checkSubscriptionPermission(userInfo?.emailWork, subscriptionStore?.stripeSubscription);
    setConsultationDisabled(!isAttorneySubscribed)
  }

  useEffect(()=>{
    if(urlQuery.get('requestId') && userInfo?.id){
      updateAttorneyConsultationRequestWaitingTime(urlQuery.get('requestId'), userInfo.id)
    }
  }, [urlQuery.get('requestId')])

  //FOR TESTNG
  useEffect(() => {
    if (!isSelfOnline)
      setToast('Lost Connection');
  }, [isSelfOnline])

  useEffect(() => {
    fetchData();
  }, [userInfo, subscriptionStore?.stripeSubscription])

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipant(participant);
      setIsClientOnline(true);
    };

    const participantDisconnected = (participant) => {
      setParticipant(null);
      setToast('Client has lost connection to LegalQ service. Attempting to reconnect...');
      setIsClientOnline(false);
    };

    if (room) {
      const tidyUp = (event) => {
        if (event.persisted) {
          return;
        }
        if (room) {
          setRoom((prevRoom) => disconnectTwilioVideoRoom(prevRoom))
        }
      };

      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.on("reconnecting", handleReconnect);

      room.participants.forEach(participantConnected);

      window.addEventListener("pagehide", tidyUp);
      window.addEventListener("beforeunload", tidyUp);
      return () => {
        window.removeEventListener("pagehide", tidyUp);
        window.removeEventListener("beforeunload", tidyUp);

        room.off("participantConnected", participantConnected);
        room.off("participantDisconnected", participantDisconnected);
      };
    }
  }, [room]);

  useEffect(() => {
    if (text || audio || video)
      document
        .querySelector("#consultation-frame")
        .scrollIntoView({ behavior: "smooth" });
  }, [text, audio, video])

  useEffect(() => {
    setMenuDisabled(activeCase ? true : false);
    if (activeCase) {
      setIsClientOnline(true);
      setIsSelfOnline(true);
      activityInterval = setInterval(() => logActivity(), UPDATE_TIMESTAMP_INTERVAL);
      pingInterval = setInterval(() => pingClient(), CLIENT_DISCONNECT_THRESHOLD * 1000);
      markAttorneyJoined(activeConsultation?.id)
    }

    return () => {
      clearInterval(activityInterval);
      clearInterval(pingInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCase])

  logActivity = async () => {
    if (!activeConsultation || !activeConsultation.id)
      return;

    try {
      markAttorneyJoined(activeConsultation?.id)

      await API.graphql(
        {
          ...graphqlOperation(setConsultationTimestamp, {
            input: {
              consultationId: activeConsultation.id,
              isAttorney: true
            },
          }),
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM
        }
      );
      if (!isSelfOnline) {
        setToast('Connection resumed!')
        setIsSelfOnline(true);
      }
    } catch (err) {
      errorHandler(err)
      if (err && err.errors && err.errors.length > 0) {
        const msg = err.errors[0].message;
        if (msg === 'Network Error')
          setIsSelfOnline(false);
      }
    }
  }

  pingClient = () => {
    if (!isSelfOnline)
      return;

    const difference = moment().diff(moment(lastSeen), 'seconds');   
    if (!lastSeen) {
      setDialogData({
        title: 'User Connection Alert',
        content: `It looks like the user might be having technical issues, feel free to give them a call at ${activeCase?.client?.phoneno ? activeCase?.client?.phoneno : ''}`,
      })
      setOpen(true);
      setIsClientOnline(false);
    }

    if (difference < CLIENT_DISCONNECT_THRESHOLD) {
      setIsClientOnline(true);
      return;
    }
    if (!isClientOnline || difference >= CLIENT_DISCONNECT_THRESHOLD) {
      if(activeCase?.consultation?.flow === ConsultationFlow.ONBOARDING && attorneyStore.attorneyJoinConsultation){
        handleComplete(true);
        return;
      }
      setToast('Client has lost connection to LegalQ service. Attempting to reconnect...');
      setIsClientOnline(false);
    } 
  }

  function onConsultationComplete(length){
    let tmp = [...activeRequests];
    let index = tmp.findIndex(item => item.case.id === activeCase.id)

    if (index !== -1) {
      let item = { ...tmp[index] };
      let newPastInteraction = {
        consultationConnectionMethod: item.type,
        length: (length / 60) >= 1 ? (length / 60).toFixed(2) + ' min(s)' : 'less than a minute',
        time: item.time,
        client: { firstName: item.firstName, lastName: item.lastName },
        payment: activeCase.payment,
        areaOfLaw: item.areaOfLaw,
        legalAdviceDescription: item.text,
        ratings: null
      };

      setPastCases([newPastInteraction, ...pastCases]);
      tmp.splice(index, 1)
      consultationStore.setActiveRequests(tmp)
    }

    (video === true || audio === true)
      && setRoom((prevRoom) => disconnectTwilioVideoRoom(prevRoom));
    setText(false);
    setAudio(false);
    setVideo(false);

    const paymentId = activeCase.payment.id;
    const caseId = activeCase.id;
    const consultationId = activeCase.consultation.id;
    showCompleteDialog(paymentId, caseId, consultationId);
    setActiveConsultation(null);
    setActiveCase(null);
  }

  const capturePayment = (paymentId, caseId, consultationId) => {
    return API.graphql(
      graphqlOperation(getPayment, {
        id: paymentId
      })
    ).then(resp => {
      const stripeToken = resp.data.getPayment.stripeToken;
      const paymentMethod = resp.data.getPayment.paymentMethod;
      const attorneyName = `${userInfo.firstName} ${userInfo.lastName}`;

      return stripeService.capturePayment(
        paymentId,
        stripeToken,
        attorneyName,
        userInfo.id,
        userInfo.cognitoId,
        paymentMethod,
        caseId,
        consultationId,
      )
    })
  }

  function handleComplete(forced = false){   
    if (forced === true) {
      updateActiveConsultation('Completed', onConsultationComplete);
      return;
    }
    setDialogData({
      title: 'Confirmation',
      content: 'Are you sure you want to end this consultation?',
      actions: [
        {
          text: 'Yes',
          handler: () => {
            updateActiveConsultation('Completed', onConsultationComplete);
          }
        },
        {
          text: 'No',
          handler: () => setOpen(false)
        }
      ]
    })
    setOpen(true);
  }

  const handleReconnect = async () => {
    let room;
    if (audio) {
      setRoom((prevRoom) => disconnectTwilioVideoRoom(prevRoom))
      const room = await connectTwilioAudioRoom(activeCase.consultation.id, userInfo.cognitoId);
      setRoom(room);
    }
    if (video) {
      setRoom((prevRoom) => disconnectTwilioVideoRoom(prevRoom))
      const room = await connectTwilioVideoRoom(activeCase.consultation.id, userInfo.cognitoId);
      setRoom(room);
    }
  }

  const showCompleteDialog = async (paymentId, caseId, consultationId) => {
    attorneyStore.setAttorneyJoinConsultation(false);
    // TODO add action ask attorney this is a success consultation
    setDialogData({
      title: 'Consultation Update',
      content: 'This consultation has been completed. Thank you!'
    })
    setOpen(true);
    const newConsultation = await getConsultation(activeCase?.consultation?.id)
    if (newConsultation?.clientJoined && newConsultation?.attorneyJoined && !newConsultation?.paymentCaptured) {
      await capturePayment(paymentId, caseId, consultationId);
      markConsultationPaymentCaptured(consultationId)
    }
  }

  const updateActiveConsultation = async (status, cb) => {
    try {
      const input = {
        id: activeConsultation.id,
        status,
      };
      console.warn('Input', input);
      const response = await API.graphql(
        graphqlOperation(updateConsultation, {
          input,
        })
      );

      if (cb)
        cb(response.data.updateConsultation.actualLength);
    } catch (error) {
      errorHandler(error)
      console.log('Update Consultation Error', error);
    }
  };


  const [actualLength, setActualLength] = useState(0);
  const [purchasedLength, setPurchasedLength] = useState(0);
  const selectedActiveRequest = activeRequests?.find(
    (activeRequest) =>
      activeRequest?.id === consultationStore?.selectedActiveRequestId
  );

  return (
    <>
      <StyledDiv style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <div style={{ position: 'fixed', zIndex: 1 }}>
          <StyledBtn onClick={() => {
            setModal(true)
          }}>
            <span className="test1">
              <TestIcon color="primary" />
            </span>
            <span className="test2">Check camera and audio</span>
          </StyledBtn>
        </div>
      </StyledDiv>
      {modal && <ModalView modal={modal} setModal={setModal} />}
      <GStyledGridLayout item xs={12} container>
        <MyDialog
          title={dialogData.title}
          content={dialogData.content}
          actions={dialogData.actions}
          open={open}
          handleClose={() => setOpen(false)}
        />
        <ConsultationContext.Provider value={{
          setActiveCase,
          setActiveConsultation,
          activeConsultation,
          activeCase,
          onConsultationComplete,
          handleComplete,
          pastCases,
          setPastCases,
          setText,
          setVideo,
          setAudio,
          setRoom,
          setConversationClient,
          setToast,
          updateActiveConsultation,
          lastSeen,
          setLastSeen,
          isSelfOnline,
          isClientOnline,
          setIsClientOnline,
          actualLength,
          setActualLength,
          purchasedLength,
          setPurchasedLength
        }}>
          <GStyledGrid marginBottom='20' item xs={12} md={12} lg={4}>
            {/* <button onClick={() => setText(true)}>start</button> */}
            <AppointmentsColumn
              setText={setText}
              setVideo={setVideo}
              setAudio={setAudio}
              setRoom={setRoom}
              setConversationClient={setConversationClient}
              isConsultationDisabled={consultationDisabled}
            />
          </GStyledGrid>
          <ConsultationFrame
            id="consultation-frame"
            item
            xs={12}
            md={12}
            lg={7}
            container
            justify="flex-end"
            active={text || audio || video ? 'true' : 'false'}
          >
            {selectedActiveRequest?.id && <FutureConsultationDetail />}
            {home && !selectedActiveRequest?.id && <ConsultingAvailability />}
            {conflictCheck && <ConflictCheck />}
            {text && <TextConsultation conversationClient={conversationClient} details={activeCase} />}
            {audio && <AudioConsultation room={room} participant={participant} details={activeCase} />}
            {audioRequest && <AudioConsultationRequest details={activeCase} />}
            {video && <VideoConsultation room={room} participant={participant} details={activeCase} />}
          </ConsultationFrame>
        </ConsultationContext.Provider>
        <Snackbar
          key={toast}
          open={toast ? true : false}
          onClose={() => setToast(false)}
          autoHideDuration={5000}
          TransitionComponent={TransitionUp}
          message={toast}
        />
      </GStyledGridLayout>
      <HowItWorks isOpen={isOpenHowItWorks} onClickClose={() => setIsOpenHowItWorks(false)}/>
      <HowItWorksLabel onClick={() => setIsOpenHowItWorks(true)}>
        <span>
          How it works
        </span>
        <Icon iconName="question_round-blue.svg"/>
      </HowItWorksLabel>
    </>
  );
};

export default observer(Consultation);
