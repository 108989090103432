import { Container, AddIcon } from "./addButton.styles";

interface IAddButtonProps {
  onClick?: () => void;
}

const AddButton = (props: IAddButtonProps) => {
  const { onClick } = props;
  return (
    <Container onClick={onClick}>
      <AddIcon iconName="fluent_add-circle-32-white.svg" />
    </Container>
  );
};

export default AddButton;
