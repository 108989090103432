import moment from "moment";
import { Container, TagLabel, RemoveIcon } from "./timeTag.styles";

interface ITimeTagProps {
  theme: "light" | "dark";
  from: Date;
  to: Date;
  onClickDelete?: () => void;
}

const TimeTag = (props: ITimeTagProps) => {
  const { theme, from, to, onClickDelete } = props;
  return (
    <Container theme={theme}>
      <TagLabel>{`${moment(from).format("hh:mmA")}-${moment(to).format(
        "hh:mmA"
      )}`}</TagLabel>
      <RemoveIcon onClick={onClickDelete} iconName="line-md_remove-gray.svg" />
    </Container>
  );
};

export default TimeTag;
