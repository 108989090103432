import { Button } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "../../../../../styles/StyledTheme";

export const CalendarSocialButton = styled(Button)<{ backgroundColor: string }>`
  width: 263px;
  height: 48px;
  border-radius: 12px;
  background-color: ${(props) => props.backgroundColor};
  color: ${styledTheme.colors.red600};
`;

export const ButtonText = styled.span`
  color: ${styledTheme.colors.white};
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
`;
