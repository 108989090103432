import get from "lodash/get";
import { API, graphqlOperation } from "aws-amplify";
import { IAttorney } from "interfaces/attorney";
import { getAttorneyOnlineStatus } from "gql";
import { errorHandler } from "utils/errorHandler";
import { updateAttorney } from "gql/mutations";
import { api, auth } from "API";

export async function getAttorneyById(attorneyId: string): Promise<IAttorney> {
  try {
    const data = await API.graphql(
      graphqlOperation(getAttorneyOnlineStatus, {
        id: attorneyId,
      })
    );
    return get(data, "data.getAttorney", {});
  } catch (error) {
    errorHandler(error);
    return {} as IAttorney;
  }
}

export async function updateAttorneyById(
  attorney: Partial<IAttorney>
): Promise<void> {
  await API.graphql(
    graphqlOperation(updateAttorney, {
      input: attorney,
    })
  );
}

export async function getAttorneyProfile(): Promise<IAttorney | undefined> {
  try {
    const response = await api.get(`/attorneys/me`, {
      headers: await auth(),
    });
    return response.data;
  } catch (error) {
    return undefined;
  }
}
