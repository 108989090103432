import { Fragment, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";

import Calendar from "./components/Calendar";
import TimeFrame from "./components/TimeFrame";
import {
  PaperWrapper,
  ContentContainer,
  Header,
  Body,
  TimeFrameContainer,
  CalendarContainer,
} from "./consultingAvailability.styles";
import useStore from "utils/useStore";
import { UserContext } from "contexts";
import CalendarsLinked from "./components/CalendarsLinked";
import SetupCalendarModal from "components/common/CalendarConnect/SetupCalendarModal";
import { getTimeFrameFilter } from "./utils";
import { useNylasCalendar } from "../../../../hooks/nylasCalendar";

const ConsultingAvailability = () => {
  const [userInfo] = useContext(UserContext);
  const { calendarStore } = useStore();
  useNylasCalendar((isOpenSetupCalendar: boolean) =>
    calendarStore.setOpenCalendarModal(isOpenSetupCalendar)
  );

  useEffect(() => {
    if (userInfo?.id) {
      const filter = getTimeFrameFilter(userInfo.id);
      calendarStore.getAttorneyTimeFrames(filter);
      calendarStore.getAttorneyOfflineTimeFrames(filter);
      calendarStore.fetchAttorneySetting(userInfo.id);
    }
  }, [userInfo?.id]);

  return (
    <Fragment>
      <PaperWrapper>
        <ContentContainer xl={12} lg={12} md={12} sm={12} xs={12}>
          <Header xl={12} lg={12} md={12} sm={12} xs={12}>
            Consulting availability
          </Header>
          <Body xl={12} lg={12} md={12} sm={12} xs={12}>
            <TimeFrameContainer xl={6} lg={12} md={12} sm={12} xs={12}>
              <TimeFrame />
            </TimeFrameContainer>
            <CalendarContainer xl={6} lg={12} md={12} sm={12} xs={12}>
              <Calendar />
              <CalendarsLinked
                onClick={() =>
                  calendarStore.setOpenCalendarModal(
                    !calendarStore.openCalendarModal
                  )
                }
              />
            </CalendarContainer>
          </Body>
        </ContentContainer>
      </PaperWrapper>
      <SetupCalendarModal
        isOpen={calendarStore.openCalendarModal}
        onClickClose={() => calendarStore.setOpenCalendarModal(false)}
      />
    </Fragment>
  );
};

export default observer(ConsultingAvailability);
