import DaySection from "./components/DaySection";
import TimeSection from "./components/TimeSection";
import { Container } from "./timeFrame.styles";

export const TimeFrame = () => {
  return (
    <Container>
      <DaySection />
      <TimeSection />
    </Container>
  );
};

export default TimeFrame;
