import styled from "styled-components";
import { Button } from "@material-ui/core";

// background-color: #c4c4c4 !important;
// color: black !important;
const StyledButton = styled(Button)`
  &.MuiButton-contained.Mui-disabled {
    box-shadow: unset !important;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: auto;
`;

const CustomButton = ({ children, ...otherProps }) => {
  return (
    <ButtonWrapper>
      <StyledButton
        type="submit"
        variant="contained"
        color="primary"
        {...otherProps}
      >
        {children}
      </StyledButton>
    </ButtonWrapper>
  );
};

export default CustomButton;
