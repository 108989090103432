export const listFutureConsultations = /* GraphQL */ `
  query ListFutureConsultations(
    $filter: ModelFutureConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFutureConsultations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        dateTimeScheduled
        dateTimeScheduledRequest
        dateTimeScheduledRequestBy
        dateTimeScheduledRequestStatus
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          futureConsultationDateTime
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            attorney {
              id
              firstName
              lastName
            }
            createdAt
          }
          payment {
            id
            paymentMethod
            dateTime
            amount
            calculatedAttorneyAmount
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            profilePictureUrl
            phone
            email
          }
        }
        consultation {
          id
          connectionMethod
          dateTimeOccurred
          status
          purchasedLength
          actualLength
          flow
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const listImmediateConsultations = /* GraphQL */ `
  query ListImmediateConsultations(
    $filter: ModelImmediateConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImmediateConsultations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          futureConsultationDateTime
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
          }
          payment {
            id
            paymentMethod
            dateTime
            amount
            calculatedAttorneyAmount
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            profilePictureUrl
            phone
            email
          }
        }
        consultation {
          id
          connectionMethod
          dateTimeOccurred
          status
          purchasedLength
          actualLength
          flow
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
