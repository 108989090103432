import moment from "moment";

import { IAttorneyTimeFrame } from "interfaces";

export function getTimeFrameLabel(timeFrames: IAttorneyTimeFrame[]): string {
  if (!Array.isArray(timeFrames)) {
    return "";
  }

  const timeFramesLabel: string = timeFrames?.reduce(
    (label: string, timeFrame: IAttorneyTimeFrame, index: number) =>
      `${label}${!index ? "" : " | "}${moment(timeFrame?.from).format(
        "hh:mm A"
      )} - ${moment(timeFrame?.to).format("hh:mm A")}`,
    ""
  );

  return timeFramesLabel;
}
