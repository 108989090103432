import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import styled from 'styled-components'
import ClientProfilePic from 'images/ClientProfilePic.png'
import QuestionRequestBanner from './QuestionRequestBanner'
import { BTMicGreenTransparent, BTSpeakerGreenTransparent, BTEndCallGreenTransparent } from 'icons'

const StyledPaper = styled(Paper)`
  height: 100%;
  background-color: ${ ({ theme }) => theme.colors.secondaryDark };
`

const AudioConsultationRequest = () => {

  return(
    <Grid item style={{height: '100%'}} xs={12}>
      <StyledPaper>
        <Grid item xs={12} container>
          <Grid item xs={4}>
            <Grid xs={4} item>
              01:25
              <hr />
              <label>TIME</label>
            </Grid>
          </Grid>
          <Grid xs={4} item>
            <img src={ClientProfilePic} alt='sophie' />
            <div>
              <h3>Sophie Clark</h3>
              <h5><b>Family Law</b> | Custody</h5>
            </div>
          </Grid>
          <Grid xs={4} item>
            $ 49
            <hr />
            <label>EARNINGS</label>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <QuestionRequestBanner text='Mrs Clark request a new question. What would you like to do?' />
        </Grid>
        <Grid item xs={12} container spacing={4}>
          <Grid xs={4} item>
            <img src={BTMicGreenTransparent} alt='mute' style={{width: '100px'}} />
          </Grid>
          <Grid xs={4} item>
            <img src={BTEndCallGreenTransparent} alt='disconnect' />
          </Grid>
          <Grid xs={4} item>
            <img src={BTSpeakerGreenTransparent} alt='speaker' style={{width: '100px'}} />
          </Grid>
        </Grid>
      </StyledPaper>
    </Grid>
  )
}

export default AudioConsultationRequest