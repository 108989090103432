import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import {
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICES_URL,
  USER_AGREEMENT_URL,
} from "constant/common";
import { AutoSchedulePackage, ConnectionMethod } from "constant/enum";
import { UserContext } from "contexts";
import isEmpty from "lodash/isEmpty";
import RegistrationHeader from "pages/Registration/RegistrationHeader";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CreateAttorney, UpdateAttorney } from "services/registrationService";
import { errorHandler } from "utils/errorHandler";
import isEmbedded from "utils/isEmbedded";
import { GStyledGrid } from "../../../components/common/MaterialUIStyledComponents";
import { US_STATE_NAMES } from "../../../constant";
import { LawAreas } from "../../../constant/form";
import {
  RegistrationWrapper,
  SubmitButton,
  SubmitSection,
  RegistrationHeaderText,
  CommonInput,
  SignIn,
  UserAgreement,
  CommonSelect,
} from "./userDetails.styles";

const UserDetails = () => {
  const [contextUserInfo, setContextUserInfo] = useContext(UserContext);
  const [form, setForm] = useState({
    state: [],
    phoneNumberUsageConsent: false,
    firmAssociation: "",
    practiceAreas: [],
    licenseNumbers: {},
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const sortedLawAreas = Object.keys(LawAreas).sort() 

  useEffect(() => {
    setForm({
      ...form,
      phoneNumberUsageConsent: contextUserInfo.phoneNumberUsageConsent
        ? contextUserInfo.phoneNumberUsageConsent
        : false,
      state: contextUserInfo.state
        ? contextUserInfo.state
        : (contextUserInfo.statesOfLicense &&
            contextUserInfo.statesOfLicense) ||
          [],
      firmAssociation: contextUserInfo.firmAssociation
        ? contextUserInfo.firmAssociation
        : "",
      practiceAreas: contextUserInfo.practiceAreas
        ? contextUserInfo.practiceAreas
        : [],
      licenseNumbers: {},
    });
  }, [contextUserInfo]);

  function validate() {
    const stateError = !form.state && "required";
    const practiceAreasError = !form.practiceAreas?.length && "required";

    const licenseNumbersError = {};
    Object.keys(form?.licenseNumbers)?.forEach((licenseNumberKey) => {
      licenseNumbersError[licenseNumberKey] =
        (!form.licenseNumbers?.[licenseNumberKey] && "required") ||
        (!form.licenseNumbers?.[licenseNumberKey]?.match(/^[0-9]+$/) &&
          "use numbers only");
    });
    const isLicenseNumbersError = Object.keys(licenseNumbersError)?.some(
      (licenseNumberKey) => licenseNumbersError?.[licenseNumberKey]
    );
    setValidationErrors({
      ...validationErrors,
      state: stateError,
      practiceAreas: practiceAreasError,
      licenseNumbersError: licenseNumbersError,
    });

    return !stateError && !practiceAreasError && !isLicenseNumbersError;
  }

  function resetFieldErrors(fieldName) {
    const dup = { ...validationErrors };
    delete dup[fieldName];
    setValidationErrors(dup);
  }

  async function handleNext(event) {
    event.preventDefault();

    if (!validate()) {
      return;
    }
    setLoading(true);
    try {
      if (contextUserInfo?.statesOfLicense?.length > 0) {
        const userInfo = await UpdateAttorney({
          ...contextUserInfo,
          ...form,
          statesOfLicense: form.state,
          acceptsTCAndPP: true,
          isOnline: true,
          communicationMethodsPreference: [
            ConnectionMethod.TEXT,
            ConnectionMethod.VIDEO,
            ConnectionMethod.VOICE,
          ],
          autoSchedulePackage: AutoSchedulePackage.PackageOne,
          licenseNumbers: JSON.stringify(form.licenseNumbers),
        });
        setContextUserInfo({ ...userInfo });
      } else {
        const userInfo = await CreateAttorney({
          ...contextUserInfo,
          ...form,
          tos: true,
          isOnline: true,
          totalAutoScheduleLeadCount: 0,
          commMethods: [
            ConnectionMethod.TEXT,
            ConnectionMethod.VIDEO,
            ConnectionMethod.VOICE,
          ],
          autoSchedulePackage: AutoSchedulePackage.PackageOne,
          licenseNumbers: JSON.stringify(form.licenseNumbers),
        });
        setContextUserInfo({ ...userInfo });
      }
      setLoading(false);
      isEmbedded()
        ? history.push("/registration/confirmation")
        : history.push("/registration/calendar-setup");
    } catch (e) {
      errorHandler(e);
      history.push("/");
    }
  }

  const invalidLicenseNumbers =
    Object.keys(form?.licenseNumbers)?.filter(
      (licenseNumberKey) => !form?.licenseNumbers?.[licenseNumberKey]
    )?.length > 0 || isEmpty(form?.licenseNumbers);

  const allowSubmit =
    !invalidLicenseNumbers && !!form.state && form.practiceAreas?.length > 0;

  return (
    <RegistrationWrapper>
      <form onSubmit={handleNext}>
        <RegistrationHeader
          step={1}
          title="Personal Details"
          header={
            <RegistrationHeaderText>
              Attorney Registration
            </RegistrationHeaderText>
          }
          description="Please fill out all your details to submit your application."
        />

        <GStyledGrid item xs={12} paddingTop="32px" container justify="center">
          <Grid item lg={10} xs={10}>
            <FormHelperText id="firm-helper-text" variant="standard">
              FIRM ASSOCIATION *if applicable
            </FormHelperText>
            <FormControl className="formControl" variant="outlined">
              <CommonInput
                disableUnderline={true}
                type="text"
                placeholder="Complete Name"
                value={form.firmAssociation}
                onChange={(e) =>
                  setForm({ ...form, firmAssociation: e.target.value })
                }
              />
            </FormControl>
          </Grid>
        </GStyledGrid>

        <GStyledGrid item xs={12} container justify="center" paddingTop="20px">
          <Grid item lg={10} xs={10}>
            <FormHelperText
              id="law-area"
              variant="standard"
              className={validationErrors.practiceAreas && "Mui-error"}
            >
              LAW AREAS
            </FormHelperText>
            <FormControl
              className="formControl"
              variant="outlined"
              error={validationErrors?.practiceAreas}
              size="small"
            >
              <CommonSelect
                multiple
                displayEmpty
                onChange={(e) => {
                  resetFieldErrors("practiceAreas");
                  setForm({ ...form, practiceAreas: e.target.value });
                }}
                value={form.practiceAreas}
                renderValue={(selected) => {
                  if (!selected.length) {
                    return <h6>Select</h6>;
                  }
                  return selected.map((item) => LawAreas[item]).join(", ");
                }}
              >
                {sortedLawAreas.map((lawAreaKey)  => (
                  <MenuItem key={lawAreaKey} value={lawAreaKey}>
                    {form.practiceAreas.indexOf(lawAreaKey) > -1 && (
                      <CheckIcon
                        color="gray.700"
                        style={{ paddingRight: "8px" }}
                      />
                    )}
                    <ListItemText primary={LawAreas[lawAreaKey]} />
                  </MenuItem>
                ))}
              </CommonSelect>
            </FormControl>
          </Grid>
        </GStyledGrid>

        <GStyledGrid item xs={12} container justify="center" paddingTop="20px">
          <Grid item lg={10} xs={10}>
            <FormHelperText
              id="state"
              variant="standard"
              className={validationErrors.state && "Mui-error"}
            >
              STATE OF LICENSE
            </FormHelperText>
            <FormControl
              className="formControl"
              variant="outlined"
              error={validationErrors?.state}
              size="small"
            >
              <CommonSelect
                multiple
                displayEmpty
                value={form.state}
                onChange={(e) => {
                  resetFieldErrors("state");
                  setForm({ ...form, state: e.target.value });
                }}
                renderValue={(selected) => {
                  if (!selected.length) {
                    return <h6>Select</h6>;
                  }
                  return selected.join(", ");
                }}
                placeholder="Select"
              >
                {US_STATE_NAMES.map((name) => (
                  <MenuItem key={name} value={name}>
                    {form.state.indexOf(name) > -1 && (
                      <CheckIcon
                        color="gray.700"
                        style={{ paddingRight: "8px" }}
                      />
                    )}
                    {name}
                  </MenuItem>
                ))}
              </CommonSelect>
            </FormControl>
          </Grid>
        </GStyledGrid>

        {form.state.map((stateValue) => (
          <GStyledGrid
            item
            xs={12}
            container
            justify="center"
            paddingTop="20px"
            key={stateValue}
          >
            <Grid item lg={10} xs={10}>
              <FormControl
                className="formControl"
                variant="outlined"
                error={validationErrors?.licenseNumbersError?.[stateValue]}
                size="small"
              >
                <FormHelperText id="firm-helper-text" variant="standard">
                  ATTORNEY LICENSE NUMBER - {stateValue}{" "}
                  {validationErrors.licenseNumbersError?.[stateValue]
                    ? `(${validationErrors.licenseNumbersError?.[stateValue]})`
                    : ""}
                </FormHelperText>
                <CommonInput
                  type="text"
                  placeholder="License number"
                  error={validationErrors?.licenseNumbersError?.[stateValue]}
                  value={form.licenseNumbers?.[stateValue]}
                  onChange={(e) => {
                    resetFieldErrors("licenseNumbers");
                    setForm({
                      ...form,
                      licenseNumbers: {
                        ...form.licenseNumbers,
                        [stateValue]: e.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </GStyledGrid>
        ))}

        <SubmitSection item xs={12} container justify="center">
          <Grid item lg={8} xs={8}>
            {loading && (
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ minHeight: 36 }}
              >
                <CircularProgress size={24} />
              </Grid>
            )}
            {!loading && (
              <SubmitButton
                disabled={!allowSubmit}
                type="submit"
                width="100%"
                padding="10px 0"
                fontSize="15px"
              >
                NEXT
              </SubmitButton>
            )}
          </Grid>
        </SubmitSection>
        <SignIn>
          Already have LegalQ Account? <a href="/">Sign in</a>
        </SignIn>
        <UserAgreement>
          <label>
            By continue you agree to the following{" "}
            <a href={PRIVACY_POLICY_URL}>Privacy Policy</a>,{" "}
            <a href={USER_AGREEMENT_URL}>User Agreement</a> and{" "}
            <a href={TERMS_OF_SERVICES_URL}>Terms of Service</a>
          </label>
        </UserAgreement>
      </form>
    </RegistrationWrapper>
  );
};

export default UserDetails;
