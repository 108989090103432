import { CalendarDayCategory } from "constant/enum/calendar";
import { IAttorneyTimeFrame, INylasAccount } from "interfaces";
import { action, makeObservable, observable } from "mobx";
import { getNylasAccountByAccessToken } from "API/nylas";
import { IRootStore } from "./rootStore";
import { fetchAttorneyTimeFrames } from "../API/attorneyTimeFrame";
import { IFilter } from "types";
import { getAttorneySetting } from "API/attorneySetting";
import { AttorneyTimeFrameCategory, NylasProvider } from "../constant/enum";

class CalendarStore {
  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      selectDayCategory: observable,
      selectDayOfWeek: observable,
      attorneyTimeFrames: observable,
      attorneyOfflineTimeFrames: observable,
      selectedDate: observable,
      nylasGoogleAccount: observable,
      nylasICloudAccount: observable,
      nylasOutlookAccount: observable,
      displayConnectHelper: observable,
      displayConnectProvider: observable,
      openCalendarModal: observable,
      setSelectDayCategory: action,
      setSelectDayOfWeek: action,
      getAttorneyTimeFrames: action,
      getAttorneyOfflineTimeFrames: action,
      setSelectedDate: action,
      fetchAttorneySetting: action,
      fetchNylasGoogleAccount: action,
      fetchNylasICloudAccount: action,
      fetchNylasOutlookAccount: action,
      setDisplayConnectHelper: action,
      setDisplayConnectProvider: action,
      setOpenCalendarModal: action,
    });
    this.rootStore = rootStore;
  }

  public nylasGoogleAccount: INylasAccount = {} as INylasAccount;
  public nylasICloudAccount: INylasAccount = {} as INylasAccount;
  public nylasOutlookAccount: INylasAccount = {} as INylasAccount;

  public openCalendarModal = false;
  public displayConnectHelper = false;
  public displayConnectProvider: NylasProvider | undefined;

  public attorneyTimeFrames: IAttorneyTimeFrame[] = [];
  public attorneyOfflineTimeFrames: IAttorneyTimeFrame[] = [];
  public selectDayCategory: CalendarDayCategory = CalendarDayCategory.WEEK_DAYS;
  public selectDayOfWeek: number[] = [1, 2, 3, 4, 5];
  public selectedDate: Date = new Date();

  public async getAttorneyTimeFrames(
    filter: IFilter<IAttorneyTimeFrame> = {}
  ): Promise<void> {
    this.attorneyTimeFrames = await fetchAttorneyTimeFrames(filter);
  }

  public async getAttorneyOfflineTimeFrames(
    filter: IFilter<IAttorneyTimeFrame> = {}
  ): Promise<void> {
    const attorneyTimeFrames: IAttorneyTimeFrame[] =
      await fetchAttorneyTimeFrames(filter);
    this.attorneyOfflineTimeFrames = attorneyTimeFrames?.filter(
      (attorneyTimeFrame: IAttorneyTimeFrame) =>
        attorneyTimeFrame?.category === AttorneyTimeFrameCategory.OFFLINE
    );
  }

  public setSelectDayCategory(dayCategory: CalendarDayCategory): void {
    this.selectDayCategory = dayCategory;
  }

  public setSelectDayOfWeek(dayOfWeek: number[]): void {
    this.selectDayOfWeek = [...dayOfWeek];
  }

  public setSelectedDate(date: Date): void {
    this.selectedDate = date;
  }

  public setDisplayConnectHelper(displayConnectHelper: boolean): void {
    this.displayConnectHelper = displayConnectHelper;
  }

  public setDisplayConnectProvider(
    displayConnectProvider: NylasProvider
  ): void {
    this.displayConnectProvider = displayConnectProvider;
  }

  public setOpenCalendarModal(openCalendarModal: boolean): void {
    this.openCalendarModal = openCalendarModal;
  }

  public async fetchAttorneySetting(attorneyId: string): Promise<void> {
    const attorneySetting = await getAttorneySetting(attorneyId);
    if (attorneySetting?.id) {
      this.selectDayCategory = attorneySetting?.calendarDayCategory;
      this.selectDayOfWeek = attorneySetting?.calendarDayOfWeek;
    }
  }

  public async fetchNylasGoogleAccount(accessToken: string): Promise<void> {
    if (accessToken) {
      this.nylasGoogleAccount = await getNylasAccountByAccessToken(accessToken);
    }
  }

  public async fetchNylasICloudAccount(accessToken: string): Promise<void> {
    if (accessToken) {
      this.nylasICloudAccount = await getNylasAccountByAccessToken(accessToken);
    }
  }

  public async fetchNylasOutlookAccount(accessToken: string): Promise<void> {
    if (accessToken) {
      this.nylasOutlookAccount = await getNylasAccountByAccessToken(
        accessToken
      );
    }
  }
}

export default CalendarStore;
