import { useContext, useEffect } from "react";
import useStore from "utils/useStore";
import { UserContext } from "contexts";
import { getSessionStorage } from "utils/common/storage";
import { StorageKeys } from "constant/enum/storage";
import isUndefined from "lodash/isUndefined";
import { updateOnlineStatus } from "components/Sidebar/utils";

export function useDashboard() {
  const [userInfo] = useContext(UserContext);
  const { attorneyStore } = useStore();
  const attorneyUpdatedStatus: boolean = getSessionStorage(
    StorageKeys.ATTORNEY_UPDATE_STATUS
  );

  async function syncOnlineStatus(): Promise<void> {
    if (userInfo?.id) {
      attorneyStore.syncOnlineStatus(userInfo?.id);
    }
  }

  async function updateOnlineStatusWhenLogin(): Promise<void> {
    if (isUndefined(attorneyUpdatedStatus)) {
      await updateOnlineStatus(attorneyStore, userInfo.id, true);
    }
  }

  useEffect(() => {
    updateOnlineStatusWhenLogin();
  }, [attorneyUpdatedStatus, userInfo?.id]);

  useEffect(() => {
    syncOnlineStatus();
  }, [userInfo?.id]);
}
