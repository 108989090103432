import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "querystring";
import { toast } from "react-toastify";
import useStore from "utils/useStore";
import {
  handleOpenCustomerPortal,
  handleOpenDashboardSubscription,
} from "../../../../utils/stripe";
import {
  Container,
  SubscribeButton,
  SubscribeWrapper,
  OvalLoader,
} from "./subscriptionAction.styles";

const SubscriptionAction = () => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { subscriptionStore, attorneyStore } = useStore();

  async function onClickSubscription(): Promise<void> {
    if (loading) {
      return;
    }
    setLoading(true);
    if (subscriptionStore?.stripeAccountExisted) {
      await handleOpenCustomerPortal();
    }
    if (!subscriptionStore?.stripeAccountExisted) {
      handleOpenDashboardSubscription();
    }
    setLoading(false);
  }

  useEffect(() => {
    const params = queryString.parse(location.search.replace("?", ""));
    if (params?.["card-success"] === "true") {
      toast.success("Successfully connected Stripe");
      history.replace(location.pathname);
    }
  }, [location]);

  return (
    <Container>
      <SubscribeWrapper xl={12} lg={12} md={12} sm={12} xs={12}>
        <SubscribeButton
          isActive={subscriptionStore?.stripeAccountExisted}
          onClick={onClickSubscription}
        >
          {loading && (
            <OvalLoader type="Oval" color="black" height="12" width="12" />
          )}
          {subscriptionStore?.stripeAccountExisted
            ? "Manage Subscription"
            : "Start Subscription"}
        </SubscribeButton>
      </SubscribeWrapper>
    </Container>
  );
};

export default observer(SubscriptionAction);
