import { Paper, Grid } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { AudioWhiteGreen, TextWhiteGreen, VideoWhiteGreen } from "icons";
import styled from "styled-components";
import { LawAreas } from '../../../constant';
import { styledTheme } from '../../../styles/StyledTheme'

const moment = require('moment');

const StyledPaper = styled(Paper)`
  margin-top: 10px;
  position: relative;
  padding: 10px 15px;
  border-radius: 12px;
  background-color: ${ ({ theme }) => theme.colors.infoLight };
  .icons {
    position: absolute;
    left: -12px;
    img {
      width: 23px;
    }
  }
  .label {
    font-size: 10px;
    color: ${ ({ theme }) => theme.colors.infoDark };
  }
  .name {
    font-size: 11px;
  }
  .area {
    font-size: 12px;
    color: ${ ({ theme }) => theme.colors.defaultDarker };
  }
  .amount {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      font-size: 25px;
    }
  }
  .rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
const DetailedReview = ({
  consultationConnectionMethod,
  length,
  time,
  client,
  areaOfLaw,
  legalAdviceDescription,
  ratings = { overallScore: 0, knowledgeScore: 0, friendlinessScore: 0 },
}) => {

  return (
    <StyledPaper className="w-100">
      <Grid item container xs={12} justify="center">
        <Grid item xs={6}>
          <div className="icons">
            {consultationConnectionMethod === "Voice" && (
              <img src={AudioWhiteGreen} alt="voice" />
            )}
            {consultationConnectionMethod === "Text" && (
              <img src={TextWhiteGreen} alt="text" />
            )}
            {consultationConnectionMethod === "Video" && (
              <img src={VideoWhiteGreen} alt="video" />
            )}
          </div>
          <Grid item xs={12} className="label">
            <label>
              <b>{`${consultationConnectionMethod.toUpperCase()} ${length}`}</b>{" "}
              | {moment(time).format('MMMM DD YYYY, h:mm:ss a')}
            </label>
          </Grid>
          <Grid item xs={12} className="name">
            <b>
              {client.firstName} {client.lastName}
            </b>
          </Grid>
          <Grid item xs={12} className="area">
            {LawAreas[areaOfLaw]} | {legalAdviceDescription}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12} className="rating">
            <span
              style={{ fontSize: "8px", color: styledTheme.colors.infoDark, fontWeight: "bold" }}
            >
              {" "}
              OVERALL{" "}
            </span>
            <Rating
              style={{ fontSize: "17px", color: styledTheme.colors.secondary }}
              name="read-only"
              value={ratings.overallScore}
              precision={0.1}
              readOnly
            />
          </Grid>
          <Grid item xs={12} className="rating">
            <span
              style={{ fontSize: "8px", color: styledTheme.colors.infoDark, fontWeight: "bold" }}
            >
              KNOWLEDGE
            </span>
            <Rating
              style={{ fontSize: "17px", color: styledTheme.colors.secondary }}
              name="read-only"
              value={ratings.knowledgeScore}
              precision={0.1}
              readOnly
            />
          </Grid>
          <Grid item xs={12} className="rating">
            <span
              style={{ fontSize: "8px", color: styledTheme.colors.infoDark, fontWeight: "bold" }}
            >
              COMMUNICATION
            </span>
            <Rating
              style={{ fontSize: "17px", color: styledTheme.colors.secondary }}
              name="read-only"
              value={ratings.friendlinessScore}
              precision={0.1}
              readOnly
            />
          </Grid>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default DetailedReview;
