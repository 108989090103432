import { api, auth } from "./index";

export async function checkStripeAccountExisted(): Promise<{
  isExisted: boolean;
}> {
  try {
    const response = await api.get(`/stripe/retrieve/existed`, {
      headers: await auth(),
    });
    return response.data;
  } catch (error) {
    return { isExisted: false };
  }
}

export async function getCustomerPortalSession(): Promise<{
  url: string;
}> {
  try {
    const response = await api.get(`/stripe/create-customer-portal-session`, {
      headers: await auth(),
    });
    return response.data;
  } catch (error) {
    return { url: "#" };
  }
}
