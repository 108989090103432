import { errorHandler } from "utils/errorHandler";
import { api } from "API";
import { AxiosResponse } from "axios";

export async function createEventTrigger(
  channel: string,
  event: string,
  metadata: unknown
): Promise<void> {
  if (!channel || !event) {
    return;
  }

  try {
    const response: AxiosResponse = await api.post(`/event-trigger`, {
      channel,
      event,
      metadata,
    });
    return response.data;
  } catch (error) {
    errorHandler(error?.response?.data?.error);
    return error?.response?.data?.error;
  }
}
