import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { breakpoints } from "styles/layout";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

export const Header = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: ${styledTheme.colors.primaryBlack};

  margin-top: 16px;
  margin-bottom: 8px;

  ${breakpoints("flex-direction", [
    {
      xxl: "row",
    },
    {
      xl: "row",
    },
    {
      lg: "column",
    },
    {
      md: "column",
    },
    {
      sm: "column",
    },
    {
      xs: "column",
    },
  ])}

  ${breakpoints("align-items", [
    {
      xxl: "center",
    },
    {
      xl: "center",
    },
    {
      lg: "flex-start",
    },
    {
      md: "flex-start",
    },
    {
      sm: "flex-start",
    },
    {
      xs: "flex-start",
    },
  ])}
`;

export const DayLabel = styled.div``;

export const TimeLabel = styled.div`
  display: flex;
  text-align: left;

  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${styledTheme.colors.primaryBlack};
`;
