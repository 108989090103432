import { Grid, makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    paddingLeft: 24,
    paddingTop: 24,
    paddingBottom: 24,
    borderRadius: 16,
    minWidth: 364,
  },
}));

export const Container = styled(Grid)``;
