export const ValidateStrategicPartner = /* GraphQL */ `
  mutation StrategicPartner($input: StrategicPartnerInput!) {
    strategicPartner(input: $input) {
      statusCode
      body
      error
    }
  }
`;

export const ListAttorneyConsultationRequests = /* GraphQL */ `
  query ListAttorneyConsultationRequests(
    $limit: Int
    $filter: ModelAttorneyConsultationRequestFilterInput
    $nextToken: String
  ) {
    listAttorneyConsultationRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        isTargetRequest
        requestedAttorneys
        createdAt
        updatedAt
        expiredTime
        status
        selectedAttorney {
          id
        }
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          futureConsultationDateTime
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
            profilePictureUrl
          }
          attorney {
            id
            firstName
            lastName
            avatarUrl
          }
          payment {
            id
            amount
            calculatedAttorneyAmount
          }
        }
        consultation {
          id
          connectionMethod
          dateTimeOccurred
          videoConnectionToken
          chatConnectionToken
          conversationTranscript
          status
          clientPrefferedConnectionMethod
          purchasedLength
          actualLength
          lastOnlineClient
          lastOnlineAttoney
          flow
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`;

export const UpdateAttorneyConsultationRequest = /* GraphQL */ `
  mutation UpdateAttorneyConsultationRequest(
    $input: UpdateAttorneyConsultationRequestInput!
  ) {
    updateAttorneyConsultationRequest(input: $input) {
      id
      createdAt
      expiredTime
      requestedAttorneys
      updatedAt
      status
    }
  }
`;

export const getAttorneyOnlineStatus = /* GraphQL */ `
  query GetAttorney($id: ID!) {
    getAttorney(id: $id) {
      id
      isOnline
    }
  }
`;
