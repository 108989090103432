import { getCustomerPortalSession } from "API/stripe";
import { REACT_APP_STRIPE_DASHBOARD_PAYMENT_URL } from "config";

export function handleOpenDashboardSubscription(): void {
  const stripePaymentUrl = REACT_APP_STRIPE_DASHBOARD_PAYMENT_URL ?? "#";
  window.location.href = stripePaymentUrl;
}

export async function handleOpenCustomerPortal(): Promise<void> {
  const { url } = await getCustomerPortalSession();
  window.open(url);
}
