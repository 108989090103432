import React from 'react'
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Snackbar, CircularProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import { confirmSignUp, resendConfirmationCode } from 'services/auth-service'
import { GStyledSpan } from '../../../components/common/StyledComponents'

const VerificationCodeDialog = ({
  open, 
  email, 
  firstName,
  lastName,
  onVerificationCompleted, 
  setErrorMsg = null, 
  resendCode = false, 
  onClose= () => {}
}) => {
  const [code, setCode] = React.useState('')
  const [error, setError] = React.useState(false)
  const [errMsg, setErrMsg] = React.useState('')
  const [status, setStatus] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    try {
      if(resendCode && open) {
        resendConfirmationCode(email, firstName, lastName)
      }
    } catch (e) {
      setErrMsg(e.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendCode, open])

  const handleSubmit = async () => {
    if(code === '') {
      setError(true)
      return
    }
    setLoading(true)
    try {
      await confirmSignUp(email, code)
      onVerificationCompleted()
    } catch(e) {
      setErrMsg(e.message)
      if(setErrorMsg){
        setErrorMsg(e.message)
      }
    }
    finally{
      setLoading(false)
    }
  }

  const handleResend = async () => {
    setStatus('sending..');
    await resendConfirmationCode(email, firstName, lastName)
    setStatus('code sent!');
    setTimeout(() => {
      setStatus('');
    }, 3000)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Snackbar anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }} open={errMsg !== ''} autoHideDuration={6000} onClose={e=>setErrMsg('')}>
        <Alert onClose={e=>setErrMsg('')} severity="error">
          {errMsg}
        </Alert>
      </Snackbar>
      <DialogTitle id="form-dialog-title">Verification Code</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A verification code has been sent to your email. Please enter it here.
        </DialogContentText>
        <TextField
          error={error}
          autoFocus
          margin="dense"
          id="varification-code"
          label="Verification Code"
          type="text"
          onChange={e => {setError(false);setCode(e.target.value)}}
          fullWidth
          onKeyPress={ e =>  e.key === 'Enter' &&  handleSubmit() }      
        />
      </DialogContent>
      <DialogActions style={{alignItems: 'center'}}>
        {
          status && <GStyledSpan fontSize= '14px' color= 'gray'>{status}</GStyledSpan>
        }
        {
          loading ?
            <Grid
            container
            justify="flex-end"
            alignItems="center"
            style={{minHeight: 36}}
            >
              <CircularProgress size={24}/>
            </Grid>
          :
          <>
            <Button onClick={handleResend} color="primary" disabled={!!status}>
              Resend Code
            </Button>
            <Button onClick={handleSubmit} color="primary" variant="contained">
              Submit
            </Button>
          </>
        }
        
      </DialogActions>
    </Dialog>
  )
}

export default VerificationCodeDialog