import { api } from "API";
import { DateTimeScheduledRequestBy } from "constant/enum";

export async function attorneyCancelFutureConsultation(
  consultationId: string,
  caseId: string,
  attorneyId: string
): Promise<void> {
  await api.post(`/consultations/future-consultation/attorney-cancel`, {
    attorneyId: attorneyId,
    consultationId: consultationId,
    caseId: caseId,
  });
}

export async function submitRescheduleFutureConsultation(
  futureConsultationId: string,
  dateTimeScheduledRequest: Date,
  dateTimeScheduledRequestBy: DateTimeScheduledRequestBy
): Promise<void> {
  await api.post(`/consultations/future-consultation/submit-reschedule`, {
    futureConsultationId: futureConsultationId,
    dateTimeScheduledRequest: dateTimeScheduledRequest,
    dateTimeScheduledRequestBy: dateTimeScheduledRequestBy,
  });
}

export async function acceptRescheduleFutureConsultation(
  futureConsultationId: string
): Promise<void> {
  await api.post(`/consultations/future-consultation/accept-reschedule`, {
    futureConsultationId: futureConsultationId,
  });
}

export async function rejectRescheduleFutureConsultation(
  futureConsultationId: string
): Promise<void> {
  await api.post(`/consultations/future-consultation/reject-reschedule`, {
    futureConsultationId: futureConsultationId,
  });
}
