import styled from "styled-components";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

export const PaperWrapper = styled(Paper)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
  padding: 20px 0;
  min-width: 100%;
  min-height: 710px;
`;

export const CommonText = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.defaultDarker};
  padding-bottom: 10px;
`;

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 0,
    borderRadius: 16,
    maxWidth: 640,
  },
}));
