import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import Amplify from 'aws-amplify'
import AWSConfig from 'aws-exports-helper'
Amplify.configure(AWSConfig)
import 'styles/index.css'

ReactDOM.render(
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>,
  document.getElementById('root')
)
