import { useEffect, useContext } from "react";
import { UserContext } from "contexts";
import { PusherChannel } from "constant/enum";
import PusherService from "utils/pusher";
import useStore from "utils/useStore";

const pusherService = new PusherService();
export function useActiveRequestSubscription() {
  const [userInfo] = useContext(UserContext);
  const { consultationStore } = useStore();

  useEffect(() => {
    const activeRequestChannel = pusherService
      .getPusher()
      .subscribe(PusherChannel.CLIENT_UPDATE_ACTIVE_REQUEST);

    if (userInfo?.id) {
      pusherService.bindWithChunking(activeRequestChannel, userInfo.id, () => {
        consultationStore.fetchActiveRequestsByAttorneyId(userInfo.id);
      });
    }

    return () => {
      activeRequestChannel.unsubscribe();
    };
  }, [userInfo?.id]);
}
