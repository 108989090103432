import Icon from "components/common/Icon";
import styled from "styled-components";

export const FlexWrapper = styled.div`
  .topContent {
    text-align: center;
    .logoSection {
      padding-top: 16px;

      .companyLogo {
        padding-bottom: 8px;
      }

      h6.title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: white;
      }
    }
  }
`;

export const ProgressBlock = styled.div`
  position: relative;
`;

export const BackIcon = styled(Icon)`
  top: 5px;
  position: absolute;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }
`;
