import { Container, AddIcon, Label } from "./addTag.styles";

interface IAddTagProps {
  onClick: () => void;
}

const AddTag = (props: IAddTagProps) => {
  const { onClick } = props;

  return (
    <Container onClick={onClick}>
      <AddIcon iconName="fluent_add-circle-32-regular.svg" />
      <Label>Add new</Label>
    </Container>
  );
};

export default AddTag;
