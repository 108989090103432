import React, { useState, useEffect } from "react";
import Alert from '@material-ui/lab/Alert';
import styled from 'styled-components';
import { GStyledH3 } from 'components/common/StyledComponents'
import { styledTheme } from 'styles/StyledTheme'

const StyledAlert = styled(Alert)`
  justify-content: center;
  ${() => `background-color: ${ styledTheme.colors.white}`};
`

const GraphDiv = styled.div`
  box-sizing: border-box;
  width: 90%;
  padding: 2px;
  padding-left: 10;
  margin: 30px 0;
  ${() => `border: 1px solid ${styledTheme.colors.secondaryDarker}`};
  border-radius: 5px;
`

const Graph = styled.div`
  height: 32px; 
  background-image: linear-gradient(
    90deg,
    #6c4 45%,
    transparent 45%,
    transparent 50%,
    #6c4 50%,
    #6c4 95%,
    transparent 95%,
    transparent);
    background-size: 40px 40px;
`


const StyledP = styled.p`
  font-size: 12px;
  color: ${ () => styledTheme.colors.defaultDarker };
  padding-bottom: 10px;
`
let isStop = false
let audioContext =  null;

const CheckAudio = ({ setModalLoad }) => {
  const [audioStatus, setAudioStatus] = useState('')
  const [graphWidth, setGraphWidth] = useState(0)
  if (audioContext === null){
    audioContext = new (window.AudioContext || window.webkitAudioContext)()
  }

  useEffect(() => {
    isStop = false
    getWaves()  
    return () => {
      isStop = true
      setModalLoad(false)
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
    
  const getWaves = () => {
    navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true
      }).then(stream => {
      window.persistAudioStream = stream;
      const audioContent = audioContext
      const audioStream = audioContent.createMediaStreamSource( stream );
      const analyser = audioContent.createAnalyser();
      audioStream.connect(analyser);
      analyser.fftSize = 1024;
      const frequencyArray = new Uint8Array(analyser.frequencyBinCount);

      const animation = () => {
        if (!isStop){
          requestAnimationFrame(animation);
          analyser.getByteFrequencyData(frequencyArray);
          let adjustedLength = 0
          for (var i = 0 ; i < 255; i++) {
            adjustedLength += frequencyArray[i]
          }
          setGraphWidth(((adjustedLength/256)*100)/256 +'%')
        } else {
          stream.getTracks().forEach( track => {
            track.stop()
          })
          // audioContent.close().then(() => {
          // }).catch(() => {
          // }) 
        }
      }
        
      animation()
    })
    .catch(error => {
      if ( error && error.message === "Permission denied"){ setAudioStatus('Your Microphone is blocked') }
      else if ( error && error.message === "Permission denied by system") { setAudioStatus('Your Browser might not have access to your microphone.')}
      /* eslint eqeqeq: 0 */
      else if (error && error == 'NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.'){ setAudioStatus('Your Microphone is blocked') }
      else if (error) { setAudioStatus('Please check your microphone first') }
    });
  }

  return ( 
      <>
        { audioStatus && <StyledAlert severity="error">{ audioStatus }</StyledAlert> }
        <div style={{height: '35%'}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <GraphDiv>
              <Graph style={{ width: `${graphWidth}` }}/>
            </GraphDiv>
          </div>   
        </div>  
        <div style={{ textAlign: 'center' }}>
          <GStyledH3 
            fontSize= '22px'
            fontWeight= 'bold'
          >
            Say something
          </GStyledH3> 
          <StyledP>For example: <strong>I'm just testing my audio</strong></StyledP>
        </div>       
      </>
  );
};

export default CheckAudio;