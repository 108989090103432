import {
  attorneyCancelFutureConsultation,
  submitRescheduleFutureConsultation,
  acceptRescheduleFutureConsultation,
  rejectRescheduleFutureConsultation,
} from "API/consultation";
import ConsultationStore from "stores/consultationStore";
import { toast } from "react-toastify";
import { DateTimeScheduledRequestBy } from "constant/enum";

export async function handleCancelFutureConsultationRequest(
  consultationStore: ConsultationStore,
  setOpenCancelModal: (openCancelModal: boolean) => void,
  setIsLoading: (isLoading: boolean) => void,
  consultationId: string,
  caseId: string,
  attorneyId: string
): Promise<void> {
  setIsLoading(true);
  await attorneyCancelFutureConsultation(consultationId, caseId, attorneyId);
  consultationStore.fetchActiveRequestsByAttorneyId(attorneyId);
  consultationStore.setSelectedActiveRequestId(undefined);
  setIsLoading(false);
  setOpenCancelModal(false);
  toast.success("Successfully cancelled appointment");
}

export async function handleSubmitFutureConsultationSchedule(
  consultationStore: ConsultationStore,
  setIsLoading: (isLoading: boolean) => void,
  dateTimeScheduledRequest: Date
): Promise<void> {
  setIsLoading(true);
  if (!consultationStore?.selectedActiveRequestId) {
    toast.error("Error to reschedule appointment, please try again");
    return;
  }

  await submitRescheduleFutureConsultation(
    consultationStore.selectedActiveRequestId,
    dateTimeScheduledRequest,
    DateTimeScheduledRequestBy.ATTORNEY
  );

  setIsLoading(false);
  toast.success("Success to reschedule appointment");
}

export async function handleAcceptRescheduleConsultation(
  setIsLoading: (isLoading: boolean) => void,
  futureConsultationId: string
): Promise<void> {
  setIsLoading(true);
  await acceptRescheduleFutureConsultation(futureConsultationId);
  setIsLoading(false);
}

export async function handleRejectRescheduleConsultation(
  setIsLoading: (isLoading: boolean) => void,
  futureConsultationId: string
): Promise<void> {
  setIsLoading(true);
  await rejectRescheduleFutureConsultation(futureConsultationId);
  setIsLoading(false);
}
