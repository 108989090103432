import { useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { Header } from "components";
import FeaturedFlexRegistration from "pages/Registration/FeaturedFlexRegistration";
import { ReactTitle } from "react-meta-tags";
import { API, graphqlOperation } from "aws-amplify";
import { listAttorneys as ListAttorneys } from "gql/queries";
import { checkAuth } from "services/auth-service";
import { UserContext } from "contexts";
import { errorHandler } from "utils/errorHandler";
import { GraphQLParamsEnum } from "constant/enum";

const Registration = ({ location: { pathname } }) => {
  const [contextUserInfo, setContextUserInfo] = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (!Object.entries(contextUserInfo)?.length) {
      getData();
    }
  }, []);

  async function getData() {
    const user = await checkAuth(history);

    try {
      const attorney = await API.graphql(
        graphqlOperation(ListAttorneys, {
          limit: GraphQLParamsEnum.LIMIT_RESULT,
          filter: {
            cognitoId: {
              eq: user.id,
            },
          },
        })
      );
      if (
        attorney.data.listAttorneys.items &&
        attorney.data.listAttorneys.items.length > 0
      ) {
        setContextUserInfo({ ...attorney.data.listAttorneys.items[0] });
      }
    } catch (error) {
      errorHandler(error);
      history.push("/");
    }
  }

  return (
    <Grid container style={{ minHeight: "100vh" }}>
      <ReactTitle title="Registration - LegalQ Attorney" />
      <Header />
      <FeaturedFlexRegistration pathname={pathname} />
    </Grid>
  );
};

export default Registration;
