import React from 'react'
import { Header, Footer } from 'components'
import FeaturedFlex from 'pages/Landing/FeaturedFlex'
import FeaturedArticles from 'pages/Landing/FeaturedArticles'
import Grid from '@material-ui/core/Grid'
import {ReactTitle} from 'react-meta-tags';

const Landing = props => {
  return (
    <Grid container>
      <ReactTitle title="LegalQ Attorney"/>
      <Header />
      <FeaturedFlex />
      <FeaturedArticles />
      <Footer/>
    </Grid>
  )
}

export default Landing
