import { Container, CloseButton } from "./header.styles";

interface IHeaderProps {
  onClose: () => void;
}

const Header = (props: IHeaderProps) => {
  const { onClose } = props;
  return (
    <Container>
      <CloseButton onClick={onClose} iconName="eva_close-circle-outline.svg" />
    </Container>
  );
};

export default Header;
