import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import OverallRatings from "./OverallRatings";
import ChannelStat from "./ChannelStat";
import CustomHeading from "../../../components/common/CustomHeading";
import {
  getReviewCount,
  getRatingByChannel,
} from "../../../services/dashboardService";
import { GStyledGrid } from '../../../components/common/MaterialUIStyledComponents'

const HomeHeader = ({ cases }) => {
  const [textTypeCases, setTextTypeCases] = useState([]);
  const [audioTypeCases, setAudioTypeCases] = useState([]);
  const [videoTypeCases, setVideoTypeCases] = useState([]);

  useEffect(() => {
    setTextTypeCases(
      cases.filter((c) => c.ratings && c.consultationConnectionMethod.toLowerCase() === "text")
    );
    setAudioTypeCases(
      cases.filter((c) => c.ratings && c.consultationConnectionMethod.toLowerCase() === "voice")
    );
    setVideoTypeCases(
      cases.filter((c) => c.ratings && c.consultationConnectionMethod.toLowerCase() === "video")
    );
  }, [cases]);

  return (
    <Grid item container xs={12} justify="center">
      <Grid item xs={12} lg={4}>
        <Grid item xs={12} lg={10}>
          <OverallRatings cases={cases} />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={8}>
        <GStyledGrid item xs={12} paddingBottom= '10px'>
          <CustomHeading>CHANNEL STATS</CustomHeading>
        </GStyledGrid>
        <Grid className="pb-5" container spacing={6}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <ChannelStat
              reviewCount={getReviewCount(textTypeCases)}
              myRating={getRatingByChannel(textTypeCases)}
              icon="text"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <ChannelStat
              reviewCount={getReviewCount(audioTypeCases)}
              myRating={getRatingByChannel(audioTypeCases)}
              icon="audio"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <ChannelStat
              reviewCount={getReviewCount(videoTypeCases)}
              myRating={getRatingByChannel(videoTypeCases)}
              icon="video"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomeHeader;
