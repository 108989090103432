import React, { useState } from 'react'
import { styledTheme } from 'styles/StyledTheme'
import styled from "styled-components";

const ReadMoreLink = styled.span`
  cursor: pointer;
  font-weight: bold;
`;

const ReadMore = ({ text, color, textLength }) => {

  const [readMore, setReadMore] = useState(false)

  return(
    <>
      {text.length > textLength && !readMore && (
        <>
          {text.substr(0, (textLength-20))}{" "}
          <ReadMoreLink
            style={{ color: styledTheme.colors[color]}}
            onClick={(e) => {e.preventDefault()
              setReadMore(true)
            }}
          >
            Read more
          </ReadMoreLink>
        </>
      )}
      {readMore && (
        <>
          {text}{" "}
          <ReadMoreLink
            style={{ color: styledTheme.colors[color] }}
            onClick={(e) => {e.preventDefault()
              setReadMore(false)
            }}
          >
            Read less
          </ReadMoreLink>
        </>
      )}
      {text.length <= textLength && <>{text}</>}
    </>
  )
}

export default ReadMore