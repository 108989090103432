import React, { useEffect, useContext, useState } from 'react'
import { Grid } from '@material-ui/core'
import { PastInteractionCard } from 'components/common'
import CustomHeading from '../../../components/common/CustomHeading'
import { UserContext, ConsultationContext } from "contexts";
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { fetchPastInteractions } from '../../../services/consultationService';
import { GStyledDiv } from '../../../components/common/StyledComponents'

import * as moment from 'moment';

export const listCases = /* GraphQL */ `
  query ListCases(
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        futureConsultationDateTime
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
        }
        client {
          id
          firstName
          lastName
        }
        attorney {
          id
        }
        payment {
          id
        }
      }
    }
  }
`;


const StyledButton = styled(Button)`
  font-size:15px;
  background-color: ${ ({ theme }) => theme.colors.primary };
  box-shadow: 2px 25px 20px -18px rgba(40,126,199,1);
  margin-bottom: 20px;
`;

const ITEMS_PER_PAGE = 5;

const PastInteractions = () => {

  const [ userInfo ] = useContext(UserContext)
  const { pastCases, setPastCases } = useContext(ConsultationContext)
  const [ page, setPage ] = useState(1)

  useEffect(() => {
    if(userInfo.id){
      fetchData(userInfo.id);
    }
  }, [userInfo])

  async function fetchData(attorneyId) {
    const data = await fetchPastInteractions(attorneyId);
    const tmp = [];
    if(!data) return;
    for(let item of [...data.future, ...data.immediate]) {
      tmp.push({
        consultationConnectionMethod: item.case.consultationConnectionMethod,
        length: (item.consultation.actualLength/60) >= 1 ? (item.consultation.actualLength/60).toFixed(2) + ' minutes' : 'less than a minute',
        time: item.dateTimeScheduled || item.createdAt,
        client: item.case.client,
        areaOfLaw: item.case.areaOfLaw,
        legalAdviceDescription: item.case.legalAdviceDescription,
        ratings: item.case.rating,
        payment: item.case.payment
      })
    }
    setPastCases(tmp.sort((a,b) => moment(b.time) - moment(a.time)));
  }
  
  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <CustomHeading>Past Interactions</CustomHeading>
      </Grid>
      <Grid item xs={12}>
        {
          pastCases && pastCases.length > 0 &&
          <>
            {
              (pastCases.slice(0, ITEMS_PER_PAGE * page)).map((interaction, i) => {
                return <PastInteractionCard key={i} {...interaction} />
              })
            }
            {
              pastCases.length > page * ITEMS_PER_PAGE &&
              <div align="center">
                <br/>
                <StyledButton 
                  variant='contained' 
                  color='primary' 
                  onClick={() => setPage(page + 1)}
                >show more</StyledButton>
              </div>
            }
          </>
        }
        <>
          {
            (!pastCases || pastCases.length === 0) && 
            <GStyledDiv color= '#c7c7c7' textAlign="center" marginBottom='20px'>
              <br/>
              No interactions found
            </GStyledDiv>
          }
        </>
      </Grid>
    </Grid>
  );
};

export default PastInteractions;
