import { Grid, SvgIcon } from "@material-ui/core";
import CustomHeading from "components/common/CustomHeading";
import {
  PaperWrapper,
  BoxWrapper,
  LargeSpacing,
  StyledIcon
} from "./availableChannels.styles";
import { styledTheme } from "../../../../../styles/StyledTheme";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import {
  TextChannelActive,
  TextChannelInactive,
  VideoChannelActive,
  VideoChannelInactive,
  AudioChannelActive,
  AudioChannelInactive
} from "icons";

interface IAvailableChannelsProps {
  communicationMethodsPreference: unknown[];
  handleUpdateAttorney: ({ communicationMethodsPreference }) => void;
}

const useStyles = makeStyles({
  root: {},
  iconText: {
    backgroundColor: styledTheme.colors.secondaryDarker,
  },
  iconVoice: {
    backgroundColor: styledTheme.colors.secondaryDark,
  },
  iconVideo: {
    backgroundColor: styledTheme.colors.secondary,
  },
  inactive: {
    backgroundColor: "#F0F5F8", 
    borderColor: "#96ACB9 !important", 
    color: "#96ACB9 !important",
  }
});

const AvailableChannels = (props: IAvailableChannelsProps) => {
  const { communicationMethodsPreference = [], handleUpdateAttorney } = props;
  const text = communicationMethodsPreference.includes("Text");
  const voice = communicationMethodsPreference.includes("Voice");
  const video = communicationMethodsPreference.includes("Video");
  const classes = useStyles();

  function handleToggleCommunicationMethod(communicationMethod): void {
    const dupArr = [...communicationMethodsPreference];
    const index = dupArr.indexOf(communicationMethod);
    if (index > -1) {
      dupArr.splice(index, 1);
    } else {
      dupArr.push(communicationMethod);
    }
    handleUpdateAttorney({
      communicationMethodsPreference: dupArr,
    });
  }

  return (
    <Grid item lg={4} md={6} xs={12}>
      <CustomHeading style={{ marginBottom: "20px" }}>
        Available channels
      </CustomHeading>

      <PaperWrapper>
        <Grid>
          <LargeSpacing
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={text ? "selected" : ""}
            onClick={() => handleToggleCommunicationMethod("Text")}
          >
            <BoxWrapper
              className={[classes.iconText, !text && classes.inactive]}
            >
              <div className="icon">
                {text && (
                  <RemoveCircleOutlineRoundedIcon
                    style={{ color: styledTheme.colors.secondaryDarker }}
                  />
                )}
                {!text && (
                  <AddCircleOutlineRoundedIcon
                    style={{ color: styledTheme.colors.infoDark }}
                  />
                )}
              </div>
              <div className="text">
                <img className="channel-icon" src={text ? TextChannelActive : TextChannelInactive}/>
                {text ? 'TEXT' : 'TEXT OFF'}
              </div>
            </BoxWrapper>
          </LargeSpacing>
          <LargeSpacing
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={voice ? "selected" : ""}
            onClick={() => handleToggleCommunicationMethod("Voice")}
          >
            <BoxWrapper
              className={[classes.iconVoice, !voice && classes.inactive]}
            >
              <div className="icon">
                {voice && (
                  <RemoveCircleOutlineRoundedIcon
                    style={{ color: styledTheme.colors.secondaryDark }}
                  />
                )}
                {!voice && (
                  <AddCircleOutlineRoundedIcon
                    style={{ color: styledTheme.colors.infoDark }}
                  />
                )}
              </div>
              <div className="text">
                <img className="channel-icon" src={voice ? AudioChannelActive : AudioChannelInactive}/>
                {voice ? 'VOICE' : 'VOICE OFF'}
              </div>
            </BoxWrapper>
          </LargeSpacing>
          <LargeSpacing
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={video ? "selected" : ""}
            onClick={() => handleToggleCommunicationMethod("Video")}
          >
            <BoxWrapper
              className={[classes.iconVideo, !video && classes.inactive]}
            >
              <div className="icon">
                {video && (
                  <RemoveCircleOutlineRoundedIcon
                    style={{ color: styledTheme.colors.secondary }}
                  />
                )}
                {!video && (
                  <AddCircleOutlineRoundedIcon
                    style={{ color: styledTheme.colors.infoDark }}
                  />
                )}
              </div>
              <div className="text">
                <img className="channel-icon" src={video ? VideoChannelActive : VideoChannelInactive}/>
                {video ? 'VIDEO' : 'VIDEO OFF'}
              </div>
            </BoxWrapper>
          </LargeSpacing>
        </Grid>
      </PaperWrapper>
    </Grid>
  );
};

export default AvailableChannels;
