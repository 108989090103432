export const createUnSubscriptionSurvey = /* GraphQL */ `
  mutation CreateUnSubscriptionSurvey(
    $input: CreateUnSubscriptionSurveyInput!
    $condition: ModelUnSubscriptionSurveyConditionInput
  ) {
    createUnSubscriptionSurvey(input: $input, condition: $condition) {
      id
      reason
      otherReason
      createdAt
      updatedAt
    }
  }
`;
