export const createImmediateConsultation = /* GraphQL */ `
  mutation CreateImmediateConsultation(
    $input: CreateImmediateConsultationInput!
    $condition: ModelImmediateConsultationConditionInput
  ) {
    createImmediateConsultation(input: $input, condition: $condition) {
      id
      case {
        id
      }
      consultation {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      paymentMethod
      paymentStatus
      dateTime
      amount
      calculatedAttorneyAmount
      stripeToken
    }
  }
`;
export const updateAttorney = /* GraphQL */ `
  mutation UpdateAttorney(
    $input: UpdateAttorneyInput!
    $condition: ModelAttorneyConditionInput
  ) {
    updateAttorney(input: $input, condition: $condition) {
      id
      firstName
      lastName
      emailWork
      emailPersonal
      cellPhone
      avatarUrl
      firmAssociation
      statesOfLicense
      licenseNumber
      liabilityInsuranceProvider
      currentProfessionalResponsibilityInvestigations
      practiceAreas
      communicationMethodsPreference
      acceptsTCAndPP
      attorneyRating
      cognitoId
      stripeAccountId
      notificationMethods
      phoneNumber
      isOnline
      lastOnline
      phoneNumberUsageConsent
      approved
      createdAt
      updatedAt
    }
  }
`;
export const createFutureConsultation = /* GraphQL */ `
  mutation CreateFutureConsultation(
    $input: CreateFutureConsultationInput!
    $condition: ModelFutureConsultationConditionInput
  ) {
    createFutureConsultation(input: $input, condition: $condition) {
      id
      dateTimeScheduled
      case {
        id
        attorney {
          id
          firstName
          lastName
        }
      }
      consultation {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const getTwilioKeys = /* GraphQL */ `
  mutation GetTwilioKeys($input: GetTwilioKeysInput!) {
    getTwilioKeys(input: $input) {
      jwtToken
    }
  }
`;
export const captureStripePayment = /* GraphQL */ `
  mutation CaptureStripePayment($input: PaymentCaptureInput) {
    captureStripePayment(input: $input) {
      statusCode
      error
    }
  }
`;
export const managePayment = /* GraphQL */ `
  mutation ManagePayment($input: ManagePaymentInput!) {
    managePayment(input: $input) {
      statusCode
      body
      error
    }
  }
`;
export const attorneySubscription = /* GraphQL */ `
  mutation AttorneySubscription($input: AttorneySubscriptionInput!) {
    attorneySubscription(input: $input) {
      statusCode
      body
      error
    }
  }
`;
export const sendConsultationSummaryEmail = /* GraphQL */ `
  mutation SendConsultationSummaryEmail(
    $input: SendConsultationSummaryEmailInput!
  ) {
    sendConsultationSummaryEmail(input: $input) {
      statusCode
      body
      error
    }
  }
`;
