import { Container, BodyText, TitleText } from "./messageSection.styles";

interface IMessageSectionProps {
  titleText: string;
  bodyText: string;
}

const MessageSection = (props: IMessageSectionProps) => {
  const { titleText, bodyText } = props;

  return (
    <Container>
      <TitleText>{titleText}</TitleText>
      <BodyText>{bodyText}</BodyText>
    </Container>
  );
};

export default MessageSection;
