import Icon from "components/common/Icon";

export enum ProgressBarState {
  STEP_ONE = 1,
  STEP_TWO = 2,
  STEP_THREE = 3,
  LAST = 4,
}

interface IProgressBarProps {
  state: ProgressBarState;
  width: number;
  height: number;
}

const ProgressBar = (props: IProgressBarProps) => {
  const { state, width, height } = props;

  return (
    <div>
      {state === ProgressBarState.STEP_ONE && (
        <Icon width={width} height={height} iconName="progress_state-1.svg" />
      )}
      {state === ProgressBarState.STEP_TWO && (
        <Icon width={width} height={height} iconName="progress_state-2.svg" />
      )}
      {state === ProgressBarState.STEP_THREE && (
        <Icon width={width} height={height} iconName="progress_state-3.svg" />
      )}
    </div>
  );
};

export default ProgressBar;
