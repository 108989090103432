import Icon from "../../Icon";
import { Container, IconWrapper, LabelWrapper } from "./buttonWithIcon.styles";

interface IButtonWithIconProps {
  label?: string;
  iconName?: string;
  inActiveIconName?: string;
  backgroundColor?: string;
  borderColor?: string;
  inActiveBorderColor?: string;
  color?: string;
  inActiveColor?: string;
  iconWidth?: string;
  onClick?: () => void;
  isActive?: boolean;
}

const ButtonWithIcon = (props: IButtonWithIconProps) => {
  const {
    isActive,
    label,
    iconName,
    inActiveIconName,
    backgroundColor,
    borderColor,
    inActiveBorderColor,
    color,
    inActiveColor,
    iconWidth,
    onClick,
  } = props;

  return (
    <Container
      onClick={onClick}
      isActive={isActive}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      inActiveBorderColor={inActiveBorderColor}
    >
      <IconWrapper iconWidth={iconWidth}>
        <Icon iconName={isActive ? iconName : inActiveIconName} />
      </IconWrapper>
      <LabelWrapper color={isActive ? color : inActiveColor}>
        {label}
      </LabelWrapper>
    </Container>
  );
};

export default ButtonWithIcon;
