import { API, graphqlOperation } from "aws-amplify";
import { getBriefFutureConsultation, getBriefImmediateConsultation } from "../gql/queries";
import * as moment from 'moment';
import { errorHandler } from "utils/errorHandler";
import { GraphQLParamsEnum } from "constant/enum/common";
import { listFutureConsultations, listImmediateConsultations } from '../gql/consultation';
import { FutureConsultationStatus } from "constant/enum";

// Verifying technology support
const Notification = window.Notification;

export const showNotification = () => {
  // Checking if the permission was given
  if (Notification.permission !== 'granted') {
    // In not, asks again for permission
    Notification.requestPermission(function(permission) {
      if (permission === "granted")
        Notification.showNotification();
    });
  }
  else {
    // Otherwise, creates the notification and sends it
    // eslint-disable-next-line
    var n = new Notification('LegalQ', { 
      body: 'New appointment requested!',
      icon: require('images/circle-logo.png')
		}); 
  }
}

if (Notification) {
  console.log('Your browser supports Notifications');
  Notification.requestPermission((e) => {
    //console.log(e)
  });
} else {
  console.log("Your browser doesn't support Notifications");
}

const isActive = ({status}) => {
  return status === 'Scheduled' || status === 'InProgress' || status === 'Ready' || status === 'Paused'
}

export let newRequestCallback = () => {};

export async function fetchActiveRequests(attorneyId){
  try {
    const response = await Promise.all([
      API.graphql(
        graphqlOperation(listImmediateConsultations, {
          limit: GraphQLParamsEnum.LIMIT_RESULT,
          filter: {
            attorneyId: {
              eq: attorneyId
            }
          }
        })
      ),
      API.graphql(
        graphqlOperation(listFutureConsultations, {
          limit: GraphQLParamsEnum.LIMIT_RESULT,
          filter: {
            attorneyId: {
              eq: attorneyId
            },
            status: {
              ne: FutureConsultationStatus.CANCELED
            }
          }
        })
      )],
    );
    const { listImmediateConsultations: {items: immediate }} = response[0].data;
    const { listFutureConsultations: {items: future} } = response[1].data;

    return {
      future: future.filter(item => isActive(item.consultation)),
      immediate:immediate.filter(item => isActive(item.consultation))
    };
  }
  catch(error) {
    errorHandler(error)
    return null;
  }

}

export async function fetchRatings(attorneyId) {
  try {
    const response = await Promise.all([
      API.graphql(
        graphqlOperation(listImmediateConsultations, {
          limit: GraphQLParamsEnum.LIMIT_RESULT,
          filter: {
            attorneyId: {
              eq: attorneyId
            }
          }
        })
      ),
      API.graphql(
        graphqlOperation(listFutureConsultations, {
          limit: GraphQLParamsEnum.LIMIT_RESULT,
          filter: {
            attorneyId: {
              eq: attorneyId
            }
          }
        })
      )],
    );

    const { listImmediateConsultations: {items: immediate }} = response[0].data;
    const { listFutureConsultations: {items: future} } = response[1].data;

    const data = {
      future: future.filter(item => item.consultation.status === 'Completed'),
      immediate: immediate.filter(item => item.consultation.status === 'Completed')
    };

    const tmp = [];
    for(let item of [...data.future, ...data.immediate]) {
      tmp.push({
        id: item.id,
        consultationConnectionMethod: item.case.consultationConnectionMethod,
        length: item.consultation.actualLength/60 >= 1 ? (item.consultation.actualLength/60).toFixed(2) + ' min(s)' : 'less than a minute',
        time: item.dateTimeScheduled || item.createdAt,
        client: item.case.client,
        areaOfLaw: item.case.areaOfLaw,
        legalAdviceDescription: item.case.legalAdviceDescription,
        ratings: item.case.rating,
        createdAt: item.case.rating ? item.case.rating.createdAt : item.case.createdAt
      })
    }

    return tmp.sort((a,b) => moment(b.time) - moment(a.time));
  }
  catch(error) {
    errorHandler(error);
    return [];
  }
}

// for home
export async function fetchMixData(attorneyId){
  try {
    const response = await Promise.all([
      API.graphql(
        graphqlOperation(listImmediateConsultations, {
          limit: GraphQLParamsEnum.LIMIT_RESULT,
          filter: {
            attorneyId: {
              eq: attorneyId
            }
          }
        })
      ),
      API.graphql(
        graphqlOperation(listFutureConsultations, {
          limit: GraphQLParamsEnum.LIMIT_RESULT,
          filter: {
            attorneyId: {
              eq: attorneyId
            }
          }
        })
      )],
    );

    const { listImmediateConsultations: {items: immediate }} = response[0].data;
    const { listFutureConsultations: {items: future} } = response[1].data;

    const data = {
      future: future.filter(item => item.case.payment && item.consultation.status === 'Completed'),
      immediate: immediate.filter(item => item.case.payment && item.consultation.status === 'Completed')
    };

    const tmp = [];
    for(let item of [...data.future, ...data.immediate]) {
      tmp.push({
        id: item.id,
        consultationConnectionMethod: item.case.consultationConnectionMethod,
        length: item.consultation.actualLength/60 >= 1 ? (item.consultation.actualLength/60).toFixed(2) + ' min(s)' : 'less than a minute',
        time: item.dateTimeScheduled || item.createdAt,
        client: item.case.client,
        areaOfLaw: item.case.areaOfLaw,
        legalAdviceDescription: item.case.legalAdviceDescription,
        createdAt: item.dateTimeScheduled || item.createdAt,
        payment: item.case.payment,
        consultation: item.consultation,
        ratings: item.case.rating
      })
    }
    
    return tmp.sort((a,b) => moment(b.time) - moment(a.time));
  }
  catch(error) {
    errorHandler(error);
    return [];
  }
}

export async function fetchPastInteractions(attorneyId) {
  try {
    const response = await Promise.all([
      API.graphql(
        graphqlOperation(listImmediateConsultations, {
          limit: GraphQLParamsEnum.LIMIT_RESULT,
          filter: {
            attorneyId: {
              eq: attorneyId
            }
          }
        })
      ),
      API.graphql(
        graphqlOperation(listFutureConsultations, {
          limit: GraphQLParamsEnum.LIMIT_RESULT,
          filter: {
            attorneyId: {
              eq: attorneyId
            }
          }
        })
      )],
    );

    const { listImmediateConsultations: {items: immediate }} = response[0].data;
    const { listFutureConsultations: {items: future} } = response[1].data;


    return {
      future: future.filter(item => item.consultation.status === 'Completed'),
      immediate: immediate.filter(item => item.consultation.status === 'Completed')
    };
  }
  catch(error) {
    errorHandler(error);
    return null;
  }

}

export const fetchFutureConsultation = async (id) => {
  try {
    const response = await API.graphql(
      graphqlOperation(getBriefFutureConsultation, {
        id
      })
    );

    return response.data.getFutureConsultation;
  }
  catch(error) {
    errorHandler(error);
    return null;
  }
}

export const fetchImmediateConsultation = async (id) => {
  try {
    const response = await API.graphql(
      graphqlOperation(getBriefImmediateConsultation, {
        id
      })
    );

    return response.data.getImmediateConsultation;
  }
  catch(error) {
    errorHandler(error);
    return null;
  }
}

export const addToStorage = (request) => {
  showNotification();
}

export const retrieveFromStorage = () => {
  let existing = localStorage.getItem('rqs');
  return existing? JSON.parse(existing): null;
}

export const clearStorage = (index) => {
  if (index === 'logout'){
    localStorage.removeItem('rqs')
  } else {
    const local = JSON.parse(localStorage.rqs)
    const filterd = local.filter( obj => obj.id !== local[index].id)
    localStorage.removeItem('rqs')
    localStorage.setItem('rqs', JSON.stringify(filterd));
  }
}
