export enum NylasProvider {
  GMAIL = "gmail",
  ICLOUD = "icloud",
  OUTLOOK = "outlook",
}

export enum NylasStateSync {
  RUNNING = "running",
  STOPPED = "stopped",
  INVALID = "invalid",
  PARTIAL = "partial",
}

export enum ConnectButtonProvider {
  GOOGLE = "google",
  APPLE = "apple",
  MICROSOFT = "microsoft",
}
