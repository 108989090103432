import React from "react";

interface IImageProps {
  imageName?: string;
  width?: number;
  height?: number;
  ref?: React.Ref<HTMLDivElement>;
  alt?: string;
  source?: string;
}

const Image = (props: IImageProps) => {
  const { imageName, width, height, alt, source, ...rest } = props;
  const imageUrl = source ?? `/assets/images/${imageName}`;

  return (
    <div {...rest}>
      <img src={imageUrl} width={width} height={height} alt={alt} />
    </div>
  );
};

export default Image;
