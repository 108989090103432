import { useContext } from "react";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { AppointmentRequestedCard } from "components/common";
import CustomHeading from "../../../components/common/CustomHeading";
import { UserContext, ConsultationContext } from "contexts";
import { acceptConsultationRequest as acceptConsultationRequestAPI, updateAttorneyConsultationRequestById } from '../../../API/consultationRequest'
import { GStyledGrid } from "../../../components/common/MaterialUIStyledComponents";
import {
  createTwilioConversationClient,
  connectTwilioVideoRoom,
  connectTwilioAudioRoom,
} from "services/twilioService";
import { observer } from "mobx-react";
import { updateAttorneyConsultationRequestAttorneyById } from "API/consultationRequest";
import { AttorneyConsultationRequestStatus, PusherChannel } from "constant/enum";
import useStore from "utils/useStore";
import { getAttorneyConsultationAttorneyId, syncConsultationRequests, getAttorneyConsultationRequest } from "./utils";
import { errorHandler } from "utils/errorHandler";
import { createEventTrigger } from "API/eventTrigger";

const AppointmentsRequested = ({
  setText,
  setVideo,
  setAudio,
  setRoom,
  setConversationClient,
  setConsultationLoading,
  isConsultationDisabled,
}) => {
  const [userInfo] = useContext(UserContext);
  const {
    setActiveCase,
    setActiveConsultation,
    setToast,
  } = useContext(ConsultationContext);
  const { attorneyStore, consultationStore } = useStore();
  const { activeRequests } = consultationStore;
  
  async function handleOnDeny(caseDetails) {
    setConsultationLoading(true);
    const foundAttorneyConsultationId = getAttorneyConsultationAttorneyId(attorneyStore, caseDetails?.requestId)
    const foundAttorneyConsultation = getAttorneyConsultationRequest(attorneyStore, caseDetails?.requestId)
    if (foundAttorneyConsultationId) {
      if (foundAttorneyConsultation?.isTargetRequest && foundAttorneyConsultation?.id) {
        const fullAttorneyConsultation = {
          ...foundAttorneyConsultation,
          case: {
            ...foundAttorneyConsultation.case,
            attorney: userInfo
          },
          status: AttorneyConsultationRequestStatus.REJECT
        }
        await Promise.all([
          updateAttorneyConsultationRequestById(foundAttorneyConsultation.id, { status: AttorneyConsultationRequestStatus.REJECT }),
          createEventTrigger(PusherChannel.ATTORNEY_REJECT_CONSULTATION_REQUEST, caseDetails?.client?.id, fullAttorneyConsultation)
        ])
      }
      await updateAttorneyConsultationRequestAttorneyById(foundAttorneyConsultationId, {
        status: AttorneyConsultationRequestStatus.REJECT
      })
    }
    syncConsultationRequests(attorneyStore, consultationStore, userInfo)
    setConsultationLoading(false);
  }
  async function handleOnAccept(caseDetails) {
    setConsultationLoading(true);
    try {
      if (!caseDetails.isFuture) {
        // SET ACTIVE CASE
        setActiveCase({
          type: caseDetails.consultationConnectionMethod
            ? caseDetails.consultationConnectionMethod.toLowerCase()
            : "text",
          areaOfLaw: caseDetails.areaOfLaw,
          description: caseDetails.legalAdviceDescription,
          state: caseDetails.venueState,
          client: caseDetails.client,
          status: "ready",
          text: caseDetails.legalAdviceDescription,
          payment: caseDetails.payment,
          id: caseDetails.id,
        });
      }

      let createdTypedConsultation = {}

      try {
        createdTypedConsultation = await acceptConsultationRequestAPI(userInfo.id, caseDetails?.client?.id, userInfo.cognitoId, caseDetails);
      } catch (error) {
        setActiveCase(null);
        handleOnDeny(caseDetails.requestId);
        setToast(error.message ?? "Appointment has been accepted by another attorney");
        setConsultationLoading(false);
        return;
      }

      // SET ACTIVE CONSULTATION
      const consultation = caseDetails.consultation;
      setActiveConsultation(consultation);
      // SET ACTIVE REQUESTS
      const updatedAt =
        createdTypedConsultation.data[
          caseDetails.isFuture
            ? "createFutureConsultation"
            : "createImmediateConsultation"
        ].updatedAt;

      consultationStore.setActiveRequests([
        ...activeRequests,
        {
          type: caseDetails.consultationConnectionMethod
            ? caseDetails.consultationConnectionMethod.toLowerCase()
            : "Text",
          updatedAt,
          time: caseDetails.futureConsultationDateTime,
          areaOfLaw: caseDetails.areaOfLaw,
          venueState: caseDetails.venueState,
          firstName: caseDetails.client.firstName,
          lastName: caseDetails.client.lastName,
          status: caseDetails.isFuture ? "Scheduled" : "in progress",
          timing: caseDetails.isFuture ? "future" : "immediate",
          text: caseDetails.legalAdviceDescription,
          case: caseDetails,
          consultation,
          ...createdTypedConsultation.data[
          caseDetails.isFuture
            ? "createFutureConsultation"
            : "createImmediateConsultation"
          ],
        },
      ]);

      if (caseDetails.isFuture) {
        setConsultationLoading(false);
        return;
      }

      // SET ACTIVE CASE
      setActiveCase({
        type: caseDetails.consultationConnectionMethod
          ? caseDetails.consultationConnectionMethod.toLowerCase()
          : "text",
        updatedAt,
        areaOfLaw: caseDetails.areaOfLaw,
        description: caseDetails.legalAdviceDescription,
        state: caseDetails.venueState,
        client: caseDetails.client,
        status: "ready",
        text: caseDetails.legalAdviceDescription,
        payment: caseDetails.payment,
        id: caseDetails.id,
        timeLimit: consultation.purchasedLength,
        consultation,
      });
      // CREATE DIALOG
      // Twilio
      if (
        caseDetails.consultationConnectionMethod &&
        caseDetails.consultationConnectionMethod.toLowerCase() === "text"
      ) {
        const conversationClient = await createTwilioConversationClient(
          userInfo.id
        );
        setText(true);
        setConversationClient(conversationClient);
      } else if (
        caseDetails.consultationConnectionMethod &&
        (caseDetails.consultationConnectionMethod.toLowerCase() === "audio" ||
          caseDetails.consultationConnectionMethod.toLowerCase() === "voice")
      ) {
        const room = await connectTwilioAudioRoom(consultation.id, userInfo.id);
        setAudio(true);
        setRoom(room);
      } else if (
        caseDetails.consultationConnectionMethod &&
        caseDetails.consultationConnectionMethod.toLowerCase() === "video"
      ) {
        const room = await connectTwilioVideoRoom(consultation.id, userInfo.id);
        setVideo(true);
        setRoom(room);
      } else {
        const conversationClient = await createTwilioConversationClient(
          consultation.id,
          userInfo.id
        );
        setText(true);
        setConversationClient(conversationClient);
      }
    } catch (error) {
      errorHandler(error);
    }
    syncConsultationRequests(attorneyStore, consultationStore, userInfo);
    setConsultationLoading(false);
  }
 
  if (!consultationStore.pendingCases || consultationStore.pendingCases.length === 0) return <></>;

  return (
    <Grid item xs={12}>
      <GStyledGrid item xs={12} paddingBottom="10px">
        <CustomHeading>Appointments Requested</CustomHeading>
      </GStyledGrid>
      <GStyledGrid item xs={12} paddingBottom="30px">
        {Array.isArray(consultationStore.pendingCases) &&
          consultationStore.pendingCases
            .map((caseDetails, index) => (
              <AppointmentRequestedCard
                key={index}
                type={
                  caseDetails.consultationConnectionMethod
                    ? caseDetails.consultationConnectionMethod
                    : "text"
                }
                time={moment(caseDetails.futureConsultationDateTime).format(
                  "MMMM DD YYYY, h:mm a"
                )}
                name={`${caseDetails.client?.firstName} ${caseDetails.client?.lastName}`}
                area={`${caseDetails.areaOfLaw}`}
                caseType={caseDetails.venueState}
                text={caseDetails.legalAdviceDescription}
                isFuture={caseDetails.isFuture}
                consultation={caseDetails.consultation}
                expiredTime={caseDetails?.expiredTime}
                handleOnAccept={() => handleOnAccept(caseDetails)}
                handleOnDeny={() => handleOnDeny(caseDetails)}
                isConsultationDisabled={isConsultationDisabled}
                attorneyConsultationRequestId={caseDetails?.requestId}
              />
            )
            )}
      </GStyledGrid>
    </Grid>
  );
};

export default observer(AppointmentsRequested);
