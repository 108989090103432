import { API, graphqlOperation } from "aws-amplify";
import { GraphQLParamsEnum } from "constant/enum";
import { listAttorneys as ListAttorneys } from "gql/queries";
import { checkAuth } from "services/auth-service";

const getAttorneyByCongnitoId = async (cognitoId) => {
  try {
    const attorneys = await API.graphql(
      graphqlOperation(ListAttorneys, {
        limit: GraphQLParamsEnum.LIMIT_RESULT,
        filter: {
          cognitoId: {
            eq: cognitoId,
          },
        },
      })
    );
    if (attorneys.data.listAttorneys.items.length !== 0) {
      return attorneys.data.listAttorneys.items[0];
    } else {
      return false;
    }
  } catch (e) {
    console.error(e);
  }
};

const getCurrentLoginAttorney = async (history = null) => {
  const user = await checkAuth(history);
  if (user) {
    return await getAttorneyByCongnitoId(user.id);
  } else {
    return false;
  }
};

export { getAttorneyByCongnitoId, getCurrentLoginAttorney };
