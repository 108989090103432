import React, { useState } from "react";
import { AddIcon, EditIcon } from "icons";
import {
  Grid,
  FormControl,
  Select,
  Input,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import styled from "styled-components";

const EditableWrapper = styled.div`
  .MuiFormControl-root {
    width:100%;
  }
  cursor: pointer;
  font-size: 16px;
  hr {
    margin: 5px 0px;
  }
`;

const IconWrapper = styled(Grid)`
  display:flex;
  justify-content: flex-end;
`;

const StyledImg = styled.img`
  width: 25px;
  height: 25px;
  transition: 0.25s;
  &:hover {
    background-color: rgb(236, 245, 250);
    border-radius:50px;
    transform: scale(1.1);
  }
`

// { value, setValue, Title }
const EditableSelect = ({
  value,
  title,
  options = [],
  handleChange = (newValue) => { },
  multiple = false,
  checkbox = false,
  valueMapping = null,
  readOnly = false,
  hr = true,
  ...rest
}) => {
  const [editMode, setEditMode] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleToggleMode = () => {
    setEditMode(!editMode);
  };

  const _handleChange = () => {
    handleToggleMode();
    handleChange(currentValue);
  };
  return (
    <EditableWrapper {...rest}>
      {editMode && (
        <Grid item xs={12}>
          <Grid container>
            <FormControl>
              <Select
                multiple={multiple}
                value={currentValue}
                onChange={(e) => setCurrentValue(e.target.value)}
                onClose={_handleChange}
                input={<Input />}
                color="primary"
                renderValue={
                  (selected) => valueMapping && multiple ?
                    (selected.map(item => valueMapping[item])).join(", ") :
                    selected.join(", ")
                }
              >
                {Object.keys(options).map((optionKey) => (
                  <MenuItem key={optionKey} value={optionKey}>
                    {checkbox && (
                      <Checkbox
                        color="primary"
                        checked={currentValue.indexOf(optionKey) > -1}
                      />
                    )}
                    {options[optionKey]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <label style={{ marginTop: "10px" }}>{title}</label>
        </Grid>
      )}
      {!editMode && (
        <Grid item xs={12}>
          <div style={readOnly ? { backgroundColor: '#e6e6e6', padding: '4px 2px' } : {}}>
            <Grid container item xs={12} justify="center" alignItems="center">
              <Grid item xs={readOnly ? 12 : 11}>
                <span>
                  {
                    multiple && valueMapping ?
                      (value.map(item => valueMapping[item])).join(", ") :
                      value.join(", ")
                  }
                </span>
              </Grid>
              {
                !readOnly &&
                <IconWrapper item xs={1}>
                  {multiple && (
                    <StyledImg onClick={handleToggleMode} src={AddIcon} alt="add" />
                  )}
                  {!multiple && (
                    <StyledImg onClick={handleToggleMode} src={EditIcon} alt="edit" />
                  )}
                </IconWrapper>
              }
            </Grid>
            {(!readOnly || hr) && <hr />}
          </div>
          <label style={{ marginTop: "10px" }}>{title}</label>
        </Grid>
      )}
    </EditableWrapper>
  );
};

export default EditableSelect;
