import { Grid, Input } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled(Grid)`
  padding-left: 34px;
  padding-right: 34px;
  padding-bottom: 52px;
`;

export const SubscriptionPlan = styled(Grid)`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: #9b9ca5;
  padding-bottom: 8px;
`;

export const SubscriptionPrice = styled(Grid)`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: #26272c;
  padding-bottom: 40px;
`;

export const InputField = styled(Grid)``;

export const InputCommon = styled(Input)`
  width: 100%;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 46px;
  color: #bbc9d5;
  border-bottom: 1px solid #d8e1ea;
`;

export const InputLabelCommon = styled.div`
  padding-top: 6.5px;

  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: #9b9ca5;
`;

export const MediumSpacing = styled.div`
  padding-bottom: 25px;
`;

export const LargeSpacingTop = styled.div`
  margin-top: 52px;
`;

export const MediumSpacingLeft = styled.div`
  margin-left: 16px;
`;

export const MediumSpacingRight = styled.div`
  margin-right: 16px;
`;

export const GridRow = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

export const CardElementContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & .StripeElement {
    width: 100%;
  }
`;

export const iframeStyles = {
  base: {
    color: "#2F9566",
    fontSize: "22px",
    iconColor: "#2F9566",
    "::placeholder": {
      color: "#bbc9d5",
    },
  },
  invalid: {
    iconColor: "#2F9566",
    color: "#F2F9566",
  },
  complete: {
    iconColor: "#bbc9d5",
    color: "#26272C",
  },
};

export const ErrorMessage = styled.div`
  color: #f56565;
  padding-top: 8px;
`;
