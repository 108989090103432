import { observer } from "mobx-react";
import moment from "moment";
import AntSwitch from "components/common/AntSwitch";
import useStore from "utils/useStore";
import { Container, Header, DayLabel, TimeLabel } from "./scheduleCard.styles";
import { useContext, useMemo } from "react";
import { IAttorneyTimeFrame } from "interfaces";
import { getTimeFrameLabel } from "./utils";
import { AttorneyTimeFrameCategory } from "constant/enum";
import { updateOfflineTimeFrame } from "../../../../utils";
import { UserContext } from "contexts";

const ScheduleCard = () => {
  const [userInfo] = useContext(UserContext);
  const { calendarStore } = useStore();
  const { selectedDate, attorneyTimeFrames, attorneyOfflineTimeFrames } =
    calendarStore;
  const selectedDayIndex: number = moment(selectedDate).day();

  const commonTimeFrames: IAttorneyTimeFrame[] = useMemo(
    () =>
      Array.isArray(attorneyTimeFrames)
        ? attorneyTimeFrames?.filter(
            (attorneyTimeFrame: IAttorneyTimeFrame) =>
              attorneyTimeFrame?.dayIndex === selectedDayIndex &&
              attorneyTimeFrame?.category == AttorneyTimeFrameCategory.COMMON
          )
        : [],
    [attorneyTimeFrames, selectedDayIndex]
  );

  const offlineTimeFrame: IAttorneyTimeFrame = useMemo(
    () =>
      attorneyOfflineTimeFrames?.find(
        (attorneyTimeFrame: IAttorneyTimeFrame) =>
          moment(attorneyTimeFrame?.from).isSame(
            moment(selectedDate),
            "date"
          ) &&
          moment(attorneyTimeFrame?.to).isSame(moment(selectedDate), "date")
      ),
    [attorneyOfflineTimeFrames, selectedDate]
  );

  return (
    <Container>
      <Header>
        <DayLabel>{moment(selectedDate).format("MMMM Do YYYY")}</DayLabel>
        <AntSwitch
          offLabel="Off"
          onLabel="Available"
          value={!offlineTimeFrame?.isOffline ?? true}
          onChange={(newValue: boolean) =>
            updateOfflineTimeFrame(
              calendarStore,
              userInfo?.id,
              selectedDayIndex,
              moment(selectedDate),
              moment(selectedDate),
              !newValue
            )
          }
        />
      </Header>
      <TimeLabel>{getTimeFrameLabel(commonTimeFrames)}</TimeLabel>
    </Container>
  );
};

export default observer(ScheduleCard);
