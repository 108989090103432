import React from 'react';
import {
  DropAreaConatainer, DropArea,
  DropAreaDescription,
  DropAreaSubText,
  SelectedFile,
  FileName
} from './styled.components';

const DropAreaComponent = (props) => {
  const { openFilePicker, selectedFiles, onRemove } = props;
  return (
    <DropAreaConatainer>
      <DropArea onClick={openFilePicker}>
        <DropAreaDescription>Drop file(s) here to upload or browse</DropAreaDescription>
        <DropAreaSubText>Support file types: images, pdf, xsl, doc, video.</DropAreaSubText>
        <DropAreaSubText>Max 100Mb for video, 5Mb for others</DropAreaSubText>
      </DropArea>

      <div style={{ marginTop: selectedFiles?.length ? 16 : 0 }}>
        {
          selectedFiles && selectedFiles.map(file => {
            return (
              <SelectedFile key={file.name}>
                <FileName>{file.name}</FileName>
                <span onClick={() => onRemove(file.name)}>X</span>
              </SelectedFile>
            )
          })
        }
      </div>
    </DropAreaConatainer>
  );
};

export default DropAreaComponent;
