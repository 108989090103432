import { Grid, Radio } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;

  background-color: #ecf5fa;
  border-radius: 16px;
`;

export const RadioCommon = styled(Radio)`
  [class*="MuiRadio-colorPrimary"] {
    color: #287ec7;
  }
`;

export const LabelCommon = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0px;
  cursor: pointer;
`;
