import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const ErrorMsgWrapper =styled(Grid)`
    background-color: ${ ({ theme }) => theme.colors.warning };
    z-index:999999;
    color:white;
    font-size:14px;
    font-weight:200;
    padding:8px;
`

const ErrorMsg = ({children}) => {

    return(
        <ErrorMsgWrapper item container xs={12} alignItems="center">
            <Grid item xs={2}>
                <ReportProblemOutlinedIcon fontSize="large" />
            </Grid>
            <Grid item xs={9}>{children}</Grid>
        </ErrorMsgWrapper>
    )
}
    

export default ErrorMsg