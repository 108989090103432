import { Button, Grid } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

export const CancelWrapper = styled(Grid)`
  margin-right: 8px;
`;

export const AcceptWrapper = styled(Grid)`
  margin-left: 8px;
`;

export const CancelButton = styled(Button)`
  width: 100%;
  background: rgba(255, 255, 255, 0.48);
  border-radius: 8px;
  padding: 16px 16px;
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.8px;
  color: ${styledTheme.colors.primaryGray};
  text-transform: initial;
`;

export const AcceptButton = styled(Button)`
  width: 100%;
  background-color: ${styledTheme.colors.red400} !important;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  color: ${styledTheme.colors.white};
  padding: 16px 16px;
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1.2px;
  text-transform: initial;

  &:disabled {
    background-color: #a0aec0;
    color: ${styledTheme.colors.white};
  }
`;

export const LoadingStyle = styled.span`
  margin-left: 4px;
`;
