import React from 'react'
import Grid from '@material-ui/core/Grid'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import styled from 'styled-components'
import data from './data'
import { styledTheme } from '../../../styles/StyledTheme'

const GridWrapper = styled(Grid)`
.paper{
  border-radius:12px;
}
.text{
  font-weight:bold;
  font-size:16px;
}
.paragraph{
  font-size:14px;
  color: ${ ({ theme }) => theme.colors.defaultDarker };
}
`
const Icon = styled(props => (
  <div {...props}>
    <AddCircleIcon className="add" />
    <RemoveCircleRoundedIcon className="minus" />
  </div>
))`
  & > .add {
    display: block;
  }
  & > .minus {
    display: none;
  }
  .Mui-expanded & > .minus {
    display: block;
  }
  .Mui-expanded & > .add {
    display: none;
  }
`;

const Account = () => {

  return (
    <GridWrapper item xs={12}>
      {
        data.account.map((item, i) => (
          <Accordion classes={{root:"paper"}} key={i}>
            <AccordionSummary
              expandIcon={<Icon style={{ color: styledTheme.colors.primary }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography classes={{root:"text"}}>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography classes={{root:"paragraph"}} dangerouslySetInnerHTML={{__html: item.summary}} style={{whiteSpace: 'pre-line'}}>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))
      }
    </GridWrapper>
  )
}

export default Account