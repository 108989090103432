import { getAttorneyById, getAttorneyProfile } from "API/attorney";
import { getAttorneyConsultationRequestAttorneys } from "API/consultationRequest";
import { fetchLeadDistributionStatistics } from "API/leadDistribution";
import { PusherChannel } from "constant/enum";
import {
  IAttorney,
  IAttorneyConsultationRequest,
  IAttorneyConsultationRequestAttorney,
} from "interfaces";
import { ILeadDistribution } from "interfaces/attorney/leadDistribution";
import { observable, makeObservable, action } from "mobx";
import { IFilter } from "types";
import { createEventTrigger } from "../API/eventTrigger";
import { IRootStore } from "./rootStore";

class AttorneyStore {
  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      attorneyConsultationRequests: observable,
      numberOfPendingRequests: observable,
      attorneyConsultationRequestAttorney: observable,
      onlineStatus: observable,
      attorneyJoinConsultation: observable,
      attorneyDetail: observable,
      leadDistributionStatistics: observable,
      setNumberOfPendingRequests: action,
      fetchAttorneyConsultationRequestAttorneys: action,
      setAttorneyConsultationRequests: action,
      setAttorneyJoinConsultation: action,
      getAttorneyDetail: action,
      getLeadDistributionStatistics: action
    });
    this.rootStore = rootStore;
  }

  public attorneyDetail: IAttorney | undefined;

  public attorneyConsultationRequests: IAttorneyConsultationRequest[] = [];

  public attorneyConsultationRequestAttorney: IAttorneyConsultationRequestAttorney[] =
    [];

  public leadDistributionStatistics: ILeadDistribution[] = []

  public numberOfPendingRequests = 0;

  public onlineStatus = true;

  public attorneyJoinConsultation = false;

  public setNumberOfPendingRequests(numberOfPendingRequests: number): void {
    this.numberOfPendingRequests = numberOfPendingRequests;
  }

  public async syncOnlineStatus(attorneyId: string): Promise<void> {
    createEventTrigger(PusherChannel.ATTORNEY_SYNC_ONLINE_STATUS, "main", {
      id: attorneyId,
    });
    const getAttorneyResponse = await getAttorneyById(attorneyId);
    this.onlineStatus = getAttorneyResponse?.isOnline;
  }

  public async fetchAttorneyConsultationRequestAttorneys(
    filter: IFilter<IAttorneyConsultationRequestAttorney> = {}
  ): Promise<IAttorneyConsultationRequestAttorney[]> {
    this.attorneyConsultationRequestAttorney =
      await getAttorneyConsultationRequestAttorneys(filter);
    return this.attorneyConsultationRequestAttorney;
  }

  public setAttorneyConsultationRequests(
    attorneyConsultationRequests: IAttorneyConsultationRequest[]
  ): void {
    this.attorneyConsultationRequests = attorneyConsultationRequests;
  }

  public setAttorneyJoinConsultation(attorneyJoinConsultation: boolean): void {
    this.attorneyJoinConsultation = attorneyJoinConsultation;
  }

  public async getAttorneyDetail(): Promise<void> {
    this.attorneyDetail = await getAttorneyProfile();
  }

  public async getLeadDistributionStatistics(): Promise<void> {
    this.leadDistributionStatistics = await fetchLeadDistributionStatistics();
  }
}

export default AttorneyStore;
