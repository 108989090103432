import Grid from "@material-ui/core/Grid";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { API, graphqlOperation } from "aws-amplify";
import { LoaderContext, UserContext } from "contexts";
import { updateAttorney as UpdateAttorney } from "gql/mutations";
import { useNylas } from 'hooks';
import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { errorHandler } from "utils/errorHandler";
import useStore from "utils/useStore";
import AvailableChannels from "./components/AvailableChannels";
import NotificationSetting from "./components/NotificationSetting";
import PersonalInformation from "./components/PersonalInformation";
import PracticeArea from "./components/PracticeArea";
import SubscriptionForm from "./components/SubscriptionForm";
import MyAccountHeader from "./MyAccountHeader";
import PasswordManagement from "./PasswordManagement";
import { unSubscribeAttorney } from "./utils";

const InformationWrapper = styled(Grid)`
  padding-top: 60px;
  padding-bottom: 40px;

  @media (max-width: 1278px) {
    height: auto;
  }
`;

const GridWrapper = styled(Grid)`
  padding-top: 20px;
`;

const { REACT_APP_STRIPE_PUBLISHABLE_KEY } = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);

const MyAccount = () => {
  const { subscriptionStore, attorneyStore } = useStore();
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [openUnSubscriptionModal, setOpenUnSubscriptionModal] = useState(false);
  const [openEditCard, setOpenEditCard] = useState(false);
  const [userInfo, updateContextUserInfo] = useContext(UserContext);
  const [_, setIsLoaderShown] = React.useContext(LoaderContext);
  const {
    firstName,
    lastName,
    emailWork,
    practiceAreas,
    communicationMethodsPreference,
    licenseNumbers,
    firmAssociation,
    statesOfLicense,
    phoneNumber,
    notificationMethods,
  } = userInfo;
  const { stripeSubscription } = subscriptionStore;
  useNylas();
  
  const handleUpdateAttorney = async (updatedInfo) => {
    setIsLoaderShown(true);
    try {
      const {
        data: { updateAttorney = {} },
      } = await API.graphql(
        graphqlOperation(UpdateAttorney, {
          input: {
            id: userInfo.id,
            ...updatedInfo,
          },
        })
      );
      const newUserContext = {
        ...userInfo,
        ...updateAttorney,
      };
      updateContextUserInfo(newUserContext);
      setIsLoaderShown(false);
    } catch (error) {
      errorHandler(error);
      setIsLoaderShown(false);
    }
  };

  useEffect(() => {
    subscriptionStore.syncStripeAccountStatus();
    attorneyStore.getLeadDistributionStatistics();
  }, []);

  return (
    <GridWrapper style={{ height: "100%" }}>
      <Grid>
        <MyAccountHeader
          firstName={firstName}
          lastName={lastName}
          emailWork={emailWork}
          openUnSubscriptionModal={openUnSubscriptionModal}
          onClickSubscribe={() => setOpenSubscriptionModal(true)}
          onClickUnSubscribe={() => setOpenUnSubscriptionModal(true)}
          onClickAcceptUnSubscribe={async (unSubscriptionSurvey) => {
            await unSubscribeAttorney(
              stripeSubscription?.id,
              userInfo,
              updateContextUserInfo,
              subscriptionStore,
              unSubscriptionSurvey
            );
            setOpenUnSubscriptionModal(false);
          }}
          onClickRejectUnSubscribe={() => {
            setOpenUnSubscriptionModal(false);
          }}
          onClickEditCard={() => setOpenEditCard(true)}
        />
      </Grid>
      <InformationWrapper container spacing={3}>
        <PersonalInformation
          licenseNumbers={licenseNumbers}
          statesOfLicense={statesOfLicense}
          firmAssociation={firmAssociation}
          phoneNumber={phoneNumber}
          handleUpdateAttorney={handleUpdateAttorney}
        />
        <PasswordManagement />
        <AvailableChannels
          handleUpdateAttorney={handleUpdateAttorney}
          communicationMethodsPreference={communicationMethodsPreference}
        />
        <PracticeArea
          practiceAreas={practiceAreas}
          handleUpdateAttorney={handleUpdateAttorney}
        />
        <NotificationSetting
          phoneNumber={phoneNumber}
          notificationMethods={notificationMethods}
          handleUpdateAttorney={handleUpdateAttorney}
        />
      </InformationWrapper>
      <Elements stripe={stripePromise}>
        <SubscriptionForm
          isOpen={openSubscriptionModal}
          onClose={() => setOpenSubscriptionModal(false)}
        />
      </Elements>
      <Elements stripe={stripePromise}>
        <SubscriptionForm
          editMode
          isOpen={openEditCard}
          onClose={() => setOpenEditCard(false)}
        />
      </Elements>
    </GridWrapper>
  );
};

export default observer(MyAccount);
