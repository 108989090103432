export const listAttorneys = /* GraphQL */ `
  query ListAttorneys(
    $filter: ModelAttorneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttorneys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        licenseNumber
        licenseNumbers
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        communicationMethodsPreference
        acceptsTCAndPP
        attorneyRating
        cognitoId
        stripeAccountId
        stripeCustomerId
        stripeSubscriptionId
        notificationMethods
        phoneNumber
        nylasGoogleAccessToken
        nylasICloudAccessToken
        nylasOutlookAccessToken
        autoSchedulePackage
        phoneNumberUsageConsent
        earnings {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        ratings {
          items {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
            updatedAt
          }
          nextToken
        }
        bankAccounts {
          items {
            id
            accountNumber
            routingNumber
            bankName
            title
            createdAt
            updatedAt
          }
          nextToken
        }
        isOnline
        lastOnline
        approved
        createdAt
        updatedAt
        cases {
          items {
            id
            venueState
            legalAdviceDescription
            areaOfLaw
            consultationConnectionMethod
            futureConsultationDateTime
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listAllConsultations = `
  query ListAllConsultations {
    listFutureConsultations(limit: 100000000) {
      items {
        id
        dateTimeScheduled
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          futureConsultationDateTime
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            attorney {
              id
              firstName
              lastName
            }
            createdAt
          }
          payment {
            id
            paymentMethod
            dateTime
            amount
            calculatedAttorneyAmount
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
          }
        }
        consultation {
          id
          connectionMethod
          dateTimeOccurred
          status
          purchasedLength
          actualLength
          flow
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
    listImmediateConsultations(limit: 100000000) {
      items {
        id
        case {
          id
          venueState
          legalAdviceDescription
          areaOfLaw
          consultationConnectionMethod
          futureConsultationDateTime
          rating {
            id
            overallScore
            friendlinessScore
            knowledgeScore
            feedbackText
            createdAt
          }
          payment {
            id
            paymentMethod
            dateTime
            amount
            calculatedAttorneyAmount
          }
          createdAt
          updatedAt
          client {
            id
            firstName
            lastName
          }
        }
        consultation {
          id
          connectionMethod
          dateTimeOccurred
          status
          purchasedLength
          actualLength
          flow
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const getBriefFutureConsultation = `
query GetFutureConsultation($id: ID!) {
  getFutureConsultation(id: $id) {
    id
    dateTimeScheduled
    case {
      id
      venueState
      legalAdviceDescription
      areaOfLaw
      consultationConnectionMethod
      futureConsultationDateTime
      rating {
        id
        overallScore
        friendlinessScore
        knowledgeScore
        feedbackText
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
      }
      payment {
        id
        calculatedAttorneyAmount
      }
    }
    consultation {
      id
      connectionMethod
      dateTimeOccurred
      videoConnectionToken
      chatConnectionToken
      conversationTranscript
      status
      clientPrefferedConnectionMethod
      purchasedLength
      actualLength
      flow
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
`;

export const getBriefImmediateConsultation = `
query GetImmediateConsultation($id: ID!) {
  getImmediateConsultation(id: $id) {
    id
    case {
      id
      venueState
      legalAdviceDescription
      areaOfLaw
      consultationConnectionMethod
      futureConsultationDateTime
      rating {
        id
        overallScore
        friendlinessScore
        knowledgeScore
        feedbackText
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      client {
        id
        firstName
        lastName
      }
      payment {
        id
        calculatedAttorneyAmount
      }
    }
    consultation {
      id
      connectionMethod
      dateTimeOccurred
      videoConnectionToken
      chatConnectionToken
      conversationTranscript
      status
      clientPrefferedConnectionMethod
      purchasedLength
      actualLength
      flow
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
`;
/*
GET SINGLE CONSULTATIONS
query GetFutureConsultation {
    getFutureConsultation(id: "86928436-84a8-454d-a124-f78d549bbbb1") {
      id
      dateTimeScheduled
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        futureConsultationDateTime
        rating {
          id
          overallScore
          friendlinessScore
          knowledgeScore
          feedbackText
          attorney {
            id
            firstName
            lastName
          }
        }
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
        }
      }
      consultation {
        id
        status
        purchasedLength
        actualLength
      }
      createdAt
      updatedAt
    }
  }
*/

