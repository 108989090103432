import { API, graphqlOperation } from "aws-amplify";
import { updateConsultationGQL } from 'gql/consultation'
import { getConsultation as getConsultationGQL } from 'graphql/queries'

export async function markAttorneyJoined(consultationId) {
  if (consultationId) {
    await API.graphql(
      graphqlOperation(updateConsultationGQL, {
        input: {
          id: consultationId,
          attorneyJoined: true,
        }
      })
    );
  }
}

export async function markConsultationPaymentCaptured(consultationId) {
  if (consultationId) {
    await API.graphql(
      graphqlOperation(updateConsultationGQL, {
        input: {
          id: consultationId,
          paymentCaptured: true,
        }
      })
    );
  }
}

export async function getConsultation(consultationId) {
  if (consultationId) {
    const response = await API.graphql(
      graphqlOperation(getConsultationGQL, {
        id: consultationId,
      })
    );
    return response?.data?.getConsultation
  }
  return {}
}