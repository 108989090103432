import React from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'

const MenuItemStyled = styled(Grid)`
    padding: 5px 40px;
    color: grey;
    display:flex;
    align-items:center;
    @media screen and (max-height: 820px) {
        padding: 5px 35px;
    }
`

const SidebarMenu = ({ children, ...props }) => {
    
    return (
        <div {...props}>
            <MenuItemStyled>
                { children }
            </MenuItemStyled>
        </div>
        )
}

export default SidebarMenu