import Icon from "components/common/Icon";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 22.5px;
  padding-bottom: 22.5px;
`;

export const Title = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #287ec7;
`;

export const Pricing = styled.div`
  margin-top: 8px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #26272c;
`;

export const PlanSection = styled.div``;

export const SettingSection = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RecoveryIcon = styled(Icon)`
  margin-right: 16px;
  cursor: not-allowed;
`;

export const SettingIcon = styled(Icon)`
  cursor: pointer;
`;
