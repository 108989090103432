import styled from "styled-components";
import { Grid, Paper } from "@material-ui/core";
import { styledTheme } from "styles/StyledTheme";
import { breakpoints } from "styles/layout";

export const PaperWrapper = styled(Paper)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-width: 100%;
  min-height: 710px;
  text-align: center;
  border-radius: 10px;
  padding: 40px;
  max-height: fit-content !important;
`;

export const ContentContainer = styled(Grid)``;

export const Header = styled(Grid)`
  text-align: left;
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: ${styledTheme.colors.primaryBlack};
  padding-bottom: 56px;
`;

export const Body = styled(Grid)`
  display: flex;

  ${breakpoints("flex-direction", [
    {
      xxl: "row",
    },
    {
      xl: "column",
    },
    {
      lg: "column",
    },
    {
      md: "column",
    },
    {
      sm: "column",
    },
    {
      xs: "column",
    },
  ])}
`;

export const TimeFrameContainer = styled(Grid)``;

export const CalendarContainer = styled(Grid)``;
