import Icon from "components/common/Icon";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;

  padding: 12px 34px;

  background: #ecf5fa;
  border: 1px solid #287ec7;
  border-radius: 16px;
`;

export const Title = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: #26272c;
`;

export const CloseButton = styled(Icon)`
  padding-left: 16px;
  cursor: pointer;
  
  img {
    width: 24px;
    height: 24px;
  }
`;
