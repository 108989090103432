import {
  PaymentMethodResult,
  Stripe,
  StripeCardElement,
} from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { IUserInfo } from "interfaces/user";
import stripeService from "services/stripeService";
import SubscriptionStore from "stores/subscriptionStore";
import { onSuccessfulSubscription } from ".";

export async function handleFormSubmit(
  event: { preventDefault: () => void },
  userInfo: IUserInfo,
  elements: {
    getElement: (element) => StripeCardElement;
  },
  stripe: Stripe,
  updateUserInfo: (userInfo) => void,
  setSubscriptionError: (message) => void,
  setSubscription: (subscription) => void,
  setCustomer: (customer) => void,
  setSubscribeStatus: (subscribeStatus) => void,
  onClose: () => void,
  subscriptionStore: SubscriptionStore
): Promise<void> {
  if (subscriptionStore?.isLoading || subscriptionStore?.subscriptionError) {
    return;
  }
  event.preventDefault();
  subscriptionStore?.setLoading(true);
  toast.info("Checking your card, please wait until complete!", {
    autoClose: 10000,
  });

  const billingDetails = {
    name: `${userInfo.firstName} ${userInfo.lastName}`,
    email: userInfo.emailWork,
  };
  try {
    const cardElement = elements.getElement("card");
    const paymentMethodReq = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: billingDetails,
    });

    if (paymentMethodReq?.error) {
      toast.error(paymentMethodReq?.error?.message);
      subscriptionStore?.setLoading(false);
      return;
    }

    const subscription = await stripeService.createAttorneySubscription({
      paymentMethodId: paymentMethodReq.paymentMethod.id,
      email: userInfo.emailWork,
      name: `${userInfo.firstName} ${userInfo.lastName}`,
    });
    await onSuccessfulSubscription(
      subscription,
      setSubscription,
      setCustomer,
      userInfo,
      updateUserInfo,
      setSubscribeStatus,
      onClose,
      subscriptionStore
    );
    toast.dismiss()
    toast.success("Completed to subscribe!");
  } catch (err) {
    toast.dismiss()
    toast.error("Error while subscribe, please contact us!");
    setSubscriptionError(err.message);
  }
  subscriptionStore?.setLoading(false);
}

export async function handleUpdatePaymentMethod(
  event: { preventDefault: () => void },
  userInfo: IUserInfo,
  subscriptionStore: SubscriptionStore,
  elements: {
    getElement: (element) => StripeCardElement;
  },
  stripe: Stripe,
  onClose: () => void
): Promise<void> {
  if (subscriptionStore?.isLoading || subscriptionStore?.subscriptionError) {
    return;
  }
  event.preventDefault();
  subscriptionStore?.setLoading(true);
  toast.info("Checking your card, please wait until complete!", {
    autoClose: 10000,
  });

  const billingDetails = {
    name: `${userInfo.firstName} ${userInfo.lastName}`,
    email: userInfo.emailWork,
  };

  try {
    const cardElement = elements.getElement("card");
    const paymentMethodResponse: PaymentMethodResult =
      await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails,
      });

    if (paymentMethodResponse?.error) {
      toast.error(paymentMethodResponse?.error?.message);
      subscriptionStore?.setLoading(false);
      return;
    }

    const oldPaymentMethodId: string =
      subscriptionStore?.stripeCustomer?.paymentMethod?.id;
    const customerId: string = subscriptionStore?.stripeCustomer?.id;
    const newPaymentMethodId: string = paymentMethodResponse?.paymentMethod?.id;

    if (!oldPaymentMethodId || !customerId || !newPaymentMethodId) {
      toast.dismiss()
      toast.error("Error while update payment method, please try again!");
      subscriptionStore?.setLoading(false);
      return;
    }

    await stripeService.updateAttorneyPaymentMethod({
      oldPaymentMethodId,
      newPaymentMethodId,
      customerId,
    });
    subscriptionStore.getSubscriptionInformation(
      userInfo?.stripeSubscriptionId
    );
    toast.dismiss()
    toast.success("Completed to update payment method!");
    onClose();
  } catch (error) {
    toast.dismiss()
    toast.error("Error while update payment method, please contact us!");
  }

  subscriptionStore?.setLoading(false);
}
