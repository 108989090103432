import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled(Grid)`
  padding-bottom: 52px;
  padding-top: 16px;
  max-width: 521px;
  width: 100%;
`;

export const MediumSpacing = styled.div`
  padding-bottom: 25px;
`;

export const SmallSpacing = styled.div`
  padding-bottom: 16px;
`;
