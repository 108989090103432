import { FormLabel, Grid, RadioGroup } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

export const RadioLabel = styled(FormLabel)`
  text-align: left;
  margin-bottom: 24px;
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: ${styledTheme.colors.primaryGray};
`;

export const RadioOptions = styled(RadioGroup)`
  display: flex;
  flex-direction: row;

  label {
    margin-bottom: 0px;
  }

  span {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const SelectSection = styled(Grid)`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  margin-top: 24px;
`;

export const SelectButton = styled.div<{ isActive: boolean }>`
  flex-grow: 1;
  padding: 10px;
  margin-right: 1px;
  min-height: 56px;
  max-width: 56px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  background: ${(props) =>
    props?.isActive
      ? styledTheme.colors.secondaryDark
      : styledTheme.colors.contentBackground};
  color: ${(props) =>
    props?.isActive
      ? styledTheme.colors.white
      : styledTheme.colors.primaryBlack};

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
