import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled(Grid)`
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 16px;

  padding-top: 23px;
  padding-bottom: 22px;
  padding-left: 48px;
  padding-right: 48px;
`;

export const PlanInformationWrapper = styled(Grid)`
  padding-right: 48px;
  border-right: 1px solid #d8e1ea;
`;

export const CardInformationWrapper = styled(Grid)``;
