import styled from "styled-components";
import Icon from "components/common/Icon";

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

export const CloseButton = styled(Icon)`
  cursor: pointer;
`;
