import Icon from "components/common/Icon";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  padding: 12px 34px;

  background-color: #5390d2;
  border-radius: 16px;
  cursor: pointer;
`;

export const AddIcon = styled(Icon)`
  img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
`;

export const Label = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: #ffffff;
`;
