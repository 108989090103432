import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext, LoaderContext } from "contexts";
import {ReactTitle} from 'react-meta-tags';
import stripe from 'services/stripeService';

// components
import Grid from "@material-ui/core/Grid";
import { Sidebar } from "components";
import MyAccount from "./MyAccount";
import DashboardHome from "./Home";
import Consultation from "./Consultation";
import Ratings from "./Ratings";
import Help from "./Help";
import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { getCurrentLoginAttorney } from "queries-helper";
import {
  Logo,
} from "icons";
import Hamburger from "icons/Hamburger.svg";
import Banner from "../../components/shared/Banner";
import stripeService from "services/stripeService";
import { observer } from "mobx-react";
import useStore from "utils/useStore";
import { useConsultationSubscription } from "hooks";
import { errorHandler } from "utils/errorHandler";
import { useDashboard } from "./hooks";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: '#f0f5f8'
  },
}));
const ViewFrame = styled(Grid)`
  width: 100%;
  height: 100%;
`;
const LogoImage = styled.img`
  width: 200px;
  height: 35px;
`;
const HamburgerImage = styled.img`
  height: 20px;
`;
const DashboardWrapper = styled.div`
  background-color: #f0f5f8;
  min-height: 100vh;
  width: 100%;
  @media (max-width: 1280px) {
    height: auto;
  }
  .makeStyles-menuButton-5 {
    /* margin-right: 16px; */
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .MuiRating-iconFilled .MuiSvgIcon-root {
    color:#3D4EAE;
  }
  .MuiToolbar-gutters {
    background-color: ${ ({ theme }) => theme.colors.white };
  }
  .MuiIconButton-root {
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 0%;
  }
  .MuiSnackbarContent-message {
    margin: 0 auto;
  }
`;

const getTitle = (pathname) => {
  if (/my-account/g.test(pathname)) return "Account"
  else if (/consultation/g.test(pathname)) return "Consultations"
  else if (/ratings/g.test(pathname)) return "Ratings"
  else if (/help/g.test(pathname)) return "Help"
  else return "Home"
}

let analytics = window.analytics;

const Dashboard = ({ location: { pathname } }, props) => {
  const [userInfo, setContextUserInfo] = useContext(UserContext); //eslint-disable-line
  const [isLoaderShown, setIsLoaderShown] = useContext(LoaderContext); //eslint-disable-line
  const [attorneyInfo, updateAttorneyInfo] = useState({});
  const [onlineStatus, setOnlineStatus] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [menuDisabled, setMenuDisabled] = useState(false);
  const [isAttorneySubscribed, setAttorneySubscribed] = useState(false);
  const {subscriptionStore, attorneyStore } = useStore()
  useConsultationSubscription()
  useDashboard()
  const { window } = props;
  const id = attorneyInfo?.id;
  const { id : liveId } = userInfo;

  useEffect(() => {
    async function fetchData() {
      const attorneySubscribed = await stripeService.checkIsAttorneySubscribed(userInfo);
      setAttorneySubscribed(attorneySubscribed);
    }
    fetchData();
  }, [userInfo])

  useEffect(() => {
    subscriptionStore.getSubscriptionInformation(
      userInfo?.stripeSubscriptionId
    );
    subscriptionStore.validateStrategicPartner(userInfo?.emailWork)
  }, [userInfo]);

  useEffect(() => {
    if (!id) fetchAttorneyInformation();
  }, [id]);

  useLayoutEffect(() => {
    if(analytics && liveId) {
      analytics.page();
      analytics.identify(userInfo.id, {
        name: userInfo.firstName + " " + userInfo.lastName,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.emailWork,
      });
    }
  }, [location, liveId]);

  useEffect(() => {
    if (liveId) stripeCheck();
  }, [liveId]);
  
  useEffect(()=> {
    setOnlineStatus(attorneyStore.onlineStatus);
  }, [attorneyStore.onlineStatus])

  const fetchAttorneyInformation = () => {
    setIsLoaderShown(true);
    try {
      getCurrentLoginAttorney(history).then((res) => {
        updateAttorneyInfo(res);
        setContextUserInfo(res);
        setIsLoaderShown(false);
      });
    } catch (error) {
      errorHandler(error)
      setIsLoaderShown(false);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const stripeCheck = () => {
    if(!userInfo.stripeAccountId)
      setContextUserInfo({...userInfo, bankAttached: false})
    else {
      stripe.retrieveAccount(userInfo.stripeAccountId)
        .then(res => {
          let bankConnected = false;
          if(res.charges_enabled && res.details_submitted) {
            bankConnected = true;
            setContextUserInfo({...userInfo, bankAttached: true})
          }
          else
            setContextUserInfo({...userInfo, bankAttached: false})

          analytics && analytics.identify(userInfo.id, {
            bankConnected
          });
        })
        .catch(err => {
            if(err === 'connErr')
              stripeCheck();
        })
    }
  }

  const myAccount = /my-account/g.test(pathname);
  const consultation = /consultation/g.test(pathname);
  const ratings = /ratings/g.test(pathname);
  const help = /help/g.test(pathname);
  const home = !myAccount && !consultation && !ratings && !help;
  const container =
    window !== undefined ? () => window().document.body : undefined;
      
  return (
    <DashboardWrapper>
      {
        !myAccount && !isAttorneySubscribed && consultation && !subscriptionStore?.isStrategicPartner &&
        <Banner>
          Please 
          visit <u style={{cursor: 'pointer'}} onClick={() => history.push('/dashboard/my-account')}>My Account</u> page 
          and subscribe
        </Banner>
      }
      <ReactTitle title={getTitle(pathname) + " - LegalQ Attorney"}/>
      <UserContext.Provider value={[
        userInfo,
        setContextUserInfo,
        onlineStatus,
        setOnlineStatus,
        menuDisabled,
        setMenuDisabled
      ]}>
        <div className={classes.root}>
          <CssBaseline />
          <Hidden only={["sm", "md", "lg", "xl"]}>
            <AppBar classes={{root:"appBar"}} position="fixed" className={`${classes.appBar} `} >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <HamburgerImage src={Hamburger} />
                  <LogoImage src={Logo} />
                  <MenuIcon />
                
                </IconButton>
                <Typography variant="h6" noWrap></Typography>
              </Toolbar>
            </AppBar>
          </Hidden>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <Sidebar></Sidebar>
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                <Sidebar></Sidebar>
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content}>
            <div />
            <ViewFrame>
              {myAccount && <MyAccount />}
              {consultation && <Consultation pathname={pathname} />}
              {ratings && <Ratings />}
              {help && <Help />}
              {home && <DashboardHome />}
            </ViewFrame>
          </main>
        </div>
      </UserContext.Provider>
    </DashboardWrapper>
  );
};

export default observer(Dashboard);
