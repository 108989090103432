import { Grid, IconButton } from "@material-ui/core";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { GStyledDiv, GStyledH5, GStyledP } from 'components/common/StyledComponents';
import Timer from "components/common/Timer";
import { ConsultationContext } from 'contexts';
import { onUpdateConsultation } from 'gql/subscriptions';
import {
  BTEndCallGreenWhite, BTMicGreenTransparent,
  BTSpeakerGreenTransparent
} from "icons";
import ClientProfilePic from "images/avatar.svg";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { styledTheme } from 'styles/StyledTheme';
import { LawAreas } from "../../../../constant";
import VoiceParticipant from "./VoiceParticipant";

const AudioWrapper = styled.div`
  height: 85vh;
  width: 100%;
  max-width: 90vw;
  border-radius: 16px;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.secondaryDark};

  @media(min-width:500px){
    max-height:750px;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Controls = styled(Grid)`
  > * {
    cursor: pointer;
    transition: all 0.25s;
    &:hover {
      opacity: 0.9;
    }
  }
  .mute {
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    height: 50px;
    width: 50px;

    &.speaker-btn {
      background-image: url(${require('icons/multimedia/muteGreen.svg')});

      &.active {
        background-image: url(${require('icons/multimedia/muteFill.svg')})
      }
    }
    &.mic-btn {
      background-image: url(${require('icons/multimedia/micOff.svg')});    

      &.active {
        background-image: url(${require('icons/multimedia/micGreen.svg')});
      }
    }
  }
`;

const StyledH3 = styled.h3`
  font-size: 17px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
`;

const StyledH5 = styled.h5`
  ${({ color }) => color && `color: ${color}`};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
`

const StyledB = styled.b`
  font-size: 13px;
  margin-right: 3px;
`

const StyledLabel = styled.label`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
`
const StyledDiv = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.colors.secondary}`};
  width: 60px;
  margin-bottom: 5px;
`

const Avatar = styled.img`
  height: 130px;
  width: 130px;
  border-radius: 50%;
`
const StyledIconButton = styled(IconButton)`
  border-radius: 50%;
  ${({ margin }) => margin && `margin: ${margin}`};
  .overlay, .endCallOverlay {
    position: absolute;
    border-radius: 50%;
    opacity: 0.3;
    transition: .2s ease;
  } 
  .overlay {
    height: 88%;
    width: 90%;
    background-color: white;
  }
  .endCallOverlay {
    opacity: 0;
    height: 100%;
    width: 100%;
    ${() => `background-color: ${styledTheme.colors.warning}`}
  }
  :hover .overlay, :hover .endCallOverlay {
    opacity: 1;
  }
`

const StyledP = styled.p`
  margin-top: 30%;
  font-size: 12px;
  ${() => `color: ${styledTheme.colors.secondaryDark}`}
`

const HiddenVideo = styled(VoiceParticipant)`
  display: none;
`

const AudioConsultation = ({ details, room, participant }) => {
  const {
    handleComplete,
    activeConsultation,
    onConsultationComplete,
    setLastSeen,
    lastSeen,
    isClientOnline,
  } = useContext(ConsultationContext);

  const [avatar, setAvatar] = useState(null);

  const [isMuted, setMuted] = useState(false)
  const [toggleMute, setToggleMute] = useState(false)
  const [isSpeakerOn, setIsSpeakerOn] = useState(true)
  const [toggleSpeaker, setToggleSpeaker] = useState(false)
  const [actualLength, setActualLength] = useState(0);

  let {
    client: { firstName, lastName, profilePictureUrl },
    areaOfLaw,
    state,
    timeLimit,
    consultation
  } = details;

  useEffect(() => {
    let subscription = API.graphql(graphqlOperation(onUpdateConsultation))
      .subscribe({
        next: async (consultation) => {
          const updatedConsultation =
            consultation.value.data.onUpdateConsultation;
          const { actualLength } = updatedConsultation;
          setActualLength(actualLength);
          if (
            updatedConsultation &&
            updatedConsultation.id === activeConsultation.id
          ) {
            if (updatedConsultation.status === 'Completed') {
              onConsultationComplete(updatedConsultation.actualLength);
            } else {
              setLastSeen(
                lastSeen !== updatedConsultation.lastOnlineClient ?
                  updatedConsultation.lastOnlineClient :
                  null
              );
            }
          }
        }
      }
      )
    return () => {
      subscription.unsubscribe();
    }
  }, [])

  useEffect(() => {
    if (room) {
      setIsSpeakerOn(true);
    }
  }, [room])

  useEffect(() => {
    if (profilePictureUrl)
      attachAvatar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilePictureUrl])

  async function attachAvatar() {
    setAvatar(await Storage.get(profilePictureUrl, {
      level: "public"
    }
    ))
  }

  return (
    <AudioWrapper>
      {
        participant &&
        <HiddenVideo
          r="remote"
          key={participant.sid}
          participant={participant}
          toggleSpeaker={toggleSpeaker}
          setIsSpeakerOn={setIsSpeakerOn}
          isSpeakerOn={isSpeakerOn}
          setMuted={() => { }}
        />
      }
      {
        room && (
          <HiddenVideo
            r="local"
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            toggleSpeaker={toggleSpeaker}
            setIsSpeakerOn={setIsSpeakerOn}
            isSpeakerOn={isSpeakerOn}
            toggleMute={toggleMute}
            setMuted={setMuted}
            isMuted={isMuted}
          />
        )}
      {/* FIRST ROW */}
      <Grid container row justify="space-around" alignItems="center">
        {/* TIMER */}
        <div align="center">
          <Timer initialTime={actualLength} timeLimit={timeLimit} isClientOnline={isClientOnline} />
          <StyledDiv />
          <StyledLabel>
            TIME
          </StyledLabel>
        </div>
        {/* CLIENT META */}
        <div align="center">
          <Avatar src={avatar || ClientProfilePic} alt="sophie" />
          <GStyledDiv
            textAlign='center'
            paddingTop='20px'
          >
            <StyledH3>
              {firstName} {lastName} <span style={{ fontSize: 12 }}>({isClientOnline ? 'Online' : 'Offline'})</span>
            </StyledH3>
            <StyledH5
              color={styledTheme.colors.secondary}
              fontSize='12px'
            >
              <b>{LawAreas[areaOfLaw]}</b> | {state}
            </StyledH5>
          </GStyledDiv>
        </div>
        {/* EARNINGS */}
        {
          details.payment?.calculatedAttorneyAmount !== 0 ? (
            <div align="center">
              <GStyledH5 color={styledTheme.colors.white}>
                <StyledB> $</StyledB>{details.payment?.calculatedAttorneyAmount.toFixed(2)}
              </GStyledH5>
              <StyledDiv />
              <StyledLabel>
                EARNINGS
              </StyledLabel>
            </div>)
            : (<div align="center">
              <div style={{ width: "60px" }}></div>
            </div>)
        }
      </Grid>
      {/* SPACER */}
      <div></div>
      {/* LAST ROW */}
      <Controls container justify="center" alignItems="center">
        <StyledIconButton
          size="small"
          style={{ borderRadius: '50%' }}
          onClick={() => {
            setToggleMute(!toggleMute)
          }}
        >
          <span
            className="mute mic-btn active"
            src={BTMicGreenTransparent}
            alt="mute"
          />
          <div className='overlay'>
            <StyledP>{isMuted ? 'mute' : 'unmute'}</StyledP>
          </div>
        </StyledIconButton>
        <StyledIconButton
          margin='0 15px'
          size="small"
          style={{ borderRadius: '50%' }}
          onClick={handleComplete}
        >
          <img
            className="disconnect"
            src={BTEndCallGreenWhite}
            alt="disconnect"
          />
          <div className='endCallOverlay'>
            <GStyledP marginTop='35%' fontSize='16px' color={styledTheme.colors.white}>end call</GStyledP>
          </div>
        </StyledIconButton>
        <StyledIconButton
          size="small"
          style={{ borderRadius: '50%' }}
          onClick={() => {
            setToggleSpeaker(!toggleSpeaker)
          }}
        >
          <span
            className="mute speaker-btn active"
            src={BTSpeakerGreenTransparent}
            alt="speaker"
          />
          <div className='overlay'>
            <StyledP>{isSpeakerOn ? 'on' : 'off'}</StyledP>
          </div>
        </StyledIconButton>
      </Controls>
    </AudioWrapper>
  )
};

export default AudioConsultation;
