import { IActiveRequest } from "interfaces/acceptConsultationRequest";
import useStore from "utils/useStore";

export function useFutureConsultationSetting() {
  const { consultationStore } = useStore();

  function onClickFutureConsultation(activeRequest: IActiveRequest): void {
    if (consultationStore?.selectedActiveRequestId === activeRequest?.id) {
      consultationStore.setSelectedActiveRequestId(undefined);
    } else {
      consultationStore.setSelectedActiveRequestId(activeRequest.id);
    }
  }
  return {
    onClickFutureConsultation,
  };
}
