import React from 'react'
import { Avatar, Grid, Paper } from '@material-ui/core'
import ClientProfilePic from 'images/ClientProfilePic.png'
import styled from 'styled-components'
import { OKGreen, CancelRed } from 'icons'
import { styledTheme } from '../../../../styles/StyledTheme'

const PaperWrapper = styled(Paper)`
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  background-color: ${ ({ theme }) => theme.colors.defaultLight };
  text-align:center;
  height:100%;
  border-radius:10px;
  @media(max-width:1279px){
    margin-top:50px;
    
  }
  .avatar{
    height:80px;
    width:80px;
  }
`

const StyledH3 = styled.h3`
  font-size: 18px;
  margin-top: 20px;
  font-weight: bold;
`
const StyledH4 = styled.h4`
  font-size: 14px;
  font-weight: bold;
`

const StyledH5 = styled.h5`
  font-size: 12px;
  color: ${ ({ theme }) => theme.colors.secondaryDark };
  margin-top: 10px;
`

const StyledP = styled.p`
  ${({color}) => color && `color: ${color}`};
  ${({fontSize}) => fontSize && `font-size: ${fontSize}`};
  ${({marginTop}) => marginTop && `margin-top: ${marginTop}`};
`

const GridWrapper = styled(Grid)`
  height:80%;
  @media(max-width:1279px){
    height:auto;
  }
` 
const ConflictNotFound = styled(Grid)`
  background-color: ${ ({ theme }) => theme.colors.secondary };
  color: white;
  border-radius:18px;
  padding:20px;
  p{
    margin:0px;
  }
  img{
    width:20px;
    
  }
`
const ConflictFound = styled(Grid)`
  background-color: white !important;
  margin-top:20px;
  border-radius:18px;
  padding:20px;
  p{
    margin:0px;
  }
  
  img{
    width:30px;
    margin-left:-5px;
    margin-right:5px;
  }
`
const Wrapper = styled.div`
  text-align:left;
  margin-top:40px;
  width:80%;
  strong{
    font-size:12px;
    color: ${ ({ theme }) => theme.colors.dark };
  }
  @media(max-width:810px){
    width:90%;
  }

`
const ConflictCheck = () => {

  return(
    <GridWrapper item xs={12} md={12} lg={11}>
      <PaperWrapper>
        <Avatar className="avatar"  alt="Cindy Baker" src={ClientProfilePic} />
        <StyledH3>Sophie Clark</StyledH3>
        <StyledH5><b>Family Law |</b> Custody</StyledH5>
        <StyledP
          fontSize= '12px'
          color={ styledTheme.colors.defaultDarker }
        >
          Question from the client lorem ipsum dolor sit amet,<br/> consectetur adipiscing elit, sed do eiusmod?
        </StyledP>
        <br />
        <Wrapper>
          <strong>Conflict check required</strong>
          <StyledP 
            color={ styledTheme.colors.defaultDarker }
            fontSize= '12px'
            marginTop= '4px'
          >
            Please complete the conflict check before proceeding. Select one of the following options:
          </StyledP>
          <ConflictNotFound container >
            <div>
              <img src={OKGreen} alt='ok' />
            </div>
            <div style={{marginLeft:"10px"}}>
              <StyledH4>Conflict not found</StyledH4>
              <StyledP 
                fontSize= '12px'
              >
                Chose this option if you can provide help on this consultation.<br/><b>The interaction will start.</b>
              </StyledP>
            </div>
          </ConflictNotFound>
          <ConflictFound container>
            <div>
              <img src={CancelRed} alt='CancelRed'/>
            </div>
            <div>
              <StyledH4>Conflict found</StyledH4>
              <StyledP 
                fontSize= '12px'
              >
                Chose this option if you are not able to proceed with consultation.<br/><b>Notice that the interaction will end.</b>
              </StyledP>
            </div>
          </ConflictFound>
        </Wrapper>
      </PaperWrapper>
    </GridWrapper>
  )
}

export default ConflictCheck