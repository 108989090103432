import get from "lodash/get";
import moment, { Moment } from "moment";
import { dateTimeLabel } from "../../constant/datetime";
import { IRangeObject } from "../../interfaces";

export function getLastDays(numberOfLastDay: number): number[] {
  const days = Array.from({ length: numberOfLastDay })
    .map((_: number, index: number) =>
      moment().subtract(index, "day").toDate().getDate()
    )
    .reverse();
  return days;
}

export function getLastMonths(numberOfLastMonth: number): number[] {
  const months = Array.from({ length: numberOfLastMonth })
    .map((_: number, index: number) =>
      moment().subtract(index, "month").toDate().getMonth()
    )
    .reverse();
  return months;
}

export function getLastWeeks(numberOfWeeks: number): IRangeObject<Moment>[] {
  const END_DATE_PERIOD = 7;
  const weeksRange = Array.from({ length: numberOfWeeks })
    .map((_, index: number) => {
      const toDate: Moment = moment().subtract(END_DATE_PERIOD * index, "days");
      const fromDate: Moment = moment().subtract(
        END_DATE_PERIOD * index,
        "days"
      );
      const weekRange: IRangeObject<Moment> = {
        to: toDate,
        from: fromDate.subtract(6, "days"),
        isRange: true,
      };

      return weekRange;
    })
    .reverse();
  return weeksRange;
}

export function getLastDaysWithLabel(numberOfLastDay: number): string[] {
  const days = Array.from({ length: numberOfLastDay })
    .map((_: number, index: number) =>
      moment().subtract(index, "day").format("DD/MM")
    )
    .reverse();
  return days;
}

export function getLastMonthsWithLabel(numberOfLastMonth: number): string[] {
  const months = Array.from({ length: numberOfLastMonth })
    .map((_: number, index: number) =>
      get(
        dateTimeLabel,
        moment().subtract(index, "month").toDate().getMonth(),
        ""
      )
    )
    .reverse();
  return months;
}

export function getLastWeeksWithLabel(numberOfWeeks: number): string[] {
  const END_DATE_PERIOD = 7;
  const weeksRange = Array.from({ length: numberOfWeeks })
    .map((_, index: number) => {
      const toDate: Moment = moment().subtract(END_DATE_PERIOD * index, "days");
      const fromDate: Moment = moment().subtract(
        END_DATE_PERIOD * index,
        "days"
      );
      const weekRange: IRangeObject<string> = {
        to: toDate.format("DD/MM"),
        from: fromDate.subtract(6, "days").format("DD"),
        isRange: true,
      };
      return `${weekRange.from}-${weekRange.to}`;
    })
    .reverse();
  return weeksRange;
}
