import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import styled from 'styled-components'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    fontSize:"10px",
  },
}));

const Wrapper = styled.div`
.MuiList-padding{
  padding:0px !important;
}
.MuiListItem-gutters {
padding:0px;
}
.MuiListItemText-root {
margin:0px;
}
.MuiTypography-body1 {
font-size:10px;
text-transform:uppercase;
font-weight:bold;
color: ${ ({ theme }) => theme.colors.secondaryDark };
}
`
const StyledMenuItem = styled(MenuItem)`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  color: ${ ({ theme }) => theme.colors.secondaryDark };
`

const ListMenu = ({options, selectedIndex, setSelectedIndex}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Wrapper className={classes.root}>
      <List component="nav" aria-label="Device settings">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          onClick={handleClickListItem}
        >
          <ListItemText primary={`${options[selectedIndex]} ⯆`}/>
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{fontSize:"5px"}}
      >
        {options.map((option, index) => (
          <StyledMenuItem 
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </StyledMenuItem>
        ))}
      </Menu>
    </Wrapper>
  );
}

export default ListMenu