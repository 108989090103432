import { CircularProgress } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const Image = styled.img`
  width: 17rem;
  border-radius: 16px;
`

const Spinner = styled(CircularProgress)`
  position: absolute;
`

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`

const Wrapper = styled.div`
  position: relative;
  width: 17rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
`

const PendingMediaMessage = (props) => {
  const { mediaUrl } = props

  return (
    <Container>
      <Wrapper>
        <Image src={mediaUrl} alt='media' />
        <Spinner />
      </Wrapper>
    </Container>
  )
}

export default PendingMediaMessage
