import React, { useState ,useEffect } from 'react'
import Alert from '@material-ui/lab/Alert';
import styled from 'styled-components'
import { styledTheme } from 'styles/StyledTheme'

const StyledAlert = styled(Alert)`
  justify-content: center;
  ${() => `background-color: ${ styledTheme.colors.white}`};
`

const TestVideo = ({ setModalLoad }) => {
  const [videoStatus, setVideoStatus] = useState('')
  const streamRef = React.useRef(null);

  useEffect(() => {
    get()
    setModalLoad(true)
    return(() => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach( track => {
          track.stop()
        })
      }
    })
  },[])

  const get = async () => {
    const videoPlayer = document.getElementById("myVideo");

    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false,
      }).then(stream => {
        videoPlayer.srcObject = stream;
        streamRef.current = stream;
      }).catch(error => {
        console.log('ERR', error)
        if (error.message === "Permission denied"){ setVideoStatus('Your Camera is blocked') }
        else if (error.message === "Permission denied by system") { setVideoStatus('Your Browser might not have access to your Camera.')}
        else if (error && error == 'NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.'){ setVideoStatus('Your Camera is blocked') }
        else { setVideoStatus('Please check your webcam first') }
      })
  };

  return(
    <div style={{ padding: 10 }}>
      { videoStatus && <StyledAlert severity="error">{ videoStatus }</StyledAlert>}
      <video 
        style={{ backgroundColor: 'black', maxHeight: '75%', width: '100%' }}
        autoPlay={true}
        id='myVideo'
      />
      </div>
  )
}

export default TestVideo