import { Container, ButtonIcon, ButtonLabel } from "./connectButton.styles";

interface IConnectButtonProps {
  iconName: string;
  label: string;
  color: string;
  isOutline?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

const ConnectButton = (props: IConnectButtonProps) => {
  const {
    iconName,
    label,
    color,
    isOutline = false,
    isDisabled,
    onClick,
  } = props;

  return (
    <Container
      onClick={onClick}
      isDisabled={isDisabled}
      isOutline={isOutline}
      customColor={color}
    >
      <ButtonIcon iconName={iconName} />
      <ButtonLabel isOutline={isOutline} customColor={color}>
        {label}
      </ButtonLabel>
    </Container>
  );
};

export default ConnectButton;
