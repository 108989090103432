import Icon from "components/common/Icon";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;
`;

export const LinkedLabel = styled.div`
  padding-right: 16px;
  font-weight: ${styledTheme.colors.primaryBlack};
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
`;

export const BadgeSection = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BadgeContainer = styled.div<{ color: string }>`
  background-color: ${(props) => props?.color};
  min-height: 36px;
  min-width: 52px;
  border-radius: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const BadgeIcon = styled(Icon)`
  img {
    width: 20px;
    height: 20px;
  }
`;
