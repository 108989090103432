import { syncConsultationRequests } from "pages/Dashboard/Consultation/utils";
import { useEffect, useContext, useCallback } from "react";
import { UserContext } from "contexts";
import useStore from "utils/useStore";
import { checkAttorneyFullPermission } from "utils/common";
import { showNotification } from "services/consultationService";
import { BELL_URL } from "constant/common";
import debounce from "lodash/debounce";
import PusherService from "utils/pusher";
import { PusherChannel } from "constant/enum";
import { IAttorneyConsultationRequest } from "interfaces";

const bell = new Audio();
bell.src = BELL_URL;

const pusherService = new PusherService();
export function useConsultationSubscription() {
  const { attorneyStore, subscriptionStore, consultationStore } = useStore();
  const { stripeSubscription } = subscriptionStore;
  const [userInfo, onlineStatus] = useContext(UserContext);

  const showNotificationDebounce = useCallback(
    debounce(() => {
      showNotification();
    }, 1000),
    []
  );

  async function handleNewRequest(
    createConsultationRequest: IAttorneyConsultationRequest
  ): Promise<void> {
    if (!onlineStatus) return;

    const { requestedAttorneys } = createConsultationRequest;
    const allowConsultation = await checkAttorneyFullPermission(
      requestedAttorneys,
      userInfo,
      stripeSubscription
    );

    if (allowConsultation) {
      bell.play().catch((e) => console.error(e));
      showNotificationDebounce();
      syncConsultationRequests(attorneyStore, consultationStore, userInfo);
    }
  }

  useEffect(() => {
    syncConsultationRequests(attorneyStore, consultationStore, userInfo);
  }, [userInfo?.id]);

  useEffect(() => {
    const createdConsultationChannel = pusherService
      .getPusher()
      .subscribe(PusherChannel.CLIENT_CREATE_CONSULTATION_REQUEST);

    const updatedConsultationChannel = pusherService
      .getPusher()
      .subscribe(PusherChannel.CLIENT_UPDATE_CONSULTATION_REQUEST);

    if (userInfo?.id) {
      pusherService.bindWithChunking(
        createdConsultationChannel,
        userInfo.id,
        (consultationRequest) => handleNewRequest(consultationRequest)
      );

      pusherService.bindWithChunking(
        updatedConsultationChannel,
        userInfo.id,
        () => {
          syncConsultationRequests(attorneyStore, consultationStore, userInfo);
        }
      );
    }

    return () => {
      createdConsultationChannel.unsubscribe();
      updatedConsultationChannel.unsubscribe();
    };
  }, [userInfo?.id]);

  useEffect(() => {
    subscriptionStore.getSubscriptionInformation(
      userInfo?.stripeSubscriptionId
    );
    subscriptionStore.validateStrategicPartner(userInfo?.emailWork);
  }, [userInfo?.stripeSubscriptionId, userInfo?.emailWork]);
}
