import React from 'react'
import styled from "styled-components";

const HeaderStyler = styled.div`
margin:auto;
display:flex;
justify-content:space-between;
align-items:center;
`
const Div = styled.div`
    border-top: 2px solid #D8E6ED;
    flex: auto;
    margin: auto;
`
const Heading = styled.h4`
    font-size: 10px;
    text-align: center;
    color: ${ ({theme}) => theme.colors.infoDark };
    text-transform: uppercase;
    padding: 10px 10px 0;
    white-space: pre;
`

export default function CustomHeading({ children,...props }) {

    return (
        <HeaderStyler {...props}>
            <Div></Div>
            <Heading>
                {children}
            </Heading>
            <Div></Div>
        </HeaderStyler>
    )
}
