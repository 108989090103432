export enum CalendarDayCategory {
  ALL_WEEK = "all-week",
  WEEK_DAYS = "week-days",
  CUSTOM = "custom",
}

export enum AttorneyTimeFrameCategory {
  COMMON = 'common',
  OVERRIDE = 'override',
  OFFLINE = 'offline',
}
