const AppleHelper = () => {
  return (
    <>
      <li>
        Sign in to your{" "}
        <a href="https://appleid.apple.com/" target="_blank">
          iCloud
        </a>{" "}
        account.
      </li>
      <li>
        In the <b>Security section</b>, click <b>edit.</b>
      </li>
      <li>
        Click <b>Generate password</b> and follow the step there. Once you’ve
        generated the password, you will be able to connect calendar with
        LegalQ.
      </li>
    </>
  );
};

export default AppleHelper;
