import { redirectAppleHelperPage } from "components/common/CalendarConnect/AppleConnectButton/utils";
import { redirectMicrosoftHelperPage } from "components/common/CalendarConnect/MicrosoftConnectButton/utils";
import { NylasProvider } from "constant/enum";

export function openExternalUrl(nylasProvider: NylasProvider): void {
  if (nylasProvider === NylasProvider.ICLOUD) {
    redirectAppleHelperPage();
  }
  if (nylasProvider === NylasProvider.OUTLOOK) {
    redirectMicrosoftHelperPage();
  }
}
