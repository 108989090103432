import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const AttorneyVideoWrapper = styled(Grid)`
  position: absolute;
  display: flex;
  justify-content: flex-end;

  video {
    border-top-right-radius: 16px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  }
`;
