import Icon from "components/common/Icon";
import ProgressBar from "components/common/ProgressBar";
import Description from "pages/Registration/RegistrationHeader/Description";
import { ReactNode } from "react";
import styled from "styled-components";
import {
  FlexWrapper,
  BackIcon,
  ProgressBlock,
} from "./registrationHeader.styles";

const RegistrationText = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  margin-bottom: 0px;
`;

interface IRegistrationHeaderProps {
  step: number;
  header: ReactNode;
  title: string;
  description: string;
  helpText: string;
  onClickBack?: () => void;
}

const RegistrationHeader = (props: IRegistrationHeaderProps) => {
  const { step, header, helpText, onClickBack, ...rest } = props;
  return (
    <FlexWrapper>
      <div className="topContent">
        <ProgressBlock>
          {onClickBack && (
            <BackIcon
              onClick={onClickBack}
              iconName="back_arrow-white.svg"
              width={18}
              height={18}
            />
          )}
          {step > 0 && step < 4 && (
            <ProgressBar state={Number(step)} width={236} height={36} />
          )}
        </ProgressBlock>

        <div className="logoSection">
          <div className="companyLogo">
            <Icon iconName="white-logo.svg" width={110} height={30} />
          </div>
          {step > 0 && step < 4 && <h6 className="title">Grow your firm.</h6>}
        </div>
      </div>
      <RegistrationText>{header || "Registration"}</RegistrationText>
      <Description helpText={helpText} {...rest} />
    </FlexWrapper>
  );
};

export default RegistrationHeader;
