import { IUnSubscriptionSurvey } from "interfaces/subscription";
import { GetValues, Reset } from "types";
import { toast } from "react-toastify";
import { IUnsubscribeForm } from "./interfaces";
import SubscriptionStore from "stores/subscriptionStore";

export async function handleUnSubscription(
  getValues: GetValues,
  reset: Reset,
  onClickAcceptUnSubscribe: (
    unSubscriptionSurvey: IUnSubscriptionSurvey
  ) => void,
  subscriptionStore: SubscriptionStore
): Promise<void> {
  if (subscriptionStore?.isLoading) return;
  toast.info("Confirming, please wait until complete.", { autoClose: 10000 });
  subscriptionStore.setLoading(true);
  const formValues: IUnsubscribeForm = getValues();
  const newUnSubscriptionSurvey: IUnSubscriptionSurvey = {
    reason: formValues?.reason ?? "",
    otherReason: formValues?.otherReason ?? "",
  };
  try {
    await onClickAcceptUnSubscribe(newUnSubscriptionSurvey);
  } catch (error) {
    toast.dismiss();
    toast.error("Error while un-subscribe, please contact us!");
  }
  reset({});
  subscriptionStore.setLoading(false);
  toast.dismiss();
  toast.success("Unsubscription complete.");
}
