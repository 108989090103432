import moment from "moment";

export function getReviewCount(cases) {
  let reviewCount = 0;
  cases.forEach((c) => {
    if (c.ratings) {
      reviewCount++;
    }
  });

  return reviewCount;
}

export function getRatingByChannel(cases) {
  let total = 0;
  cases.forEach((c) => {
    if (c.ratings) {
      const { overallScore, knowledgeScore, friendlinessScore } = c.ratings;
      const totalScore = overallScore + knowledgeScore + friendlinessScore;
      total += totalScore / 3;
    }
  });

  if (total === 0) return 0;

  return total ? (total / cases.length).toFixed(1) : 0;
}

export function getGeneralRating(cases) {
  let total = 0;
  cases.forEach((c) => {
    if (c.ratings) {
      const { overallScore, knowledgeScore, friendlinessScore } = c.ratings;
      const totalScore = overallScore + knowledgeScore + friendlinessScore;
      total += totalScore / 3;
    }
  });

  if (total === 0) return 0;

  return (total / cases.filter(c => c.ratings).length).toFixed(1);
}

export function getLastMonths(lastMonths) {
  const months = [];

  for (let index = 1; index <= lastMonths; index++) {
    const date = moment().subtract(lastMonths - index, "months");
    months.push(`${date.format("MMMM")} ${date.format("YYYY")}`);
  }

  return months.reverse();
}

export function getLastMonthsIndexes(lastMonths) {
  const months = [];

  for (let index = 0; index <= lastMonths; index++) {
    const date = moment().subtract(lastMonths - index, "months");
    months.push(`${date.month()}`);
  }

  return months.reverse();
}
