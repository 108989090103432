import Loader from "react-loader-spinner";
import { styledTheme } from "styles/StyledTheme";
import {
  AcceptButton,
  Container,
  CancelButton,
  CancelWrapper,
  AcceptWrapper,
  LoadingStyle,
} from "./footerSection.styles";

interface IFooterSectionProps {
  cancelButtonText: string;
  acceptButtonText: string;
  disabled: boolean;
  onClickCancel: (event) => void;
  onClickAccept: (event) => void;
}

const FooterSection = (props: IFooterSectionProps) => {
  const {
    cancelButtonText,
    acceptButtonText,
    disabled,
    onClickCancel,
    onClickAccept,
  } = props;

  return (
    <Container xl={12} lg={12} md={12} sm={12} xs={12}>
      <CancelWrapper xl={4} lg={4} md={4} sm={4} xs={4}>
        <CancelButton
          disabled={disabled}
          color="primary"
          onClick={onClickCancel}
        >
          {cancelButtonText}
        </CancelButton>
      </CancelWrapper>
      <AcceptWrapper xl={8} lg={8} md={8} sm={8} xs={8}>
        <AcceptButton
          disabled={disabled}
          color="primary"
          onClick={onClickAccept}
        >
          {disabled ? (
            <>
              <Loader
                type="Oval"
                color={styledTheme.colors.white}
                height={14}
                width={14}
              />
              <LoadingStyle>Loading</LoadingStyle>
            </>
          ) : (
            acceptButtonText
          )}
        </AcceptButton>
      </AcceptWrapper>
    </Container>
  );
};

export default FooterSection;
