export default {
  howItWorks: [
    {
      title: 'What is LegalQ?',
      summary: `LegalQ is a digital platform that connects users with attorneys for on-demand legal advice.  Users can submit legal questions and connect to an attorney for a limited scope consultation in real-time via text, voice, or video chat – all in the app!

      Users obtain legal advice at affordable, fixed rates, while attorneys earn competitive pay and an opportunity to grow their client base.
      
      LegalQ makes connecting with an attorney simple, accessible, and worth every penny.
      `
    },
    {
      title: 'What kind of support is offered to attorneys?',
      summary: `Our <a target="_blank" href="mailto:support@legalq.io">Customer Success Team</a> is available to assist you and ensure you get the most out of LegalQ. You may also visit our <a target="_blank" href="https://legalq.io/contact-legalq">Contact Us</a> page.
      `
    },
    {
      title: 'Can I choose which method I want to be available (text, voice, video)? Or do I need to be willing to do all three?',
      summary: `Yes, you can choose whether to be available via text, voice, or video chat.  Selecting all three options increases the potential traffic of incoming requests you receive.  You can modify your selection at any time.
      `
    },
    {
      title: 'What are the requirements for attorneys to join LegalQ?',
      summary: `You must be licensed to practice law in Minnesota.  Attorneys must also be active in good standing with the Minnesota bar.  Not in your state?  <a target="_blank" href="https://legalq.io/attorneys">Get on the waitlist</a>.
      `
    },
    {
      title: 'As a retired attorney I want to stay in the game. Can I use LegalQ?',
      summary: `Yes, as long as you meet the requirements to join.
      `
    },
    {
      title: 'I’m recently admitted to the Bar. Can I use LegalQ?',
      summary: `Yes, as long as you meet the requirements to join.
      `
    },
    {
      title: 'How do I receive consultation requests?',
      summary: `You can view incoming consultation requests on your attorney dashboard. If you’d like to receive on-demand consultation requests, simply change your status to "Online" and LegalQ will notify you (by a ping noise as well as an icon in the dashboard) of incoming requests. When you don’t want to receive incoming requests, you can change your status back to "Offline" – it’s that easy!
      `
    },
    {
      title: 'I’m receiving consultation requests not relevant to my practice.  What should I do?',
      summary: `Incoming inquiry notifications are sent out based on the information in your profile and the practice areas that you have selected. If you are receiving consultation requests that don't match your practice areas, you can edit your profile and unselect the unwanted practice areas.
      `
    },
  ],
  payment: [
    {
      title: 'Please email us at contact@legalq.io for questions about payment.',
      summary: `LegalQ charges the user a flat rate of $39 for a 15-minute consultation or $59 for a 30-minute consultation. Attorneys receive 60% of these transactions. You can track your earnings for consultations on your attorney dashboard. LegalQ initiates payments on the 5th day of the month for the prior month's earnings.
      `
    },
    {
      title: 'What is LegalQ\'s Fee Dispute policy?',
      summary: `LegalQ offers a partial or full refund to users that dispute the amount charged for a consultation for any reason. The user is required to report the dispute within forty-eight (48) hours after the consultation.
      `
    },
  ],
  reviews: [
    { 
      title: 'How do reviews work?',
      summary: `LegalQ encourages, but does not require users to rate their experience. The ratings support LegalQ’s commitment to providing a platform that serves the needs of our users. The areas rated on a 5 star basis are: Overall, Knowledge, and Communication.
      `
    },
  ],
  account: [
    {
      title: 'What areas of law does LegalQ cover?',
      summary:`The current list of legal categories include:
      1.	Debt & Bankruptcy Law
      2.	Business/Corporate Law
      3.	Criminal Defense (includes DUI/DWI)
      4.	Employment & Labor Law
      5.	Family & Divorce
      6.	General Practice
      7.	Immigration Law
      8.	Intellectual Property
      9.	Landlord-Tenant
      10.	Personal Injury
      11.	Probate & Trust Law 
      12.	Real Estate Law
      13.	Social Security Disability Law
      14.	Tax Law
      `
    },
    {
      title: 'How do I use my LegalQ Dashboard?',
      summary:`For guidance on how to navigate the LegalQ Attorney Dashboard, please watch the dashboard walkthrough.
      `
    },
    {
      title: 'How can I quit LegalQ?',
      summary:`If you wish to stop consulting on LegalQ you can simply change the status on your attorney dashboard to "Offline".  You also have the option to delete your account by <a target="_blank" href="mailto:support@legalq.io">emailing us</a> – no long-term commitment necessary!
      `
    },
  ]
}