import { Container, CustomInput, ErrorMessage } from "./commonInput.styles";

interface ICommonInputProps {
  placeholder: string;
  onChange?: (event) => void;
  value?: string;
  errorMessage?: any;
}

const CommonInput = (props: ICommonInputProps) => {
  const { placeholder, onChange, value, errorMessage } = props;

  return (
    <Container>
      <CustomInput
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};

export default CommonInput;
