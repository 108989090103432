export enum PusherAppKey {
  DEVELOP = "f4dd72beb24629939b73",
  PRODUCTION = "e636b9345d4fca2b069b",
  STAGING = "f067edeca0789a79c744",
}

export enum PusherChannel {
  //INFO: Attorney channel
  ATTORNEY_REJECT_CONSULTATION_REQUEST = "attorneyRejectConsultationRequest",
  ATTORNEY_SYNC_ONLINE_STATUS = "attorneySyncOnlineStatus",
  //INFO: Client channel
  CLIENT_ONBOARDING_CONSULTATION_RECHARGE = "clientOnboardingConsultationRecharge",
  CLIENT_CREATE_CONSULTATION_REQUEST = "clientCreateConsultationRequest",
  CLIENT_UPDATE_CONSULTATION_REQUEST = "clientUpdateConsultationRequest",
  CLIENT_UPDATE_ACTIVE_REQUEST = "clientUpdateActiveRequest",
}
