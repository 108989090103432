import { Grid } from "@material-ui/core";
import Calendar from "react-calendar";
import styled from "styled-components";
import { breakpoints } from "styles/layout";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)`
  padding: 24px;
  background-color: ${styledTheme.colors.backgroundBlue};
  border-radius: 16px;
  max-width: 414px;

  ${breakpoints("margin-top", [
    {
      xxl: "0px",
    },
    {
      xl: "24px",
    },
    {
      lg: "24px",
    },
    {
      md: "24px",
    },
    {
      sm: "24px",
    },
    {
      xs: "24px",
    },
  ])}
`;

export const ReactCalendar = styled(Calendar)`
  border: none;
  width: initial !important;
  background-color: ${styledTheme.colors.backgroundBlue};

  [class*="react-calendar__month-view__days__day"] {
    height: 48px;
    width: 48px;
    border-radius: 16px;
    color: ${styledTheme.colors.secondaryDark};

    font-family: ${styledTheme.fonts.Inter};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    &:hover {
      background-color: ${styledTheme.colors.secondaryDark};
      color: ${styledTheme.colors.white};
    }
  }

  [class*="react-calendar__tile--now"] {
    background-color: ${styledTheme.colors.backgroundBlue};
    border-color: ${styledTheme.colors.secondaryDark};
    color: ${styledTheme.colors.secondaryDark};
    border: 1px solid #5390d2;

    &:hover {
      background-color: ${styledTheme.colors.secondaryDark};
      color: ${styledTheme.colors.white};
    }
  }

  [class*="react-calendar__tile--active"] {
    background-color: ${styledTheme.colors.secondaryDark};
    border-color: ${styledTheme.colors.secondaryDark};
    color: ${styledTheme.colors.white};

    &:hover {
      background-color: ${styledTheme.colors.secondaryDark};
      color: ${styledTheme.colors.white};
    }

    &:focus {
      background-color: ${styledTheme.colors.secondaryDark};
      color: ${styledTheme.colors.white};
    }
  }

  [class*="react-calendar__month-view__days__day--neighboringMonth"] {
    color: ${styledTheme.colors.gray300};
  }

  [class*="react-calendar__navigation__arrow"] {
    &:hover {
      background-color: ${styledTheme.colors.secondaryDark} !important;
      color: ${styledTheme.colors.white} !important;
    }
  }

  [class*="react-calendar__navigation__label"] {
    font-family: ${styledTheme.fonts.Inter};
    font-style: normal;
    font-weight: bold;

    color: ${styledTheme.colors.primaryBlack};
    background-color: ${styledTheme.colors.backgroundBlue} !important;

    &:hover {
      background-color: ${styledTheme.colors.backgroundBlue} !important;
      color: ${styledTheme.colors.primaryBlack} !important;
    }
  }

  [class*="react-calendar__year-view__months__month"] {
    &:hover {
      background-color: ${styledTheme.colors.secondaryDark} !important;
      color: ${styledTheme.colors.white} !important;
    }
  }

  [class*="react-calendar__decade-view__years__year"] {
    &:hover {
      background-color: ${styledTheme.colors.secondaryDark} !important;
      color: ${styledTheme.colors.white} !important;
    }
  }

  [class*="react-calendar__century-view__decades__decade"] {
    &:hover {
      background-color: ${styledTheme.colors.secondaryDark} !important;
      color: ${styledTheme.colors.white} !important;
    }
  }
`;

export const Divider = styled.hr`
  border: 1px solid ${styledTheme.colors.gray200};
`;
