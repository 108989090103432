export const onUpdateCase = /* GraphQL */ `
  subscription OnUpdateCase {
    onUpdateCase {
      id
      client {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        email
        phone
        profilePictureUrl
        promoCredits
        birthday
        gender
        permanentStripePaymentToken
        cognitoId
        createdAt
        updatedAt
      }
      attorney {
        id
      }
      venueState
      legalAdviceDescription
      areaOfLaw
      payment {
        id
        paymentMethod
        dateTime
        amount
        stripeToken
        createdAt
        updatedAt
        owner
      }
      consultationConnectionMethod
      futureConsultationDateTime
      createdAt
      updatedAt
    }
  }
`;
export const onBriefUpdateCase = /* GraphQL */ `
  subscription onBriefUpdateCase {
    onUpdateCase {
      id
      client {
        id
        cognitoId
        firstName
        lastName
        profilePictureUrl
      }
      payment {
        id
        calculatedAttorneyAmount
      }
      legalAdviceDescription
      areaOfLaw
      consultationConnectionMethod
      venueState
      attorney {
        id
        cognitoId
      }
      rating {
        id
      }
      futureConsultationDateTime
      createdAt
      updatedAt
    }
  }
`;

export const onCreateAttorneyConsultationRequest = /* GraphQL */ `
  subscription OnCreateAttorneyConsultationRequest {
    onCreateAttorneyConsultationRequest {
      id
      requestedAttorneys
      createdAt
      updatedAt
      expiredTime
      selectedAttorney {
        id
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        futureConsultationDateTime
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          profilePictureUrl
        }
        payment {
          id
          calculatedAttorneyAmount
        }
      }
      consultation {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        lastOnlineClient
        lastOnlineAttoney
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const onUpdateAttorneyConsultationRequest = /* GraphQL */ `
  subscription OnUpdateAttorneyConsultationRequest {
    onUpdateAttorneyConsultationRequest {
      id
      requestedAttorneys
      createdAt
      updatedAt
      expiredTime
      selectedAttorney {
        id
      }
      case {
        id
        venueState
        legalAdviceDescription
        areaOfLaw
        consultationConnectionMethod
        futureConsultationDateTime
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          profilePictureUrl
        }
        payment {
          id
          calculatedAttorneyAmount
        }
      }
      consultation {
        id
        connectionMethod
        dateTimeOccurred
        videoConnectionToken
        chatConnectionToken
        conversationTranscript
        status
        clientPrefferedConnectionMethod
        purchasedLength
        actualLength
        lastOnlineClient
        lastOnlineAttoney
        createdAt
        updatedAt
        owner
      }
    }
  }
`;

export const onUpdateConsultation = /* GraphQL */ `
  subscription OnUpdateConsultation {
    onUpdateConsultation {
      id
      connectionMethod
      dateTimeOccurred
      videoConnectionToken
      chatConnectionToken
      conversationTranscript
      status
      clientPrefferedConnectionMethod
      purchasedLength
      actualLength
      lastOnlineClient
      lastOnlineAttoney
      createdAt
      updatedAt
      owner
    }
  }
`;
