import { api } from "API";
import { AxiosResponse } from "axios";
import { IAttorneyTimeFrame } from "interfaces";
import { IFilter } from "types";
import { errorHandler } from "utils/errorHandler";

export async function createAttorneyTimeFrame(
  attorneyTimeFrame: IAttorneyTimeFrame
): Promise<void> {
  try {
    await api.post(`/attorney-time-frames`, attorneyTimeFrame);
  } catch (error) {
    throw error?.response?.data?.error;
  }
}

export async function fetchAttorneyTimeFrames(
  filter: IFilter<IAttorneyTimeFrame>
): Promise<IAttorneyTimeFrame[]> {
  try {
    const attorneyTimeFrames: AxiosResponse = await api.get(
      `/attorney-time-frames?filter=${JSON.stringify(filter)}`
    );
    return attorneyTimeFrames?.data;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function deleteAttorneyTimeFrameById(
  attorneyTimeFrameId: string
): Promise<void> {
  if (!attorneyTimeFrameId) {
    throw new Error("attorneyTimeFrameId is required, please contact us");
  }

  try {
    await api.delete(`/attorney-time-frames/${attorneyTimeFrameId}`);
  } catch (error) {
    throw error?.response?.data?.error;
  }
}

export async function updateOfflineAttorneyTimeFrame(
  attorneyTimeFrame: IAttorneyTimeFrame
): Promise<void> {
  try {
    await api.post(`/attorney-time-frames/offline`, attorneyTimeFrame);
  } catch (error) {
    throw error?.response?.data?.error;
  }
}