import { Grid } from "@material-ui/core";
import Icon from "components/common/Icon";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;
`;

export const Title = styled.div`
  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${styledTheme.colors.gray600};
  cursor: default;
`;

export const CloseButton = styled(Icon)`
  cursor: pointer;
  margin-right: 24px;
`;
