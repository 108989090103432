import React, { useState } from "react";
import { changePassword } from "../../../services/auth-service";

// components
import { Grid, Paper } from "@material-ui/core";
import styled from "styled-components";
import EditableInput from "components/common/EditableInput";
import CustomHeading from "components/common/CustomHeading";
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomButton from "../../../components/common/Custom-button";

const PaperWrapper = styled(Paper)`
  overflow: hidden;
  min-height: 330px;
  font-size: 22px;
  background-color: ${ ({ theme }) => theme.colors.white };
  border-radius: 18px;
  .wrapper {
    width: 80%;
    margin: auto;
    padding: 20px 0px;
    p {
      font-size: 16px;
      margin: 0px !important;
    }
    hr {
      margin-bottom: 0px !important;
    }
    label {
      font-size: 10px;
      font-weight: bold;
      color: ${ ({ theme }) => theme.colors.defaultDark };
    }
  }
`;

const initProps = {
  currtPass: "",
  newPass: "",
  oldPass: ""
};

const PersonalInformation = () => {

  const [form, setForm] = useState({...initProps});
  const [errors, setErrors] = useState({...initProps});
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  function changeHandler(key, val) {
    setForm({...form, [key]: val})
    setErrors({...errors, [key]: ''})
  }

  async function onSubmit() {
    let errors = {};

    const {currPass, newPass, confirmPass} = form;

    !currPass && (errors.currPass = 'required');
    
    (!newPass && (errors.newPass = 'required')) || 
    (newPass.length < 8 && (errors.newPass = 'at least 8 characters'));
    
    (!confirmPass && (errors.confirmPass = 'required')) || 
    (confirmPass !== newPass && (errors.confirmPass = 'password does not match'));

    setErrors(errors)
    if(Object.keys(errors).length > 0)
      return;

    setLoading(true);
    let res = await changePassword(currPass, newPass);
    setLoading(false);
    setErrorMsg(res.error);
    setSuccessMsg(res.success);
    setShowAlert(true);
    if(res.success) setForm({...initProps});
  }

  return (
    <Grid item lg={4} md={6} xs={12}>
      <CustomHeading style={{ marginBottom: "20px" }}>
        CHANGE PASSWORD
      </CustomHeading>

      <PaperWrapper square>
        <Collapse in={showAlert}>
          <Alert
            severity={successMsg ? "success" : "error"}
            onClose={() => setShowAlert(false)}
          >
            {errorMsg || successMsg}
          </Alert>
        </Collapse>
        <div className="wrapper"onKeyUp={(e) => e.key === 'Enter' && onSubmit()}>
          <EditableInput
            type="password"
            title="CURRENT PASSWORD"
            onChange={(val) => changeHandler('currPass', val)}
            alwaysEnabled={true}
            errorMsg={errors.currPass}
          />
          <div style={{marginTop: 20}}>
            <EditableInput
              type="password"
              title="NEW PASSWORD"
              onChange={(val) => changeHandler('newPass', val)}
              alwaysEnabled={true}
              errorMsg={errors.newPass}
            />
          </div>
          <div style={{marginTop: 20}}>
            <EditableInput
              type="password"
              title="CONFIRM PASSWORD"
              onChange={(val) => changeHandler('confirmPass', val)}
              alwaysEnabled={true}
              errorMsg={errors.confirmPass}
            />
          </div>
          <div align="center" style={{paddingTop: 20, minHeight:36}}>
            {!loading &&
              <CustomButton
                variant="contained"
                style={{
                  padding: "10px 27px",
                  width: 'unset',
                  boxShadow: "2px 25px 20px -18px rgba(33, 111, 182)",
                  fontSize: "12px",
                }}
                disabled={loading}
                onClick={onSubmit}
              >
                Submit
              </CustomButton>
            }
            {loading && <CircularProgress size={24}/>}
          </div>
        </div>
      </PaperWrapper>
    </Grid>
  );
};

export default PersonalInformation;
