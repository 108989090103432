import React from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { styledTheme } from '../../styles/StyledTheme'

const PercentageWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 800px) {
    display: inline-block;
  }
`;

const TitleGrid = styled(Grid)`
  font-size: 10px;
  color: ${ ({ theme }) => theme.colors.defaultDark };
  margin-top: 10px;
  width: 25%;
`

const PercentSpan = styled.span`
  @media (max-width: 800px) {
      display: none;
  }
`

const PercentItem = styled.span`
  font-size: 10px;
  padding: 5px 10px;
  white-space: pre;
  flex: 1 1 auto;
  ${({width}) => `width: ${width}%;`};
  ${({backgroundColor}) => backgroundColor && `background-color: ${backgroundColor}`};
  ${({marginRight}) => marginRight && `margin-right: ${marginRight}`};
  ${({borderRadius}) => borderRadius && `border-radius: ${borderRadius}`};
`;

const StyledGrid = styled(Grid)`
  width: 100%;
  color: white;
  margin-top: 10px;
`

const PercentageBar = ({
  title = null,
  text,
  voice,
  video,
  showPercentage = true,
}) => {
  
  return (
    <PercentageWrapper item xs={12}>
      {title && (
        <TitleGrid
          item
          xs={2}
          container
          wrap="wrap"
          direction="column"
          justify="center"
        >
          {title}
        </TitleGrid>
      )}
      <StyledGrid
        xs={12}
        sm={12}
        lg={title ? 10 : 12}
        item
        container
        wrap="nowrap"
        alignItems="center"
      >
        <PercentItem
          width={ !isNaN(text.percent) ? parseFloat(text.percent - 1) : '' }
          backgroundColor={ styledTheme.colors.secondaryDarker }
          borderRadius= '10px 0px 0px 10px'
          marginRight= '2px'
        >
          {text.value}
          <PercentSpan>
            {showPercentage && ` | ${text.percent} %`}
          </PercentSpan>
        </PercentItem>
        <PercentItem
          width={ !isNaN(voice.percent) ? parseFloat(voice.percent - 1) : '' }
          backgroundColor={ styledTheme.colors.secondaryDark }
          marginRight='2px'
        >
          {voice.value}
          <PercentSpan>
            {showPercentage &&` | ${voice.percent} %`}
          </PercentSpan>
        </PercentItem>
        <PercentItem
          width={ !isNaN(video.percent) ? parseFloat(video.percent - 1) : '' } 
          backgroundColor= { styledTheme.colors.secondary }
          borderRadius= '0px 10px 10px 0px'    
        >
          {video.value}
          <PercentSpan>
            {showPercentage &&` | ${video.percent} %`}
          </PercentSpan>
        </PercentItem>
      </StyledGrid>
    </PercentageWrapper>
  );
};

export default PercentageBar;
