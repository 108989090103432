import {
  AcceptButton,
  Container,
  CancelButton,
  CancelWrapper,
  AcceptWrapper,
} from "./footerSection.styles";

interface IFooterSectionProps {
  cancelButtonText: string;
  acceptButtonText: string;
  disabled: boolean;
  onClickCancel: (event) => void;
  onClickAccept: (event) => void;
}

const FooterSection = (props: IFooterSectionProps) => {
  const {
    cancelButtonText,
    acceptButtonText,
    disabled,
    onClickCancel,
    onClickAccept,
  } = props;

  return (
    <Container xl={12} lg={12} md={12} sm={12} xs={12}>
      <CancelWrapper xl={6} lg={6} md={6} sm={6} xs={6}>
        <CancelButton
          disabled={disabled}
          color="primary"
          onClick={onClickCancel}
        >
          {cancelButtonText}
        </CancelButton>
      </CancelWrapper>
      <AcceptWrapper xl={6} lg={6} md={6} sm={6} xs={6}>
        <AcceptButton
          disabled={disabled}
          color="primary"
          onClick={onClickAccept}
        >
          {disabled ? "Loading" : acceptButtonText}
        </AcceptButton>
      </AcceptWrapper>
    </Container>
  );
};

export default FooterSection;
