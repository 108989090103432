import Pusher, { Channel } from "pusher-js";
import { AMPLIFY_ENV } from "../config";
import { AmplifyEnvironmentEnum, PusherAppKey } from "../constant/enum";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PusherOutput = any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventInput = any;

function getPusherAppKey(): string {
  if (AMPLIFY_ENV === AmplifyEnvironmentEnum.MOBILE) {
    return PusherAppKey.PRODUCTION;
  }
  if (AMPLIFY_ENV === AmplifyEnvironmentEnum.STAGING) {
    return PusherAppKey.STAGING;
  }
  return PusherAppKey.DEVELOP;
}

class PusherService {
  private pusher: Pusher;

  constructor() {
    const credentials = getPusherAppKey();
    this.pusher = new Pusher(credentials, {
      cluster: "mt1",
    });
  }

  public getPusher(): Pusher {
    return this.pusher;
  }

  public bindWithChunking(
    channel: Channel,
    event: string,
    callback: (output?: PusherOutput) => void
  ): void {
    //Allow normal unchunked events.
    channel.bind(event, callback);

    //Allows arbitrarily long messages.
    const events: PusherOutput = {};
    channel.bind("chunked-" + event, (data: EventInput) => {
      const eventKey: string = data.id;
      if (!Object.prototype.hasOwnProperty.call(events, eventKey)) {
        events[eventKey] = { chunks: [], receivedFinal: false };
      }
      const singleEvent = events[eventKey];
      singleEvent.chunks[data.index] = data.chunk;
      if (data.final) singleEvent.receivedFinal = true;
      if (
        singleEvent.receivedFinal &&
        singleEvent.chunks.length === Object.keys(singleEvent.chunks).length
      ) {
        callback(JSON.parse(singleEvent.chunks.join("")));
        delete events[eventKey];
      }
    });
  }
}

export default PusherService;
