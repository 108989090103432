import { Container, Title, CloseButton } from "./areaTag.styles";

interface IAreaTagProps {
  label: string;
  onDelete: () => void;
}

const AreaTag = (props: IAreaTagProps) => {
  const { label, onDelete } = props;
  return (
    <Container>
      <Title>{label}</Title>
      <CloseButton onClick={onDelete} iconName="line-md_remove.svg" />
    </Container>
  );
};

export default AreaTag;
