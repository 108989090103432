import { Button, Grid } from "@material-ui/core";
import Icon from "components/common/Icon";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import { styledTheme } from "styles/StyledTheme";

export const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
`;

export const DatetimeIndicator = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${styledTheme.colors.contentBackground};
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 40px;

  width: fit-content;
`;

export const DateLabel = styled.label`
  margin-bottom: 0px !important;
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: pointer;

  input {
    cursor: pointer;
    font-family: ${styledTheme.fonts.Inter};
    color: ${styledTheme.colors.primaryBlack};
    background-color: ${styledTheme.colors.contentBackground};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    text-align: start;
    border-width: 0px;
    width: 100px;

    &:focus {
      outline: none;
    }
  }
`;

export const TimeLabel = styled.label`
  margin-bottom: 0px !important;
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: pointer;

  input {
    cursor: pointer;
    font-family: ${styledTheme.fonts.Inter};
    color: ${styledTheme.colors.primaryBlack};
    background-color: ${styledTheme.colors.contentBackground};
    font-style: normal;
    font-weight: bold;
    text-align: start;
    font-size: 14px;
    line-height: 120%;
    border-width: 0px;
    width: 80px;

    &:focus {
      outline: none;
    }
  }
`;

export const CalendarIcon = styled(Icon)`
  padding-left: 20px;
  padding-right: 20px;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const ButtonGroup = styled(Grid)`
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RequestChangeButton = styled(Button)`
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 12px;
  min-width: 248px;

  font-family: ${styledTheme.fonts.Inter};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.8px;
  text-transform: initial;
  color: ${styledTheme.colors.white};

  background-color: ${styledTheme.colors.secondaryDark} !important;
  box-shadow: 0px 16px 30px -10px rgba(118, 217, 142, 0.5),
    0px 20px 20px -20px #5390d2;

  &:disabled {
    background-color: #a0aec0 !important;
    color: ${styledTheme.colors.white};
  }
`;

export const CommonLoader = styled(Loader)`
  padding-right: 4px;
`;

export const CancelButton = styled(Button)`
  min-width: 91px;
  padding-top: 16px;
  padding-bottom: 16px;

  font-family: ${styledTheme.colors.red400};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: initial;
  color: ${styledTheme.colors.primaryGray};
  background-color: transparent !important;
`;

export const CalendarContainer = styled.div`
  [class*="react-datepicker__day--selected"] {
    background-color: ${styledTheme.colors.secondaryDark};
    color: ${styledTheme.colors.white} !important;
    font-family: ${styledTheme.fonts.Inter};
  }

  [class*="react-datepicker__day--outside-month"] {
    color: ${styledTheme.colors.secondaryGray} !important;
  }

  [class*="react-datepicker__day react-datepicker__day--"] {
    color: ${styledTheme.colors.secondaryDark};
  }

  [class*="react-datepicker__day-name"] {
    color: ${styledTheme.colors.gray800};
  }

  [class*="react-datepicker__header"] {
    background-color: ${styledTheme.colors.white};
  }
`;
