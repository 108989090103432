import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import styled from 'styled-components'
import { AudioGreenWhite, TextGreenWhite, VideoGreenWhite } from 'icons'

const ReviewsCount = styled.span`
  font-size: 30px;
  span{
    font-size:14px;
  }
`
const MyRatings = styled.span`
  font-size: 30px;
`
const TotalRatings = styled.span`
  font-size: 14px;
`
const PaperStyler = styled(Paper)`
  position:relative;
  color:white;
  font-size:10px;
  background-color:${ ({ color }) => color };
  height:100%;
  border-radius:12px;
  .rating{

  }
  .icon{
    position:absolute;
    left:-14px;
    top:24px;
    img{
      width:30px;
    }
  }
  .border{
    width:0px;
    height:80px;
  }
  .content{
    padding:10px 0px;
  }
`

const ChannelRating = ({reviewCount, myRating, icon, reviewedPercent, color}) => {

  return(
    <PaperStyler color={color}>
        <div className="icon">
        {
              icon === 'text' &&
                <img src={TextGreenWhite} alt='text' />
            }
            {
              icon === 'voice' &&
                <img src={AudioGreenWhite} alt='audio' />
            }
            {
              icon === 'video' &&
                <img src={VideoGreenWhite} alt='video' />
            }
        </div>
        <Grid item className="content" xs={12} container justify="center" alignItems="center">
          <Grid item xs={4} container direction="column" justify="center" alignItems="center">
            <ReviewsCount>
              {reviewCount}
            </ReviewsCount>
            <span className="rating">
              TOTAL
            </span>
          </Grid>
          <Grid item xs={4} container direction="column" justify="center" alignItems="center" >
            <div>
            <MyRatings>{myRating}</MyRatings><TotalRatings>/5.0</TotalRatings>
            </div>
            <span className="rating">
              AVERAGE
            </span>
          </Grid>
          <Grid item xs={4} container direction="column" justify="center" alignItems="center">
            <ReviewsCount>
              {reviewedPercent ? reviewedPercent.toFixed(1): reviewedPercent} <span>%</span> 
            </ReviewsCount>
            <span className="rating">
              USERS RATED
            </span>
          </Grid>
        </Grid> 
            
            
    </PaperStyler>
  )
}

export default ChannelRating