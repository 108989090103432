import { IStripeSubscription } from "interfaces/subscription";
import { IStripeCustomer } from "interfaces/user";
import moment from "moment";
import {
  Container,
  CardNumberWrapper,
  CardNumberLabel,
  CardNumberInput,
  BillingTimeWrapper,
  BillingTimeInput,
  BillingTimeLabel,
  SubscribeButton,
  SubscribeWrapper,
} from "./planInformation.styles";

interface IPlanInformationProps {
  stripeCustomer: IStripeCustomer;
  stripeSubscription: IStripeSubscription;
  onClickUnSubscribe: () => void;
}

const PlanInformation = (props: IPlanInformationProps) => {
  const { stripeCustomer, stripeSubscription, onClickUnSubscribe } = props;
  const cardNumber: string = stripeCustomer?.paymentMethod?.last4
    ? `**** **** **** ${stripeCustomer?.paymentMethod?.last4}`
    : "";
  const nextBillingTime: string = moment
    .unix(stripeSubscription?.current_period_end)
    .format("MM/DD/YYYY");

  return (
    <Container>
      <CardNumberWrapper xl={4}>
        <CardNumberInput disabled value={cardNumber} />
        <CardNumberLabel>Card number</CardNumberLabel>
      </CardNumberWrapper>
      <BillingTimeWrapper xl={4}>
        <BillingTimeInput disabled value={nextBillingTime} />
        <BillingTimeLabel>Next Billing Date</BillingTimeLabel>
      </BillingTimeWrapper>
      <SubscribeWrapper xl={4}>
        <SubscribeButton onClick={onClickUnSubscribe}>
          UnSubscribe
        </SubscribeButton>
      </SubscribeWrapper>
    </Container>
  );
};

export default PlanInformation;
