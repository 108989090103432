import { Modal, Container, Fade, Backdrop } from "@material-ui/core";
import FooterSection from "./components/FooterSection";
import MessageSection from "./components/MessageSection";
import { useStyles } from "./confirmModal.styles";

interface IConfirmModalProps {
  titleText?: string;
  bodyText?: string;
  cancelButtonText?: string;
  acceptButtonText?: string;
  disabled?: boolean;
  isOpen: boolean;
  onClickClose: (event) => void;
  onClickCancel: (event) => void;
  onClickAccept: (event) => void;
}

const ConfirmModal = (props: IConfirmModalProps) => {
  const classes = useStyles();

  const {
    titleText,
    bodyText,
    cancelButtonText,
    acceptButtonText,
    isOpen,
    disabled,
    onClickClose,
    onClickCancel,
    onClickAccept,
  } = props;

  return (
    <Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={onClickClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <MessageSection titleText={titleText} bodyText={bodyText} />
            <FooterSection
              cancelButtonText={cancelButtonText}
              acceptButtonText={acceptButtonText}
              onClickAccept={onClickAccept}
              onClickCancel={onClickCancel}
              disabled={disabled}
            />
          </div>
        </Fade>
      </Modal>
    </Container>
  );
};

export default ConfirmModal;
